<template>
  <section class="innerbanner section section-height-3 border-0" :style="setBackgound">
    <div class="container position-relative pt-5 pb-5-5 mt-5 mb-5">
      <div class="row justify-content-end pt-1 mt-lg-5">
        <div class="col-7 col-md-5 position-relative">
          <ul
            class="breadcrumb d-block ps-2 appear-animation animated fadeInLeftShorterPlus appear-animation-visible"
            data-appear-animation="fadeInLeftShorterPlus"
            data-appear-animation-delay="200"
            style="animation-delay: 200ms"
          >
            <!-- <li><a href="/">Home</a></li> -->
          </ul>
          <h1
            class="position-absolute top-100 left-0 text-color-light font-weight-bold text-6 line-height-3 text-end mt-5-5"
          >
            <span
              class="d-block position-relative z-index-1 pb-5 ps-lg-3 appear-animation animated fadeInLeftShorterPlus appear-animation-visible"
              data-appear-animation="fadeInLeftShorterPlus"
              data-appear-animation-delay="800"
              style="animation-delay: 800ms"
              >{{ post.name }}</span
            >
            <span class="custom-svg-position-1 custom-svg-position-1-variation">
              <svg
                class="svg-fill-color-dark mt-1 appear-animation animated fadeInLeftShorterPlus appear-animation-visible"
                data-appear-animation="fadeInLeftShorterPlus"
                data-appear-animation-delay="400"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 859.45 88.44"
                xml:space="preserve"
                preserveAspectRatio="none"
                style="animation-delay: 400ms"
              >
                <polyline
                  points="7.27,84.78 855.17,84.78 855.17,4.79 84.74,4.79 "
                ></polyline>
              </svg>
            </span>
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    };
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        "background-image":
          "url(" + this.settings.storageUrl + this.settings.banner_image + ")",
        "background-size": "cover",
        "background-position": "center",
      };
    },
  },
};
</script>

<style>
section.section.section-height-3 {
  padding: 73.846px 0;
  padding: 4.6153846154rem 0;
}

section.section {
  background: #f7f7f7;
  border-top: 5px solid #f1f1f1;
  margin: 30px 0;
  padding: 50px 0;
}

.pb-5-5 {
  padding-bottom: 36px !important;
  padding-bottom: 2.25rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.position-relative {
  position: relative !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.left-0 {
  left: 0 !important;
}

.line-height-3 {
  line-height: 1.3 !important;
}

.text-6 {
  font-size: 1.8em !important;
}
.mt-5-5 {
  margin-top: 36px !important;
  margin-top: 2.25rem !important;
}

.fadeInLeftShorterPlus {
  animation-name: fadeInLeftShorterPlus;
}
.appear-animation-visible {
  opacity: 1;
}

.z-index-1 {
  z-index: 1 !important;
}

.custom-svg-position-1 {
  position: absolute;
  top: -38px;
  left: -90px;
  max-height: none;
  width: 50vw;
}

.custom-svg-position-1 > svg {
  height: 5.3vw;
  min-height: 96px;
}

html .svg-fill-color-dark {
  fill: #212529 !important;
}

.innerbanner{
  margin-top: -2rem !important;
}
</style>