<template>
	<section
		class=" cat-section blog-section pb-0 appear-animate"
		v-animate
	>
		<div class="container">
			<h2
				class="section-title heading-border border-0 appear-animate"
				data-animation-name="fadeInUp"
				v-animate
			>{{ $t('Latest News') }}</h2>

			<pv-carousel
				class="appear-animate"
				data-animation-name="fadeIn"
				:options="blogSlider"
				v-animate
			>
				<pv-post-one
					class="swiper-slide"
					v-for="(post,index) in posts"
					:key="'post' + index"
					:post="post"
				></pv-post-one>
			</pv-carousel>

			<hr class="mt-0 m-b-5" />

		</div>
	</section>
</template>

<script>
import PvCarousel from '../Utility/Common/PvCarousel4.vue';
import PvPostOne from './posts/PvPostOne4.vue';
import { blogSlider } from '@/utils/data/carousel';
import axios from 'axios'

export default {
	components: {
		PvCarousel,
		PvPostOne,
	},

	data: function () {
		return {
			blogSlider: blogSlider,
			posts: [],
		}
	},
	mounted: function () {
		this.getPosts();
	},
	methods: {
		getPosts() {
                this.slides = [];
                this.isLoading = true;
                axios.get('https://api.cnphub.com/public/posts')
                .then(res => {
                    this.posts = res.data.data ? res.data.data : [];
                })
                .catch(res => {
                  console.log(res);
                });
            },

        },
}
</script>