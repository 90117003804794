<template>
  <section class="home-section mb-2">
    <div class="container theme16">
      <div class="row">
        <div class="col-md-6 col-xl-6 col-lg-6 mb-xl-0 mb-2">
          <PvCarousel40
            class="home-slider slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider"
            v-animate
          >
            <div
              class="home-slide home-slide1 banner swiper-slide"
              v-for="(post, index) in posts"
              :key="index"
            >
              <img
                v-if="post.image"
                width="100%"
                :src="getImage(post.image, 'medium')"
              />
              <div class="slider-content">
                <h6
                  v-if="post.created_at"
                  class="slider-date appear-animate"
                  data-animation-name="fadeInDownShorter"
                  data-animation-delay="100"
                  v-animate
                >
                  {{ getPostDate(post.created_at) }}
                </h6>
                <h3
                  class="appear-animate"
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay="1100"
                  v-animate
                >
                  <a class="slider-title" :href="'/article/' + post.slug">{{
                   getPostDescription(post.name, 110)
                  }}</a>
                </h3>
              </div>

              <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->

              <!-- <div class=" appear-animate" v-html="post.subtitle"></div> -->
            </div>
          </PvCarousel40>
        </div>
        <div class="col-md-3  mb-20 sub-list-col-1">
          <div
            class="article"
            v-for="(post, index) in getShortList(1, 4)"
            :key="index"
          >
            <div v-if="index == 0">
              <img class="mb-2"
                v-if="post.image"
                width="100%"
                :src="getImage(post.image, 'medium')"
              />
              <div class="slider-date">{{ getPostDate(post.created_at) }}</div>
              <h4  class="post-title">
                <a :href="'/article/' + post.slug">{{
                  getPostDescription(post.name, 110)
                }}</a>
              </h4>
              <div class=" appear-animate" v-html="getPostDescription(post.content, 110)"></div>
            </div>
            <!-- <div v-if="index > 0" class="small-article">
              <h4>
                <a :href="'/article/' + post.slug">{{
                  getPostDescription(post.name)
                }}</a>
              </h4>
            </div> -->
          </div>
        </div>
        <div class="col-md-3  mb-20 sub-list-col-2">
          <div
            class="article"
            v-for="(post, index) in getShortList(4, 7)"
            :key="index"
          >
            <div v-if="index == 0">
              <img  class="mb-2"
                v-if="post.image"
                width="100%"
                :src="getImage(post.image, 'medium')"
              />
              <div class="slider-date">{{ getPostDate(post.created_at) }}</div>
              <h4 class="post-title">
                <a :href="'/article/' + post.slug">{{
                  getPostDescription(post.name, 110)
                }}</a>
              </h4>
              <div class=" appear-animate" v-html="getPostDescription(post.content, 110)"></div>  
            </div>
            <!-- <div v-if="index > 0" class="small-article">
              <h4>
                <a :href="'/article/' + post.slug">{{
                  getPostDescription(post.name)
                }}</a>
              </h4>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from "./PvCarousel40.vue";
import axios from "axios";
import imageHelper from "../../../helpers/image-helper";

export default {
  components: {
    PvCarousel40,
  },
  props: {
    posts: Array,
  },
  data: function () {
    return {
      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-dots",
          clickable: true,
        },
      },
    };
  },
  created() {},
  mounted() {
    // this.getSliderNews();
  },
  methods: {
    getShortList(start, shortListSize) {
      if (this.posts) return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));

    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1);
    //       }

    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;

    // },
  },
};
</script>

<style>
.theme16 .home-slider img {
  height: auto;
}
h4.post-title {
  margin-top: 30px;
}
.theme16 .home-slider {
  /*   
  min-height: 50rem;
  max-height: 50rem; */
}

.theme16 .home-slider {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
}
.theme16 .slider-content{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
background: rgba(0,0,0,0.5);
}
.theme16 .home-slider .slider-title {
  color: #fff;
}

.sub-list-col-1 h4 a,
.sub-list-col-2 h4 a {
  color: #000;
}

.theme2.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

.theme16 .small-article {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #f2f2f2;
}

.theme16 .small-article h4 a {
  font-size: 0.8em;
  font-weight: bold;
}

.theme16 .home-slider .slider-date,
.theme16 .sub-list-col-1 .slider-date,
.theme16 .sub-list-col-2 .slider-date {
  width: 160px;
  padding: 10px;
  color: #fff;
  background:var(--color);
  margin-top: -33px;
  display: block;
  position: absolute;
}

.theme2.top-6-articles .article {
}

.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {
  border-color: var(--color);
}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 2.4em;
}
</style>
