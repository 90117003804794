<template>
	<footer class="footer bg-dark position-relative">
		<div class="footer-middle">
			<div class="container position-static">
				

				<div class="row">
					<div class="col-lg-3 col-sm-6 pb-2 pb-sm-0">
						<div class="widget">
							<h4 class="widget-title">{{ $t("About Us") }}</h4>
							<img
								:src="'https://cnphub.menaws.com/storage/'+settings.logo"
								alt="Logo"
								class="logo-footer"
								width="101"
								height="40"
							/>
							<p class="m-b-4">{{ settings.about }}</p>
							<a
								href="javascript:;"
								class="read-more text-white"
							>{{ $t("Read More") }}</a>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-4 pb-sm-0">
						<div class="widget mb-2">
							<h4 class="widget-title mb-1 pb-1">{{ $t("Contact Info") }}</h4>
							<ul class="contact-info m-b-4">
								<li>
									<span class="contact-info-label">Address:</span>123 Street Name, City, England
								</li>
								<li>
									<span class="contact-info-label">Phone:</span>
									<a :href="'tel:'+settings.phone">{{ settings.phone }}</a>
								</li>
								<li>
									<span class="contact-info-label">Email:</span>
									<a :href="settings.email">{{ settings.email }}</a>
								</li>
							</ul>
							<div class="social-icons">
								<a
									:href="settings.facebook"
									class="social-icon social-facebook icon-facebook"
									title="Facebook"
								></a>
								<a
									:href="settings.twitter"
									class="social-icon social-twitter icon-twitter"
									
									title="Twitter"
								></a>
								<a
									:href="settings.instagram"
									class="social-icon social-linkedin fab fa-instagram"
									
									title="Linkedin"
								></a>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-2 pb-sm-0">
						<div class="widget">
							<h4 class="widget-title pb-1">Customer Service</h4>

							<ul class="links">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>								
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-0">
						<div class="widget mb-1 mb-sm-3">
							<h4 class="widget-title">Popular Tags</h4>

							<div class="tagcloud">
								<router-link v-for="item in popularTags" :key="item.id" :to="{path: '{{ item.name }}' }">{{ item.name }}</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom">
				<div class="container d-sm-flex align-items-center">
					<div class="footer-left">
						<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</span>
					</div>

					<div class="footer-right ml-auto mt-1 mt-sm-0">
						<div class="payment-icons">
							<span
								class="payment-icon visa"
								v-lazy:background-image="'./images/payments/payment-visa.svg'"
							></span>
							<span
								class="payment-icon paypal"
								v-lazy:background-image="'./images/payments/payment-paypal.svg'"
							></span>
							<span
								class="payment-icon stripe"
								v-lazy:background-image="'./images/payments/payment-stripe.png'"
							></span>
							<span
								class="payment-icon verisign"
								v-lazy:background-image="'./images/payments/payment-verisign.svg'"
							></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
			popularTags : [],
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
      this.getPopularTags();
    },
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
		getPopularTags() {
			axios.get('https://api.cnphub.com/public/popularTags')
			.then(res => {
				this.popularTags = res.data.data.tags ? res.data.data.tags : [];
			})
			.catch(res => {
				console.log(res);
			});
		},
	},
};
</script>