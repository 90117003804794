<template>
	<div class="product-default left-details">
		<figure>
			<router-link :to="`${product.slug}`">
				<img
					:key="`related-large-${product.id}`"
					v-lazy="product.image"
					alt="large-picture"
				/>
			</router-link>
		</figure>
		
	</div>
</template>

<script>
import { baseUrl } from '@/api/index';

export default {
	components: {
	},
	props: {
		product: Object,
		adClass: String,
		isActions: {
			type: Boolean,
			default: true
		}
	},
	data: function() {
		return {
			baseUrl: baseUrl,
		};
	},
	computed: {
		
	},
	mounted: function() {
	},
	methods: {
	}
};
</script>