<template>
    <main :class="'lang-' + getLang() +' id-'+ getWebsite() + ' main home'">
    <MainHeader :attributes="header.attributes"></MainHeader>

    <component :is="settings.inner_pages_banner" :key="settings.inner_pages_banner"></component>

    <div class="container py-4">
      <div class="row">
        <pv-blog-sidebar v-if="settings.inner_page_style == 'LeftSideBar'" :popularCategories="popularCategories"
          :popularTags="popularTags" :recentPosts="recentPosts"></pv-blog-sidebar>
        <div :class="contentShow()">
          <div class="blog-section row">
            <template>
              <p class="blogs-info" v-if="posts.length === 0">{{ $t('No posts were found matching your selection.') }}</p>

              <div v-for="(post, index) in posts" :key="index" :class="settings.columns_count">
                <pv-post :post="post"></pv-post>
              </div>
            </template>
          </div>
          <div style="text-align:center;margin-bottom: 40px;" v-if="posts.length > 0">
              <button class="button-arounder" v-bind:class="[isFinished ? 'finish' : 'load-more']"  @click='loadMore()' v-cloak>{{ buttonText }}</button>
          </div>
        </div>
        <pv-blog-sidebar v-if="settings.inner_page_style == 'RightSideBar'" :popularCategories="popularCategories"
          :popularTags="popularTags" :recentPosts="recentPosts"></pv-blog-sidebar>
      </div>
    </div>

    <MainFooter :attributes="footer.attributes"></MainFooter>

  </main>
</template>
<script>
import axios from 'axios'
import PvPost from '../Posts/partials/PvPost';
import PvBlogSidebar from '../Posts/partials/PvBlogSidebar';
import MainHeader from "../Utility/Header/MainHeader.vue";
import MainFooter from "../Utility/Footer/MainFooter.vue";

import Banner1 from "../Posts/partials/banner/banner1";
import Banner2 from "../Posts/partials/banner/banner2";
import Banner3 from "../Posts/partials/banner/banner3";
import Banner4 from "../Posts/partials/banner/banner4";
import Banner5 from "../Posts/partials/banner/banner5";
import Banner6 from "../Posts/partials/banner/banner6";
import Banner7 from "../Posts/partials/banner/banner7";
import Banner8 from "../Posts/partials/banner/banner8";

export default {
  components: {
    PvPost,
    PvBlogSidebar,
    MainHeader,//by Abdulhamid
    MainFooter,//by Abdulhamid
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
    Banner8,
  },
  props: {
  },
  data: function () {
    return {
      posts: [],
      popularCategories: [],
      recentPosts: [],
      relatedPosts: [],
      popularTags: [],
      settings:[],
      header: '',
      footer: '',
      category: "",
      isFinished: false,
      page: 2,  // Record selection position
      rowperpage: 12,  // Number of records fetch at a time
      buttonText: this.$t('Load More')
    };
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    const category = this.category;
    const tag = this.tag;
    const search_tearm = this.search_tearm;
    if(category != ''){
      return {
        title: `${category.name}`,
        meta: [
          { name: "description", content: `${category.description}` },
          { property: "og:title", content: `${category.name}` },
          { property: "og:site_name", content: `${category.name}` },
          { property: "og:description", content: `${category.description}` },
          { property: "og:type", content: "category" },
          { property: "og:url", content: `${category.slug}` },
          { property: "og:image", content: `${category.image}` },
        ],
      };
    }else if(tag != ''){
      return {
        title: `${tag.name}`,
        meta: [
          { name: "description", content: `${tag.description}` },
          { property: "og:title", content: `${tag.name}` },
          { property: "og:site_name", content: `${tag.name}` },
          { property: "og:description", content: `${tag.description}` },
          { property: "og:type", content: "tag" },
          { property: "og:url", content: `${tag.slug}` },
          { property: "og:image", content: `${tag.image}` },
        ],
      };
    }else{
      return {
        title: `${search_tearm}`,
        meta: [
          { name: "description", content: `${search_tearm}` },
          { property: "og:title", content: `${search_tearm}` },
          { property: "og:site_name", content: `${search_tearm}` },
          { property: "og:description", content: `${search_tearm}` },
          { property: "og:type", content: "tag" },
          { property: "og:url", content: `${search_tearm}` },
          { property: "og:image", content: `${search_tearm}` },
        ],
      };
    }
    
  },
  mounted() {
    this.getListDate();
    this.getPopularCategories();
    this.getRecentPosts();
    this.getPopularTags();
    this.header = JSON.parse(localStorage.getItem("header"));
    this.footer = JSON.parse(localStorage.getItem("footer"));
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  watch: {
    '$route': {
      handler: 'asyncData',
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    getLang: function(){
      return localStorage.getItem("locale");
    },
    getWebsite: function () {
      return this.settings.id;
    },
    asyncData() {
      this.page = 2;
      this.getListDate();
      document.querySelector("body").classList.remove("mmenu-active");
    },
    getListDate() {
      var slug = window.location.pathname.split("/").pop();
      var type = window.location.pathname.split("/");

      type = type[1];
      var callFunc = '';
      if (type == 'category') {
        callFunc = "https://api.cnphub.com/public/Category_by_slug?slug=" + slug + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      } else if (type == 'tag'){
        callFunc = "https://api.cnphub.com/public/Tag_by_slug?slug=" + slug + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      } else if(type == 'search'){
        var search_term = location.search.split('search_term=')[1];
        callFunc = "https://api.cnphub.com/public/Search?search_term=" + search_term + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      }

      axios
        .get(
          callFunc
        )
        .then(res => {
          this.posts = res.data.data.posts.data ? res.data.data.posts.data : [];
          this.category = res.data.data.category ? res.data.data.category : '';
          this.tag = res.data.data.tag ? res.data.data.tag : '';
          this.search_tearm = res.data.data.search_tearm ? res.data.data.search_tearm : '';
        })
    },
    loadMore() {
      var slug = window.location.pathname.split("/").pop();
      var type = window.location.pathname.split("/");

      type = type[1];
      var callFunc = '';
      if (type == 'category') {
        callFunc = "https://api.cnphub.com/public/Category_by_slug?slug=" + slug + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      } else if (type == 'tag'){
        callFunc = "https://api.cnphub.com/public/Tag_by_slug?slug=" + slug + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      } else if(type == 'search'){
        var search_term = location.search.split('search_term=')[1];
        callFunc = "https://api.cnphub.com/public/Search?search_term=" + search_term + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      }
      axios
        .get(
          callFunc
        )
        .then((res) => {
          if (res.data.data.posts.data.length !== 0) {
            this.page++;
            var len = res.data.data.posts.data.length;
            if (len > 0) {
              this.buttonText = this.$t("Loading ...");
              setTimeout(() => this.appendData(res.data.data.posts.data), 500);
            } else {
              this.posts = res.data.data.posts ? res.data.data.posts : [];
            }
          } else {
            this.buttonText = this.$t('No more records avaiable.');
            this.isFinished = true;
          }
        });
    },
    appendData(data) {
      this.buttonText = this.$t('Load More')
      for (let i = 0; i < data.length; i++) {
        this.posts.push(data[i]);
      }
    },
    getPopularCategories() {
      axios.get('https://api.cnphub.com/public/popularCategories')
        .then(res => {
          this.popularCategories = res.data.data.popularCategories ? res.data.data.popularCategories : [];
        })
    },
    getRecentPosts() {
      axios.get('https://api.cnphub.com/public/recentPosts')
        .then(res => {
          this.recentPosts = res.data.data.recentPosts ? res.data.data.recentPosts : [];
        })
    },
    getPopularTags() {
      axios.get('https://api.cnphub.com/public/popularTags')
        .then(res => {
          this.popularTags = res.data.data.popularTags ? res.data.data.popularTags : [];
        })
    },
    contentShow() {
      if (this.settings.inner_page_style == 'Full') {
        return 'col-md-12'
      }
      else {
        return 'col-md-9'
      }
    }
  },
};
</script>

<style>
.banner-image {
  /* background: url(var(--inner-pages-banner)); */
  background-size: cover;
}


button {
  background: white;
  border: solid 2px black;
  padding: .375em 1.125em;
  font-size: 1rem;
}

.button-arounder {
  font-size: 2rem;
  background: var(--color);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: var(--bs-border-color);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  color: var(--color);
}



</style>