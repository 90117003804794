<template>
	<footer class="footer font2">
		<pv-instagram-section></pv-instagram-section>

		<div class="container">
			<div class="footer-middle">
				<div class="row">
					<div class="col-lg-5 m-b-4">
						<router-link to="/">
							<img
								src="@/assets/css/images/logo-black.png"
								alt="Logo"
								width="101"
								height="40"
								class="m-b-3 logo"
							>
						</router-link>

						<p class="footer-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
							<br>Praesent pulvinar feugiat quam, vitae aliquam.</p>

						<div class="row ls-0 footer-question">
							<div class="col-5">
								<h6 class="mb-0">QUESTIONS</h6>
								<h3>1-888-123-456</h3>
							</div>

							<div class="col-7">
								<div class="social-icons">
									<a
										href="javascript:;"
										class="social-icon social-facebook icon-facebook"
										
										title="Facebook"
									></a>
									<a
										href="javascript:;"
										class="social-icon social-twitter icon-twitter"
										
										title="Twitter"
									></a>
									<a
										href="javascript:;"
										class="social-icon social-instagram icon-instagram"
										
										title="Instagram"
									></a>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-2 col-sm-4 m-b-4">
						<div class="widget">
							<h4 class="widget-title">My Account</h4>

							<ul class="links">
								<li>
									<router-link to="/pages/account">My Account</router-link>
								</li>
								<li>
									<a href="javascript:;">Track Your Order</a>
								</li>
								<li>
									<a href="javascript:;">Payment Methods</a>
								</li>
								<li>
									<a href="javascript:;">Shipping Guide</a>
								</li>
								<li>
									<a href="javascript:;">FAQs</a>
								</li>
								<li>
									<a href="javascript:;">Product Support</a>
								</li>
								<li>
									<a href="javascript:;">Privacy</a>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-lg-2 col-sm-4 m-b-4">
						<div class="widget">
							<h4 class="widget-title">About</h4>

							<ul class="links">
								<li>
									<router-link to="/pages/about-us">About Porto</router-link>
								</li>
								<li>
									<a href="javascript:;">Our Guarantees</a>
								</li>
								<li>
									<a href="javascript:;">Terms And Conditions</a>
								</li>
								<li>
									<a href="javascript:;">Privacy policy</a>
								</li>
								<li>
									<a href="javascript:;">Return Policy</a>
								</li>
								<li>
									<a href="javascript:;">Intellectual Property Claims</a>
								</li>
								<li>
									<a href="javascript:;">Site Map</a>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-sm-4 m-b-4">
						<div class="widget">
							<h4 class="widget-title">Features</h4>

							<ul class="links">
								<li>
									<a href="javascript:;">Super Fast Vue Template</a>
								</li>
								<li>
									<a href="javascript:;">1st Fully working Ajax Theme</a>
								</li>
								<li>
									<a href="javascript:;">40 Unique Shop Layouts</a>
								</li>
								<li>
									<a href="javascript:;">Powerful Admin Panel</a>
								</li>
								<li>
									<a href="javascript:;">Mobile &amp; Retina Optimized</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-dark">
			<div class="container">
				<div class="footer-bottom d-flex justify-content-between align-items-center flex-wrap">
					<div class="footer-left">
						<p class="footer-copyright py-3 pr-4 mb-0">&copy; © {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}
						</p>
					</div>

					<div class="footer-right">
						<div class="footer-right ml-auto mt-1 mt-sm-0">
							<div class="payment-icons mr-0">
								<span
									class="payment-icon visa"
									v-lazy:background-image="'./images/payments/payment-visa.svg'"
								></span>
								<span
									class="payment-icon paypal"
									v-lazy:background-image="'./images/payments/payment-paypal.svg'"
								></span>
								<span
									class="payment-icon stripe"
									v-lazy:background-image="'./images/payments/payment-stripe.png'"
								></span>
								<span
									class="payment-icon verisign"
									v-lazy:background-image="'.//images/payments/payment-verisign.svg'"
								></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import PvInstagramSection from '../Common/PvInstagramSection.vue';

export default {
	components: {
		PvInstagramSection
	}
};
</script>