<template>
  <section class="home-section mb-2">
    <div :class="getContainer + 'slider'">
      <template>
        <component :is="attributes.theme" :posts="posts" :key="attributes.theme"></component>
      </template>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import SliderTheme1 from './SliderTheme1.vue';
import SliderTheme2 from './SliderTheme2.vue';
import SliderTheme3 from './SliderTheme3.vue';
import SliderTheme4 from './SliderTheme4.vue';
import SliderTheme5 from './SliderTheme5.vue';
import SliderTheme6 from './SliderTheme6.vue';
import SliderTheme7 from './SliderTheme7.vue';
import SliderTheme8 from './SliderTheme8.vue';
import SliderTheme9 from './SliderTheme9.vue';
import SliderTheme10 from './SliderTheme10.vue';
import SliderTheme11 from './SliderTheme11.vue';
import SliderTheme14 from './SliderTheme14.vue';
import SliderTheme12 from './SliderTheme12.vue';
import SliderTheme15 from './SliderTheme15.vue';
import SliderTheme16 from './SliderTheme16.vue';
import SliderTheme17 from './SliderTheme17.vue';
import SliderTheme18 from './SliderTheme18.vue';
import SliderTheme19 from './SliderTheme19.vue';
import SliderTheme20 from './SliderTheme20.vue';
import SliderTheme8extra from './SliderTheme8extra.vue';
import SliderTheme9extra from './SliderTheme9extra.vue';
import SliderTheme10extra from './SliderTheme10extra.vue';
import SliderTheme11extra from './SliderTheme11extra.vue';

export default {
  components: {
    PvCarousel40,
    SliderTheme1,
    SliderTheme2,
    SliderTheme3,
    SliderTheme4,
    SliderTheme5,
    SliderTheme6,
    SliderTheme7,
    SliderTheme8,
    SliderTheme9,
    SliderTheme10,
    SliderTheme12,
    SliderTheme11,
    SliderTheme14,
    SliderTheme15,
    SliderTheme16,
    SliderTheme17,
    SliderTheme18,
    SliderTheme19,
    SliderTheme20,
    SliderTheme8extra,
    SliderTheme9extra,
    SliderTheme10extra,
    SliderTheme11extra,
  },
  // props: {
  // attributes: {
  //   attributes: {
  //   theme: String,
  //     theme: String,
  //   limit: Number,
  //     limit: Number,
  //   },
  // },
  // },
  props: {
  
      attributes: {
        theme: String,
        images_query: Number,
        limit: Number,
      },

  },

  data: function () {
    return {
      posts: [],

      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  async created() {
    await this.getSliderNews();
  },
  mounted() {


  },
  methods: {
    getContainer() {

      if (this.attributes.theme == 'SliderTheme14')
        return '';
      else return 'container  ';
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    sortItems() {
      if (this.sortatoz === "alphabetically") {
        return this.posts.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        return this.posts.sort(function () { return 0.5 - Math.random() });
      }
    },
    async getSliderNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));

      axios.get(this.settings.BaseUrl + '/public/slider-news?limit=' + this.attributes.limit+'&images_query='+this.attributes.images_query)
        .then(res => {
          this.posts = res.data.data.posts ? res.data.data.posts : '';
          this.posts = this.sortItems();
        })
        .catch(res => {
          console.log(res);
        });
    },
  },
}
</script>

<style>
.home-section .bg-black-trans {
  background: rgba(0, 0, 0, 0.3);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 0.8rem;
  font-size: 2.4em;
}
</style>