<template>
  <section
    class="innerbanner section section-with-shape-divider section-height-3 overlay overlay-show border-0"
    data-plugin-parallax=""
    data-plugin-options="{'speed': 1.5, 'parallaxHeight': '120%', 'fadeIn': true}"
    data-image-src="img/demos/restaurant/backgrounds/background-4.jpg"
    style="position: relative; overflow: hidden"
  >
    <div
      class="parallax-background fadeIn animated"
      :style="setBackgound"
    ></div>
    <div class="container pt-3 pb-5 mb-5">
      <div class="row mb-3">
        <div class="col">
          <ul
            class="breadcrumb d-block text-center custom-font-secondary text-6 font-weight-medium positive-ls-3"
          >
            <li>
              <!-- <a
                href="/"
                class="text-decoration-none opacity-hover-8"
                >Home</a
              > -->
            </li>
          </ul>
          <h1
            class="d-block text-color-light font-weight-bold text-center text-12 positive-ls-1 line-height-2 mb-0"
            v-if="post"
          >
            {{ post.name }}
          </h1>
        </div>
      </div>
    </div>
    <a
      href="#blogContent"
      data-hash=""
      data-hash-offset="0"
      data-hash-offset-lg="100"
      data-hash-force="true"
      class="text-decoration-none text-color-dark text-color-hover-primary text-5-5 position-absolute transform3dx-n50 left-50pct bottom-5 mb-4 z-index-2"
    >
      <i class="fa fa-arrow-down font-weight-bold"></i>
    </a>
    <div
      class="shape-divider shape-divider-bottom shape-divider-reverse-y"
      style="height: 116px"
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 116"
        preserveAspectRatio="xMinYMin"
      >
        <path
          fill="#FFF"
          d="M453,92c11.7-4.87,28.46-11.43,49-18c42.29-13.52,76.36-19.33,115-25c51.58-7.57,100.28-14.72,171-20
								c24.87-1.86,82.88-5.76,158-6c69.99-0.23,122.54,2.82,159,5c51.18,3.06,95.17,5.69,155,14c71.5,9.94,115.42,21.02,127,24
								c33.7,8.68,61.62,17.79,82,25C1130.33,91.33,791.67,91.67,453,92z"
        ></path>
        <rect y="90" fill="#FFF" width="1920" height="26"></rect>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    };
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        "background-image":
          "url(" + this.settings.storageUrl + this.settings.banner_image + ")",
        "background-size": "cover",
        "background-position": "center",
        "position": "absolute",
        "top": "0px",
        "left": "0px",
        "width": "100%",
        "height": "120%",
        "transform": "translate3d(0px, -66.5143px, 0px)",
        "background-position-x": "50%",
      
      };
    },
  },
};
</script>

<style>
section.section.section-height-3 {
    padding: 73.846px 0;
    padding: 4.6153846154rem 0;
}

.overlay:hover:before, .overlay.overlay-show:before, .overlay.show:before {
    opacity: .8;
}

.overlay:before {
    background: #2C2C2C;
}

.overlay:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #212529;
    opacity: 0;
    pointer-events: none;
    transition: ease opacity 300ms 100ms;
}

section.section>.container {
    position: relative;
}

.overlay .container, .overlay .container-fluid {
    position: relative;
    z-index: 3;
}

.z-index-2 {
    z-index: 2!important;
}

.text-decoration-none {
    text-decoration: none!important;
}

.transform3dx-n50 {
    transform: translate3d(-50%,0,0);
}

.bottom-5 {
    bottom: 5px!important;
}

.left-50pct {
    left: 50%;
}

.text-5-6, .text-5-5 {
    font-size: 1.65em!important;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y {
    transform: rotateY(180deg);
}

.section.section-with-shape-divider .shape-divider.shape-divider-bottom {
    top: auto;
    bottom: 0;
}

.section.section-with-shape-divider .shape-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
}

.section.section-with-shape-divider .shape-divider svg {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%,0,0);
}

html .text-color-dark, html .text-dark {
    color: #212529 !important;
}

.text-5-6, .text-5-5 {
    font-size: 1.65em!important;
}

.z-index-2 {
    z-index: 2!important;
}

.text-decoration-none {
    text-decoration: none!important;
}

.transform3dx-n50 {
    transform: translate3d(-50%,0,0);
}

.bottom-5 {
    bottom: 5px!important;
}

.fa-arrow-down{
  color: #fff;
}

.text-decoration-none a{
  color: #fff !important;
}

ul li a{
  color: #fff ;
}

.innerbanner{
  margin-top: -2rem !important;
}
</style>