<template>
	<div
		class="home-top-container appear-animate"
		data-animation-name="fadeIn"
		data-animation-delay="100"
		v-animate
	>
		<div class="container">
			<div class="row">
				<div class="col-lg-6 mb-2" v-if="slider.length > 0">
					<div class="home-banner banner banner1 banner-md-vw banner-sm-vw d-flex align-items-center">
						<figure class="w-100">
							<img
								v-lazy="slider[0].image"
								style="background: #3f4c5c"
								alt="banner"
								width="565"
								height="500"
								image-ratio="88.49"
							/>
						</figure>
					</div>
				</div>

				<div class="col-lg-3 col-sm-6 mb-2" v-if="slider.length > 1">
					<div class="row h-100">
						<div class="col-lg-12 mb-2">
							<div class="banner banner2 banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										v-lazy="slider[1].image"
										alt="banner"
										width="273"
										height="240"
										style="background: #f4f4f4"
										image-ratio="100"
									/>
								</figure>
							</div>
						</div>

						<div class="col-lg-12" v-if="slider.length > 2">
							<div class="banner banner3 text-uppercase banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										v-lazy="slider[2].image"
										alt="banner"
										width="273"
										height="240"
										style="background: #fff"
										image-ratio="100"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-sm-6 mb-2" v-if="slider.length > 3">
					<div class="row h-100">
						<div class="col-lg-12 mb-2">
							<div class="banner banner2 banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										v-lazy="slider[3].image"
										alt="banner"
										width="273"
										height="240"
										style="background: #f4f4f4"
										image-ratio="100"
									/>
								</figure>
							</div>
						</div>

						<div class="col-lg-12" v-if="slider.length > 4">
							<div class="banner banner3 text-uppercase banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										v-lazy="slider[4].image"
										alt="banner"
										width="273"
										height="240"
										style="background: #fff"
										image-ratio="100"
									/>
								</figure>
							</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'


export default {
	data: function() {
		return {
			slider: []
		};
	},
	mounted() {
      this.getWebsiteElements();
    },
	methods: {
      getWebsiteElements() {
		this.slides = [];
		axios.get('https://api.cnphub.com/public/posts')
		.then(res => {
			this.slider = res.data.data ? res.data.data : [];
		})
		.catch(res => {
			console.log(res);
		});
	},

	},
};
</script>