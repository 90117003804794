var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video-news-area"},[_c('div',{staticClass:"container custom-container"},[_c('div',{class:[
        'video-news-box',
        _vm.darkClass && 'section' in _vm.darkClass ? _vm.darkClass.section : '',
      ]},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"video-news-post"},[_vm._m(0),_c('div',{class:[
                'video-news-post-item',
                _vm.darkClass && 'section_2' in _vm.darkClass
                  ? _vm.darkClass.section_2
                  : '',
              ]},[_c('div',{staticClass:"video-news-post-thumb"},[_c('img',{attrs:{"src":require("@/assets/images/video-post-thumb.jpg"),"alt":""}}),_c('div',{staticClass:"play-btn"},[_c('a',{staticClass:"video-popup",on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('toggleVideo')}}},[_c('i',{staticClass:"fab fa-youtube"})])])]),_c('div',{staticClass:"video-news-post-content"},[_vm._m(1),_c('h3',{staticClass:"title"},[_c('router-link',{attrs:{"to":"/posts/postOne"}},[_vm._v("Riots Report Shows London Needs To Maintain Police Numbers, Says Mayor")])],1)])])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"populer-post"},[_vm._m(2),_c('div',{staticClass:"trending-sidebar-slider position-relative"},[_c('span',{staticClass:"prev slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssPrev}},[_c('i',{staticClass:"fal fa-angle-left"})]),_c('slider',{ref:"trendingSidebarSlide",attrs:{"settings":_vm.videoNewsSlide}},[_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(0, 4)),function(small,index){return [_c('row-card',{key:index,class:[
                        'populer_item-style',
                        _vm.darkClass && 'news_item' in _vm.darkClass
                          ? _vm.darkClass.news_item
                          : '',
                      ],attrs:{"trending":true,"datas":small}})]})],2),_c('div',{staticClass:"post_gallery_items"},[_vm._l((_vm.smallPostGallery.slice(0, 4)),function(small,index){return [_c('row-card',{key:index,class:[
                        'populer_item-style',
                        _vm.darkClass && 'news_item' in _vm.darkClass
                          ? _vm.darkClass.news_item
                          : '',
                      ],attrs:{"trending":true,"datas":small}})]})],2)]),_c('span',{staticClass:"next slick-arrow",staticStyle:{"display":"block"},on:{"click":_vm.tssNext}},[_c('i',{staticClass:"fal fa-angle-right"})])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title section-title-2"},[_c('h3',{staticClass:"title"},[_vm._v("Videos News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-meta"},[_c('div',{staticClass:"meta-categories"},[_c('a',{attrs:{"href":"#"}},[_vm._v("TECHNOLOGY")])]),_c('div',{staticClass:"meta-date"},[_c('span',[_vm._v("March 26, 2020")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h3',{staticClass:"title"},[_vm._v("Popular")])])
}]

export { render, staticRenderFns }