<template>
  <div class="container">
    <div class="row pb-1 pt-2">
      <div class="col-md-9">
        <div class="heading heading-border heading-middle-border">
          <h3 class="text-4 cat-title">
            <strong
              class="font-weight-bold text-1 px-3 text-light py-2 bg-secondary"
              >{{ cat1name }}</strong
            >
          </h3>
        </div>

        <div class="row pb-1">
          <div class="col-lg-6 mb-4 pb-1">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
              v-for="(post, index) in cat1Posts.slice(0, 1)"
              :key="index"
            >
              <div class="row">
                <div class="col">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="thumb-info-caption-text">
                    <div
                      class="d-inline-block text-default text-1 mt-2 float-none"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                    <h5
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                      v-html="getPostDescription(post.content, 400)"
                    >
                    </h5>
                    
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div class="col-lg-6">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-4 mb-2"
              v-for="(post, index) in cat1Posts.slice(1, 5)"
              :key="index"
            >
              <div class="row align-items-center pb-1">
                <div class="col-sm-4">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
                <div class="col-sm-8 ps-sm-0">
                  <div class="thumb-info-caption-text">
                    <div class="d-inline-block text-default text-1 float-none">
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block pb-2 line-height-2 text-3 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div class="heading heading-border heading-middle-border">
          <h3 class="text-4 cat-title">
            <strong
              class="font-weight-bold text-1 px-3 text-light py-2 bg-secondary"
              >{{ cat2name }}</strong
            >
          </h3>
        </div>

        <div class="row pb-1">
          <div class="col-lg-6 mb-4 pb-1">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
              v-for="(post, index) in cat2Posts.slice(0, 1)"
              :key="index"
            >
              <div class="row">
                <div class="col">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="thumb-info-caption-text">
                    <div
                      class="d-inline-block text-default text-1 mt-2 float-none"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                    <h5
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                      v-html="getPostDescription(post.content, 400)"
                    >
                    </h5>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div class="col-lg-6">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-4 mb-2"
              v-for="(post, index) in cat2Posts.slice(1, 5)"
              :key="index"
            >
              <div class="row align-items-center pb-1">
                <div class="col-sm-4">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
                <div class="col-sm-8 ps-sm-0">
                  <div class="thumb-info-caption-text">
                    <div class="d-inline-block text-default text-1 float-none">
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block pb-2 line-height-2 text-3 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div class="heading heading-border heading-middle-border">
          <h3 class="text-4 cat-title">
            <strong
              class="font-weight-bold text-1 px-3 text-light py-2 bg-secondary"
              >{{ cat3name }}</strong
            >
          </h3>
        </div>

        <div class="row pb-1">
          <div class="col-lg-6 mb-4 pb-1">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
              v-for="(post, index) in cat3Posts.slice(0, 1)"
              :key="index"
            >
              <div class="row">
                <div class="col">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="thumb-info-caption-text">
                    <div
                      class="d-inline-block text-default text-1 mt-2 float-none"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                    <h5
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                      v-html="getPostDescription(post.content, 400)"
                    >
                    </h5>
                  </div>
                </div>
              </div>
            </article>
          </div>

          <div class="col-lg-6">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-4 mb-2"
              v-for="(post, index) in cat3Posts.slice(1, 5)"
              :key="index"
            >
              <div class="row align-items-center pb-1">
                <div class="col-sm-4">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
                <div class="col-sm-8 ps-sm-0">
                  <div class="thumb-info-caption-text">
                    <div class="d-inline-block text-default text-1 float-none">
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block pb-2 line-height-2 text-3 text-dark font-weight-bold mb-0"
                    >
                      <a
                        :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ getPostDescription(post.name, 100) }}</a
                      >
                    </h4>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div class="col-md-3 cat-title">
        <h3 class="font-weight-bold text-3 pt-1">{{ $t("Latest News") }}</h3>

        <div class="pb-2">
          <div class="mb-4 pb-2" v-for="(post, index) in recentPosts" :key="index">
            <article
              class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
            >
              <div class="row">
                <div class="col">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'medium')"
                      class="img-fluid border-radius-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="thumb-info-caption-text">
                    <div
                      class="d-inline-block text-default text-1 mt-2 float-none"
                    >
                      <a
                      :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-default"
                        >{{ getPostDate(post.created_at) }}</a
                      >
                    </div>
                    <h4
                      class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                    >
                      <a
                      :href="'/article/' + post.slug"
                        class="text-decoration-none text-color-dark text-color-hover-primary"
                        >{{ post.name }}</a
                      >
                    </h4>
                  </div>
                </div>
              </div>
            </article>
          </div>
          
        </div>

          <div class="widget mb-5">
            <h3 class="font-weight-bold text-3 pt-1">{{ $t("popularCategories") }}</h3>
            <div class="mb-3 pb-1">
              <a
                :href="`/category/${category.slug}`"
                v-for="(category, index) in popularCategories.slice(0, 5)"
                :key="index"
              >
                <span
                  class="badge badge-dark badge-sm text-uppercase pa-2 mr-b"
                  >{{ category.name }}</span
                >
              </a>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  components: {},
  data: function () {
    return {
      row1count: "",
      row1class: "",
      row2count: "",
      row2class: "",
      cat1name: "",
      cat2name: "",
      cat3name: "",
      cat1Posts: [],
      cat2Posts: [],
      cat3Posts: [],
      popularCategories: [],
      recentPosts: [],
    };
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
      images_query:Number,
    },
  },
  mounted() {
    var row1 = this.attributes.row1.split(" ");
    var row2 = this.attributes.row2.split(" ");
    var row3 = this.attributes.row3.split(" ");
    this.row1class = row1[0];
    this.row1count = row1[1];
    this.row2class = row2[0];
    this.row2count = row2[1];
    this.row3class = row3[0];
    this.row3count = row3[1];
    this.getPopularCategories();
    this.getRecentPosts();
    this.getPosts();
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostMonth(date) {
      return this.$postHelper.getPostDay(date);
    },
    getPostDay(date) {
      return this.$postHelper.getPostMonth(date);
    },
    getPostDescription(text , value) {
      return this.$postHelper.getPostSmallDescription(text, value);
    },
    getPosts() {
      axios
        .get(
          "https://api.cnphub.com/public/twoCategoryData" +
            "?limit=" +
            this.row1count +
            "&cat1=" +
            this.attributes.cat1 +
            "&cat2=" +
            this.attributes.cat2+'&images_query='+this.attributes.images_query,
          {}
        )
        .then((res) => {
          this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : "";
        })
        .catch((res) => {
          console.log(res);
        });

      axios
        .get(
          "https://api.cnphub.com/public/twoCategoryData" +
            "?limit=" +
            this.row2count +
            "&cat1=" +
            this.attributes.cat1 +
            "&cat2=" +
            this.attributes.cat2+'&images_query='+this.attributes.images_query,
          {}
        )
        .then((res) => {
          this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : "";
        })
        .catch((res) => {
          console.log(res);
        });

      axios
        .get(
          "https://api.cnphub.com/public/twoCategoryData" +
            "?limit=" +
            this.row3count +
            "&cat1=" +
            this.attributes.cat2 +
            "&cat2=" +
            this.attributes.cat3+'&images_query='+this.attributes.images_query,
          {}
        )
        .then((res) => {
          this.cat3Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat3name = res.data.data.cat2name ? res.data.data.cat2name : "";
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getPopularCategories() {
      axios
        .get(
          "https://api.cnphub.com/public/popularCategories" + "?limit=3"+'&images_query='+this.attributes.images_query
        )
        .then((res) => {
          this.popularCategories = res.data.data.popularCategories
            ? res.data.data.popularCategories
            : [];
        });
    },
    getRecentPosts() {
      axios
        .get("https://api.cnphub.com/public/recentPosts" + "?limit=4"+'&images_query='+this.attributes.images_query)
        .then((res) => {
          this.recentPosts = res.data.data.recentPosts
            ? res.data.data.recentPosts
            : [];
        });
    },
  },
};
</script>


<style>
.heading.heading-middle-border {
  position: relative;
}

html .bg-color-secondary,
html .bg-secondary {
  background-color: var(--color) !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.heading.heading-middle-border h2,
.heading.heading-middle-border h3 {
  padding-right: 10px;
}

.text-1 {
  font-size: 1.2em !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.thumb-info-caption-text h4 {
  font-size: 1.2rem;
  color: var(--color) !important;
}

.thumb-info-caption-text h4 a {
  font-size: 1.2rem;
  color: var(--secondary_color) !important;
}

.thumb-info-caption-text .text-color-default {
  color: var(--secondary_color);
}

.lang-ar .text-3, .lang-ar .text-4{
  text-align: right;
}

.lang-ar .thumb-info-caption-text .text-default{
  text-align: right;
}

.lang-ar .text-default{
  /* float: right; */
}

.lang-ar .text-decoration-none{
  text-align: right;
}

.lang-ar .post-title{
  text-align: right;
}

.lang-ar .post-content p{
  text-align: right;
}

.lang-ar .thumb-info-caption-text{
  text-align: right;
}

</style>