<template>
	<article class="post">
		<figure
			class="post-media"
			:class="post.type==='video'? 'post-video':''"
		>
			<router-link
				:to="'/article/'+post.slug+''"
			>
				<img
					v-lazy="`${post.image}`"
					alt="blog"
					:width="400"
					:height="400"
					
				/>
			</router-link>

		</figure>

		<div class="post-body">
			<h2 class="post-title">
				<router-link :to="'/article/'+post.slug+''">{{ post.title }}</router-link>
			</h2>

			<div
				class="post-content"
				v-if="isContent"
			>
				<p>{{ post.description }}</p>
			</div>

			
		</div>
	</article>
</template>
<script>
import { baseUrl } from '@/api';
import { baseSlider1 } from '@/utils/data/carousel';

export default {
	components: {
	},
	props: {
		post: Object,
		isContent: {
			type: Boolean,
			default: true
		},
		isAuthor: {
			type: Boolean,
			default: true
		},
		isMeta: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			baseUrl: baseUrl,
			baseSlider1: baseSlider1
		};
	},
	computed: {
	},
	methods: {
		
	}
};
</script>