<template>
  <div>
    <section class="section section-no-border bg-color-light m-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-sm-7">
            <h2 class="font-weight-bold">{{ $t("About us") }}</h2>
            <p v-html="settings.about_text">
            </p>
          </div>
          <div class="col-lg-5 col-sm-5 d-none d-sm-block">
            <div class="custom-box-squares">
              <img
                class="custom-cloud left-pos-2"
                alt=""
                :src="getImage(settings.about_image ,'default')"
              />
              <div
                class="custom-big-square left-pos-2 custom-box-shadow"
                :style="setBackgound"
              ></div>
              <div
                class="custom-small-square left-pos-2 custom-box-shadow"
                :style="setBackgound"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
  setBackgound() {
      return {
        "background-image":
          "url(" + this.settings.storageUrl + this.settings.about_image + ")",
        "background-size": "cover",
        "background-position": "center",
      };
    },
  },
  
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>

</style>