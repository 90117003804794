<template>
  <div class="trending-social">
    <div class="section-title">
      <h3 class="title">Follow us</h3>
    </div>
    <ul>
      <li>
        <a href="#">
          <i class="fab fa-facebook-f"></i>
          <span>34,456</span>
          <p>Fans</p>
        </a>
      </li>
      <li>
        <a class="item-2" href="#">
          <i class="fab fa-twitter"></i>
          <span>34,456</span>
          <p>Followers</p>
        </a>
      </li>
      <li>
        <a class="item-3" href="#">
          <i class="fab fa-youtube"></i>
          <span>34,456</span>
          <p>Subscribers</p>
        </a>
      </li>
    </ul>
    <ul>
      <li>
        <a class="item-4" href="#">
          <i class="fab fa-instagram"></i>
          <span>34,456</span>
          <p>Followers</p>
        </a>
      </li>
      <li>
        <a class="item-5" href="#">
          <i class="fab fa-vimeo-v"></i>
          <span>34,456</span>
          <p>Followers</p>
        </a>
      </li>
      <li>
        <a class="item-6" href="#">
          <i class="fab fa-maxcdn"></i>
          <span>34,456</span>
          <p>Fans</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
