<template>
	<footer class="footer bg-dark position-relative">
		<div class="footer-middle">
			<div class="container position-static">
				<div class="row">
					<div class="col-lg-3 col-sm-6 pb-5 pb-sm-0">
						<div class="widget">
							<h4 class="widget-title">{{ $t("About Us") }}</h4>
							<img
								src="@/assets/css/images/logo-footer.png"
								alt="Logo"
								class="logo-footer"
								width="101"
								height="40"
							>
							<p class="m-b-4 ls-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec vestibulum magna, et dapibus lacus. Duis nec vestibulum magna, et dapibus lacus.</p>
							<div class="social-icons">
								<a
									href="javascript:;"
									class="social-icon social-facebook icon-facebook"
									
									title="Facebook"
								></a>
								<a
									href="javascript:;"
									class="social-icon social-twitter icon-twitter"
									
									title="Twitter"
								></a>
								<a
									href="javascript:;"
									class="social-icon social-linkedin fab fa-linkedin-in"
									
									title="Linkedin"
								></a>
							</div>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-5 pb-sm-0">
						<div class="widget mb-2">
							<h4 class="widget-title pb-1">Customer Service</h4>

							<ul class="links">
								<li><a href="javascript:;">Help & FAQs</a></li>
								<li><a href="javascript:;">Order Tracking</a></li>
								<li><a href="javascript:;">Shipping & Delivery</a></li>
								<li><a href="javascript:;">Orders History</a></li>
								<li><a href="javascript:;">Advanced Search</a></li>
								<li><a href="/pages/account">My Account</a></li>
								<li><a href="javascript:;">Careers</a></li>
								<li><a href="/pages/about-us">{{ $t("About Us") }}</a></li>
								<li><a href="javascript:;">Corporate Sales</a></li>
								<li><a href="javascript:;">Privacy</a></li>
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-5 pb-sm-0">
						<div class="widget widget-post">
							<h4 class="widget-title pb-1">{{ $t('Latest News') }}</h4>

							<ul class="links">
								<li><a href="javascript:;">Top Jeans Collection<br /><span class="font1">JULY 23, 2021</span></a></li>
								<li><a href="javascript:;">Post Format Standard<br /><span class="font1">FEBRUARY 26, 2020</span></a></li>
								<li><a href="javascript:;">Post Format Video<br /><span class="font1">FEBRUARY 26, 2019</span></a></li>
							</ul>
						</div>
					</div>

					<div class="col-lg-3 col-sm-6 pb-5 pb-sm-0">
						<div class="widget widget-newsletter">
							<h4 class="widget-title">Subscribe newsletter</h4>
							<p class="mb-2 ls-0">Get all the latest information on events, sales and offers. Sign up
								for newsletter:
							</p>
							<form
								action="#"
								class="mb-0"
							>
								<input
									type="email"
									class="form-control"
									placeholder="Email address"
									required
								>
								<input
									type="submit"
									class="btn btn-primary ls-10 shadow-none"
									value="Subscribe"
								>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom d-sm-flex align-items-center">
				<div class="footer-left">
					<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</span>
				</div>

				<div class="footer-right ml-auto mt-1 mt-sm-0">
					<div class="payment-icons mr-0">
						<span
							class="payment-icon visa"
							v-lazy:background-image="'./images/payments/payment-visa.svg'"
						></span>
						<span
							class="payment-icon paypal"
							v-lazy:background-image="'./images/payments/payment-paypal.svg'"
						></span>
						<span
							class="payment-icon stripe"
							v-lazy:background-image="'./images/payments/payment-stripe.png'"
						></span>
						<span
							class="payment-icon verisign"
							v-lazy:background-image="'./images/payments/payment-verisign.svg'"
						></span>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {};
</script>