<template>
	<div class="product-default left-details product-widget mb-2" >
		<figure>
			<router-link :to="'/article/' + post.slug + ''">
				<template v-if="isLazy">
					<img alt="blog" :width="200" :height="200" v-lazy="`${getImage(post.image,'thumb')}`"
						:src="getImage(post.image,'thumb')" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" />
					<!-- <img v-if="post.image" :src="`${getImage(post.image, 'thumb')}`" :v-lazy="`${getImage(post.image, 'thumb')}`" :width="80" :height="80"
						:alt="post.name" /> -->
				</template>


			</router-link>
		</figure>

		<div class="product-details">


			<h2 class="product-title">
				<router-link :to="'/article/' + post.slug + ''"  v-html="getPostDescription(post.name,99)"></router-link>
			</h2>

		</div>
	</div>
</template>

<script>
import { baseUrl } from '@/api/index';

export default {
	props: {
		post: Object,
		isLazy: {
			type: Boolean,
			default: true
		},
		isCat: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			baseUrl: baseUrl,

		};
	},
	mounted: function () {
	},
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getPostDescription(text) {
			return this.$postHelper.getPostSmallDescription(text, 120);
		},
	}
};
</script>