<template>
	<header class="header theme7">
		<div class="header-wrapper">
			<div class="header-top sticky-header mobile-sticky">
				<div class="container">
					<div class="header-left header-dropdowns">
						<!-- <div class="header-dropdown d-none d-lg-block">
							<a href="javascript:;" class="pl-0">ENG</a>
							<div class="header-menu">
								<ul>
									<li>
										<a href="javascript:;">ENG</a>
									</li>
									<li>
										<a href="javascript:;">FRA</a>
									</li>
								</ul>
							</div>
						</div> -->
						<div class="header-dropdown  dropdown-language  d-none d-lg-block">
							<div v-for="(language, index) in languages" :key="index">
								<a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
									<i :class="'flag-' + language.lang_flag + ' flag'"></i> </a>
							</div>
							<div class="header-menu">
								<ul>
									<li v-for="(language, index) in languages" :key="index"
										@click="selectLang(language)">
										<a href="javascript:;">
											<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
												language.lang_name
											}}</a>
									</li>
								</ul>
							</div>
						</div>

						<button class="mobile-menu-toggler d-lg-none d-block" type="button" @click="showMobileMenu">
							<i class="fas fa-bars text-white"></i>
						</button>

						<router-link to="/" class="logo d-block d-lg-none">
							<img :src="settings.light_logo" width="160" height="40" alt="Porto Logo" />
						</router-link>
					</div>

					<div class="header-right">
						<a :href="settings.facebook" class="social-icon social-facebook icon-facebook"
							title="Facebook"></a>
						<a :href="settings.twitter" class="social-icon social-twitter icon-twitter" title="Twitter"></a>

						<pv-header-search class="d-lg-none d-block mr-4"></pv-header-search>




					</div>
				</div>
			</div>

			<div class="header-middle d-lg-block d-none">
				<div class="container d-block">
					<div class="header-left d-none d-lg-flex justify-content-center">
						<router-link to="/" class="logo">
							<img :src="settings.light_logo" alt="Porto Logo" width="160" height="40" />
						</router-link>
						<br>
					</div>

					<div class="header-right d-lg-block d-flex">
						<pv-header-search class="d-lg-block d-none show" adClass="d-lg-none d-inline-block"
							adClass1="d-lg-flex position-relative border-0"></pv-header-search>

						<pv-header-sidebar></pv-header-sidebar>
						<pv-main-menu></pv-main-menu>
					</div>
				</div>
			</div>

			<div class=" d-none d-lg-block  position-absolute">
				<!-- <div class="container d-block text-center">
					<div class="header-left d-block">
						<h6 class="mb-0">CALL US NOW</h6>
					</div>

					<div class="header-right d-block pr-0">
						<div class="header-contact text-white">
							<a href="javascript:;">+123 456 7890</a>
						</div>
					</div>
				</div> -->
				<div class="">
					<p>{{ $t('Contacs Us') }}</p>
					<a :href="'mailto:'+ settings.email" class=""><i class="icon-envelop"></i> {{ settings.email }} </a>
					
				</div>
			</div>
		</div>
	</header>
</template>


<script>
import PvMainMenu from './HeaderTheme7/PvMainMenu.vue';
import PvHeaderSearch from './PvHeaderSearch.vue';
import PvHeaderSidebar from './HeaderTheme7/PvHeaderSidebar';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		PvHeaderSearch,
		PvHeaderSidebar
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			languages: '',
		};
	},
	created() {
		this.getWebsiteSettings();
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];

			this.settings = JSON.parse(localStorage.getItem("settings"));

			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			localStorage.setItem("langSwitch", true);
			window.location.reload();
		},
	}
};
</script>


<style>
#app .header.theme7 .logo {
	max-width: 240px;
	padding-right: 15px;
}

#app .header.theme7 {
	position: fixed;
	width: 30rem;
	top: 0;
	min-height: 100vh;
	padding: 0.81rem 2.6rem 14.7rem 2rem;
	background-color: #222529;
	
	color: #fff;
	z-index: 999;
}
#app .header.theme7 .header-top {
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid var(--color);
}
</style>