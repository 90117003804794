<template>

	<header class="header" :style="cssVars">

		<component :is="'style'">
			:root {
			--color: {{ primary_color }};
			
			--header-color: {{ header_color }};
			--footer-color: {{ footer_color }};
			}
			{{ custom_css }}
		</component>
		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left">
					<button class="mobile-menu-toggler mr-2" type="button" @click="showMobileMenu">
						<i class="fas fa-bars"></i>
					</button>
					<router-link to="/" class="logo">

						<img :src="settings.logo" width="150" height="80" alt="Porto Logo" />
					</router-link>

					<pv-main-menu></pv-main-menu>
				</div>
				<div class="header-right">
					<pv-header-search></pv-header-search>



					<div class="header-dropdown mr-auto mr-sm-3 mr-md-0">
						<div v-for="(language, index) in languages" :key="index">
							<a href="javascript:;"  v-if="language.lang_locale==$i18n.locale">
							<!-- <i class="flag-us flag"></i>{{$i18n.locale}} </a> -->
							<i :class="'flag-' + language.lang_flag + ' flag'"></i>{{ language.lang_name }} </a>
						</div>
						

						<div class="header-menu">
							<ul>
								<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">

									<a href="javascript:;">
										<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
	language.lang_name
										}}</a>
								</li>
							</ul>
						</div>
					</div>


				</div>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';

import PvHeaderSearch from './PvHeaderSearch';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,

		PvHeaderSearch
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			primary_color:'red',
			footer_color:'red',
			custom_css:null,
			settings: '',
			submenu: '',
			languages: [],
		};
	},
	computed: {
		cssVars() {
			return {

				'background-color': this.settings.header_color,
			}
		},
	},
	mounted() {
		
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.primary_color=this.settings.primary_color;
			this.footer_color=this.settings.footer_color;
			this.header_color=this.settings.header_color;
			this.custom_css=this.settings.custom_css;
			if (localStorage.getItem("footerMenu"))
				this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
			//console.log(localStorage.getItem("languages"));
			this.languages = JSON.parse(localStorage.getItem("languages"));
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			window.location.reload();
		},
	}
};
</script>

<style>
.main.home .main-nav .menu > li > a:hover {
  
    color: #fff;
}
#app  .logo {
    max-width: 140px;
    padding-right: 15px;
}
h4.section-sub-title {
	font-size: 22px!important;
    color: #555;
    border-radius: 10px 50px 50px 10px;
    padding: 5px;
    border-left: 5px solid var(--color);
    background: #e9e9e9;
}
.post-media .post-date{
	display: none;
}
.post-media .post-date{
	color: var(--color)
}
#app .main-nav .menu > li {
    margin-right: 1.5rem;
    letter-spacing: -0.01em;
}
#app .post-title-block{
	padding: 10px;
	background: var(--color);
	color:#fff;
	min-height: 80px;
}
</style>

