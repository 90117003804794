<template>
	<header class="header header-2" :style="cssVars">
		<div class="header-middle theme11 sticky-header" :style="BackgroundImg">
			<div class="container">
				<div class="header-left">
					<button class="mobile-menu-toggler mr-2" type="button" @click="showMobileMenu">
						<i class="fas fa-bars"></i>
					</button>
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="150" height="80" alt="Porto Logo" />
					</router-link>
				</div>
				<div class="header-right">
					<pv-header-search></pv-header-search>
				</div>
			</div>
		</div>
		<div class="header-bottom theme11 sticky-header desktop-sticky d-none d-lg-block">
			<div class="container">
				<!-- <div class="header-left">
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="101" height="40" />
					</router-link>
				</div> -->
				<div :style="MenuHeaderCssVars" class="col-md-12">
					<div class="header-center">
						<pv-main-menu></pv-main-menu>
						<div class="header-dropdown  dropdown-language  mr-auto mr-sm-3 mr-md-0">
							<div v-for="(language, index) in languages" :key="index">
								<a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
									<!-- <i class="flag-us flag"></i>{{$i18n.locale}} </a> -->
									<i :class="'flag-' + language.lang_flag + ' flag'"></i>{{ language.lang_name }} </a>
							</div>
							<div class="header-menu">
								<ul>
									<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">

										<a href="javascript:;">
											<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
												language.lang_name
											}}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="header-right">
						<pv-header-search></pv-header-search>

						<a href="javascript:;" class="header-icon header-icon-user" title="login">
							<i class="icon-user-2"></i>
						</a>

						<router-link to="/pages/wishlist" class="header-icon" title="wishlist">
							<i class="icon-wishlist-2"></i>
						</router-link>
					</div>
				</div>

			</div>
		</div>
	</header>
</template>

<script>

import PvMainMenu from "./PvMainMenu.vue";
import PvHeaderSearch from "./PvHeaderSearch.vue";
document.querySelector('body').classList.add('loaded');


// import TopHeaderMenu from './TopHeaderMenu.vue';



document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		// TopHeaderMenu,
		PvHeaderSearch
	},
	computed: {
		cssVars() {
			return {
				'background-color': this.settings.header_color,
			}
		},
		MenuHeaderCssVars() {
			return {
				'background-color': this.settings.primary_color,
			}
		},
		BackgroundImg() {
			
			return {
				'background': 'url('+this.settings.storageUrl + 'banners/96-960496-world-map-clipart-transparent-3d-world-map-png.png'+')',
				'background-size': 'contain',
				'background-position': 'center',
				'background-repeat': 'no-repeat',
			}

		}
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];

			this.settings = JSON.parse(localStorage.getItem("settings"));

			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			localStorage.setItem("langSwitch", true);
			window.location.reload();
		},

	}
};
</script>

<style>
.header-middle.theme11  {
	background-size: contain!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}
.main.home .theme11 .main-nav .menu>li>a ,main .theme11 .main-nav .menu>li>a {

	color: #fff !important;
}
.header .theme11 .mobile-menu-toggler {
  color: var(--color);
  margin-bottom: 6px;
}
.theme11  h4.section-sub-title {
    font-size: 22px !important;
    color: #000;
    border-radius: 10px 50px 50px 10px;
    padding: 5px;
    border-left: 0px solid var(--color);
    background: transparent;
}
.theme11  h4.section-sub-title span{
	font-weight: 900;
	position: relative;
}
.theme11  h4.section-sub-title :after{
	

	background-image: url(./../../../assets/images/logo-after-3.jpg);
	
    background-size: cover;
    content: ' ';
    display: block;
    width: 50px;
    height: 13px;
    position: absolute;
    right: -60px;
    bottom: 3px;

}
.main.home .theme11 .main-nav .menu>li>a:hover {

	color: #f2f2f2 !important;
}

#app .logo {
	max-width: 140px;
	padding-right: 15px;
}

.theme1.header-bottom {
	border-bottom: 1px solid #e7e7e7;

	background: transparent;
}

.theme1.header-bottom .container {
	border-top: 1px solid #f2f2f2;
}

h4.section-sub-title {
	font-size: 22px !important;
	color: #555;
	border-radius: 10px 50px 50px 10px;
	padding: 5px;
	border-left: 5px solid var(--color);
	background: #e9e9e9;
}

.post-media .post-date {
	display: none;
}

.post-media .post-date {
	color: var(--color)
}

#app .main-nav .menu>li {
	margin-right: 1.5rem;
	letter-spacing: -0.01em;
}

#app .post-title-block {
	padding: 10px;
	background: var(--color);
	color: #fff;
	min-height: 80px;
}
@media (max-width: 767px) 
{
	#app .header-middle.theme11 {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: 50% !important;
}
}
</style>