<template>
  <section class="innerbanner section section-with-shape-divider page-header page-header-modern page-header-lg border-0 my-0 lazyloaded" data-bg-src="img/demos/business-consulting-3/backgrounds/background-5.jpg" :style="setBackgound">
					<div class="container pb-5 my-3">
						<div class="row mb-4">
							<div class="col-md-12 align-self-center p-static order-2 text-center">
								<h1 class="font-weight-bold text-color-dark text-10">{{ post.name }}</h1>
							</div>
							<div class="col-md-12 align-self-center order-1">
								<ul class="breadcrumb d-block text-center">
									<!-- <li><a href="/">Home</a></li> -->
								</ul>
							</div>
						</div>
					</div>
					<div class="shape-divider shape-divider-bottom shape-divider-reverse-x" style="height: 123px;">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 123" preserveAspectRatio="xMinYMin">
							<polygon fill="#F3F3F3" points="0,90 221,60 563,88 931,35 1408,93 1920,41 1920,-1 0,-1 "></polygon>
							<polygon fill="#FFFFFF" points="0,75 219,44 563,72 930,19 1408,77 1920,25 1920,-1 0,-1 "></polygon>
						</svg>
					</div>
    </section>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        'background-image': 'url(' + this.settings.storageUrl + this.settings.banner_image + ')',
        'background-size': 'cover',
        'background-position': 'center'
      }
    },
  },
};
</script>

<style>
.section.section-with-shape-divider .shape-divider svg {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%,0,0);
}

svg {
    vertical-align: middle;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-x {
    transform: rotateX(180deg);
}

.section.section-with-shape-divider .shape-divider.shape-divider-bottom {
    top: auto;
    bottom: 0;
}

.section.section-with-shape-divider .shape-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
}

.page-header.page-header-modern.page-header-lg {
    padding: 65px 0;
}

.section.section-with-shape-divider {
    position: relative;
    overflow: hidden;
}

section.section {
    background: #f7f7f7;
    border-top: 5px solid #f1f1f1;
    margin: 30px 0;
    padding: 50px 0;
}


.page-header {
    background-color: #212529;
    margin: 0 0 35px;
    padding: 30px 0;
    position: relative;
    text-align: left;
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important;
}

.innerbanner{
  margin-top: -2rem !important;
}

</style>