<template>
  <div v-if="settings.status == 'pending' || settings.status == 'draft'">
      <soon :settings="settings">
      </soon>
  </div> 
  <main :class="'lang-' + getLang() +' id-'+ getWebsite() + ' main home'" v-else>
    <MainHeader :attributes="header.attributes"></MainHeader>

    <component :is="settings.inner_pages_banner" :post="post" :key="settings.inner_pages_banner"></component>

    <div v-if="!loader" class="container py-4">
      <div class="row">
        <post-sidebar v-if="settings.inner_page_style == 'LeftSideBar'" :popularCategories="popularCategories"
          :popularTags="popularTags" :recentPosts="recentPosts" :post="post" :logos="logos" :photos="photos"
          :videos="videos"></post-sidebar>
        <div :class="contentShow()">
          <article class="post">
            <div v-if="settings.title_position == 'Before_Image'" class="m-b">
              <div class="post-date ms-0">
                <span class="day">{{
                  this.$postHelper.getPostDay(post.created_at)
                }}</span>
                <span class="month">{{
                  this.$postHelper.getPostMonth(post.created_at)
                }}</span>
              </div>
              <h2 class="post-title">
                <router-link :to="'/article/' + post.slug + ''">{{
                  post.name
                }}</router-link>
              </h2>
              <div class="post-meta">
                <span><i class="far fa-folder"></i>
                  <a :href="'/category/' + category.slug + ''" v-for="(category, index) in categories" :key="index">{{
                    category.name }} ,
                  </a>
                </span>
              </div>
            </div>

            <figure class="post-media" v-if="settings.title_position !== 'Beside_Image'">
              <router-link :to="'/article/' + post.slug + ''">
                <template>
                  <img v-if="post.image" :src="getImage(post.image, 'default')" :alt="post.name" :width="300" :height="300" />
                </template>
              </router-link>
            </figure>

            <div class="row pb-1" v-if="settings.title_position == 'Beside_Image'">
              <div class="col-lg-7 mb-4 pb-2">
                <article
                  class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
                  <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                    <img v-if="post.image"  :src="getImage(post.image, 'default')" class="img-fluid" :alt="post.name">
                  </div>
                </article>
              </div>
              <div class="col-lg-5">
                <div class="post-date ms-0">
                  <span class="day">{{
                    this.$postHelper.getPostDay(post.created_at)
                  }}</span>
                  <span class="month">{{
                    this.$postHelper.getPostMonth(post.created_at)
                  }}</span>
                </div>
                <h2> {{ post.name }}</h2>
                <div class="post-meta">
                  <span><i class="far fa-folder"></i>
                    <a :href="'/category/' + category.slug + ''" v-for="(category, index) in categories" :key="index">{{
                      category.name }} ,
                    </a>
                  </span>
                </div>
                <p v-html="this.$postHelper.getPostSmallDescription(post.content, 250)">
                </p>

              </div>
            </div>

            <div class="post-body">
              <div v-if="settings.title_position == 'After_Image'">
                <div class="post-date ms-0">
                  <span class="day">{{
                    this.$postHelper.getPostDay(post.created_at)
                  }}</span>
                  <span class="month">{{
                    this.$postHelper.getPostMonth(post.created_at)
                  }}</span>
                </div>
                <h2 class="post-title">
                  <router-link :to="'/article/' + post.slug + ''">{{
                    post.name
                  }}</router-link>
                </h2>
                <div class="post-meta">
                  <span><i class="far fa-folder"></i>
                    <a :href="'/category/' + category.slug + ''" v-for="(category, index) in categories" :key="index">{{
                      category.name }} ,
                    </a>
                  </span>
                </div>
              </div>

              <div class="post-content">
                <p v-html="post.content"></p>
              </div>
            </div>
          </article>
          <!-- <pv-related-posts :posts="relatedPosts"></pv-related-posts> -->
        </div>
        <post-sidebar v-if="settings.inner_page_style == 'RightSideBar'" :popularCategories="popularCategories"
          :popularTags="popularTags" :recentPosts="recentPosts" :post="post" :logos="logos" :photos="photos"
          :videos="videos"></post-sidebar>
        <div class="cnp-mobile-sidebar">
          <post-sidebar  :popularCategories="popularCategories"
            :popularTags="popularTags" :recentPosts="recentPosts" :post="post" :logos="logos" :photos="photos"
            :videos="videos"></post-sidebar>
        </div>
        <after-post v-if="settings.inner_page_style == 'Full'" :popularCategories="popularCategories"
          :popularTags="popularTags" :recentPosts="recentPosts" :post="post" :logos="logos" :photos="photos"
          :videos="videos"></after-post>
      </div>
    </div>
    

    <MainFooter :attributes="footer.attributes"></MainFooter>
    <pv-mobile-menu></pv-mobile-menu>
  </main>
   
</template>
<script>
import axios from "axios";
import MainHeader from "../../../components/Utility/Header/MainHeader.vue";
import MainFooter from "../../../components/Utility/Footer/MainFooter.vue";
import PostSidebar from "../partials/PostSidebar";
import AfterPost from "../partials/AfterPost";
import Banner1 from "../partials/banner/banner1";
import Banner2 from "../partials/banner/banner2";
import Banner3 from "../partials/banner/banner3";
import Banner4 from "../partials/banner/banner4";
import Banner5 from "../partials/banner/banner5";
import Banner6 from "../partials/banner/banner6";
import Banner7 from "../partials/banner/banner7";
import Banner8 from "../partials/banner/banner8";
// import AfterPost1 from "../partials/AfterPost1";
import PvMobileMenu from '../../Utility/Common/PvMobileMenu.vue';//components/Utility/Common/PvMobileMenu.vue
import { useFavicon } from '@vueuse/core'
import { computed, ref } from "vue";
import soon from "../../soon.vue";
import Vue from 'vue'
import VueGtag from "vue-gtag";
import router from '../../../router'

export const adIntegration = () => {
  const adScript = document.createElement('script');

  adScript.type = 'text/javascript';
  adScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  document.head.appendChild(adScript);
};

export default {
  components: {
    PvMobileMenu,
    MainHeader,
    MainFooter,
    PostSidebar,
    AfterPost,
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
    Banner8,
    soon
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    const post = this.post;
    if(post != ''){
    return {
      title: `${(post.name !== 'undefined') ? post.name : 'News'}`,
      meta: [
        { name: "description", content: `${post.description}` },
        { property: "og:title", content: `${post.name}` },
        { property: "og:site_name", content: `${post.name}` },
        { property: "og:description", content: `${post.description}` },
        { property: "og:type", content: "post" },
        { property: "og:url", content: `${post.slug}` },
        { property: "og:image", content: `${post.image}` },
      ],
    };
    }
  },
  jsonld() {
    const post = this.post;
    const settings = this.settings;
    console.log(settings);
    if(post != ''){
      return {
         "@context": "http://schema.org/",
         "@type": "Article",
         "name": post.name,
         "description": post.description,
         "discussionUrl": 'https://'+settings.domain+'/article/'+post.slug
      }
    }
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },
  data: function () {
    return {
      loader:true,
      post: [],
      categories: [],
      popularCategories: [],
      recentPosts: [],
      relatedPosts: [],
      popularTags: [],
      header: "",
      footer: "",
      logos: "",
      videos: "",
      photos: "",
      banner: "",
    };
  },
  created() {
    //  this.getPostLang()
    //this.checkLang();
    this.getPostData();
    this.getPopularCategories();
    this.getRecentPosts();
    this.getPopularTags();
    this.getWebsiteSettings();
    this.getLanguage();
    this.getMenu();
    this.favicon();
    this.header = JSON.parse(localStorage.getItem("header"));
    this.footer = JSON.parse(localStorage.getItem("footer"));
    this.settings = JSON.parse(localStorage.getItem("settings"));
    this.banner = this.settings.inner_pages_banner;
  },
  mounted() {
    this.favicon();
    adIntegration();
    setTimeout(() => this.mainPageAd(), 3000);
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
  computed: {},
  methods: {

    // checkLang(){
    //   var slug = decodeURI(window.location.pathname.split("/").pop());//.pathname.split("/").pop()
    //   //console.log('slug=='+decodeURI(slug));
    //   let regExp = /[\u0600-\u06FF]/g;
    //   if(regExp.test(slug) && localStorage.getItem("locale")=='en'){
    //     localStorage.setItem("locale", 'ar');
    //     localStorage.setItem("lang_code", 'ar');
    //     localStorage.setItem("lang", 'ar');
    //     window.location.reload();
    //   }
    // },
    mainPageAd(){
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {

        var tags = JSON.parse(this.settings.tag_script);

        window.googletag.destroySlots();

        for(var i=0; i< tags.length; i++){
          window.googletag.defineSlot(''+ tags[i].id +'', [parseInt(tags[i].size1), parseInt(tags[i].size2)], ''+ tags[i].name +'').addService(window.googletag.pubads());
        }

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();

        for(var j=0; j< tags.length; j++){
            window.googletag.display('' +tags[j].name+ '');
        }

      });
    },
    addAnalytics(){
      Vue.use(VueGtag, {
        config: { id: this.settings.google_analytics }
      }, router);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getLang: function () {
      return localStorage.getItem("locale");
    },
    getWebsite: function () {
      return this.settings.id;
    },
    favicon() {
      const type = ref('vueuse')
      const favicon = computed(() =>
        type.value = this.$helpers.getImageFullPath(this.settings.favicon, 'medium')
      )
      useFavicon(favicon, {
        baseUrl: '',
        rel: 'icon',
      })
    },
    getWebsiteElements() {
      this.body = [];
      this.isLoading = true;
      axios.get('https://api.cnphub.com/public/website')
        .then(res => {
          // this.body = res.data.data ? res.data.data : [];
          this.body = res.data.data.elements ? res.data.data.elements : [];
          localStorage.setItem("body", res.data.data.elements ? JSON.stringify(res.data.data.elements) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    async getWebsiteSettings() {
      this.settings = [];
      axios.get('https://api.cnphub.com/public/settings')
        .then(res => {

          this.websiteSettings = res.data.data.settings ? res.data.data.settings : '';
          localStorage.setItem("settings", res.data.data.settings ? JSON.stringify(res.data.data.settings) : '');
          localStorage.setItem("submenu", res.data.data.submenu ? JSON.stringify(res.data.data.submenu) : '');
          localStorage.setItem("languages", res.data.data.languages ? JSON.stringify(res.data.data.languages) : '');
          this.settings = res.data.data.settings ? res.data.data.settings : [];
          localStorage.setItem("header", this.settings.header ? this.settings.header : 'Header1');
          localStorage.setItem("footer", this.settings.footer ? this.settings.footer : 'Footer1');
          localStorage.setItem("webiste_id", this.settings.id ? this.settings.id : '5');
          this.getWebsiteElements();
          this.addAnalytics();
        })
        .catch(res => {
          console.log(res);
        });
    },
    getLanguage: function () {
      if (localStorage.getItem("locale")) {
        this.$i18n.locale = localStorage.getItem("locale");
      } else {
        localStorage.setItem("locale", this.$i18n.locale);
        localStorage.setItem("lang_code", 'en_US');
        localStorage.setItem("lang", 'en');
      }
    },
    getMenu() {
      axios.get('https://api.cnphub.com/public/menu')
        .then(res => {
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
          this.footerMenu = res.data.data.footerMenu ? res.data.data.footerMenu : [];
          localStorage.setItem("mainMenu", res.data.data.mainMenu ? JSON.stringify(res.data.data.mainMenu) : '');
          localStorage.setItem("footerMenu", res.data.data.footerMenu ? JSON.stringify(res.data.data.footerMenu) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    asyncData() { },
    getPostData() {
      var slug = window.location.pathname.split("/").pop();
      try {
      axios
        .get("https://api.cnphub.com/public/post" + "?slug=" + slug+ "&langSwitch=" + localStorage.getItem("langSwitch"))
        .then((res) => {
          if(res.data.data == 'empty'){
            this.$router.push({ name: 'Home' });
          }
          this.post = res.data.data.post ? res.data.data.post : [];

          // get slug language 
          var postLang = res.data.data.post.lang ? res.data.data.post.lang : 'en';

          // check if current locale don't equal post locale reload
          if(localStorage.getItem("lang_code") !== postLang)
            window.location.reload();

          if(postLang=='ar'){
            localStorage.setItem("locale", 'ar');
            localStorage.setItem("lang_code", 'ar');
            localStorage.setItem("lang", 'ar');
           }else{
            localStorage.setItem("locale", 'en');
            localStorage.setItem("lang_code", 'en_US');
            localStorage.setItem("lang", 'en');
           }

          localStorage.setItem("langSwitch",false);
          
          this.loader=false;
          this.logos = JSON.parse(this.post.logos);
          this.photos = JSON.parse(this.post.photos);
          this.videos = JSON.parse(this.post.videos);
          this.categories = res.data.data.categories
            ? res.data.data.categories
            : [];
          this.relatedPosts = res.data.data.relatedPosts
            ? res.data.data.relatedPosts
            : [];
        });
      } catch (error) {
          this.$router.push({ name: 'PageNotFound' });
      }
    },
    getPostLang() {
      if(localStorage.getItem("langSwitch")=='true'){
        localStorage.setItem("langSwitch",false);
      }else{
        var slug = window.location.pathname.split("/").pop();
      try {
      axios
        .get("https://api.cnphub.com/public/post-lang" + "?slug=" + slug)
        .then((res) => {
          if(res.data.data == 'empty'){
            this.$router.push({ name: 'Home' });
          }
          var postLang=res.data.data.postLang ? res.data.data.postLang : 'en';
          // alert(localStorage.getItem("locale"));
          if(localStorage.getItem("lang_code")!=postLang ||
          (localStorage.getItem("lang_code") =='en_US' && postLang=='ar') ||
          (localStorage.getItem("lang_code") =='ar' && postLang=='en_US') ){
           // alert(postLang);
           if(postLang=='ar'){
            localStorage.setItem("locale", 'ar');
            localStorage.setItem("lang_code", 'ar');
            localStorage.setItem("lang", 'ar');
           }else{
            localStorage.setItem("locale", 'en');
            localStorage.setItem("lang_code", 'en_US');
            localStorage.setItem("lang", 'en');
           }
          
          window.location.reload();
          // alert(postLang);
          }
        
          
        });
      } catch (error) {
          this.$router.push({ name: 'PageNotFound' });
      }
      }
      
    },
    getPopularCategories() {
      axios
        .get(
          "https://api.cnphub.com/public/popularCategories" + "?limit=3"
        )
        .then((res) => {
          this.popularCategories = res.data.data.popularCategories
            ? res.data.data.popularCategories
            : [];
        });
    },
    getRecentPosts() {
      axios
        .get("https://api.cnphub.com/public/recentPosts" + "?limit=3")
        .then((res) => {
          this.recentPosts = res.data.data.recentPosts
            ? res.data.data.recentPosts
            : [];
        });
    },
    getPopularTags() {
      axios
        .get(
          "https://api.cnphub.com/public/popularTags" + "?slug=test-new"
        )
        .then((res) => {
          this.popularTags = res.data.data.popularTags
            ? res.data.data.popularTags
            : [];
        });
    },
    contentShow() {
      if (this.settings.inner_page_style == "Full") return "col-md-12";
      else if (this.settings.inner_page_style == "Center2Sidebar")
        return "col-md-6";
      else return "col-md-9";
    },
  },
};
</script>

<style>
.page-header .breadcrumb a {
  color: var(--color) !important;
}

.page-header .breadcrumb li {
  color: #fff;
}

.post a {
  color: var(--color) !important;
}

.post-meta span a:hover,
.post-meta span a:focus {
  background-color: none !important;
}

.m-b {
  margin-bottom: 20px;
}
</style>
