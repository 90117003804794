<template>
	<footer class="footer theme10 bg-dark position-relative">
		<div class="footer-middle">
			<div class="container position-static">
				<div class="row">
					<div class="col-lg-12 col-sm-12 pb-2 pb-sm-0">
						<div class="logo">
							<router-link to="/" class="logo">

								<img :src="settings.logo" width="150" height="80" alt="Logo" />
							</router-link>
						</div>
						<br>
						<ul class="links">
							<li v-for="item in footerMenu" :key="item.id">
								<router-link :to="item.url">{{ item.title }}</router-link>
							</li>
						</ul>

					</div>


				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom">
				<div class="container  align-items-center">
					<div class="pb-2 pb-sm-0">
						<div class="widget">


						</div>
					</div>
					<div class="d-sm-flex">
						<div class="footer-left">

						</div>
						<div class="footer-middle ml-auto mt-1 mt-sm-0 text-center">

							<div class="payment-icons">
								<div class="social-icons">
									<a v-if="settings.facebook" :href="settings.facebook"
										class="social-icon social-facebook icon-facebook" title="Facebook"></a>
									<a v-if="settings.twitter" :href="settings.twitter"
										class="social-icon social-twitter icon-twitter" title="Twitter"></a>
									<a v-if="settings.instagram" :href="settings.instagram"
										class="social-icon social-linkedin fab fa-linkedin-in" title="Linkedin"></a>
								</div>
							</div>
							<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{
								$t('All Rights Reserved.')
							}}</span>
						</div>

						<div class="footer-right ml-auto mt-1 mt-sm-0">

						</div>
					</div>

				</div>
			</div>
		</div>
	</footer>
</template>
<script>
import axios from 'axios'

export default {
	props: {
		attributes: {
			settings: Array,
			footerMenu: Array,
		},
	},
	data: function () {
		return {

		};
	},
	created() {
		this.getWebsiteSettings();
	},
	computed: {
	},
	mounted() {


	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},

	},
};
</script>

<style>
#app .footer {
	background: var(--footer-color) !important;
}

.theme10.footer .logo img {
	margin: 0 auto;
}

#app .footer-middle {
	padding-top: 1.7rem;
	padding-bottom: 0rem;
}

.theme10.footer .logo img {
	position: absolute;
	background: var(--color);
	top: -55px;
	padding: 10px;
}

.theme10.footer .social-icon:not(:hover):not(:active):not(:focus) {
	color: var(--color);
}

.theme10.footer .footer-bottom {
	border-top: 4px solid var(--color);
}
</style>