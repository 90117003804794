<template>
	<header29 ></header29>
</template>

<script>
import Header29 from '../Header29/index.vue';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		Header29
	},
	computed: {
		HeaderCssVars() {
			return {
				'background-color': this.settings.header_color,
			}
		},
		TopHeaderCssVars() {
			return {
				'background-color': this.settings.secondary_color,
			}
		},
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];

			this.settings = JSON.parse(localStorage.getItem("settings"));

			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			localStorage.setItem("langSwitch", true);
			window.location.reload();
		},

	}
};
</script>

<style>
#app .header.theme5 .logo {
	max-width: 240px;
	padding-right: 15px;
}

.header.theme5 {
	background: #fff;
	padding: 0px ;
	box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 50%);

}
.theme5  .header-search-category .form-control {
    border-radius: 0rem 0 0 0rem;
}
.theme5 .header-center {
	margin: 0 auto;
}
.theme5  .header-middle {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}
.theme5  .header-search-category .btn {
    border-radius: 0 0rem 0rem 0;
}
.theme5 .header-top .main-nav .menu>li>a {
	font-size: 11px;
	font-weight: 500;
	padding: 2rem 0;
	color: #fff;
}

.main.home .header-middle .menu>li>a:hover {
	color: var(--color);
}

#app .theme5 .main-articles.top-news-sidebar {
    background: #fff;
    border: 1px solid #f2f2f2;
}

#app  .theme5 .section-sub-title{
	color: #000;
    font-weight: 700;
    border: 0;
    font-size: 22px!important;
    border-radius: 0;
    padding: 5px;
	padding-left: 0px;
    border-bottom: 5px solid #000;
    background: #fff;
}


#app .theme-3 .logo {
	max-width: 220px;
	width: 240px;
	padding-right: 15px;
}
.theme5 .section-sub-title {
    color: #222529;
    font-weight: 700;
    border: 0px;
    font-size: 22px!important;
    border-radius: 0px;
    padding: 5px;
    border-bottom: 5px solid var(--color);
    background: #fff;
}
.theme5 .time,.theme4 .main-articles .time{
  color:var(--secondary-color)!important;
  font-weight: 600;
}
.upper-header-top .main-nav .menu > li > a{
	color: #fff;
}
</style>