<template>
	<nav class="main-nav">
		<ul class="menu menu-vertical sf-arrows">
			<li
				:class="{active: $route.path === '/'}"
				class="border-0"
			>
				<nuxt-link to="/">HOME</nuxt-link>
			</li>
			<li
				:class="{active: $route.path.indexOf('/shop') > -1}"
				class="border-0"
			>
				<nuxt-link
					to="/shop"
					class="sf-with-ul border-0"
					:class="{active: $route.path.indexOf('/shop') > -1}"
				>CATEGORIES</nuxt-link>

				<div class="megamenu megamenu-fixed-width megamenu-3cols">
					<div class="row">
						<div class="col-lg-4">
							<a
								href="javascript:;"
								class="nolink"
							>VARIATION 1</a>

							<ul class="submenu">
								<li
									v-for="item in [{title:'test',url:'test',hot:1}]"
									:key="item.id"
								>
									<nuxt-link :to="item.url">
										{{ item.title }}
										<span
											class="tip tip-hot"
											v-if="item.hot"
										>Hot</span>
									</nuxt-link>
								</li>
							</ul>
						</div>

						<div class="col-lg-4">
							<a
								href="javascript:;"
								class="nolink"
							>VARIATION 2</a>

							<ul class="submenu">
								<li
									v-for="item in [{title:'test',url:'test',hot:1}]"
									:key="item.id"
								>
									<nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
								</li>
							</ul>
						</div>
						<div class="col-lg-4 p-0">
							<div class="menu-banner">
								<figure>
									<img
										src="https://d-themes.com/vue/porto/demo-10/images/home/slider/slide-1.jpg"
										alt="Menu banner"
										width="300"
										height="300"
									/>
								</figure>
								<div class="banner-content">
									<h4>
										<span class>UP TO</span>
										<br />
										<b class>50%</b>
										<i>OFF</i>
									</h4>
									<nuxt-link
										to="/shop"
										class="btn btn-sm btn-dark"
									>SHOP NOW</nuxt-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>

			<li
				:class="{active: $route.path.indexOf('/product') > -1}"
				class="border-0"
			>
				<nuxt-link
					to="/product/default/casual-note-bag"
					class="sf-with-ul border-0"
					:class="{active: $route.path.indexOf('/product') > -1}"
				>PRODUCTS</nuxt-link>

				<div class="megamenu megamenu-fixed-width megamenu-3cols">
					<div class="row">
						<div class="col-lg-4">
							<a
								href="javascript:;"
								class="nolink"
							>PRODUCT PAGES</a>

							<ul class="submenu">
								<li
									v-for="item in [{title:'test',url:'test',hot:1}]"
									:key="item.title"
								>
									<nuxt-link :to="item.url">
										{{ item.title }}
										<span
											class="tip tip-hot"
											v-if="item.hot"
										>Hot</span>
									</nuxt-link>
								</li>
							</ul>
						</div>

						<div class="col-lg-4">
							<a
								href="javascript:;"
								class="nolink"
							>PRODUCT LAYOUTS</a>

							<ul class="submenu">
								<li
									v-for="item in [{title:'test',url:'test',hot:1}]"
									:key="item.title"
								>
									<nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
								</li>

								<li>
									<a href="javascript:;">Build your own</a>
								</li>
							</ul>
						</div>

						<div class="col-lg-4 p-0">
							<div class="menu-banner menu-banner-2 position-relative">
								<figure>
									<img
									src="https://d-themes.com/vue/porto/demo-10/images/home/slider/slide-1.jpg"
										alt="Menu banner"
										class="product-promo"
										width="380"
										height="790"
									/>
								</figure>
								<i>OFF</i>
								<div class="banner-content">
									<h4>
										<span class>UP TO</span>
										<br />
										<b class>50%</b>
									</h4>
								</div>
								<nuxt-link
									to="/shop"
									class="btn btn-sm btn-dark"
								>SHOP NOW</nuxt-link>
							</div>
						</div>
					</div>
				</div>
			</li>

			<li
				:class="{active: isPageItemActived}"
				class="border-0"
			>
				<a
					href="javascript:;"
					class="sf-with-ul border-0"
					:class="{active: isPageItemActived}"
				>PAGES</a>

				<ul>
					<li
						v-for="item in mainMenu.other"
						:key="item.title"
					>
						<nuxt-link
							:to="item.url"
							:class="{'sub-menu-link': item.subPages}"
						>{{ item.title }}</nuxt-link>

						<ul v-if="item.subPages">
							<li
								v-for="subItem in item.subPages"
								:key="subItem.title"
							>
								<nuxt-link :to="subItem.url">{{ subItem.title }}</nuxt-link>
							</li>
						</ul>
					</li>
				</ul>
			</li>

			<li
				:class="{active: $route.path.indexOf('/pages/blog') > -1}"
				class="border-0"
			>
				<nuxt-link
					to="/pages/blog"
					:class="{active: $route.path.indexOf('/pages/blog') > -1}"
					class="border-0"
				>BLOG</nuxt-link>
			</li>
			<li class="border-0">
				<nuxt-link
					to="/pages/contact-us"
					class="border-0"
				>CONTACT US</nuxt-link>
			</li>
			<li class="border-0">
				<nuxt-link
					to="/pages/about-us"
					class="border-0"
				>ABOUT US</nuxt-link>
			</li>
			<li class="border-0">
				<nuxt-link
					to="/pages/login"
					class="border-0"
				>LOG IN</nuxt-link>
			</li>
		</ul>
	</nav>
</template>

<script>

//import { VueSlideToggle } from 'vue-slide-toggle';

export default {
	components: {
		//VueSlideToggle
	},
	
	data: function() {
		return {
			isHomeActive: false,
			sidebarOpened: false,
			// mainMenu
		};
	},
	watch: {
		$route: function() {
			this.isHomeActive = this.$route.path === '/' ? true : false;
		}
	},
	computed: {
		isPageItemActived: function() {
			let exItems = ['blog', 'about-us', 'contact-us'];
			if (
				this.$route.path.includes('/pages') &&
				exItems.findIndex(item => this.$route.path.includes(item)) ===
					-1
			) {
				return true;
			}

			return false;
		}
	},
	mounted: function() {
		let items = document.querySelectorAll('.menu-vertical > li');
		items.forEach(item => {
			item.addEventListener('mouseenter', this.mouseOverHandler);
			item.addEventListener('mouseleave', this.mouseLeaveHandler);
		});
		this.isHomeActive = this.$route.path === '/' ? true : false;
	},
	destroyed: function() {
		window.removeEventListener('resize', this.resizeHandler);

		let items = document.querySelectorAll('.sf-with-ul');
		items.forEach(item => {
			item.removeEventListener('mouseover', this.mouseOverHandler);
			item.removeEventListener('mouseleave', this.mouseLeaveHandler);
		});
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];

			this.settings = JSON.parse(localStorage.getItem("settings"));

			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
		mouseOverHandler: function(e) {
			let currentTarget = e.currentTarget;
			let item;

			currentTarget.classList.add('show');
			if (e.currentTarget.querySelector('.megamenu')) {
				item = e.currentTarget.querySelector('.megamenu');
			} else if (e.currentTarget.querySelector('ul')) {
				item = e.currentTarget.querySelector('ul');
			}
			if (item) {
				setTimeout(() => {
					if (currentTarget.classList.contains('show')) {
						item.setAttribute(
							'style',
							'left: 108%; display: block'
						);
					}
				}, 50);
				setTimeout(() => {
					if (currentTarget.classList.contains('show')) {
						item.setAttribute(
							'style',
							'left: 116%; display: block'
						);
					} else item.setAttribute('style', '');
				}, 200);
			}
		},
		mouseLeaveHandler: function(e) {
			e.currentTarget.classList.remove('show');
			if (e.currentTarget.querySelector('.megamenu'))
				e.currentTarget
					.querySelector('.megamenu')
					.setAttribute('style', '');
			if (e.currentTarget.querySelector('ul'))
				e.currentTarget.querySelector('ul').setAttribute('style', '');
		}
	}
};
</script>