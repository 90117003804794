<template>
	<header class="header theme10 header-2 mb-2" :style="TopHewaderCssVars">

		<div class="container">
			<div class="header-top d-flex align-items-center w-100">
				<div class="header-left">
					<top-header-menu></top-header-menu>
					<!-- <p class="top-message ls-0 text-uppercase text-white d-none d-sm-block">{{ settings.about }}</p> -->
				</div>

				<div class="header-right header-dropdowns w-sm-100">
					<div class="header-dropdown dropdown-expanded d-none d-lg-block mr-2">
						<a href="javascript:;">Links</a>
						<div class="header-menu">
							<ul>
								<li>
									<router-link to="/contact">{{ $t('Contact Us') }}</router-link>
								</li>
								<li>
									<router-link to="/about">{{ $t('About Us') }}</router-link>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator"></span>
					<div class="header-dropdown  dropdown-language  mr-auto mr-sm-3 mr-md-0">
						<div v-for="(language, index) in languages" :key="index">
							<a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
								<i :class="'flag-' + language.lang_flag + ' flag'"></i>{{ language.lang_name }} </a>
						</div>


						<div class="header-menu">
							<ul>
								<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">
									<a href="javascript:;">
										<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
											language.lang_name
										}}</a>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator"></span>

					<div class="social-icons">
						<a v-if="settings.facebook" :href="settings.facebook"
							class="social-icon social-facebook icon-facebook"></a>
						<a v-if="settings.twitter" :href="settings.twitter"
							class="social-icon social-twitter icon-twitter"></a>
						<a v-if="settings.instagram" :href="settings.instagram"
							class="social-icon social-instagram icon-instagram"></a>
					</div>

					<pv-header-search class="pl-3 pr-1"></pv-header-search>
				</div>
			</div>
		</div>
		<div class="header-middle sticky-header mobile-sticky" :style="HeaderCssBackground">
			<div class="container">
				<div class="header-left col-lg-3 w-auto pl-0 logo-container">
					<button class="mobile-menu-toggler mr-2" type="button" @click="showMobileMenu">
						<i class="fas fa-bars"></i>
					</button>
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="250" height="40" :alt="settings.webiste" />
					</router-link>
				</div>
				<div class="header-center">
					<pv-main-menu></pv-main-menu>
				</div>
				<div class="header-right w-lg-max ml-0">


					<div class="header-contact d-none d-lg-flex pl-4 ml-3 mr-xl-5">
						<!-- <pv-header-search class="pl-3 pr-1"></pv-header-search> -->
					</div>


				</div>
			</div>
		</div>

		<div class="header-bottom sticky-header desktop-sticky d-none d-lg-block">
			<div class="container">
				<div class="header-left">
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="101" height="40" />
					</router-link>
				</div>
				<div class="header-center">

				</div>
				<div class="header-right">
					<pv-header-search></pv-header-search>

					<!-- <a href="javascript:;" class="header-icon header-icon-user" title="login">
						<i class="icon-user-2"></i>
					</a>

					<router-link to="/pages/wishlist" class="header-icon" title="wishlist">
						<i class="icon-wishlist-2"></i>
					</router-link> -->

				</div>
			</div>
		</div>
	</header>
</template>

<script>

import PvMainMenu from "./PvMainMenu.vue";
import PvHeaderSearch from "./PvHeaderSearch.vue";
document.querySelector('body').classList.add('loaded');


import TopHeaderMenu from './TopHeaderMenu.vue';



document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		TopHeaderMenu,
		PvHeaderSearch
	},
	computed: {
		cssVars() {
			return {
				'background-color': this.settings.primary_color,
			}
		},
		TopHewaderCssVars() {
			return {
				'background-color': this.settings.header_color,
				'margin-bottom': '50px!important',
			}
		},
		HeaderCssBackground() {
			return {
				'background': this.settings.storageUrl + '/general/header-bg.png',
			}
		},
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];

			this.settings = JSON.parse(localStorage.getItem("settings"));

			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			localStorage.setItem("langSwitch", true);
			window.location.reload();
		},

	}
};
</script>

<style>
#app .header.theme10 .logo-container .logo img {
	position: absolute;
	background: var(--color);
	top: -18px;
	padding: 10px;
}

#app .header.theme10 .logo {
	max-width: 250px;
	padding-right: 15px;
	margin-top: -30px;
}

.top-news-posts.theme2 .section-sub-title,
.news-List-2-col.theme2 .section-sub-title {
	color: #222529;
	font-weight: 700;
	border: 0px;
	font-size: 22px !important;
	border-radius: 0px;
	padding: 5px;
	border-bottom: 5px solid var(--color);
	background: #fff;
}

#app .header.header-2 .logo {
	max-width: 250px;
	padding-right: 15px;
}

#app .header.header-2 .header-top {
	padding-top: 7px;
	padding-bottom: 7px;
	border-bottom: 1px solid #ffff;
}

#app .header.header-2 .header-search-category .form-control {
	border-radius: 0rem 0 0 0rem;
}

#app .header.header-2 .main-nav .menu>li>a:hover {
	color: var(--color);
}

#app .header.header-2 .header-contact h6 {
	margin-top: 2px;
	color: #fff;
}

#app .header.header-2 .separator {
	height: 20px;
	border-left: 1px solid #fff;
	margin: 0 15px;
}

#app .header.theme10 .main-nav .menu>li>a:hover {
	color: #f2f2f2;
}

#app .header.theme10 .main-nav .menu>li>a {
	color: #fff;
	text-transform: capitalize;
}

#app .header.theme10 .header-middle {
	padding-top: 1rem;
	padding-bottom: 1rem;
	color: #fff;
}

/* .theme10 .header-middle{
	background: url(https://cnphub.menaws.com);
} */
@media (max-width: 767px) {
	#app .header.theme10 .logo {
		max-width: 250px;
		padding-right: 15px;
		width: 100px;
	}

	#app .header.theme10 .logo-container .logo img {
		top: 0px;
	}

	.theme10 .swiper-carousel.dot-inside .swiper-pagination-bullets {
		width: 170px;
	}
}</style>