<template>
	<div class="home-top-container mt-lg-2">
		<div class="container">
			<div class="row">
				<div class="col-lg-9 col-md-12 mb-2">
					<pv-carousel
						class="home-slider swiper-carousel swiper-theme h-100 slide-animate"
						:options="introSlider"
						v-animate
					>
						<div class="home-slide home-slide1 banner banner-md-vw h-100 swiper-slide">
							<figure>
								<img
									class="slide-bg"
									v-lazy="slider[4].image"
									alt="slider image"
									width="880"
									height="467"
									image-ratio="53.06"
								/>
							</figure>

							<div class="banner-layer banner-layer-middle intro-banner">
								<div
									class="appear-animate"
									data-animation-name="fadeInLeftShorter"
									data-animation-delay="200"
								>
									<h4 class="text-white">Find the Boundaries. Push Through!</h4>
									<h2 class="text-white m-b-1">Summer Sale</h2>
									<h3 class="text-white text-uppercase m-b-3">70% Off</h3>
									<h5 class="text-white text-uppercase d-inline-block mb-1 pb-1 ls-n-20 align-text-bottom">
										Starting At
										<b class="coupon-sale-text bg-secondary text-white d-inline-block">$
											<em>199</em>99</b>
									</h5>
									<router-link
										to="/shop"
										class="btn btn-dark btn-md ls-10 align-bottom"
									>Shop Now!</router-link>
								</div>
							</div>
						</div>

						<div class="home-slide home-slide2 banner banner-md-vw h-100 swiper-slide">
							<figure>
								<img
									class="slide-bg"
									v-lazy="slider[4].image"
									alt="slider image"
									width="880"
									height="467"
									image-ratio="53.06"
								/>
							</figure>

							<div class="banner-layer banner-layer-middle text-uppercase">
								<div
									class="appear-animate"
									data-animation-name="fadeInLeftShorter"
									data-animation-delay="200"
								>
									<h4 class="text-white m-b-2">Over 200 products with discounts</h4>
									<h2 class="text-white m-b-3">Great Deals</h2>
									<h5 class="text-white d-inline-block mb-0 align-top mr-4 pt-2">Starting At
										<b class="ml-2 mr-3">$
											<em>299</em>99</b>
									</h5>
									<router-link
										to="/shop"
										class="btn btn-primary btn-md ls-10"
									>Get Yours!</router-link>
								</div>
							</div>
						</div>
					</pv-carousel>
				</div>

				<div class="col-lg-3 top-banners">
					<div class="row">
						<div class="col-md-4 col-lg-12">
							<div class="banner banner1 banner-md-vw-large banner-sm-vw-large mb-2">
								<figure>
									<img
										v-lazy="slider[4].image"
										alt="banner"
										width="280"
										height="143"
										image-ratio="51.07"
										style="background: #f6f7f9"
									>
								</figure>
								<div class="banner-layer banner-layer-middle text-right">
									<h3 class="m-b-2">Handbags</h3>
									<h4 class="text-secondary text-uppercase">Starting at $99</h4>
									<router-link
										to="/shop"
										class="text-dark text-uppercase ls-10 py-1"
									>Shop Now</router-link>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-12">
							<div class="banner banner2 banner-md-vw-large banner-sm-vw-large text-uppercase mb-2">
								<figure>
									<img
										v-lazy="slider[4].image"
										alt="banner"
										width="280"
										height="141"
										image-ratio="50.34"
										style="background: #fcfcfc"
									>
								</figure>
								<div class="banner-layer banner-layer-middle text-center">
									<h3 class="m-b-1 text-primary">Deal Promos</h3>
									<h4 class="mb-1 pb-1 text-body">Starting at $99</h4>
									<router-link
										to="/shop"
										class="text-dark ls-10 pb-1"
									>Shop Now</router-link>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-12">
							<div class="banner banner3 banner-md-vw-large banner-sm-vw-large mb-2">
								<figure>
									<img
										v-lazy="slider[4].image"
										alt="banner"
										width="280"
										height="143"
										image-ratio="51.07"
										style="object-position: left; background: #b8c1d0"
									>
								</figure>
								<div class="banner-layer banner-layer-middle">
									<h3 class="m-b-2">Black Jackets</h3>
									<h4 class="text-white text-uppercase">Starting at $99</h4>
									<router-link
										to="/shop"
										class="text-dark text-uppercase ls-10 pb-1"
									>Shop Now</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PvCarousel from '../../Utility/Common/PvCarousel13.vue';
import axios from 'axios'

export default {
	components: {
		PvCarousel
	},
	data: function() {
		return {
			slider: [],
		};
	},
	mounted() {
      this.getWebsiteElements();
    },
	methods: {
      getWebsiteElements() {
			this.slides = [];
			this.isLoading = true;
			axios.get('https://api.cnphub.com/public/posts')
			.then(res => {
				this.slider = res.data.data ? res.data.data : [];
			})
			.catch(res => {
				console.log(res);
			});
		},
	},
};
</script>