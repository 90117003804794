<template>
  <div>
    <div class="mobile-menu-overlay" @click="hideMobileMenu"></div>

    <div :class="'lang-' + getLang() + ' mobile-menu-container'">
      <!-- <div class="mobile-menu-container"> -->

      <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close" @click="hideMobileMenu">
          <i class="fa fa-times"></i>
        </span>

        <nav class="mobile-nav">
          <ul class="mobile-menu">
            <!-- {{ mainMenu }} -->
            <li v-for="item in mainMenu" :key="item.id">
              <router-link :to="item.url">{{ item.title }}</router-link>
              <div class="megamenu megamenu-fixed-width megamenu-3cols" v-if="item.has_child == 1">
                <div class="row">
                  <div class="">
                    <ul class="submenu">
                      <li v-for="subitem in item.child" :key="subitem.id" v-on:click="hideMobileMenu">
                        <a :href="subitem.url">
                          {{ subitem.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>


            <li :class="{ open: catOpened }">
              <!-- <router-link to="/shop" class="sub-menu-link menu-with-ul">
								Categories
								<span class="mmenu-btn" @click.prevent="catOpened = !catOpened"></span>
							</router-link> -->

              <vue-slide-toggle :open="catOpened">
                <ul>
                  <!-- <li class="sub-menu-link menu-with-ul" >
                    <router-link to="/" >Home</router-link>
                  </li> -->
                </ul>
              </vue-slide-toggle>
            </li>
          </ul>
        </nav>
        <div class=" mobile-language   mr-auto">
          <!-- <div v-for="(language, index) in languages" :key="index">
            <a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
              <i :class="'flag-' + language.lang_flag + ' flag'"></i> {{ language.lang_name }}</a>
          </div> -->
          <!-- <div class="header-menu"> -->
            <ul>
              <li  v-for="(language, index) in languages" :key="index" @click="selectLang(language)">
                <a v-if="language.lang_locale != $i18n.locale" href="javascript:;">
                  <i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
                    language.lang_name
                  }}</a>
              </li>
            </ul>
          <!-- </div> -->
        </div>
        <!-- <form class="search-wrapper mb-2" action="#">
					<input type="text" class="form-control mb-0" :placeholder="$t('Search')" v-model="search_term" required />
					<button class="btn icon-search text-white bg-transparent p-0" type="submit"
						@click="submitSearchForm"></button>
				</form> -->

        <div class="social-icons">
          <a v-if="settings.facebook" :href="settings.facebook" class="social-icon social-facebook icon-facebook"></a>
          <a v-if="settings.twitter" :href="settings.twitter" class="social-icon social-twitter icon-twitter"></a>
          <a v-if="settings.instagram" :href="settings.instagram" class="social-icon social-instagram icon-instagram"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueSlideToggle } from "vue-slide-toggle";
// import { mainMenu } from '@/utils/data/menu';

export default {
  components: {
    VueSlideToggle,
  },
  data: function () {
    return {
      search_term: "",
      mainMenu: null,
      catOpened: false,
      pageOpened: false,
      settings: [],
    };
  },
  mounted() { },
  created() {
    setTimeout(() => {
      this.getMainMenu();
      //this.getWebsiteSettings();
    }, 500);
    this.hideMobileMenu();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      localStorage.setItem("locale", newVal);
    },
  },
  methods: {
    getLang: function () {
      return localStorage.getItem("locale");
    },
    getWebsiteSettings() {
      this.settings = [];

      this.settings = JSON.parse(localStorage.getItem("settings"));

      
      this.mainMenu = JSON.parse(localStorage.getItem("mainMenu"));
    },
    selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			window.location.reload();
		},
    getMainMenu() {
      axios
        .get("https://api.cnphub.com/public/menu?id=1")
        .then((res) => {
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
        })
        .catch((res) => {
          console.log(res);
        });
        this.languages = JSON.parse(localStorage.getItem("languages"));
    },
    hideMobileMenu: function () {

      document.querySelector("body").classList.remove("mmenu-active");
    },
  },
};
</script>
<style>
.mobile-language{
  margin-left: 10px;
  margin-right: 12px;;
}
</style>
