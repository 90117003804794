<template>
  <div class="full-width sort-destination-loader sort-destination-loader-showing recent-posts bg-dark m-0 p-0">
    <div class="row portfolio-list sort-destination m-0 p-0" data-sort-id="portfolio">
      <div class="col-sm-6 col-lg-3 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat1Posts.slice(0, 4)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    <!-- <div class="slider-cats " >
                      <span v-for="(s_cat, s_cat_index) in post.cats" class="slider-cat " :key="s_cat_index">{{
                        s_cat.name
                      }}
                      </span>
                    </div> -->
                  </div>
                  <!-- {{  post.ctas_temp }} -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>

      <div class="col-sm-6 col-lg-6 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat3Posts.slice(0, 2)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <!-- <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    {{ cat3name }}
                  </div> -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>

      <div class="col-sm-4 col-lg-4 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat2Posts.slice(0, 1)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <!-- <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    {{ cat2name }}
                  </div> -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>
      <div class="col-sm-4 col-lg-4 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat2Posts.slice(1, 2)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <!-- <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    {{ cat2name }}
                  </div> -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>
      <div class="col-sm-4 col-lg-4 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat2Posts.slice(2, 3)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <!-- <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    {{ cat2name }}
                  </div> -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>

      <div class="col-sm-6 col-lg-3 p-0 m-0 isotope-item text-start" v-for="(post, index) in cat2Posts.slice(3, 7)"
        :key="index">
        <a :href="'/article/' + post.slug">
          <article>
            <div
              class="thumb-info thumb-info-no-borders thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom border-radius-0">
              <div class="thumb-info-wrapper thumb-info-wrapper-opacity-6">
                <img v-if="post.image" :src="getImage(post.image, 'medium')" class="img-fluid" :alt="post.name" />
                <img v-else :src="getImage('banners/image.jpg', 'default')" class="img-fluid" :alt="post.name" />
                <div class="date p-absolute z-index-2 top-10 right-10 me-0 ms-2 ps-1 pt-1">
                  <div class="day bg-color-light font-weight-extra-bold py-2 text-color-dark">
                    {{ getPostDay(post.created_at) }}
                  </div>
                  <div class="month text-1 bg-color-light line-height-9 text-default w-100 top-2 d-block py-0">
                    <div class="text-1">
                      {{ getPostMonth(post.created_at) }}
                    </div>
                  </div>
                </div>
                <div class="thumb-info-title bg-transparent p-4">
                  <!-- <div class="thumb-info-type bg-color-primary px-2 mb-1">
                    {{ cat2name }}
                  </div> -->
                  <div class="thumb-info-inner mt-1">
                    <h2 class="text-color-light line-height-2 text-4 font-weight-bold mb-0">
                      {{ getPostDescription(post.name, 100) }}
                    </h2>
                  </div>
                  <div class="thumb-info-show-more-content">
                    <p class="mb-0 text-1 line-height-9 mb-1  text-light opacity-5 d-none d-lg-block pargraph"
                      v-html="getPostDescription(post.content, 140)">

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </a>
      </div>


    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  components: {},
  data: function () {
    return {
      row1count: "",
      row1class: "",
      row2count: "",
      row2class: "",
      row3count: "",
      row3class: "",
      cat1Posts: [],
      cat2Posts: [],
      cat3Posts: [],
      cat1name: '',
      cat2name: '',
      cat3name: '',
    };
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },
  mounted() {
    var row1 = this.attributes.row1.split(" ");
    var row2 = this.attributes.row2.split(" ");
    var row3 = this.attributes.row3.split(" ");
    this.row1class = row1[0];
    this.row1count = row1[1];
    this.row2class = row2[0];
    this.row2count = row2[1];
    this.row3class = row3[0];
    this.row3count = row3[1];
    this.getPosts();
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostMonth(date) {
      return this.$postHelper.getPostDay(date);
    },
    getPostDay(date) {
      return this.$postHelper.getPostMonth(date);
    },
    getPostDescription(text, length) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    getPosts() {
      this.settings = JSON.parse(localStorage.getItem("settings"));
      axios
        .get(
          "https://api.cnphub.com/public/threeCategoryData" +
          "?limit=" +
          7 +
          "&cat1=" +
          this.attributes.cat1 +
          "&cat2=" +
          this.attributes.cat2 +
          "&cat3=" +
          this.attributes.cat3+'&images_query=1',
          {}
        )
        .then((res) => {
          // this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          // this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : "";
          // this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          // this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : "";
          // this.cat3Posts = res.data.data.cat3 ? res.data.data.cat3 : [];
          // this.cat3name = res.data.data.cat3name ? res.data.data.cat3name : "";
        })
        .catch((res) => {
          console.log(res);
        });
        axios.get(this.settings.BaseUrl + '/public/top-news?limit=14'+'&images_query=1')
        .then(res => {

          var posts = res.data.data.posts ? res.data.data.posts : '';
          //this.posts =this.sortItems();
          this.cat1Posts =posts.slice(0, 4);
          this.cat2Posts =posts.slice(4, 11);
          this.cat3Posts =posts.slice(11, 14);

        })
        .catch(res => {
          console.log(res);
        });
    },
  },
};
</script>


<style>
article {
  display: block;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.thumb-info.thumb-info-no-borders,
.thumb-info.thumb-info-no-borders img {
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.thumb-info {
  display: block;
  position: relative;
  text-decoration: none;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.thumb-info.thumb-info-bottom-info-show-more img {
  transform: none !important;
}

.thumb-info.thumb-info-no-zoom img {
  transition: none;
}

.thumb-info img {
  transition: all .3s ease;
  border-radius: 3px;
  position: relative;
  width: 100%;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.thumb-info .thumb-info-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 4px;
  margin: 0;
  overflow: hidden;
  display: block;
  position: relative;
}

.thumb-info.thumb-info-bottom-info-show-more img {
  transform: none !important;
}

.thumb-info.thumb-info-no-zoom img {
  transition: none;
}

.thumb-info img {
  transition: all 0.3s ease;
  border-radius: 3px;
  position: relative;
  width: 100%;
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-title {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
  background: #212529;
  color: #fff;

}
.thumb-info .thumb-info-inner h2{
  padding: 5px;
}
.thumb-info.thumb-info-bottom-info .thumb-info-title {
  background: #fff;
  left: 0;
  width: 100%;
  max-width: 100%;
  transition: transform .2s ease-out, opacity .2s ease-out;
  transform: translate3d(0, 100%, 0);
  top: auto;
  bottom: 0;
  opacity: 0;
  padding: 15px;
  text-shadow: none;
  color: #777;
}

.thumb-info .thumb-info-title {
  transition: all 0.3s;
  background: rgba(33, 37, 41, 0.8);
  color: #fff;
  font-weight: 600;
  left: 0;
  letter-spacing: -0.05em;
  position: absolute;
  z-index: 2;
  max-width: 90%;
  font-size: 17px;
  padding: 13px 21px 2px;
  bottom: 13%;
}

.background-transparent,
.bg-transparent,
.bg-color-transparent {
  background: 0 0 !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}


.right-10 {
  right: 10px !important;
}

.top-10 {
  top: 10px !important;
}

.p-absolute {
  position: absolute !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.me-0 {
  margin-right: 0 !important;
}

.background-transparent,
.bg-transparent,
.bg-color-transparent {
  background: 0 0 !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

img,
svg {
  vertical-align: middle;
}

.recent-posts .date {
  margin-right: 10px;
  text-align: center;
}

.recent-posts .date .day {
  background: #f7f7f7;
  color: #ccc;
  display: block;
  font-size: 18px;
  font-weight: 500;
  font-weight: 700;
  padding: 8px;
}

.recent-posts .date .month {
  background: #ccc;
  color: #fff;
  font-size: 0.9em;
  padding: 3px 10px;
  position: relative;
  top: -2px;
}

.thumb-info.thumb-info-bottom-info .thumb-info-type {
  float: none;
  background: 0 0;
  padding: 0;
  margin: 0;
}

.thumb-info .thumb-info-type {
  background-color: #ccc;
  border-radius: 2px;
  display: inline-block;
  float: left;
  font-size: .6em;
  font-weight: 600;
  letter-spacing: 0;
  margin: 8px -2px -15px 0;
  padding: 2px 12px;
  text-transform: uppercase;
  z-index: 2;
  line-height: 2.3;
}

html .bg-color-primary,
html .bg-primary {
  background-color: var(--color) !important;
}

html .full-width .text-color-light,
html .full-width .text-light {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.4);
}

.text-4 {
  font-size: 1.1em !important;
}

.thumb-info.thumb-info-bottom-info-dark .thumb-info-title {
  color: #fff !important;
}

.thumb-info.thumb-info-no-borders .thumb-info-wrapper:after {
  border-radius: 0;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.thumb-info .thumb-info-wrapper-opacity-6:after {
  opacity: .6;
}

.thumb-info .thumb-info-wrapper:after,
.thumb-info .thumb-info-wrapper .thumb-info-wrapper-overlay:before {
  content: "";
  background: rgba(33, 37, 41, .8);
  transition: all .3s;
  border-radius: 4px;
  bottom: -4px;
  color: #fff;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  display: block;
  opacity: 0;
  z-index: 1;
}

.thumb-info.thumb-info-bottom-info-show-more .thumb-info-show-more-content {
  transition: ease max-height 800ms;
  overflow: hidden;
  max-height: 0;
  display: block;
}

.thumb-info.thumb-info-bottom-info:hover .thumb-info-title {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.thumb-info .thumb-info-inner {
  transition: all .3s;
  display: block;
}

.text-1 {
  font-size: .8em !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
  background: #fff;
  left: 0;
  width: 100%;
  max-width: 100%;
  transition: transform .2s ease-out, opacity .2s ease-out;
  transform: translate3d(0, 100%, 0);
  top: auto;
  bottom: 0;
  opacity: 0;
  padding: 15px;
  text-shadow: none;
  color: #777;
}

.thumb-info .thumb-info-title {
  transition: all .3s;
  background: rgba(33, 37, 41, .8);
  color: #fff;
  font-weight: 600;
  left: 0;
  letter-spacing: -.05em;
  position: absolute;
  z-index: 2;
  max-width: 90%;
  font-size: 17px;
  padding: 13px 21px 2px;
  bottom: 13%;
}

.p-4 {
  padding: 1.5rem !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.right-10 {
  right: 10px !important;
}

.top-10 {
  top: 10px !important;
}

.p-absolute {
  position: absolute !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.me-0 {
  margin-right: 0 !important;
}

.thumb-info.thumb-info-bottom-info .thumb-info-title {
  background: #fff;
  left: 0;
  width: 100%;
  max-width: 100%;
  transition: transform .2s ease-out, opacity .2s ease-out;
  transform: translate3d(0, 100%, 0);
  top: auto;
  bottom: 130px;
  opacity: 1;
  padding: 15px;
  text-shadow: none;
  color: #777;
}

.thumb-info.thumb-info-bottom-info-show-more:hover .thumb-info-show-more-content {
  max-height: 200px;
}

.thumb-info:hover .thumb-info-title {
  bottom: 0px !important;
}

.thumb-info .thumb-info-wrapper:after,
.thumb-info .thumb-info-wrapper .thumb-info-wrapper-overlay:before {
  content: "";
  background: rgba(33, 37, 41, 0.8);
  transition: all 0.3s;
  border-radius: 4px;
  bottom: -4px;
  color: #FFF;
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  display: block;
  opacity: 0;
  z-index: 1;
}

html .text-color-light,
html .text-light {
  text-align: left;
}

p.text-light.pargraph {
  font-size: 12px !important;
  line-height: 16px !important;
  padding: 8px;
}

@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
}
</style>