<template>
	<footer class="footer">
		<div class="footer-middle">
			<div class="container">
				<div class="row">
					<div class="col-lg-9">
						<div class="row row-sm">
							<div class="col-sm-4">
								<div class="widget">
									<h4 class="widget-title">CONTACT INFO</h4>
									<ul class="contact-info">
										<li>
											<span class="contact-info-label">Phone:</span>Toll Free <a href="tel:">(123) 456-7890</a>
										</li>
										<li>
											<span class="contact-info-label">Email:</span>
											<router-link to="mailto:mail@example.com">mail@example.com</router-link>
										</li>

									</ul>
									<div class="social-icons">
										<a
											href="javascript:;"
											class="social-icon social-facebook icon-facebook"
										></a>
										<a
											href="javascript:;"
											class="social-icon social-twitter icon-twitter"
										></a>
										<a
											href="javascript:;"
											class="social-icon social-linkedin fab fa-linkedin-in"
										></a>
									</div>
								</div>
							</div>
							<div class="col-sm-4">
								<div class="widget pl-sm-1">
									<h4 class="widget-title">CUSTOMER SERVICE</h4>

									<ul class="links">
										<li>
											<router-link to="/pages/about-us">{{ $t("About Us") }}</router-link>
										</li>
										<li>
											<router-link to="/pages/contact-us">Contact Us</router-link>
										</li>
										<li>
											<router-link to="/pages/account">My Account</router-link>
										</li>
										<li><a href="javascript:;">Orders history</a></li>
										<li><a href="javascript:;">Advanced search</a></li>
									</ul>
								</div>
							</div>

							<div class="col-sm-4">
								<div class="widget pl-sm-2">
									<h4 class="widget-title">ABOUT US</h4>
									<ul class="links">
										<li><a href="javascript:;">Super Fast Vue Template</a></li>
										<li><a href="javascript:;">Top Rated Theme in Themeforest</a></li>
										<li><a href="javascript:;">33 Unique Shop Demos</a></li>
										<li><a href="javascript:;">Powerful Admin Panel</a></li>
										<li><a href="javascript:;">Mobile & Retina Optimized</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-3">
						<div class="widget widget-newsletter">
							<h4 class="widget-title">Subscribe newsletter</h4>
							<p>Get all the latest information on events, sales and offers. Sign up for newsletter:
							</p>
							<form action="#">
								<input
									type="email"
									class="form-control"
									placeholder="Email address"
									required
								>

								<input
									type="submit"
									class="btn"
									value="Go!"
								>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom d-sm-flex align-items-center">
				<div class="footer-left">
					<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</span>
				</div>

				<div class="footer-right ml-auto mt-1 mt-sm-0">
					<div class="payment-icons mr-0">
						<span
							class="payment-icon visa"
							v-lazy:background-image="'./images/payments/payment-visa.svg'"
						></span>
						<span
							class="payment-icon paypal"
							v-lazy:background-image="'./images/payments/payment-paypal.svg'"
						></span>
						<span
							class="payment-icon stripe"
							v-lazy:background-image="'./images/payments/payment-stripe.png'"
						></span>
						<span
							class="payment-icon verisign"
							v-lazy:background-image="'.//images/payments/payment-verisign.svg'"
						></span>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {};
</script>