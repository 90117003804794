<template>
  <header class="header theme12 header-2" :style="cssVars">
    <div class="header-top text-uppercase" :style="TopHeaderCssVars">
      <div class="container">
        <div class="header-left header-dropdowns ml-0">
          <div class="header-dropdown dropdown-expanded mr-3">
            <!-- <a href="javascript:;">Links</a> -->
            <div class="header-menu">
              <top-header-menu></top-header-menu>
            </div>
          </div>
        </div>
        <div class="header-left header-dropdowns ml-0">
          <div class="secondary-menu-mobile hidden-md hidden-lg visible-xs-block">
            <ul>
              <li>
                <router-link to="/about">About Us</router-link>
              </li>
              <li>
                <router-link to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="header-right">
          <div
            class="header-dropdown dropdown-language languages mr-auto mr-sm-3 mr-md-0"
          >
            <div v-for="(language, index) in languages" :key="index">
              <a
                href="javascript:;"
                v-if="language.lang_locale == $i18n.locale"
              >
                <!-- <i class="flag-us flag"></i>{{$i18n.locale}} </a> -->
                <i :class="'flag-' + language.lang_flag + ' flag'"></i
                >{{ language.lang_name }}
              </a>
            </div>
            <div class="header-menu">
              <ul>
                <li
                  v-for="(language, index) in languages"
                  :key="index"
                  @click="selectLang(language)"
                >
                  <a href="javascript:;">
                    <i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i
                    >{{ language.lang_name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-middle theme12 sticky-header">
      <div class="container">
        <div class="header-left">
          <button
            class="mobile-menu-toggler mr-2"
            type="button"
            @click="showMobileMenu"
          >
            <i class="fas fa-bars"></i>
          </button>
          <router-link to="/" class="logo">
            <img
              :src="settings.logo"
              width="150"
              height="80"
              alt="Porto Logo"
            />
          </router-link>
        </div>
        <div class="header-right">
          <pv-header-search></pv-header-search>
        </div>
      </div>
    </div>
    <div
      class="header-bottom theme12 sticky-header desktop-sticky d-none d-lg-block"
    >
      <div class="container">
        <!-- <div class="header-left">
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="101" height="40" />
					</router-link>
				</div> -->
        <div class="header-center">
          <pv-main-menu></pv-main-menu>
        </div>
        <div class="header-right">
          <pv-header-search></pv-header-search>

          <a
            href="javascript:;"
            class="header-icon header-icon-user"
            title="login"
          >
            <i class="icon-user-2"></i>
          </a>

          <router-link
            to="/pages/wishlist"
            class="header-icon"
            title="wishlist"
          >
            <i class="icon-wishlist-2"></i>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import PvMainMenu from "./PvMainMenu.vue";
import PvHeaderSearch from "./PvHeaderSearch.vue";
document.querySelector("body").classList.add("loaded");

import TopHeaderMenu from "./TopHeaderMenu.vue";

document.querySelector("body").classList.add("loaded");

export default {
  components: {
    PvMainMenu,
    TopHeaderMenu,
    PvHeaderSearch,
  },
  computed: {
    cssVars() {
      return {
        "background-color": this.settings.header_color,
      };
    },
    TopHeaderCssVars() {
      return {
        "background-color": this.settings.secondary_color,
      };
    },
  },
  data: function () {
    return {
      isHome: {
        type: Boolean,
        default: true,
      },
      settings: "",
      submenu: "",
      languages: "",
    };
  },
  mounted() {
    this.getWebsiteSettings();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      localStorage.setItem("locale", newVal);
    },
  },
  methods: {
    getWebsiteSettings() {
      this.settings = [];

      this.settings = JSON.parse(localStorage.getItem("settings"));

      this.languages = JSON.parse(localStorage.getItem("languages"));
    },
    showMobileMenu: function () {
      document.querySelector("body").classList.add("mmenu-active");
    },
    showMobileSearch: function (e) {
      let headerSearch = e.currentTarget.closest(".header-search");
      headerSearch.classList.add("show");
      headerSearch
        .querySelector(".header-search-wrapper")
        .classList.add("show");
    },
    selectLang: function (language) {
      this.$i18n.locale = language.lang_locale;
      localStorage.setItem("lang_code", language.lang_code);
      localStorage.setItem("langSwitch", true);
      window.location.reload();
    },
  },
};
</script>

<style>
.header-middle.theme12 {
  padding-top: 0rem;
}
/* .theme12 .header-dropdown {
    padding: 4px 0;
    color: #000;
} */
.theme12 .header-dropdown.languages {
  padding: 4px 0;
  color: #fff;
  background: var(--color);
  padding: 6px 8px;
}
.header.theme12 .header-top {
  border-bottom: 0px solid #ffff;
}
.header-middle.theme12 .header-search-category .btn {
  border-radius: 0 1rem 1rem 0;
}
.main.home .theme12 .main-nav .menu > li > a {
  color: #fff !important;
}
.main.home .theme12 .main-nav .menu > li > a:hover {
  color: #f2f2f2 !important;
}
#app .logo {
  max-width: 140px;
  padding-right: 15px;
}
.theme12.header-bottom {
  border-bottom: 1px solid #e7e7e7;

  background: transparent;
}
.theme12.header-bottom .container {
  border-top: 1px solid var(--secondary-color);
}
.theme12.header-bottom {
  border-bottom: 1px solid var(--secondary-color);
  background: transparent;
}
.theme12 h4.section-sub-title {
  font-size: 28px !important;
  color: var(--secondary-color);
  border-radius: 10px 50px 50px 10px;
  padding: 5px;
  font-weight: 900;
  border-left: 5px solid var(--color);
  background: transparent;
}
.post-media .post-date {
  display: none;
}
.post-media .post-date {
  color: var(--color);
}
#app .main-nav .menu > li {
  margin-right: 1.5rem;
  letter-spacing: -0.01em;
}
#app .post-title-block {
  padding: 10px;
  background: var(--color);
  color: #fff;
  min-height: 80px;
}
@media (max-width: 767px) {
  #app .theme12 .mobile-menu-toggler {
    color: #fff !important;
  }
  #app .theme12 .header-dropdown.dropdown-expanded li + li {
    margin-left: 0.4rem;
    letter-spacing: 0.025em;
  }
  .secondary-menu-mobile ul li{
    line-height: 24px;
    display: inline-block;
    padding: 5px 7px;
    color: #555;
  }
  .secondary-menu-mobile ul{
	margin-top: 15px;
  }
}
</style>
