<template>
	<div class="product-default left-details product-widget">
		<figure>
			<router-link :to="'/article/' + product.slug + ''">
				<template v-if="isLazy">
					<img alt="blog" :width="200" :height="200" v-lazy="`${getImage(product.image,'thumb')}`"
						:src="getImage(product.image)" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" />
					<!-- <img lazy="loaded" class="fade-in fadeIn" :src="getImage(product.image)" 
					v-lazy="`${product.image}`" 
					:key="'small-product' + product.id" alt="small-product-image"
						:height="200" :width="200" /> -->
						
				</template>
				
				<template v-else>
					<img :src="`${product.url}`" :key="'small-product' + product.id" alt="small-product-image"
						:height="200" :width="200" />
				</template>
				
			</router-link>
		</figure>

		<div class="product-details post-details">
			<h2 class="product-title font2">
				<router-link :to="'/article/' + product.slug + ''">{{ product.name }}</router-link>
			</h2>

			<div class="price-box font2" key="singlePrice">
				<template>
					<!-- <span class="product-price">{{ getDate(product.updated_at) }}</span> -->
					<span class="time">{{
						getPostDate(product.updated_at)
					}}</span>
				</template>
			</div>
		</div>

	</div>
</template>

<script>
import { baseUrl } from '@/api';

export default {
	props: {
		product: Object,
		isLazy: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			baseUrl: baseUrl,
		};
	},
	mounted: function () {
		//this.product.image=this.getImage(this.product.image, 'thumb');
	},
	methods: {
		getPostDate(date) {
			return this.$postHelper.getPostDate(date);
		},
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getDate(date) {
			return Date.parse(date);
		}
	}
};
</script>