<template>
  <div class="main black-background">
    <section class="section bg-dark m-0 border-0">
      <div class="container py-5">
        <div class="row justify-content-between align-items-center py-5">
          <div class="col-lg-6 text-center text-lg-start py-5">
            <h3 class="mb-4 text-color-light">{{ $t("Get in Touch") }}</h3>
            <p
              class="text-4 text-light line-height-4 opacity-6 mb-4"
              v-html="settings.contact_text"
            ></p>

            <div class="hstack d-block d-lg-flex gap-3">
              <div class="ms-auto">
                <i
                  class="icons icon-envelope text-color-primary text-8 p-relative top-12 me-2"
                ></i>
                <a
                  :href="'mailto:' + settings.email"
                  class="text-decoration-none text-5 text-primary text-color-hover-light ws-nowrap font-weight-semi-bold"
                  >{{ settings.email }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-5 py-5">
            <div
              class="card border-radius bg-color-light border-0 box-shadow-3"
            >
              <div class="card-body p-5 m-2 text-center">
                <h4 class="card-title mb-2 text-6">{{$t("Book Discovery Call!")}}</h4>
                <!-- <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->

                <form
                  class="contact-form"
                  action="php/contact-form.php"
                  method="POST"
                  novalidate="novalidate"
                >
                  <div
                    class="contact-form-success alert alert-success  mt-4"
                    v-if="success"
                  >
                    <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}

                  </div>

                  <div
                    class="contact-form-error alert alert-danger mt-4"
                    v-if="error"
                  >
                  <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>

                  <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
                    <strong>{{ $t("Error!") }}</strong> 
                    <ul>
                      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>

                  <div class="row">
                    <div class="form-group col text-start">
                      <input
                        type="text"
                        value=""
                        :placeholder="$t('Your Name')"
                        v-model="formData.name"
                        class="form-control text-2-5 p-3"
                        name="name"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col text-start">
                      <input
                        type="email"
                        value=""
                        :placeholder="$t('Email')"
                        v-model="formData.email"
                        class="form-control text-2-5 p-3"
                        name="email"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <div class="d-grid gap-2">
                        <input
                          :value="$t('Get Started')"
                          v-on:click="checkForm"
                          class="btn border-0 btn-tertiary btn-primary bg-hover-primary text-color-hover-light text-color-primary text-3-5 p-3"
                          data-loading-text="Loading..."
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col pt-4">
                      <div class="hstack gap-3">
                        <div class="ms-auto">
                          <i
                            class="icons icon-envelope text-4 p-relative top-5 me-2"
                          ></i>
                          <a
                            :href="'mailto:' + settings.email"
                            class="text-decoration-none text-2 text-dark text-color-hover-primary ws-nowrap font-weight-semi-bold"
                            >{{ settings.email }}</a
                          >
                        </div>
                        <div class="vr"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  props: {},

  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    checkForm: function (e) {
      console.log('form check')

      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      (this.loading = true),
        axios
          .post(this.settings.BaseUrl + "/public/contact", this.formData)
          .then((res) => {
            this.loading = false;
            this.success = true;
            // this.response = res.data.data ? res.data.data : [];
          })
          .catch((res) => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
          });
    },
  },
};
</script>

<style>
html .bg-color-hover-primary:hover,
html .bg-hover-primary:hover {
  background-color: var(--secondary-color);
}

html .text-color-hover-light:hover,
html .text-hover-light:hover {
  color: #fff !important;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.gap-2 {
  gap: 0.5rem !important;
}

html .btn-tertiary {
	border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}
</style>