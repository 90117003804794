<template>
	<section class="featured-section bg-white">
		<div class="container">
			<div
				class="appear-animate"
				data-animation-name="fadeInUpShorter"
				data-animation-delay="200"
				v-animate
			>
				<h2 class="section-title pb-3 mb-2">Featured Books</h2>

				<div
					class="row"
					v-if="products.length === 0"
				>
					<div
						class="col-6 col-md-4 col-lg-3 col-xl-2"
						v-for="index in [1,2,3,4,5,6]"
						:key="'featured-skel-' + index"
					>
						<div class="product-loading-overlay"></div>
					</div>
				</div>

				<div class="row">
					<div
						class="col-6 col-md-4 col-lg-3 col-xl-2"
						v-for="(product,index) in products.slice(0, 6)"
						:key="'featured' + index"
					>
						<pv-product-one :product="product"></pv-product-one>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import PvProductOne from './products/PvProductOne25.vue';
import { baseSlider3 } from '@/utils/data/carousel';
import axios from 'axios'

export default {
	components: {
		PvProductOne
	},
	data: function () {
		return {
			baseSlider3: baseSlider3,
			products: [],
		};
	},
	mounted: function () {
		this.getPosts();
	},
	methods: {
		getPosts() {
			this.slides = [];
			this.isLoading = true;
			axios.get('https://api.cnphub.com/public/posts')
			.then(res => {
				this.products = res.data.data ? res.data.data : [];
			})
			.catch(res => {
				console.log(res);
			});
		},
	},
};
</script>