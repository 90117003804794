<template>
  <section class="home-section mb-2">
    <div class="container ">
      <template>

        <component :is="attributes.theme" :topNews="topNews" :cat1Posts="cat1Posts" :cat1name="cat1name"
          :key="attributes.theme"></component>
      </template>
    </div>
  </section>
</template>
<script>
import axios from 'axios'

import NewsList1ColTheme1 from './NewsList1ColTheme1.vue'
import NewsList1ColTheme2 from './NewsList1ColTheme2.vue'
import NewsList1ColTheme3 from './NewsList1ColTheme3.vue'
import NewsList1ColTheme4 from './NewsList1ColTheme4.vue'
import NewsList1ColTheme5 from './NewsList1ColTheme5.vue'
import NewsList1ColTheme12 from './NewsList1ColTheme12.vue'
export default {
  components: {
    NewsList1ColTheme1,
    NewsList1ColTheme2,
    NewsList1ColTheme3,
    NewsList1ColTheme4,
    NewsList1ColTheme5,
    NewsList1ColTheme12,
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
      top_news_sidebar: String,
      images_query:null,
    },
  },
  data: function () {
    return {
      cat1Posts: [],

      cat1name: '',

      topNews: [],
    };
  },
  created: function () {
    this.getPosts();

    // setTimeout(() => {
      if (this.attributes.top_news_sidebar == '1')
      this.getTopNews();
    // }, 1500);
  },
  mounted: function () {


  },
  methods: {
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));
      axios.get(this.settings.BaseUrl + '/public/top-news?limit=' + this.attributes.limit+'&images_query='+this.attributes.images_query)
        .then(res => {
          this.topNews = res.data.data.posts ? res.data.data.posts : '';
        })
        .catch(res => {
          console.log(res);
        });
    },
    getPosts() {
      this.isLoading = true;
      if (!this.attributes.limit) this.attributes.limit = 5;
      axios
        .get("https://api.cnphub.com/public/twoCategoryData" + '?limit=' + this.attributes.limit + '&cat1=' + this.attributes.cat1+'&images_query='+this.attributes.images_query, {
        })
        .then((res) => {
          this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : '';


        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

