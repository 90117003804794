<template>
	<section class="best-sellers">
		<div class="container">
			<h2 class="section-title mb-2">Best Selling Items</h2>

			<div
				class="row"
				v-if="products.length === 0"
				data-animation-name="fadeIn"
				data-animation-delay="200"
				v-animate
			>
				<div
					class="col-sm-6 col-md-4"
					v-for="index in [1,2,3,4,5,6]"
					:key="'best-skel-' + index"
				>
					<div class="product-loading-overlay"></div>
				</div>
			</div>

			<div
				class="row"
				v-if="products.length > 0"
				data-animation-name="fadeIn"
				data-animation-delay="200"
				v-animate
			>
				<div
					class="col-sm-6 col-md-4"
					v-for="(product,index) in products.slice(0,6)"
					:key="'best' + index"
				>
					<pv-product-one24 :product="product"></pv-product-one24>
				</div>
			</div>

			<div class="view-more-container text-center">
				<router-link
					to="/shop"
					class="btn btn-round btn-view-more"
				>{{ $t("Read More") }}</router-link>
			</div>

		</div>
	</section>
</template>

<script>
import PvProductOne24 from './products/PvProductOne24.vue';
import { getTopSellingProducts } from '@/utils/service';
import {
	getProductsByAttri,
	getTopRatedProducts
} from '@/utils/service';
import { getCookie } from '@/utils';
import Api, { baseUrl } from '@/api';

export default {
	components: {
		PvProductOne24,
	},
	data: function () {
		return {
			products: [],
		}
	},
	mounted: function() {
		Api.get(`${baseUrl}/demo2`)
			.then(response => {
				this.products = response.data.products;
				this.posts = response.data.posts;
				this.featuredProducts = getProductsByAttri(
					response.data.products
				);
				this.newProducts = getProductsByAttri(
					response.data.products,
					'is_new'
				);
				this.bestProducts = getTopSellingProducts(
					response.data.products
				);
				this.topRatedProducts = getTopRatedProducts(
					response.data.products
				);
			})
			.catch(error => ({ error: JSON.stringify(error) }));

	},
}
</script>