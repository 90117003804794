<template>
	<div class="categories-section bg-primary">
		<div
			class="container appear-animate"
			data-animation-name="fadeInUpShorter"
			data-animation-delay="200"
			v-animate
		>
			<h2 class="section-title border-0 title-decorate text-center text-white d-flex align-items-center">
				<span>BROWSE OUR CATEGORIES</span>
			</h2>

			<pv-carousel7
				class="swiper-carousel"
				:options="categorySlider"
			>
				<div class="banner banner-image swiper-slide">
					<router-link to="/shop">
						<img
							v-lazy="'@/assets/css/images/home/banners/cats/banner-1.jpg'"
							alt="banner"
							width="273"
							height="231"
							image-ratio="84.61"
							style="background: #eef2f5"
						/>
					</router-link>
					<div class="banner-layer banner-layer-middle">
						<h3>DRESS</h3>
						<span>2 PRODUCTS</span>
					</div>
				</div>

				<div class="banner banner-image swiper-slide">
					<router-link to="/shop">
						<img
							v-lazy="'@/assets/css/images/home/banners/cats/banner-2.jpg'"
							alt="banner"
							width="273"
							height="231"
							image-ratio="84.61"
							style="background: #eef2f5"
						/>
					</router-link>
					<div class="banner-layer banner-layer-middle">
						<h3>TOYS</h3>
						<span>4 PRODUCTS</span>
					</div>
				</div>

				<div class="banner banner-image swiper-slide">
					<router-link to="/shop">
						<img
							v-lazy="'@/assets/css/images/home/banners/cats/banner-3.jpg'"
							alt="banner"
							width="273"
							height="231"
							image-ratio="84.61"
							style="background: #eef2f5"
						/>
					</router-link>
					<div class="banner-layer banner-layer-middle">
						<h3>SHOES</h3>
						<span>3 PRODUCTS</span>
					</div>
				</div>

				<div class="banner banner-image swiper-slide">
					<router-link to="/shop">
						<img
							v-lazy="'@/assets/css/images/home/banners/cats/banner-4.jpg'"
							alt="banner"
							width="273"
							height="231"
							image-ratio="84.61"
							style="background: #eef2f5"
						/>
					</router-link>
					<div class="banner-layer banner-layer-middle">
						<h3>WATCHES</h3>
						<span>6 PRODUCTS</span>
					</div>
				</div>

				<div class="banner banner-image swiper-slide">
					<router-link to="/shop">
						<img
							v-lazy="'@/assets/css/images/home/banners/cats/banner-2.jpg'"
							alt="banner"
							width="273"
							height="231"
							image-ratio="84.61"
							style="background: #eef2f5"
						/>
					</router-link>
					<div class="banner-layer banner-layer-middle">
						<h3>Dress</h3>
						<span>4 Products</span>
					</div>
				</div>
			</pv-carousel7>
		</div>
	</div>
</template>

<script>
import PvCarousel7 from '../Utility/Common/PvCarousel7.vue';
import { categorySlider } from '@/utils/data/carousel';

export default {
	components: {
		PvCarousel7
	},
	data: function() {
		return {
			categorySlider: categorySlider
		};
	}
};
</script>