<template>
	<footer class="footer">
		<div class="container">
			<div class="footer-top">
				<router-link
					to="/"
					class="footer-logo"
				>
					<img
						:src="'https://cnphub.menaws.com/storage/'+settings.logo"
						alt="footer=logo"
						width="202"
						height="80"
					>
				</router-link>
			</div>
			<div class="footer-middle">
				<ul class="link-btns scrolling-box">
					<li v-for="item in footerMenu" :key="item.id">
						<router-link :to="item.url">{{ item.title }}</router-link>
					</li>
				</ul>
				<form
					action=" #"
					method="get"
					class="mb-0"
				>
					<div class="submit-wrapper">
						<label class="text-uppercase text-white mb-0">newsletter</label>
						<input
							type="search"
							class="form-control mb-0"
							name="q"
							id="q"
							placeholder="Email Address..."
							required=""
						>
						<button
							class="btn btn-primary"
							type="submit"
						>GO</button>
					</div>

				</form>
			</div>
			<div class="footer-bottom d-lg-flex justify-content-between align-items-center flex-wrap">
				<p class="footer-copyright py-3 pr-4 mb-0">&copy; © {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</p>

				<div class="social-icons">
					<a
						href="javascript:;"
						class="social-icon social-facebook icon-facebook"
						
					></a>
					<a
						href="javascript:;"
						class="social-icon social-twitter icon-twitter"
						
					></a>
					<a
						href="javascript:;"
						class="social-icon social-linkedin fab fa-linkedin-in"
						
					></a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
    },
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
	},
};
</script>