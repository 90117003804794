<template>
  <section class="error-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 soon-top">
          <div class="error-content text-center">
            <h3 class="title soon-font">{{ $t("Under Renovation") }}</h3>
          </div>
        </div>
        <!-- <div class="col-lg-7">
          <div class="error-thumb text-center">
            <img src="@/assets/images/soon.webp" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {

  props: {
    settings: {
      type: Object,
    },
  },

};
</script>

<style>

.soon-top{
  margin-top: 50px;
}

.soon-font{
  font-size: 36px;
}
</style>
