<template>
  <div class="news-List-2-col  theme11">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">
        <div class="row">
          <div v-if="topNewsSection" :class="'col-md-3 mb-20 main-articles top-news-sidebar'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span>{{ $t('Most Popular Articles') }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in topNews" :key="index">
              <div class=" col-md-12  col-xs-12">
                <img v-if="post.image && index < 2" width="100%" :src="getImage(post.image, 'medium')">
              </div>
              <div class=" col-md-12 col-xs-12">
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>
          </div>
          <!-- Post Wrapper Start -->
          <div :class="colClassSection + 'mb-20 main-articles master'">
            <div class="pb-5 pb-md-0 news-list-1-col">
              <h4 class="section-sub-title"><span class="">{{ cat1name }}</span></h4>
            </div>
            <div class="story-short article " v-for="(post, index) in cat1Posts" :key="index">
              <div class=" row ">
                <div class=" col-md-12 col-xs-12">
                  <h4><a :href="'/article/' + post.slug">
                      {{ post.name }}</a></h4>
                  <div class="time">{{ getPostDate(post.created_at) }} </div>
                  <div class=" ">
                  <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content, 150)"></p>
                </div>
                  <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
                </div>
                <!-- <div class=" col-md-3  col-xs-12">
                  <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
                </div> -->
              </div>
            </div>
          </div><!-- Post Wrapper End -->
          <div :class="'col-md-5 mb-20 main-articles'">
            <div class="pb-5 pb-md-0 news-list-1-col">
              <h4 class="section-sub-title"><span class="word-1">{{ cat2name }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat2Posts" :key="index">
              <div class=" col-md-5  col-xs-12">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              </div>
              <div class=" col-md-7 col-xs-12">
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <h4><a :href="'/article/' + post.slug">
                    {{ getPostDescription(post.name,90) }}</a></h4>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
              <!-- <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;">{{
                  getPostDescription(post.content, 80)
                }}<br></p> -->
            </div>
          </div><!-- Post Wrapper End -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  components: {},
  props: {

    cat1Posts: Array,
    cat2Posts: Array,
    topNews: Array,
    cat1name: String,
    cat2name: String,

  },

  data: () => ({
    settings: null,
    colClass: 'col-md-6  col-xs-12',

  }),


  mounted() {


  },
  computed: {
    colClassSection: {
      // getter
      get() {
        return this.topNews.length > 0 ? 'col-md-6  col-xs-12' : 'col-md-7  col-xs-12'
      },
    },
    topNewsSection: {
      // getter
      get() {
        return this.topNews.length > 0 ? 1 : 0
      },
    }
  },
  created() {


    // if (this.topNews.length > 0) {
    //   setTimeout(() => {
    //     this.colClass = 'col-md-4  col-xs-12';
    //   }, 1500);

    // }
    // console.log(this.topNews);
    // console.log(this.topNews.length);
  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, lenght = 160) {
      return this.$postHelper.getPostSmallDescription(text, lenght);
    },

  },
};
</script>

<style>
.theme11 .main-articles.master .story-short.article {
  padding-bottom: 20px;
  border-top: 1px solid #d1d1d1;
}
.theme11 h4.section-sub-title{

}
.theme11 h4.section-sub-title::after{
  width: 60px;
  height: 20px;
}
.theme11 .top-news-sidebar h4{
  font-size: 1.5rem;
  line-height: 1.35;

}
.top-news-sidebar.main-articles .article {
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.theme11 .main-articles.master .story-short.article  h4{
  margin-top: 10px;
  margin-bottom: 0.8rem;
}
</style>