<template>
  <div>
    <nav aria-label="breadcrumb" class="breadcrumb-nav">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/" class=""><i class="icon-home"></i></a>
          </li>
          <li aria-current="page" class="breadcrumb-item active">{{ $t("About Us") }}</li>
        </ol>
      </div>
    </nav>
    <div class="about-section">
      <div class="container">
        <h2 class="subtitle">{{ $t("About Us") }}</h2>
        <p v-html="settings.about_text"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>
</style>