<template>
  <header class="header theme-13" :style="TopHeaderCssVars">
    <div class="header-top">
      <div class="container-fluid">
        <div class="header-left font1">
          <p class="top-message ls-n-10">
            <b class="font-weight-extra-bold">{{ settings.about }}</b>
          </p>
        </div>

        <div class="header-right">
          <div class="header-dropdown dropdown-expanded d-none d-lg-block">
            <a href="javascript:;">Links</a>
            <div class="header-menu">
              <ul>
                <li v-for="item in topMenu" :key="item.id">
                  <router-link :to="item.url">{{ item.title }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="header-dropdown  dropdown-language  d-none d-sm-block">
            <div v-for="(language, index) in languages" :key="index">
							<a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
								<i :class="'flag-' + language.lang_flag + ' flag'"></i> </a>
						</div>
            <div class="header-menu">
              <ul>
                <li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">
                  <a href="javascript:;">
										<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
											language.lang_name
										}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-middle sticky-header font2" :style="cssVars">
      <div class="container-fluid">
        <div class="header-left">
          <button
              class="mobile-menu-toggler  mr-2"
              type="button"
              @click="showMobileMenu"
            >
              <i class="fas fa-bars"></i>
            </button>
          <router-link to="/" class="logo">
            <img
              :src="settings.logo"
              width="101"
              height="40"
              :alt="settings.name"
            />
          </router-link>

          <nav class="main-nav w-100">
            <ul class="menu sf-arrows">
              <li v-for="item in mainMenu" :key="item.id">
                <router-link :to="item.url">{{ item.title }}</router-link>
              </li>
            </ul>
          </nav>
        </div>

        <div class="header-right">
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  computed: {
    cssVars() {
      return {
        "background-color": this.settings.header_color,
      };
    },
    TopHeaderCssVars() {
      return {
        "background-color": this.settings.primary_color,
      };
    },
  },
  data: function () {
    return {
      isHome: {
        type: Boolean,
        default: true,
      },
      settings: "",
      submenu: "",
      languages: "",
      topMenu: [],
      mainMenu: [],
    };
  },
  mounted() {
    this.getWebsiteSettings();
    this.getMainMenu();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      localStorage.setItem("locale", newVal);
    },
  },
  methods: {
    getWebsiteSettings() {
      this.settings = [];
      this.settings = JSON.parse(localStorage.getItem("settings"));
      this.languages = JSON.parse(localStorage.getItem("languages"));
    },
    getMainMenu() {
      axios
        .get("https://api.cnphub.com/public/menu?id=1")
        .then((res) => {
          this.topMenu = res.data.data.top_header_menu ? res.data.data.top_header_menu : [];
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
        })
        .catch((res) => {
          console.log(res);
        });
    },
    showMobileMenu: function () {
      document.querySelector("body").classList.add("mmenu-active");
    },
    showMobileSearch: function (e) {
      let headerSearch = e.currentTarget.closest(".header-search");
      headerSearch.classList.add("show");
      headerSearch
        .querySelector(".header-search-wrapper")
        .classList.add("show");
    },
    selectLang: function (language) {
      this.$i18n.locale = language.lang_locale;
      localStorage.setItem("lang_code", language.lang_code);
      localStorage.setItem("langSwitch", true);
      window.location.reload();
    },
  },
};
</script>

<style>
.header.theme-13 .mobile-menu-toggler {
  color: var(--color);
  margin-bottom: 6px;
}
@media (min-width: 992px){
	.header .container-fluid {
		padding-left: 3vw;
		padding-right: 3vw;
	}
  
}
#app .header.theme-13  .mobile-menu   .submenu li{
    
  }
 #app .header.theme-13  .mobile-menu   .submenu li a{
    padding: 0.3rem 0.4rem 0.7rem;
  }
.top-message .font-weight-extra-bold{
  color: #fff;
}

</style>