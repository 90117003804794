<template>
			<div role="main" class="main">

  <div class="container container-xl-custom pt-5">
    <div class="row">
      <div class="col">
        <!-- <h3 class="mb-1">{{ $t("GET IN TOUCH") }}</h3> -->
        <h3 class="text-secondary font-weight-bold text-capitalize text-7 mb-3">          
          {{ $t("Send Us a Message") }}
        </h3>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-lg-7 pb-5">
        <p v-html="settings.contact_text"></p>
        <form
          class="contact-form custom-form-style-1"
          ref="form"
          novalidate="novalidate"
        >
          <div class="contact-form-success alert alert-success mt-4" v-if="success">
            <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}
          </div>
          <div class="contact-form-error alert alert-danger mt-4" v-if="error">
            <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
            <span class="mail-error-message text-1 d-block"></span>
          </div>

          <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
            <strong>{{ $t("Error!") }}</strong> 
            <ul>
              <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
            <span class="mail-error-message text-1 d-block"></span>
          </div>

          <div class="row">
            <div class="form-group col">
              <input
                type="text"
                :placeholder="$t('Your Name')"
                value=""
                v-model="formData.name"
                data-msg-required="Please enter your name."
                maxlength="100"
                class="form-control bg-color-tertiary"
                name="name"
                id="name"
                required=""
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <input
                type="email"
                :placeholder="$t('E-mail Address')"
                value=""
                v-model="formData.email"
                data-msg-required="Please enter your email address."
                data-msg-email="Please enter a valid email address."
                maxlength="100"
                class="form-control bg-color-tertiary"
                name="email"
                id="email"
                required=""
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <input
                type="text"
                :placeholder="$t('Phone Number')"
                value=""
                v-model="formData.phone"
                data-msg-required="Please enter your phone number."
                maxlength="100"
                class="form-control bg-color-tertiary"
                name="phone"
                id="phone"
                required=""
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <input
                type="text"
                :placeholder="$t('Subject')"
                value=""
                v-model="formData.subject"
                data-msg-required="Please enter the subject."
                maxlength="100"
                class="form-control bg-color-tertiary"
                name="subject"
                id="subject"
                required=""
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <textarea
                maxlength="5000"
                :placeholder="$t('Message')"
                data-msg-required="Please enter your message."
                rows="5"
                v-model="formData.message"
                class="form-control bg-color-tertiary"
                name="message"
                id="message"
                required=""
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <input
                :value="$t('SEND MESSAGE')"
                v-on:click="checkForm"
                class="btn btn-outline btn-primary rounded-0 py-3 px-5 font-weight-semibold"
                data-loading-text="Loading..."
              />
            </div>
            <div class="loader" v-if="loading"></div>
          </div>
        </form>
      </div>
      <div class="col-lg-5">
        <div class="custom-card-style-2 card-contact-us mb-5">
          <div class="m-4">
            <div class="row flex-column px-5 pt-3 pb-4">
              <div class="row px-3 mb-3">
                <h3
                  class="text-secondary font-weight-bold text-capitalize my-3"
                >
                {{ $t("Contact Info") }}
                </h3>
                <p v-html="settings.contact_text">
                </p>
              </div>
              <hr class="my-3 opacity-5" />
              <div class="row px-lg-3 py-2 align-items-center">
                <div class="col-2 col-lg-1 px-1 text-center">
                  <i class="far fa-envelope text-7 text-secondary"></i>
                </div>
                <div class="col-10 col-lg-11">
                  <h4
                    class="text-secondary font-weight-bold text-capitalize mb-0"
                  >
                  {{ $t("E-mail Address") }}
                    
                  </h4>
                  <p class="mb-0">
                    <a class="px-0 text-default" :href="settings.email"
                      >{{ settings.email }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</template>
<script>
import axios from 'axios'
// import {required, email} from "vuelidate/lib/validators";

export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    checkForm: function (e) {
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      this.loading = true,
      axios
        .post(this.settings.BaseUrl + '/public/contact', this.formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          // this.response = res.data.data ? res.data.data : [];
            })
        .catch((res) => {
          this.error = true;
        }).finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
        });
    }
  },
};
</script>

<style>
.custom-card-style-2:before {
    background-color: #ff3f00;
}

.custom-card-style-2:before {
    content: "";
    display: block;
    top: 0;
    left: 0;
    height: 3px;
    width: 100%;
}

.m-4 {
    margin: 1.5rem!important;
}
.pb-4 {
    padding-bottom: 1.5rem!important;
}

.pt-3 {
    padding-top: 1rem!important;
} 

.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important;
}

.flex-column {
    flex-direction: column!important;
}

.custom-card-style-2.card-contact-us {
    margin-top: 6.4px !important;
    margin-top: 0.4rem !important;
}

.custom-card-style-2 {
    box-shadow: 0px 0px 35px -5px rgba(0, 0, 0, 0.1);
    border-left: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    margin-top: 16px;
    margin-top: 1rem;
}

html .text-color-secondary, html .text-secondary {
    color: #220c3c !important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 13.6px;
    font-size: 1.2rem;
    line-height: 1.85;
}

.form-control.bg-tertiary, .form-control.bg-color-tertiary {
    color: #777 !important;
    border-color: #f4f4f4;
}

.custom-form-style-1 .form-control {
    border: 0;
    padding: 19.2px;
    padding: 1.2rem;
    box-shadow: none !important;
    height: auto;
}

html .bg-color-tertiary, html .bg-tertiary {
    background-color: #f4f4f4 !important;
}

.form-group:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

html .btn-outline.btn-primary {
    background-color: transparent;
    background-image: none;
    border-color: var(--color);
}

html .btn-outline.btn-primary:hover, html .btn-outline.btn-primary.hover {
    color: #FFF;
    background-color: var(--color);
    border-color: var(--color);
}

html .text-color-secondary, html .text-secondary {
    color: #220c3c !important;
}

.text-center {
    text-align: center!important;
}

p {
    color: #777;
    line-height: 26px;
    margin: 0 0 20px;
}

.text-capitalize {
    text-transform: capitalize!important;
}

h4 {
    font-size: 1.4em;
    font-weight: 600;
    line-height: 27px;
    margin: 0 0 14px;
}

.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#b04040;
    /* background-image: url('../themes/shopwise/images/ajax-loader.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.2;
    filter: alpha(opacity=40);
}

.alert-success{
  text-align: center;
    font-weight: bold;
    color: green;
}

.alert-danger{
    text-align: center;
    font-weight: bold;
    color: red;
}

.btn-primary{
  color: var(--color);
  cursor:pointer;
}

</style>