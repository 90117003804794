<template>
  <div class="container">
    <div class="product-widgets-container row pb-2">

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" v-if="cat1.length > 0">
        <h4 class="section-sub-title">{{ cat1name }}</h4>

        <article class="post">
          <div class="post-media">
            <a :href="cat1[0].name" class=""
              ><img
                alt="blog"
                :width="450"
                :height="230"
                v-lazy="`${cat1[0].image}`"
                lazy="loaded"
                class="fade-in fadeIn"
                style="animation-duration: 0.3s; animation-delay: 0s"
            /></a>
            <div class="post-date">
              <span class="day">8</span> <span class="month">Apr</span>
            </div>
          </div>
        </article>

        <pv-small-product
          :product="product"
          v-for="(product, index) in cat1.slice(1, 4)"
          :key="'featured-product' + index"
        ></pv-small-product>
      </div>

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" v-if="cat2.length > 0">
        <h4 class="section-sub-title">{{ cat2name }}</h4>

        <article class="post">
          <div class="post-media">
            <a :href="cat2[0].name" class=""
              ><img
                alt="blog"
                :width="450"
                :height="230"
                v-lazy="`${cat2[0].image}`"
                lazy="loaded"
                class="fade-in fadeIn"
                style="animation-duration: 0.3s; animation-delay: 0s"
            /></a>
            <div class="post-date">
              <span class="day">8</span> <span class="month">Apr</span>
            </div>
          </div>
        </article>

        <pv-small-product
          :product="product"
          v-for="(product, index) in cat2.slice(1, 4)"
          :key="'best-product' + index"
        ></pv-small-product>
      </div>

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" v-if="cat3.length > 0">
        <h4 class="section-sub-title">{{ cat3name }}</h4>

        <article class="post">
          <div class="post-media">
            <a :href="cat3[0].name" class=""
              ><img
                alt="blog"
                :width="450"
                :height="230"
                v-lazy="`${cat3[0].image}`"
                lazy="loaded"
                class="fade-in fadeIn"
                style="animation-duration: 0.3s; animation-delay: 0s"
            /></a>
            <div class="post-date">
              <span class="day">8</span> <span class="month">Apr</span>
            </div>
          </div>
        </article>

        <pv-small-product
          :product="product"
          v-for="(product, index) in cat3.slice(1, 4)"
          :key="'latest-product' + index"
        ></pv-small-product>
      </div>

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" v-if="cat4.length > 0">
        <h4 class="section-sub-title">{{ cat4name }}</h4>

        <pv-small-product
          :product="product"
          v-for="(product, index) in cat4.slice(0, 6)"
          :key="'latest-product' + index"
        ></pv-small-product>
      </div>

    </div>
  </div>
</template>

<script>
import PvSmallProduct from "./PvSmallProduct4";
import axios from "axios";

export default {
  components: {
    PvSmallProduct,
  },
  props: {
    attributes:  {
      type: Array,
    },
  },
  data: function () {
    return {
      cat1: [],
      cat2: [],
      cat3: [],
      cat4: [],
      cat1name: '',
      cat2name: '',
      cat3name: '',
      cat4name: '',
    };
  },
  mounted: function () {
    // this.getPosts();
  },
  methods: {
    getPosts() {
      this.isLoading = true;
      axios
            .get("https://api.cnphub.com/public/fourCategoryData"+'?cat1='+this.attributes.cat1+'&'+'cat2='+this.attributes.cat2+'&'+'cat3='+this.attributes.cat3+'&'+'cat4='+this.attributes.cat4, {

        })
        .then((res) => {
          this.cat1 = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : '';
          this.cat2 = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : '';
          this.cat3 = res.data.data.cat3 ? res.data.data.cat3 : [];
          this.cat3name = res.data.data.cat3name ? res.data.data.cat3name : '';
          this.cat4 = res.data.data.cat4 ? res.data.data.cat4 : [];
          this.cat4name = res.data.data.cat4name ? res.data.data.cat4name : '';
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>