<template>
  <section class="home-section mb-2">
    <div class="container theme2">
      <div class="row">
        <div class="col-md-8 col-xl-8 col-lg-8 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider theme2 slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in posts" :key="index">
              <div class="slide-bg" style="background: #f2f2f2"></div>
              <div class="container d-flex align-items-center bg-black-trans">
                <div class="banner-layer d-flex flex-column">
                  <h6 v-if="post.created_at" class=" text-transform-none appear-animate"
                    data-animation-name="fadeInDownShorter" data-animation-delay="100" v-animate>{{
                      getPostDate(post.created_at)
                    }}
                  </h6>
                  <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->
                  <h3 class=" appear-animate " data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                    v-animate>{{ post.name }}</h3>
                  <!-- <span class="custom-font text-transform-none appear-animate" data-animation-name="fadeInRightShorter"
                    data-animation-delay="1100" v-animate><span>Extra!</span></span> -->
                  <h5 class=" appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="1400"
                    v-animate>
                  </h5>
                  <div class=" appear-animate" v-html="post.subtitle"></div>
                  <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html=" getPostDescription(post.content, 180)"></p>
                  <a class="readmore" :href="'/article/' + post.slug">{{ $t('Continue Reading') }}</a>
                  <!-- <h4 class="d-inline-block appear-animate" data-animation-name="fadeInRightShorter"
                    data-animation-delay="1800" v-animate>
                    <sup>UP TO</sup>
                    <b class="coupon-sale-text ls-10 text-white align-middle">50%</b>
                  </h4> -->
                </div>
              </div>
            </div>


          </PvCarousel40>
        </div>
        <div class="col-md-4 col-12  mb-20 top-6-articles theme2">
          <div class="article" v-for="(post, index) in posts" :key="index">
            <h5><a :href="'/article/' + post.slug">{{  getPostDescription(post.name, 90) }}</a></h5><span class="time">{{
              getPostDate(post.created_at)
            }}
              <span></span></span>
          </div>


        </div><!-- Small Post Wrapper End -->




      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from './../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {

      mainSliderArticle: [],
      articlesList: null,


      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  mounted() {
    // this.getSliderNews();

  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text,length=120) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));




    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1); 
    //       }


    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;



    // },
  },
}
</script>

<style>
.home-slider.theme2 {
  border: 4px solid var(--color);
  min-height: 50rem;
  max-height: 50rem;
}

.home-slider.theme2 {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 3px;
}

.theme2.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

#app .home-slider.theme2 .slide-bg {
  /* min-height: 50vh; */
  min-height: 450px;
}


.theme2.top-6-articles .article {}

.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 0.8rem;
  font-size: 2.4em;
}
.theme2 .top-6-articles .article h5{
  min-height: 50px;
}

</style>