<template>
	<header class="header">
		<div class="header-top">
			<div class="container">
				<div class="header-left">
					<div class="header-dropdown mr-auto mr-sm-3 mr-md-0">
						<a href="javascript:;">
							<i class="flag-us flag"></i>Eng</a>

						<div class="header-menu">
							<ul>
								<li>
									<a href="javascript:;">
										<i class="flag-us flag mr-2"></i>ENG</a>
								</li>
								<li>
									<a href="javascript:;">
										<i class="flag-fr flag mr-2"></i>FRA</a>
								</li>
							</ul>
						</div>
					</div>

					<div class="header-dropdown ml-4">
						<a href="javascript:;">USD</a>
						<div class="header-menu">
							<ul>
								<li>
									<a href="javascript:;">EUR</a>
								</li>
								<li>
									<a href="javascript:;">USD</a>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator"></span>

					<div class="header-menu">
						<ul class="mb-0 d-none d-lg-flex">
							<li>
								<router-link to="/pages/checkout">Checkout</router-link>
							</li>
							<li>
								<router-link to="/pages/cart">Cart</router-link>
							</li>
							<li>
								<router-link to="/pages/contact-us">Contact Us</router-link>
							</li>
						</ul>
					</div>
				</div>

				<div class="header-right">
					<div class="header-contact d-none d-sm-flex align-items-center ml-auto">
						<i class="icon-phone-2"></i>
						<h6>
							<a
								href="tel:#"
								class="d-block"
							>+123 5678 890</a>
						</h6>
					</div>

					<span class="separator d-sm-block d-none"></span>

					<pv-header-search></pv-header-search>

					<router-link
						to="/pages/wishlist"
						class="header-icon position-relative"
						title="wishlist"
					>
						<i class="icon-wishlist-2 text-dark"></i>
						<span class="badge-circle">{{ wishList.length }}</span>
					</router-link>

					<pv-cart-menu></pv-cart-menu>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left d-lg-flex d-none">
					<pv-main-menu></pv-main-menu>
				</div>

				<div class="header-center justify-content-between">
					<router-link
						to="/"
						class="logo"
					>
						<img
							src="@/assets/css/images/logo-black.png"
							width="101"
							height="40"
							alt="Porto Logo"
						/>
					</router-link>

					<button
						class="mobile-menu-toggler"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>
				</div>

				<div class="header-right d-none d-lg-flex">
					<div class="main-nav d-flex w-lg-max justify-content-end">
						<ul class="menu">
							<li>
								<router-link
									to="/pages/blog"
									:class="{active: $route.path.indexOf('/pages/blog') > -1}"
								>Blog</router-link>
							</li>
							<li>
								<router-link to="/pages/about-us">{{ $t("About Us") }}</router-link>
							</li>
							<li>
								<router-link to="/pages/contact-us">Contact Us</router-link>
							</li>
							<li>
								<a
									href="/https://1.envato.market/DdLk5"
									target="_blank"
								>Buy Porto!</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';
import PvCartMenu from './PvCartMenu';
import PvHeaderSearch from './PvHeaderSearch';
import { mapGetters } from 'vuex';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		PvCartMenu,
		PvHeaderSearch
	},
	data: function() {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			isFullwidth: {
				type: Boolean,
				default: false
			}
		};
	},
	computed: {
		...mapGetters('wishlist', ['wishList'])
	},
	watch: {
		$route: function() {
			if (this.$route.path.includes('fullwidth')) {
				this.isFullwidth = true;
			} else this.isFullwidth = false;
		}
	},
	mounted: function() {
		if (this.$route.path.includes('fullwidth')) {
			this.isFullwidth = true;
		} else this.isFullwidth = false;
	},
	methods: {
		
		showMobileMenu: function() {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function(e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		}
	}
};
</script>