<template>
	<section class="home-section mb-2">
		<div v-html="settings.banner_728_90_footer" class="header-add">
        </div>
		
		<div class=" Footer">
			<template>
				<component :is="attributes.theme" :key="attributes.theme" :attributes="attributes"  :footerMenu="footerMenu" :settings='settings'>
				</component>
			</template>
		</div>
	</section>
</template>
  
<script>

import axios from 'axios'
import FooterTheme2 from './FooterTheme2.vue'
import FooterTheme3 from './FooterTheme3.vue'
import FooterTheme4 from './FooterTheme4.vue'
import FooterTheme5 from './FooterTheme5.vue'
import FooterTheme7 from './FooterTheme7.vue'
import FooterTheme6 from './FooterTheme6.vue'
import FooterTheme8 from './FooterTheme8.vue'
import FooterTheme9 from './FooterTheme9.vue'
import FooterTheme10 from './FooterTheme10.vue'
import FooterTheme11 from './FooterTheme10.vue'
import FooterTheme14 from './FooterTheme14.vue'
import FooterTheme18 from './FooterTheme18.vue'

import Footer1 from './Footer1.vue'
import Footer2 from './Footer2.vue'
import Footer3 from './Footer3.vue'
import Footer7 from './Footer7.vue'
import Footer8 from './Footer8.vue'
export default {
	components: { 
		FooterTheme2,
		FooterTheme3,
		FooterTheme4,
		FooterTheme5,
		FooterTheme6,
		FooterTheme7,
		FooterTheme8,
		FooterTheme9,
		FooterTheme10,
		FooterTheme11,
		FooterTheme14,
		FooterTheme18,
		Footer1,
		Footer2,
		Footer3,
		Footer7,
		Footer8,
	},
	props: {
		attributes: {
			type: Array,
			limit: Number,
		},
	},
	data: function () {
		return {
			footerMenu: [],
			settings: [],
		};
	},
	created() {
		this.getWebsiteSettings();
	},
	methods: {
		getWebsiteSettings(){
			this.settings = JSON.parse(localStorage.getItem("settings"));
		}
	},
};
</script>
 


<style>
#app .footer {
	background: var(--footer-color) !important;
}

#app .footer-middle {
	padding-top: 1.7rem;
	padding-bottom: 0rem;
}
</style>