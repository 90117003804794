<template>
  <section
    class="innerbanner page-header page-header-modern custom-page-header-style-1 bg-color-primary page-header-lg"
  >
    <div class="container container-xl-custom py-5">
      <div class="row">
        <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1
            class="font-weight-extra-bold text-14 appear-animation animated fadeInRightShorter appear-animation-visible"
            data-appear-animation="fadeInRightShorter"
            data-appear-animation-delay="200"
            style="animation-delay: 200ms"
          >
            {{ post.name  }}
          </h1>
        </div>
        <div class="col-md-4 order-1 order-md-2 align-self-center">
          <ul
            class="breadcrumb d-block text-md-end breadcrumb-light appear-animation animated fadeInRightShorter appear-animation-visible"
            data-appear-animation="fadeInRightShorter"
            data-appear-animation-delay="400"
            style="animation-delay: 400ms"
          >
            <!-- <li><a href="#">Home</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        'background-image': 'url(' + this.settings.storageUrl + this.settings.banner_image + ')',
      }
    },
  },
};
</script>

<style>
html .bg-color-primary, html .bg-primary {
    background-color: var(--color);
}
.innerbanner{
  margin-top: -2rem !important;
}
</style>