<template>
  <div>
    <section
      class="section section-height-3 bg-color-grey-scale-1 m-0 border-0 mt-2 mb-2"
    >
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 pb-sm-4 pb-lg-0 pe-lg-5 mb-sm-5 mb-lg-0">
            <h2 class="text-color-dark font-weight-normal text-6 mb-2">
              <strong class="font-weight-extra-bold">{{ $t("Who We Are") }}</strong>
            </h2>
            <p class="lead" v-html="settings.about_text"></p>
          </div>
          <div
            class="col-sm-8 col-md-6 col-lg-4 offset-sm-4 offset-md-4 offset-lg-2 position-relative mt-sm-5"
            style="top: 1.7rem"
          >
            <img
              :src="getImage(settings.about_image, 'default')"
              class="img-fluid position-absolute d-none d-sm-block appear-animation animated expandIn appear-animation-visible"
              data-appear-animation="expandIn"
              data-appear-animation-delay="300"
              style="top: 10%; left: -50%; animation-delay: 300ms"
              alt=""
            />
            <!-- <img
            :src="settings.light_logo"
              class="img-fluid position-absolute d-none d-sm-block appear-animation animated expandIn appear-animation-visible"
              data-appear-animation="expandIn"
              style="top: -33%; left: -29%; animation-delay: 100ms"
              alt=""
            />
            <img
            :src="settings.logo"
              class="img-fluid position-relative appear-animation mb-2 animated expandIn appear-animation-visible"
              data-appear-animation="expandIn"
              data-appear-animation-delay="600"
              alt=""
              style="animation-delay: 600ms"
            /> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>
</style>