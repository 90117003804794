<template>
	<section class="category-section">
		<div class="container">
			<!-- <h2
				class="section-title m-b-2 appear-animate"
				data-animation-name="fadeInUpShorter"
				v-animate
			>Shop By
				Category</h2> -->
			<!-- <h5
				class="section-info p-b-4 mb-2 appear-animate"
				data-animation-name="fadeInUpShorter"
				data-animation-delay="200"
				v-animate
			>Only the best seller products added recently in our catalog</h5> -->

			<isotope
				class="row grid appear-animate"
				data-animation-name="fadeInUpShorter"
				data-animation-delay="400"
				v-animate
				:options="isotopeOptions"
				:list="[]"
			>
				<div
					class="grid-item col-sm-6 col-md-3 height-3-5"
					key="cat-1"
				>
					<div class="product-category">
						<router-link :to="{path: '/shop', query: {category: 'body'}}">
							<figure style="background-color: #f4f4f2">
								<img
									:src="slider[0].image"
									alt="category"
									width="264"
									height="345"
								>
							</figure>
						</router-link>
						<div class="category-content34">
							<h3>Body</h3>
						</div>
					</div>
				</div>
				<div
					class="grid-item col-sm-6 height-5-5"
					key="cat-2"
				>
					<div class="product-category">
						<router-link :to="{path: '/shop', query: {category: 'skincare'}}">
							<figure style="background-color: #f4f4f2">
								<img
									:src="slider[1].image"
									alt="category"
									width="550"
									height="564"
								>
							</figure>
						</router-link>
						<div class="category-content34">
							<h3>Skincare</h3>
						</div>
					</div>
				</div>
				<div
					class="grid-item col-sm-6 col-md-3 height-2-5"
					key="cat-3"
				>
					<div class="product-category">
						<router-link :to="{path: '/shop', query: {category: 'hair'}}">
							<figure style="background-color: #f4f4f2">
								<img
									:src="slider[2].image"
									alt="category"
									width="264"
									height="199"
								>
							</figure>
						</router-link>
						<div class="category-content34">

							<h3>Makeup</h3>
						</div>
					</div>
				</div>
				<div
					class="grid-item col-sm-6 col-md-3 height-3-5"
					key="cat-4"
				>
					<div class="product-category">
						<router-link :to="{path: '/shop', query: {category: 'fragrance-1'}}">
							<figure style="background-color: #f4f4f2">
								<img
									:src="slider[3].image"
									alt="category"
									width="264"
									height="345"
								>
							</figure>
						</router-link>
						<div class="category-content34">
							<h3>Fragrance</h3>
						</div>
					</div>
				</div>
				<div
					class="grid-item col-sm-6 col-md-3 height-2-5"
					key="cat-5"
				>
					<div class="product-category">
						<router-link :to="{path: '/shop', query: {category: 'hair'}}">
							<figure style="background-color: #f4f4f2">
								<img
									:src="slider[4].image"
									alt="category"
									width="264"
									height="199"
								>
							</figure>
						</router-link>
						<div class="category-content34">
							<h3>Hair</h3>
						</div>
					</div>
				</div>
				<div
					class="grid-col-sizer col-1"
					key="cat-sizer"
				></div>
			</isotope>
		</div>
	</section>
</template>

<script>
import isotope from 'vueisotope';
import axios from 'axios'

export default {
	components: {
		isotope
	},
	data: function () {
		return {
			isotopeOptions: {
				itemSelector: 'grid-item',
				layoutMode: 'masonry',
				percentPosition: false,
				masonry: {
					columnWidth: '.grid-col-sizer'
				}
			},
			slider: [],
		}
	},
	mounted() {
      this.getWebsiteElements();
    },
	methods: {
      getWebsiteElements() {
                this.slides = [];
                this.isLoading = true;
                axios.get('https://api.cnphub.com/public/posts')
                .then(res => {
                    this.slider = res.data.data ? res.data.data : [];
                })
                .catch(res => {
                  console.log(res);
                });
            },

        },
}
</script>