export default {

    hasVidFun(videos) {


        for (let index = 0; index < videos.length; index++) {

            for (let j = 0; j < videos[index].length; j++) {

                if (videos[index][j].key == "youtube_url" && videos[index][j].value != null) {

                    return 1;
                }
            }


        }
        return 0;
    },
    hasLogosFun(logos ) {
        // console.log(logos);
        for (let index = 0; index < logos.length; index++) {
            for (let j = 0; j < logos[index].length; j++) {
                if (logos[index][j].key == "logo_url" && logos[index][j].value != null) {
                    return 1;
                }
            }
        }
        return 0;
    },
    hasPhotosFun(photos ) {
        // console.log(logos);
        for (let index = 0; index < photos.length; index++) {
            for (let j = 0; j < photos[index].length; j++) {
                if (photos[index][j].key == "image" && photos[index][j].value != null) {
                    return 1;
                }
            }
        }
        return 0;
    },
    
    getPostDate(date) {
        var mydate = new Date(date);

        var settings = JSON.parse(localStorage.getItem("settings"));
        return this.getPostTime(date, settings.date_format);//mydate.toDateString();
    },

    getPostSmallDescription(text, length) {
        if (text) {
            var more = "...";
            if (text.length < length) more = '';
            return (text.substring(0, length) + more).replace(/(<([^>]+)>)/ig, '');
        }
        return '';

    },

    getPostTime(date, formatIndex = 4) {
        var mydate = new Date(date);
        var dateFormat = [
            {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            },
            {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            },
            {
                year: 'numeric',
                month: '2-digit',
                day: 'numeric',
            },
            {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
            },
            {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            },
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
        ];
        if (formatIndex == 0) return mydate.toDateString();
        return mydate.toLocaleDateString('en-us', dateFormat[formatIndex]);//new Intl.DateTimeFormat('en-US',dateFormat[0] ).format(date);
    },

    // getPostDay(date) {
    //     var mydate = new Date(date);
    //     // return date;
    //     return mydate.getDay();
    // },
    getPostDay(date) {
        // var mydate = new Date(date);
        var mydate = new Date(date);
        var dateFormat =
        {
            // year: 'numeric' ,
            // month:  'short' ,
            day: 'numeric',
        };
        return mydate.toLocaleDateString('en-us', dateFormat);
    },
    getPostMonth(date) {
        // var mydate = new Date(date);
        var mydate = new Date(date);
        var dateFormat =
        {
            // year: 'numeric' ,
            month: 'short',
            // day: 'numeric' ,
        };
        return mydate.toLocaleDateString('en-us', dateFormat);
    },

}