<template>
	<div class="instagram-section">
		<h2 class="section-title text-center">From Our Instagram</h2>
		<h6 class="section-subtitle text-center font-weight-normal">@portoecommerce</h6>

		<pv-carousel
			class="swiper-carousel swiper-theme"
			:options="instagramSlider"
		>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-1.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-2.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-3.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-4.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-5.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-6.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-7.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-8.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
			<a
				href="javascript:;"
				class="swiper-slide"
			>
				<img
					v-lazy="'@/assets/css/images/home/instagrams/instagram-9.jpg'"
					width="400"
					height="400"
					image-ratio="100"
					alt="Feed"
					style="background: #f4f4f4"
				></a>
		</pv-carousel>
	</div>
</template>

<script>
import PvCarousel from './PvCarousel.vue';
import { instagramSlider } from '@/utils/data/carousel';

export default {
	components: {
		PvCarousel
	},
	data: function() {
		return {
			instagramSlider: instagramSlider
		};
	}
};
</script>