<template>
  <aside class="sidebar blog-sidebar  col-lg-3" sticky-container>
    <div class="sidebar-content" v-sticky="isSticky" sticky-offset="{top: 75}">
      <div class="sidebar-wrapper">
        <div class="sidebar-content">          

          <form class="mb-2" action="page-search-results.html" method="get">
            <div class="input-group mb-3 pb-1">
              <input
                class="form-control text-1"
                :placeholder="$t('Search')"
                name="s"
                type="text"
              />
              <button type="submit" class="btn btn-dark text-1 p-2">
                <i class="fas fa-search m-2"></i>
              </button>
            </div>
          </form>

          <!-- <div class="px-3 mb-5">
            <h3 class="text-color-quaternary text-capitalize font-weight-bold text-5 m-0 mb-3 section-sub-title">{{ $t("Recent Posts") }}</h3>
            <div class="pb-2 mb-1">
              <div v-for="(post, index) in recentPosts" :key="index">
                <a :href="'/article/' + post.slug" 
              class="date-color text-color-dark text-hover-primary font-weight-bold text-3 d-block pb-3 line-height-4">
                {{ getPostDate(post.created_at) }}
              </a>
              <a :href="'/article/' + post.slug" class="post-color text-color-dark text-hover-primary font-weight-bold text-3 d-block pb-3 line-height-4">
                {{ post.name }}
              </a>
              </div>
            </div>
          </div> -->

          <div class="row mb-5">
            <div class="col">
              <h4 class="font-weight-semi-bold section-sub-title"><span>{{ $t("Recent Posts") }}</span></h4>
              <div class="product row row-gutter-sm align-items-center mb-4" v-for="(post, index) in recentPosts"
                :key="index">
                <div class="col-md-5 col-lg-5">
                  <div class="product-thumb-info border-0">
                    <a :href="'/article/' + post.slug">
                      <div class="product-thumb-info-image">
                        <img :alt="post.name" class="img-fluid" :src="getImage(post.image, 'thumb')" v-if="post.image">
                        <img :alt="post.name" class="img-fluid" :src="getImage('banners/image.jpg' , 'thumb')" v-else>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-md-7 col-lg-7 ms-md-0 ms-lg-0 ps-lg-1 pt-1">
                  <a :href="'/article/' + post.slug" class="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0">{{ post.name }}</a>
                  <p class="price text-4 mb-0">
                    <span class="sale text-color-dark font-weight-semi-bold">{{ getPostDate(post.created_at) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="widget mb-5">
            <h4 class="widget-title section-sub-title"><span>{{ $t("popularCategories") }}</span></h4>
            <div class="mb-3 pb-1">
              <a
                :href="`/category/${category.slug}`"
                v-for="(category, index) in popularCategories.slice(0, 5)"
                :key="index"
              >
                <span
                  class="badge badge-dark badge-sm text-uppercase pa-2 mr-b"
                  >{{ category.name }}</span
                >
              </a>
            </div>
          </div>

          <div class="widget mb-5" v-if="popularTags.length > 0 ">
            <h4 class="widget-title section-sub-title">{{ $t("PopularTags") }}</h4>
            <div class="mb-3 pb-1">
              <a
                :href="`/tag/${tag.slug}`"
                v-for="(tag, index) in popularTags"
                :key="index"
              >
                <span
                  class="badge badge-dark badge-sm text-uppercase pa-2 mr-b"
                  >{{ tag.name }}</span
                >
              </a>
            </div>
          </div>

          <h4 v-if="hasPhotosFun()==1" class="section-sub-title">{{ $t("Images") }}</h4>
          <div v-for="(item, index) in photos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <a
                href="#"
                target="_blank"
                class="my-4 pt-3 d-block"
                v-if="logo.key !== 'caption'"
              >
                <img
                  :alt="logo.caption"
                  class="img-fluid"
                  :src="getImage(logo.value)"
                />
              </a>
            </div>
          </div>

          <h4 v-if="hasLogosFun()==1" class="section-sub-title">{{ $t("Logos") }}</h4>
          <div v-for="(item, index) in logos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <a
                href="#"
                target="_blank"
                class="my-4 pt-3 d-block"
                v-if="logo.key !== 'logo_url'"
              >
                <img
                  :alt="logo.caption"
                  class="img-fluid"
                  :src="getImage(logo.value)"
                />
              </a>
            </div>
          </div>

          <h4 v-if="hasVidFun()==1" class="section-sub-title">{{ $t("Videos") }}</h4>
          <div v-for="(item, index) in videos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <video-embed
                :params="{ autoplay: 1 }"
                :src="logo.value"
              ></video-embed>
            </div>
          </div>

          <div class="widget widget-categories mb-5" v-if="settings.about">
            <h4 class="section-sub-title">{{ $t("About Us") }}</h4>
            <p>{{ settings.about }}</p>
          </div>
          
        </div>
      </div>
    </div>
  </aside>
</template>
  
  <script>
import Sticky from "vue-sticky-directive";

export default {
  directives: {
    Sticky,
  },
  data: function () {
    return {
      isSticky: false,
      hasVid:0,
    };
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    popularCategories: {
      type: Array,
    },
    recentPosts: {
      type: Array,
    },
    popularTags: {
      type: Array,
    },
    logos: {
      type: String,
    },
    videos: {
      type: String,
    },
    photos: {
      type: String,
    },
  },
  mounted: function () {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler: function () {
      this.isSticky = window.innerWidth > 991 ? true : false;
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    hasVidFun() {
    
       return  this.$postHelper.hasVidFun(this.videos);
    },
    hasLogosFun() {
      
      return  this.$postHelper.hasLogosFun(this.logos);
    },
    hasPhotosFun() {
      
      return  this.$postHelper.hasPhotosFun(this.photos);
    },
    count(element) {
      if (element !== undefined) {
        return element.length;
      }else{
        return 0;
      }
    },
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
};
</script>
  
  <style>
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.btn-dark {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}

.badge-dark {
    color: #fff;
    background-color: var(--color) !important;
}

.col-lg-7 a{
  color: var(--color) !important;
}

.pa-2{
  padding: 0.6rem !important;
}

.mr-b{
  margin: 0.4rem !important;
}

.date-color{
  color: #000 !important;
  padding: 5px;
  background-color: var(--color);
}

.post-color{
  color: #000 !important;
}

.section-title-style{
  font-size: 22px!important;
  color: #555;
  border-radius: 10px 50px 50px 10px;
  padding: 5px;
  border-left: 5px solid var(--color);
  background: #e9e9e9;
}

</style>