<template>
	<section class="featured-products-container curved-border bg-gray">
		<div class="container">
			<h2 class="section-title mb-2">Featured Items</h2>

			<div
				class="row"
				v-if="products.length === 0"
				data-animation-name="fadeIn"
				data-animation-delay="200"
				v-animate
			>
				<pv-carousel24
					class="featured-products w-100"
					:options="featuredSlider"
				>
					<div
						class="swiper-slide"
						v-for="index in [1,2,3]"
						:key="'featured-skel' + index"
					>
						<div class="product-loading-overlay"></div>
					</div>
				</pv-carousel24>
			</div>

			<div
				class="row"
				v-if="products.length > 0"
			>
				<pv-carousel24
					class="featured-products dots-small appear-animate w-100"
					:options="featuredSlider"
					data-animation-name="fadeIn"
					data-animation-delay="200"
					v-animate
				>
					<pv-product-one24
						:product="product"
						class="swiper-slide"
						v-for="(product,index) in products.slice(0,3)"
						:key="'featured' + index"
					></pv-product-one24>
				</pv-carousel24>
			</div>

			<hr>
		</div>

		<div
			class="banner-section appear-animate"
			data-animation-name="fadeIn"
			data-animation-delay="200"
			v-animate
		>
			<div class="container position-static">
				<div class="banner banner1 bg-white position-static">
					<div class="row no-gutters m-0 align-items-center">
						<div class="col-md-5 col-xl-6 position-static">
							<!-- <div class="shape-wrapper">
								<img
									v-lazy="'./images/home/shape2.png'"
									alt="shape"
									width="129"
									height="408"
								>
							</div> -->

							<img
								class="position-relative"
								v-lazy="'./images/home/banners/banner-99.jpg'"
								alt="banner"
								width="610"
								height="242"
							>
						</div>
						<div class="col-md-7 col-xl-6 px-4 px-md-0">
							<div class="row align-items-center py-5">
								<div class="col-sm-6 col-lg-7 mb-4 mb-sm-0">
									<h2 class="mb-0">Meet Porto</h2>
									<p class="mb-0">The easier way to build your
										portfolio & showcase your work.</p>
								</div>
								<div class="col-sm-6 col-lg-5 text-sm-center">
									<router-link
										to="/shop"
										class="btn btn-dark btn-round ls-0"
									>Get Started
										<i class="fas fa-chevron-right"></i></router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import PvCarousel24 from '../Utility/Common/PvCarousel24.vue';
import PvProductOne24 from './products/PvProductOne24.vue';
import { featuredSlider } from '@/utils/data/carousel';
import {
	getProductsByAttri,
	getTopSellingProducts,
	getTopRatedProducts
} from '@/utils/service';
import { getCookie } from '@/utils';
import Api, { baseUrl } from '@/api';


export default {
	components: {
		PvCarousel24,
		PvProductOne24
	},
	data: function () {
		return {
			featuredSlider: featuredSlider,
			products: [],
		}
	},
	mounted: function() {
		Api.get(`${baseUrl}/demo2`)
			.then(response => {
				this.products = response.data.products;
				this.posts = response.data.posts;
				this.featuredProducts = getProductsByAttri(
					response.data.products
				);
				this.newProducts = getProductsByAttri(
					response.data.products,
					'is_new'
				);
				this.bestProducts = getTopSellingProducts(
					response.data.products
				);
				this.topRatedProducts = getTopRatedProducts(
					response.data.products
				);
			})
			.catch(error => ({ error: JSON.stringify(error) }));

	},
}
</script>