import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import PageNotFound from '../components/404.vue'
import RemoveRoute from '../components/410.vue'
import PostOne from '../components/Posts/PostOne/index.vue'
import Category from '../components/List/MainList'
import Tag from '../components/List/MainList'
import Search from '../components/List/MainList'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/story-:dynamicPart(.+)',
    name: 'RemoveRoute',
    component: RemoveRoute
  },
  {
    path: '/posts/postOne',
    name: 'PostOneold',
    component: PostOne
  },
  {
    path: '/article/:slug',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: ':lan/article/:slug',
    name: 'PostOne',
    component: PostOne
  },
  {
    path: '/category/:slug',
    name: 'category',
    component: Category
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/tag/:slug',
    name: 'tag',
    component: Tag
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  // and finally the default route, when none of the above matches:
  { 
    path: "*",
    name: 'PageNotFound',
    component: PageNotFound,
   }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
