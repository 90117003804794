<template>
	<footer class="footer bg-dark">
		<div class="footer-middle">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-xl-2 col-lg-2 d-flex align-items-center">
						<nuxt-link to="/">
							<img
								:src="'https://cnphub.menaws.com/storage/'+settings.logo"
								alt="Logo"
								class="logo-footer"
							>
						</nuxt-link>
					</div>

					<div class="col-md-12 col-xl-3 col-lg-3">
						<div class="widget">
							<h4 class="widget-title">{{ $t("GET IN TOUCH") }}</h4>
							<ul class="contact-info">
								<li>
									<span class="contact-info-label">Address</span>{{ settings.address }}
								</li>
								<li>
									<span class="contact-info-label">Phone</span><a :href="settings.email">Toll Free {{ settings.phone }}</a>
								</li>
								<li>
									<span class="contact-info-label">Email</span> <a :href="settings.email">{{ settings.email }}</a>
								</li>
								<li>
									<span class="contact-info-label">Working Days/Hours</span>
									Mon - Sun / 9:00AM - 8:00PM
								</li>
							</ul>

							<div class="social-icons">
								<a
									:href="settings.instagram"
									class="social-icon social-instagram icon-instagram"
									title="Instagram"
								></a>
								<a
									:href="settings.twitter"
									class="social-icon social-twitter icon-twitter"
									title="Twitter"
								></a>
								<a
									:href="settings.facebook"
									class="social-icon social-facebook icon-facebook"
									title="Facebook"
								></a>
							</div>
						</div>
					</div>

					<div class="col-md-6 col-xl-3 col-lg-3 col-12">
						<div class="widget">
							<h4 class="widget-title">Customer Services</h4>

							<ul class="links">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>
							</ul>
						</div>
					</div>

					<div class="col-md-6 col-xl-4 col-lg-4 col-12">
						<div class="widget widget-newsletter">
							<h4 class="widget-title ls-n-10">Subscribe newsletter</h4>
							<p>Get all the latest information on events,<br /> sales and offers. Sign up for
								newsletter:
							</p>
							<form
								action="#"
								class="mb-0"
							>
								<input
									type="email"
									class="form-control m-b-3"
									placeholder="Email address"
									required
								>

								<input
									type="submit"
									class="btn btn-outline-primary shadow-none"
									value="Subscribe"
								>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom">
				<div class="container d-sm-flex align-items-center">
					<div class="footer-left">
						<span class="footer-copyright">© {{ $t('copyright') }}<span class="d-inline-block">2023.</span>
							{{ $t('All Rights Reserved.') }}
						</span>
					</div>

					
				</div>
			</div>
		</div>
	</footer>
</template>


<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
    },
	methods: {
      getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
	},
};
</script>