<template>
	<div class="product-default inner-quickview inner-icon">
		<figure>
			<router-link :to="product.slug">
				<img
					:key="`related-large-${product.id}`"
					v-lazy="`${product.image}`"
					:alt="product.name"
					:width="600"
					:height="900"
					:image-ratio="150"
					:class="{'last-image': 'index === 1'}"
				/>
			</router-link>

		</figure>

		
	</div>
</template>

<script>
import { baseUrl } from '@/api';

export default {
	props: {
		product: Object,
		adClass: String,
		isActions: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			baseUrl: baseUrl,
		};
	},
	computed: {
	},
	mounted: function () {
	},
	methods: {
		
	}
};
</script>