<template>
	<div class="product-default left-details product-widget post-small-2 mb-2">
		<figure>
			<router-link :to="'/article/' + post.slug + ''">
				<template v-if="isLazy">
					<img alt="blog" :width="200" :height="200" v-lazy="`${getImage(post.image,'thumb')}`" v-if="post.image"
						:src="getImage(post.image)" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" />
					<img :alt="post.name" :width="200" :height="200" v-lazy="`${getImage('banners/image.jpg','thumb')}`" v-else
						:src="getImage(post.image)" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" />
				</template>


			</router-link>
		</figure>

		<div class="product-details">


			<h2 class="product-title">
				<div class="time">{{ getPostDate(post.created_at) }} </div>
				<router-link :to="'/article/' + post.slug + ''" v-html="getPostDescription(post.name,300)"></router-link>
				
			</h2>

		</div>
	</div>
</template>

<script>
import { baseUrl } from '@/api/index';

export default {
	props: {
		post: Object,
		isLazy: {
			type: Boolean,
			default: true
		},
		isCat: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			baseUrl: baseUrl,

		};
	},
	mounted: function () {
	},
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getPostDate(date) {
			return this.$postHelper.getPostDate(date);
		},
		getPostDescription(text, lenght = 280) {
			return this.$postHelper.getPostSmallDescription(text, lenght);
		},
	}
};
</script>

<style>
.post-small-2 .time{
	color: var(--color);
    padding: 4px 0;
    font-size: 12px;
}
</style>