<template>
  <div class="main">
    <section class="section bg-transparent position-relative border-0 m-0 p-0">
      <div class="container custom-container-background custom-z-index-1 py-5">
        <div class="row position-relative z-index-1 pt-5-5">
          <div class="col">

            <h2 class="text-color-dark font-weight-medium text-8 mt-5 mb-3">
              <strong>{{ $t("Get Touch with us") }}</strong>
            </h2>

            <p
              class="text-4 line-height-8 mb-4"
              v-html="settings.contact_text"
            ></p>

            <div class="row py-4 mb-3">
              <div class="col-lg-12 pb-4 pb-lg-0">
                <ul class="list list-unstyled">
                  <li class="d-flex align-items-center mb-3">
                    <i
                      class="icon icon-envelope text-color-dark text-3 font-weight-bold position-relative top-1 me-3-5"
                    ></i>
                    <a
                      :href="'mailto:' + settings.email"
                      class="d-inline-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-4-5"
                      >{{ settings.email }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>

            <!-- <hr class="my-5" /> -->


            <form
              class="contact-form custom-form-style-1"
                ref="form"
            >
              <div class="contact-form-success alert alert-success mt-4" v-if="success">
                <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}

              </div>

              <div class="contact-form-error alert alert-danger mt-4" v-if="error">
                <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
                <span class="mail-error-message text-1 d-block"></span>
              </div>

              <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
                    <strong>{{ $t("Error!") }}</strong> 
                    <ul>
                      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>

              <div class="row row-gutter-sm">
                <div class="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value=""
                      v-model="formData.name"
                    data-msg-required="Please enter your name."
                    maxlength="100"
                    class="form-control"
                    name="name"
                    id="name"
                    required
                    :placeholder="$t('Your Name')"

                  />
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value=""
                      v-model="formData.phone"
                    data-msg-required="Please enter your phone number."
                    maxlength="100"
                    class="form-control"
                    name="phone"
                    id="phone"
                    required
                    :placeholder="$t('Phone Number')"
                  />
                </div>
              </div>
              <div class="row row-gutter-sm">
                <div class="form-group col-lg-6 mb-4">
                  <input
                    type="email"
                    value=""
                    v-model="formData.email"
                    data-msg-required="Please enter your email address."
                    data-msg-email="Please enter a valid email address."
                    maxlength="100"
                    class="form-control"
                    name="email"
                    id="email"
                    required
                    :placeholder="$t('E-mail Address')"
                  />
                </div>
                <div class="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value=""
                    v-model="formData.subject"
                    data-msg-required="Please enter the subject."
                    maxlength="100"
                    class="form-control"
                    name="subject"
                    id="subject"
                    required
                    :placeholder="$t('Subject')"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col mb-4">
                  <textarea
                    maxlength="5000"
                    v-model="formData.message"
                    data-msg-required="Please enter your message."
                    rows="10"
                    class="form-control"
                    name="message"
                    id="message"
                    required
                    :placeholder="$t('Message')"
                  ></textarea>
                </div>
              </div>
              <div class="row">
                <div class="form-group col mb-3">
                  <button
                    v-on:click="checkForm"
                    class="btn btn-secondary btn-rounded border-0 font-weight-bold text-3 btn-px-5 py-3"
                    data-loading-text="Loading..."
                  >
                    {{ $t("Send a Message") }}
                  </button>
                </div>
                  <div class="loader" v-if="loading"></div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    checkForm: function (e) {
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      this.loading = true,
      axios
        .post(this.settings.BaseUrl + '/public/contact', this.formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          // this.response = res.data.data ? res.data.data : [];
            })
        .catch((res) => {
          this.error = true;
        }).finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
        });
    }
  },
};
</script>

<style>
section.section {
  background: #f7f7f7;
  border-top: 5px solid #f1f1f1;
  margin: 30px 0;
  padding: 50px 0;
}

.row-gutter-sm .col,
.row-gutter-sm .col-1,
.row-gutter-sm .col-10,
.row-gutter-sm .col-11,
.row-gutter-sm .col-12,
.row-gutter-sm .col-2,
.row-gutter-sm .col-3,
.row-gutter-sm .col-4,
.row-gutter-sm .col-5,
.row-gutter-sm .col-6,
.row-gutter-sm .col-7,
.row-gutter-sm .col-8,
.row-gutter-sm .col-9,
.row-gutter-sm .col-auto,
.row-gutter-sm .col-lg,
.row-gutter-sm .col-lg-1,
.row-gutter-sm .col-lg-10,
.row-gutter-sm .col-lg-11,
.row-gutter-sm .col-lg-12,
.row-gutter-sm .col-lg-2,
.row-gutter-sm .col-lg-3,
.row-gutter-sm .col-lg-4,
.row-gutter-sm .col-lg-5,
.row-gutter-sm .col-lg-6,
.row-gutter-sm .col-lg-7,
.row-gutter-sm .col-lg-8,
.row-gutter-sm .col-lg-9,
.row-gutter-sm .col-lg-auto,
.row-gutter-sm .col-md,
.row-gutter-sm .col-md-1,
.row-gutter-sm .col-md-10,
.row-gutter-sm .col-md-11,
.row-gutter-sm .col-md-12,
.row-gutter-sm .col-md-2,
.row-gutter-sm .col-md-3,
.row-gutter-sm .col-md-4,
.row-gutter-sm .col-md-5,
.row-gutter-sm .col-md-6,
.row-gutter-sm .col-md-7,
.row-gutter-sm .col-md-8,
.row-gutter-sm .col-md-9,
.row-gutter-sm .col-md-auto,
.row-gutter-sm .col-sm,
.row-gutter-sm .col-sm-1,
.row-gutter-sm .col-sm-10,
.row-gutter-sm .col-sm-11,
.row-gutter-sm .col-sm-12,
.row-gutter-sm .col-sm-2,
.row-gutter-sm .col-sm-3,
.row-gutter-sm .col-sm-4,
.row-gutter-sm .col-sm-5,
.row-gutter-sm .col-sm-6,
.row-gutter-sm .col-sm-7,
.row-gutter-sm .col-sm-8,
.row-gutter-sm .col-sm-9,
.row-gutter-sm .col-sm-auto,
.row-gutter-sm .col-xl,
.row-gutter-sm .col-xl-1,
.row-gutter-sm .col-xl-10,
.row-gutter-sm .col-xl-11,
.row-gutter-sm .col-xl-12,
.row-gutter-sm .col-xl-2,
.row-gutter-sm .col-xl-3,
.row-gutter-sm .col-xl-4,
.row-gutter-sm .col-xl-5,
.row-gutter-sm .col-xl-6,
.row-gutter-sm .col-xl-7,
.row-gutter-sm .col-xl-8,
.row-gutter-sm .col-xl-9,
.row-gutter-sm .col-xl-auto {
  padding-left: 10px;
  padding-right: 10px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 13.6px;
  font-size: 1.2rem;
  line-height: 1.85;
}

.custom-form-style-1 .form-control {
  height: calc(1.8em + 1.85rem + 2px);
  padding: 15.6px 20px;
  padding: 0.975rem 1.25rem;
  background: transparent;
  border-width: 2px;
  border-color: #ccc;
  border-radius: 35px;
  line-height: 1.5 !important;
}

.custom-container-background:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 125%;
  height: 300px;
  background: #fff;
}

.z-index-1 {
  z-index: 1 !important;
}

.custom-container-background {
  position: relative;
  margin-top: -100px;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

form {
  display: block;
  margin-top: 0em;
}

.row-gutter-sm {
  margin-right: -10px;
  margin-left: -10px;
}

.custom-z-index-1 {
  z-index: 10;
}

section.section > .container {
  position: relative;
}

.btn-primary {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}
.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#b04040;
    /* background-image: url('../themes/shopwise/images/ajax-loader.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.2;
    filter: alpha(opacity=40);
}

.alert-success{
  text-align: center;
    font-weight: bold;
    color: green;
}

.alert-danger{
    text-align: center;
    font-weight: bold;
    color: red;
}

.btn-primary{
  color: var(--color);
  cursor:pointer;
}

</style>