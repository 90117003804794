<template>
  <section class="home-section mb-2">
    <div :class="getContainerClass() ">
      <template>

        <component :is="attributes.theme" :topNews="topNews" :cat1Posts="cat1Posts" :cat1name="cat1name"
          :cat2Posts="cat2Posts" :cat2name="cat2name" :key="attributes.theme"></component>
      </template>
    </div>
  </section>
</template>
<script>
import axios from 'axios'

import NewsList2ColTheme1 from './NewsList2ColTheme1.vue'
import NewsList2ColTheme2 from './NewsList2ColTheme2.vue'
import NewsList2ColTheme3 from './NewsList2ColTheme3.vue'
import NewsList2ColTheme4 from './NewsList2ColTheme4.vue'
import NewsList2ColTheme5 from './NewsList2ColTheme5.vue'
import NewsList2ColTheme7 from './NewsList2ColTheme7.vue'
import NewsList2ColTheme8 from './NewsList2ColTheme8.vue'
import NewsList2ColTheme9 from './NewsList2ColTheme9.vue'
import NewsList2ColTheme10 from './NewsList2ColTheme10.vue'
import NewsList2ColTheme11 from './NewsList2ColTheme11.vue'
export default {
  components: {
    NewsList2ColTheme1,
    NewsList2ColTheme2,
    NewsList2ColTheme3,
    NewsList2ColTheme4,
    NewsList2ColTheme5,
    NewsList2ColTheme7,
    NewsList2ColTheme8,
    NewsList2ColTheme9,
    NewsList2ColTheme10,
    NewsList2ColTheme11,
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
      images_query:null,
    },
  },
  data: function () {
    return {
      cat1Posts: [],
      cat2Posts: [],
      cat1name: '',
      cat2name: '',
      topNews: [],
    };
  },
  created: function () {
    this.getPosts();

    setTimeout(() => {


      if (this.attributes.top_news_sidebar == '1')

        this.getTopNews();
    }, 1500);
  },
  mounted: function () {

  },
  methods: {
    getContainerClass() {
      if (this.attributes.theme == 'NewsList2ColTheme10') {
        return '';
      } else {
        return 'container';
      }
      
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));

      axios.get(this.settings.BaseUrl + '/public/top-news?limit=' + this.attributes.limit+'&images_query='+this.attributes.images_query)
        .then(res => {

          this.topNews = res.data.data.posts ? res.data.data.posts : '';




        })
        .catch(res => {
          console.log(res);
        });
    },
    getPosts() {
      this.isLoading = true;
      if (!this.attributes.limit) this.attributes.limit = 5;
      axios
        .get("https://api.cnphub.com/public/twoCategoryData" + '?limit=' + this.attributes.limit + '&cat1=' + this.attributes.cat1 + '&' + 'cat2=' + this.attributes.cat2+'&images_query='+this.attributes.images_query, {
        })
        .then((res) => {
          this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : '';
          this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : '';

        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

