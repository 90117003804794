<template>
    <div class="container mt-2">
    <div
        class="info-boxes-container row row-joined mb-1 appear-animate"
        data-animation-name="fadeInUpShorter"
        data-animation-duration="1000"
        v-animate
    >
        <div class="info-box info-box-icon-left col-lg-4">
            <i class="icon-shipping"></i>

            <div class="info-box-content">
                <h4>FREE SHIPPING &amp; RETURN</h4>
                <p class="text-body">Free shipping on all orders over $99.</p>
            </div>
        </div>

        <div class="info-box info-box-icon-left col-lg-4">
            <i class="icon-money"></i>

            <div class="info-box-content">
                <h4>MONEY BACK GUARANTEE</h4>
                <p class="text-body">100% money back guarantee</p>
            </div>
        </div>

        <div class="info-box info-box-icon-left col-lg-4">
            <i class="icon-support"></i>

            <div class="info-box-content">
                <h4>ONLINE SUPPORT 24/7</h4>
                <p class="text-body">Lorem ipsum dolor sit amet.</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {};
</script>