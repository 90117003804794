<template>
  <div class="header-topbar">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <div class="topbar-trending">
            <span>Trending</span>
            <div class="trending-slider">
              <span
                @click="prev"
                class="prev slick-arrow"
                style="display: block"
                ><i class="fal fa-angle-left"></i
              ></span>
              <slider :settings="settings" ref="carousel">
                <template v-for="(data, index) in news">
                  <div :key="index" class="trending-item">
                    <p>
                      {{ data.news }}
                    </p>
                  </div>
                </template>
              </slider>
              <span
                @click="next"
                class="next slick-arrow"
                style="display: block"
                ><i class="fal fa-angle-right"></i
              ></span>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="topbar-social d-flex align-items-center">
            <p>Thursday, March 26, 2020</p>
            <div class="social">
              <ul>
                <li>
                  <a :href="facebook"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a :href="twitter"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a :href="youtube"><i class="fab fa-youtube"></i></a>
                </li>
                <li>
                  <a :href="instagram"><i class="fab fa-instagram"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Slider from "../../Helpers/Slider.vue";
import json from "../../Data/TrandingHeaderOneTopBar";
import axios from 'axios'

export default {
  components: { Slider },
  data: () => ({
    settings: {
      dots: false,
      arrows: false,
      autoplay: false,
    },
    news: json.datas,
    facebook: '',
    twitter: '',
    youtube: '',
    instagram: '',
  }),
  mounted() {
      this.getTrending();
      this.getSocial();
    },
  methods: {
    hideSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    getTrending() {
          // this.news = [];
          axios.get('https://cnphub.menaws.com/api/v1/trending')
          .then(res => {
              this.news = res.data.data ? res.data.data : [];
          })
          .catch(res => {
            console.log(res);
          });
      },
      getSocial() {
          // this.news = [];
          axios.get('https://cnphub.menaws.com/api/v1/social')
          .then(res => {
              this.facebook = res.data.data.facebook ? res.data.data.facebook : '';
              this.twitter = res.data.data.twitter ? res.data.data.twitter : '';
              this.youtube = res.data.data.youtube ? res.data.data.youtube : '';
              this.instagram = res.data.data.instagram ? res.data.data.instagram : '';
              console.log(res.data.data);
          })
          .catch(res => {
            console.log(res);
          });
      },
    next() {
      this.$refs.carousel.next();
    },
    prev() {
      this.$refs.carousel.prev();
    },
  },
};
</script>

<style>
</style>