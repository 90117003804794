<template>
  <div></div>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    };
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        "background-image":
          "url(" + this.settings.storageUrl + this.settings.banner_image + ")",
        "background-size": "cover",
        "background-position": "center",
        "position": "absolute",
        "top": "0px",
        "left": "0px",
        "width": "100%",
        "height": "120%",
        "transform": "translate3d(0px, -66.5143px, 0px)",
        "background-position-x": "50%",
      
      };
    },
  },
};
</script>

<style>
section.section.section-height-3 {
    padding: 73.846px 0;
    padding: 4.6153846154rem 0;
}

.overlay:hover:before, .overlay.overlay-show:before, .overlay.show:before {
    opacity: .8;
}

.overlay:before {
    background: #2C2C2C;
}

.overlay:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: #212529;
    opacity: 0;
    pointer-events: none;
    transition: ease opacity 300ms 100ms;
}

section.section>.container {
    position: relative;
}

.overlay .container, .overlay .container-fluid {
    position: relative;
    z-index: 3;
}

.z-index-2 {
    z-index: 2!important;
}

.text-decoration-none {
    text-decoration: none!important;
}

.transform3dx-n50 {
    transform: translate3d(-50%,0,0);
}

.bottom-5 {
    bottom: 5px!important;
}

.left-50pct {
    left: 50%;
}

.text-5-6, .text-5-5 {
    font-size: 1.65em!important;
}

.section.section-with-shape-divider .shape-divider.shape-divider-reverse-y {
    transform: rotateY(180deg);
}

.section.section-with-shape-divider .shape-divider.shape-divider-bottom {
    top: auto;
    bottom: 0;
}

.section.section-with-shape-divider .shape-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    z-index: 1;
}

.section.section-with-shape-divider .shape-divider svg {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate3d(-50%,0,0);
}

html .text-color-dark, html .text-dark {
    color: #212529 !important;
}

.text-5-6, .text-5-5 {
    font-size: 1.65em!important;
}

.z-index-2 {
    z-index: 2!important;
}

.text-decoration-none {
    text-decoration: none!important;
}

.transform3dx-n50 {
    transform: translate3d(-50%,0,0);
}

.bottom-5 {
    bottom: 5px!important;
}

.fa-arrow-down{
  color: #fff;
}

.text-decoration-none a{
  color: #fff !important;
}

ul li a{
  color: #fff ;
}

.innerbanner{
  margin-top: -2rem !important;
}
</style>