<template>
  <div>
    <div class="container pt-4 pb-5 mb-3">
      <div class="row align-items-center">
        <div
          class="col-md-8 mb-4 mb-md-0 appear-animation animated fadeIn appear-animation-visible"
          data-appear-animation="fadeIn"
          data-appear-animation-delay="500"
          style="animation-delay: 500ms"
        >
          <h2 class="text-color-dark font-weight-bold text-5 mb-0">
            {{ $t("Contact Us") }}
          </h2>

          <p v-html="settings.contact_text"></p>
        </div>
        <div
          class="col-md-4 mb-4 mb-md-0 appear-animation animated fadeIn appear-animation-visible"
          data-appear-animation="fadeIn"
          data-appear-animation-delay="100"
          style="animation-delay: 100ms"
        >
          <h2 class="text-color-dark font-weight-bold text-5 mb-0">
            {{ settings.website }}
          </h2>

          <ul class="list list-icons list-icons-style-2 list-icons-lg">
            <li class="text-3 font-weight-medium mb-1 line-height-5">
              <i class="icon-envelope icons border-width-2 text-3"></i
              ><a :href="'mailto:' + settings.email" class="p-relative top-5">{{
                settings.email
              }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <section
      class="parallax section section-text-light section-parallax bg-primary mt-0 mb-0"
      data-plugin-parallax=""
      data-plugin-options="{'speed': 1.5}"
      data-image-src="img/demos/business-consulting-4/parallax/parallax-1.jpg"
      style="position: relative; overflow: hidden"
    >
      <div class="parallax-background"></div>
      <div class="contact-form-success alert alert-success mt-4" v-if="success">
                <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}
              </div>
              <div class="contact-form-error alert alert-danger mt-4" v-if="error">
                <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
                <span class="mail-error-message text-1 d-block"></span>
              </div>
              <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
                <strong>{{ $t("Error!") }}</strong> 
                <ul>
                  <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
                <span class="mail-error-message text-1 d-block"></span>
              </div>
      <div class="container py-5">
        <div class="row justify-content-center">
          <div class="col">
            <div
              class="border-top border-color-light-5 text-light pt-4 appear-animation animated fadeIn appear-animation-visible"
              data-appear-animation="fadeIn"
              data-appear-animation-delay="300"
              style="animation-delay: 300ms"
            >
              <div class="row align-items-center">
                <div class="col-12 mb-3 mb-lg-0 col-lg">
                  <h2 class="text-color-light font-weight-bold text-5 mb-0">
                    {{ $t("Request a Call Back") }}
                  </h2>
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-lg">
                  <input
                    type="text"
                    v-model="formData.name"
                    class="form-control text-3 h-auto py-3 line-height-1 border-0"
                    :placeholder="$t('Your Name')"
                    aria-label="Full Name"
                  />
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-lg">
                  <input
                    type="email"
                    v-model="formData.email"
                    class="form-control text-3 h-auto py-3 line-height-1 border-0"
                    :placeholder="$t('Email')"
                    aria-label="Email"
                  />
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-lg">
                  <input
                    type="text"
                    v-model="formData.phone"
                    class="form-control text-3 h-auto py-3 line-height-1 border-0"
                    :placeholder="$t('Phone Number')"
                    aria-label="Phone Number"
                  />
                </div>
                <div class="col-12 mb-3 mb-lg-0 col-lg">
                  <div class="d-grid gap-2">
                    <a
                        v-on:click="checkForm"
                      class="btn btn-modern btn-dark btn-arrow-effect-1 text-capitalize text-2-5 px-5 py-3 anim-hover-translate-top-5px transition-2ms"
                      >{{$t('Submit')}} <i class="fas fa-arrow-right ms-2"></i
                    ></a>
                  </div>
                  <div class="loader" v-if="loading"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    checkForm: function (e) {
      console.log('form check')
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      this.loading = true,
      console.log(this.formData);
      console.log('this.formData');
      axios
        .post(this.settings.BaseUrl + '/public/contact', this.formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          // this.response = res.data.data ? res.data.data : [];
            })
        .catch((res) => {
          this.error = true;
        }).finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
        });
    }
  },
};
</script>

<style>
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.line-height-1 {
  line-height: 1 !important;
}

.text-3 {
  font-size: 1em !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.h-auto {
  height: auto !important;
}

.border-0 {
  border: 0 !important;
}

html .btn-dark {
  background-color: #212529;
  border-color: #212529 #212529 #0a0c0d;
  color: #fff;
  --color: #212529;
  --hover: #32383e;
  --disabled: #212529;
  --active: #101214;
}

.btn-modern {
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 12.8px 24px;
  padding: 0.8rem 1.5rem;
  font-weight: 600;
}

.fa-solid,
.fas {
  font-weight: 900;
}

.gap-2 {
  gap: 0.5rem !important;
}

.d-grid {
  display: grid !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
</style>