<template>
  <section class="category-section theme-10-extra">
    <div class="container slider-main-container" v-if="posts.length > 0">
      <isotope
        class="row grid appear-animate"
        data-animation-name="fadeInUpShorter"
        data-animation-delay="400"
        v-animate
        :options="isotopeOptions"
        :list="[]"
      >
        <div class="grid-item col-sm-6 col-md-3 height-3-5" key="cat-1">
          <div class="product-category">
            <a
              :href="'/article/' + posts[0].slug"
            >
              <figure style="background-color: #f4f4f2">
                <img
                  :src="getImage(posts[0].image, 'medium')"
                  alt="category"
                  width="100%"
                  height="100%"
                />
              </figure>
            </a>
            <div class="category-content34">
              <h3>
                <a :href="'/article/' + posts[1].slug">{{ posts[0].name }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div
          class="grid-item col-sm-6 height-5-5"
          key="cat-2"
          v-if="posts.length > 1"
        >
          <div class="product-category">
            <a
              :href="'/article/' + posts[1].slug"
            >
              <figure style="background-color: #f4f4f2">
                <img
                  :src="getImage(posts[1].image, 'medium')"
                  alt="category"
                  width="100%"
                  height="564"
                />
              </figure>
            </a>
            <div class="category-content34">
              <h3>
                <a :href="'/article/' + posts[1].slug">{{ posts[1].name }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div
          class="grid-item col-sm-6 col-md-3 height-2-5"
          key="cat-3"
          v-if="posts.length > 2"
        >
          <div class="product-category">
            <a
              :href="'/article/' + posts[2].slug"
            >
              <figure style="background-color: #f4f4f2">
                <img
                  :src="getImage(posts[2].image, 'medium')"
                  alt="category"
                  width="100%"
                  height="100%"
                />
              </figure>
            </a>
            <div class="category-content34">
              <h3>
                <a :href="'/article/' + posts[2].slug">{{ posts[2].name }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div
          class="grid-item col-sm-6 col-md-3 height-3-5"
          key="cat-4"
          v-if="posts.length > 3"
        >
          <div class="product-category">
            <a
              :href="'/article/' + posts[3].slug"
            >
              <figure style="background-color: #f4f4f2">
                <img
                  :src="getImage(posts[3].image, 'medium')"
                  alt="category"
                  width="100%"
                  height="100%"
                />
              </figure>
            </a>
            <div class="category-content34">
              <h3>
                <a :href="'/article/' + posts[3].slug">{{ posts[3].name }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div
          class="grid-item col-sm-6 col-md-3 height-2-5"
          key="cat-5"
          v-if="posts.length > 4"
        >
          <div class="product-category">
            <a
              :href="'/article/' + posts[4].slug"
            >
              <figure style="background-color: #f4f4f2">
                <img
                  :src="getImage(posts[4].image, 'medium')"
                  alt="category"
                  width="100%"
                  height="100%"
                />
              </figure>
            </a>
            <div class="category-content34">
              <h3>
                <a :href="'/article/' + posts[4].slug">{{ posts[4].name }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div class="grid-col-sizer col-1" key="cat-sizer"></div>
      </isotope>
    </div>
  </section>
</template>

<script>
import isotope from "vueisotope";
import axios from "axios";
export default {
  components: {
    isotope,
  },
  data: function () {
    return {
      isotopeOptions: {
        itemSelector: "grid-item",
        layoutMode: "masonry",
        percentPosition: false,
        masonry: {
          columnWidth: ".grid-col-sizer",
        },
      },
    };
  },
  mounted() {},
  props: {
    posts: Array,
  },
  created() {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  },
};
</script>

<style>
.slider-main-container > div {
  height: 42vw;
}

.category-content34 {
  background: rgba(0, 0, 0, 0.4);
}

.category-section {
  min-height: 500px;
}

.slider-main-container .height-3-5 img {
  height: 15vw;
}

.slider-main-container .height-5-5 img {
  height: 31.2vw;
}

.slider-main-container .height-2-5 img {
  height: 15vw;
}

.slider-main-container .grid-item h3 a {
  color: #fff;
}

.slider-main-container .grid-item h3 {
  margin-bottom: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.2em;
  line-height: 1.35;
  text-transform: none;
}

@media only screen and (max-width: 1366) {
}

@media (max-width: 1280) {
}

@media (max-width: 1100) {
}

@media (max-width: 768px) {
  .theme-10-extra .slider-main-container > div {
    height: auto;
  }
  .theme-10-extra .slider-main-container .height-5-5,
  .theme-10-extra .slider-main-container .height-2-5,
  .theme-10-extra .slider-main-container .height-3-5 {
    position: relative !important;
    left: auto!important;
    top: auto!important;
  }

  .theme-10-extra .slider-main-container .height-5-5 img,
  .theme-10-extra .slider-main-container .height-2-5 img,
  .theme-10-extra .slider-main-container .height-3-5 img {
    height: 100%;
  }
}
</style>
