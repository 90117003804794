<template>
  <section class="home-section">
    <div class="Header">
      <template>
        <component :is="'style'">
          :root { --color: {{ primary_color }}; --header-color:
          {{ header_color }}; --footer-color: {{ footer_color }};
          --secondary-color: {{ secondary_color }}; --inner-pages-banner:
          {{ inner_pages_banner }}; --banner-image: {{ secondary_color }}; }
          {{ custom_css }}
          body{
          {{ font }}
          } p,a,span{
          {{ font }}
          } h1,h2,h3,h4,h5,h6{
          {{ font }}
          }
        </component>
        <component
          :is="attributes.theme"
          :key="attributes.theme"
          :attributes="attributes"
          :footerMenu="footerMenu"
          :settings="settings"
        >
        </component>

        <div v-html="settings.banner_728_90" class="header-add">
        </div>

      </template>
    </div>
  </section>
</template>

<script>
import HeaderTheme1 from "./HeaderTheme1.vue";
import HeaderTheme2 from "./HeaderTheme2.vue";
import HeaderTheme3 from "./HeaderTheme3.vue";
import HeaderTheme4 from "./HeaderTheme4.vue";
import HeaderTheme5 from "./HeaderTheme5.vue";
import HeaderTheme6 from "./HeaderTheme6.vue";
import HeaderTheme7 from "./HeaderTheme7.vue";
import HeaderTheme8 from "./HeaderTheme8.vue";
import HeaderTheme9 from "./HeaderTheme9.vue";
import HeaderTheme10 from "./HeaderTheme10.vue";
import HeaderTheme11 from "./HeaderTheme11.vue";
import HeaderTheme12 from "./HeaderTheme12.vue";
import HeaderTheme13 from "./HeaderTheme13.vue";
import HeaderTheme14 from "./HeaderTheme14.vue";
import HeaderTheme15 from "./HeaderTheme15.vue";
import HeaderTheme16 from "./HeaderTheme16.vue";
import HeaderTheme18 from "./HeaderTheme18.vue";
import HeaderTheme19 from "./HeaderTheme19.vue";
export default {
  components: {
    HeaderTheme19,
    HeaderTheme18,
    HeaderTheme16,
    HeaderTheme15,
    HeaderTheme14,
    HeaderTheme13,
    HeaderTheme12,
    HeaderTheme11,
    HeaderTheme10,
    HeaderTheme9,
    HeaderTheme8,
    HeaderTheme7,
    HeaderTheme6,
    HeaderTheme5,
    HeaderTheme4,
    HeaderTheme3,
    HeaderTheme2,
    HeaderTheme1,
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },

  data: function () {
    return {
      primary_color: "",
      footer_color: "",
      header_color: "",
      secondary_color: "",
      inner_pages_banner: "",
      custom_css: "",
      font: "",
      settings: [],
    };
  },
  created() {
    this.getWebsiteSettings();
  },
  mounted() {
    //this.getWebsiteSettings();
  },
  methods: {
    getWebsiteSettings() {
      this.settings = [];
      // this.footerMenu = [];
      this.settings = JSON.parse(localStorage.getItem("settings"));
      // this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
      // this.languages = JSON.parse(localStorage.getItem("languages"));
      this.primary_color = this.settings.primary_color;
      this.footer_color = this.settings.footer_color;
      this.secondary_color = this.settings.secondary_color;
      this.header_color = this.settings.header_color;
      this.custom_css = this.settings.custom_css;
      this.font = this.settings.font;
      this.inner_pages_banner = this.$helpers.getImageFullPath(
        this.settings.inner_pages_banner,
        "medium"
      );
    },
  },
};
</script>

<style>
.text-secondary-color {
  color: var(--secondary-color);
}
.text-grey {
  color: #f2f2f2;
}
.floating-date {
  position: absolute;
}
.text-about {
  text-align: left;
}
#app .post-content p {
  -webkit-box-orient: inherit;
}
.post .post-media img {
  width: auto;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .sidebar.mobile-sidebar {
    position: relative !important;

    transform: translate(20px) !important;
  }
  #app .page-header {
    background-position: center !important;
    background-size: cover !important;
  }
}
@media only screen and (max-width: 768px) {
  .sidebar.mobile-sidebar {
    position: relative;
  }
  .home-section .home-slide1 h3 {
    margin-bottom: 0.8rem!important;
    font-size: 1.6em!important;
  }
  .header-dropdown.dropdown-language {
    margin-right: 30px !important;
  }
  .header-dropdown.dropdown-language li i {
    display: none;
  }
  .section-title-style {
    font-size: 18px !important;
  }
  h4.section-sub-title{
	font-size: 18px !important;
  }
}

.header-add{
  margin-top: 10px;
  margin-bottom: 35px;
  text-align: center;
}

#app .post-content p,#app .post-content p p {
    -webkit-box-orient: horizontal;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 55;
    overflow: visible;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}
</style>
