<template>
  <div class="news-List-2-col  theme3">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">
       
        <div class="row">


          <!-- Post Wrapper Start -->
          <div :class="colClassSection + 'mb-20 main-articles'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span>{{ cat1name }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat1Posts" :key="index">
              <div class=" col-md-12  col-xs-12">
                <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-12 col-xs-12">
                <br>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>


          </div><!-- Post Wrapper End -->

          <div :class="colClassSection + 'mb-20 main-articles'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span>{{ cat2name }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat2Posts" :key="index">
              <div class=" col-md-12  col-xs-12">
                <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-12 col-xs-12">
                <br>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>


          </div><!-- Post Wrapper End -->
          

          <div v-if="topNews" :class="colClassSection + 'mb-20 main-articles top-news-sidebar'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span>{{ $t('Top News') }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in topNews" :key="index">
              <div class=" col-md-12  col-xs-12">
                <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-12 col-xs-12">
                <br>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>




export default {
  components: {},
  props: {

    cat1Posts: Array,
    cat2Posts: Array,
    topNews: Array,
    cat1name: String,
    cat2name: String,

  },

  data: () => ({
    settings: null,
    colClass: 'col-md-6  col-xs-12',

  }),
  mounted() {


  },
  computed: {
    colClassSection: {
      // getter
      get() {
        return this.topNews.length > 0 ?'col-md-4  col-xs-12':'col-md-6  col-xs-12'
      },
    }
  },
  created() {

   
    // if (this.topNews.length > 0) {
    //   setTimeout(() => {
    //     this.colClass = 'col-md-4  col-xs-12';
    //   }, 1500);
      
    // }
    // console.log(this.topNews);
    // console.log(this.topNews.length);
  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    },

  },
};
</script>

<style>
.top-news-sidebar {

}
.news-List-2-col.theme2 .story-short.article.row {
  border-radius: 15px;
  border: 4px solid indigo;
  margin: 5px;
  padding: 10px;
  font-size: 14px;
  position: relative;
  padding-bottom: 25px;
  background: white;
}

.news-List-2-col.theme2 .story-short h4 {
  min-height: 50px;
}
</style>