<template>
  <div class="container container-xl-custom">
    <div class="row py-5">
      <div class="col-md-4 col-lg-4">
        <h3 class="font-weight-bold text-3 mb-0">{{ cat1name }}</h3>
        <ul class="simple-post-list">
          <li v-for="(post, index) in cat1Posts.slice(0,3)" :key="index">
            <article>
              <div class="post-image">
                <div class="img-thumbnail img-thumbnail-no-borders d-block">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      class="border-radius-0"
                      :src="getImage(post.image, 'medium')"
                      :alt="post.name"/>
                    <img v-else
                      class="border-radius-0"
                      :src="getImage('banners/image.jpg' , 'default')"
                      :alt="post.name"/>
                  </a>
                </div>
              </div>
              <div class="post-info">
                <h4 class="font-weight-normal text-3 line-height-4 mb-0">
                  <a :href="'/article/' + post.slug" class="text-dark">{{
                    getPostDescription(post.name, 10)
                  }}</a>
                </h4>
                <div class="post-meta">{{ getPostDate(post.created_at) }}</div>
              </div>
            </article>
          </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-4">
        <h3 class="font-weight-bold text-3 mb-0">{{ cat2name }}</h3>
        <ul class="simple-post-list">
          <li v-for="(post, index) in cat2Posts.slice(0,3)" :key="index">
            <article>
              <div class="post-image">
                <div class="img-thumbnail img-thumbnail-no-borders d-block">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      class="border-radius-0"
                      :src="getImage(post.image, 'medium')"
                      :alt="post.name"/>
                    <img v-else
                      class="border-radius-0"
                      :src="getImage('banners/image.jpg' , 'default')"
                      :alt="post.name"/>
                  </a>
                </div>
              </div>
              <div class="post-info">
                <h4 class="font-weight-normal text-3 line-height-4 mb-0">
                  <a :href="'/article/' + post.slug" class="text-dark" v-html="getPostDescription(post.name, 10)"></a>
                </h4>
                <div class="post-meta">{{ getPostDate(post.created_at) }}</div>
              </div>
            </article>
          </li>
        </ul>
      </div>
      <div class="col-lg-4">
        <h3 class="font-weight-bold text-3 mt-4 mt-4 mt-md-0">
          {{ cat3name }}
        </h3>
        <div
          class="post-block post-author pt-2"
          v-for="(post, index) in cat3Posts.slice(0,1)"
          :key="index"
        >
          <div class="img-thumbnail img-thumbnail-no-borders d-block pb-3">
            <a :href="'/article/' + post.slug">
              <img v-if="post.image"
                class="border-radius-0"
                :src="getImage(post.image, 'medium')"
                :alt="post.name"/>
              <img v-else
                class="border-radius-0"
                :src="getImage('banners/image.jpg' , 'default')"
                :alt="post.name"/>
            </a>
          </div>
          <p>
            <strong class="name"
              ><a
                :href="'/article/' + post.slug"
                class="text-4 pb-2 pt-2 d-block text-dark"
                >{{ getPostDescription(post.name, 10) }}</a
              ></strong
            >
          </p>
          <!-- <p>{{ getPostDescription(post.content, 20)}}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  components: {},
  data: function () {
    return {
      cat1Posts: [],
      cat2Posts: [],
      cat3Posts: [],
      cat1name: '',
      cat2name: '',
      cat3name: ''
    };
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    },
    getPosts() {
      axios
        .get(
          "https://api.cnphub.com/public/threeCategoryData" +
            "?limit=" +
            this.attributes.limit +
            "&cat1=" +
            this.attributes.cat1 +
            "&cat2=" +
            this.attributes.cat2 +
            "&cat3=" +
            this.attributes.cat3,
          {}
        )
        .then((res) => {
          this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : "";
          this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : "";
          this.cat3Posts = res.data.data.cat3 ? res.data.data.cat3 : [];
          this.cat3name = res.data.data.cat3name ? res.data.data.cat3name : "";
        })
        .catch((res) => {
          console.log(res);
        });

     
    },
  },
};
</script>


<style>
.img-thumbnail {
  border-radius: 4px;
  position: relative;
}
.d-block {
  display: block !important;
}
ul.simple-post-list .post-image {
  float: left;
  margin-right: 12px;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto;
}
.post-image .img-thumbnail a img {
  width: 100px;
}
ul.simple-post-list li {
  border-bottom: 1px dotted #e2e2e2;
  padding: 15px 0;
}
li {
  line-height: 24px;
}

.post-author .img-thumbnail {
  display: inline-block;
  float: left;
  margin-right: 20px;
}

.post-author {
  margin-bottom: 0rem !important;
  padding-bottom: 0rem !important;
}

.img-thumbnail {
  border-radius: 4px;
  position: relative;
}

.text-3{
  color: var(--color);
}

.simple-post-list .post-info a:hover {
  color: var(--color) !important;
}

html .text-color-dark, html .text-dark {
    color: #212529!important;
}

</style>