<template>
  <div class="main">
    <div class="container">
      <div class="row py-4">
        <div class="col-lg-6">
          <h2 class="font-weight-bold text-8 mt-2 mb-0">{{ $t("Contact Us") }}</h2>
          <p class="mb-4">
            {{ $t("Feel free to ask for details, don't save any questions!") }}
          </p>

          <form
            class="contact-form"
            ref="form"
            novalidate="novalidate"
          >
            <div class="contact-form-success alert alert-success d-none mt-4" v-if="success">
              <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}

            </div>

            <div class="contact-form-error alert alert-danger d-none mt-4" v-if="error">
              <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
              <span class="mail-error-message text-1 d-block"></span>
            </div>

            <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
              <strong>{{ $t("Error!") }}</strong> 
              <ul>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
              </ul>
              <span class="mail-error-message text-1 d-block"></span>
            </div>

            <div class="row">
              <div class="form-group col-lg-6">
                <label class="form-label mb-1 text-2">{{ $t('Full Name') }}</label>
                <input
                  type="text"
                  value=""
                  v-model="formData.name"
                  data-msg-required="Please enter your name."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="name"
                  required=""
                />
              </div>
              <div class="form-group col-lg-6">
                <label class="form-label mb-1 text-2">{{ $t('Email Address') }}</label>
                <input
                  type="email"
                  value=""
                  v-model="formData.email"
                  data-msg-required="Please enter your email address."
                  data-msg-email="Please enter a valid email address."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="email"
                  required=""
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <label class="form-label mb-1 text-2">{{ $t('Subject') }}</label>
                <input
                  type="text"
                  value=""
                  v-model="formData.subject"
                  data-msg-required="Please enter the subject."
                  maxlength="100"
                  class="form-control text-3 h-auto py-2"
                  name="subject"
                  required=""
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <label class="form-label mb-1 text-2">{{$t('message')}}</label>
                <textarea
                  maxlength="5000"
                  v-model="formData.message"
                  data-msg-required="Please enter your message."
                  rows="8"
                  class="form-control text-3 h-auto py-2"
                  name="message"
                  required=""
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group col">
                <input
                  :value="$t('SEND MESSAGE')"
                  v-on:click="checkForm"
                  class="btn btn-primary btn-modern"
                  data-loading-text="Loading..."
                />
              </div>
              <div class="loader" v-if="loading"></div>
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <div
            class="appear-animation animated fadeIn appear-animation-visible"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="800"
            style="animation-delay: 800ms"
          >
            <h4 class="mt-2 mb-1"><strong>{{ $t("Contact Info") }}</strong></h4>
            <ul class="list list-icons list-icons-style-2 mt-2">
              <li>
                <i class="fas fa-envelope top-6"></i>
                <strong class="text-dark">{{ $t("Email") }}:</strong>
                <a :href="'mailto:'+settings.email">{{ settings.email }}</a>
              </li>
            </ul>
          </div>

          <h4 class="pt-5"><strong>{{ $t("Get in Touch") }}</strong></h4>
          <p class="lead mb-0 text-4" v-html="settings.contact_text">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    checkForm: function (e) {
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      this.loading = true,
      axios
        .post(this.settings.BaseUrl + '/public/contact', this.formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          // this.response = res.data.data ? res.data.data : [];
            })
        .catch((res) => {
          this.error = true;
        }).finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
        });
    }
  },
};
</script>

<style>
section.section {
  background: #f7f7f7;
  border-top: 5px solid #f1f1f1;
  margin: 30px 0;
  padding: 50px 0;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

section {
  display: block;
}

section.section > .container {
  position: relative;
}

.btn-primary {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}
.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#b04040;
    /* background-image: url('../themes/shopwise/images/ajax-loader.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.2;
    filter: alpha(opacity=40);
}

.alert-success{
  text-align: center;
    font-weight: bold;
    color: green;
}

.alert-danger{
    text-align: center;
    font-weight: bold;
    color: red;
}

.btn-primary{
  border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}
</style>