<template>
	<aside class="sidebar-home col-lg-3 mobile-sidebar toggle-menu-wrap">
		<div class="side-menu-wrapper text-uppercase d-none d-lg-block">
			<h2 class="side-menu-title cursor-pointer">
				<a class="d-block " href="javascript:;" @click="sidebarOpened = !sidebarOpened">
					<i class="fas fa-bars"></i>  {{ $t('Categories') }}
				</a>
			</h2>

			<vue-slide-toggle :open="isHomeActive || sidebarOpened">
				<!-- <pv-main-menu></pv-main-menu> -->
				<nav class="side-nav bg-white w-100">
					<ul class="menu menu-vertical">
						<li v-for="item in mainMenu" :key="item.id">
							<router-link :to="item.url">{{ item.title }}</router-link>
							<div class="megamenu megamenu-fixed-width megamenu-3cols" v-if="item.has_child == 1">
								<div class="row">
									<div class="">
										<ul class="submenu">
											<li v-for="subitem in item.child" :key="subitem.id">
												<router-link :to="subitem.url">
													{{ subitem.title }}
												</router-link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</nav>
				<!-- <nav class="side-nav bg-white">
					<ul class="menu menu-vertical sf-arrows">
						<li :class="{active: $route.path === '/'}">
							<nuxt-link to="/">
								<i class="sicon-home"></i>Home
							</nuxt-link>
						</li>

						<li :class="{active: $route.path.indexOf('/shop') > -1}">
							<nuxt-link
								to="/shop"
								class="sf-with-ul"
								:class="{active: $route.path.indexOf('/shop') > -1}"
							>
								<i class="sicon-briefcase"></i>Categories
							</nuxt-link>

							<div class="megamenu megamenu-fixed-width megamenu-3cols">
								<div class="row">
									<div class="col-lg-4">
										<a
											href="javascript:;"
											class="nolink"
										>VARIATION 1</a>

										<ul class="submenu">
											<li
												v-for="item in mainMenu.shop.variation1"
												:key="item.id"
											>
												<nuxt-link :to="item.url">
													{{ item.title }}
													<span
														class="tip tip-hot"
														v-if="item.hot"
													>Hot</span>
												</nuxt-link>
											</li>
										</ul>
									</div>

									<div class="col-lg-4">
										<a
											href="javascript:;"
											class="nolink"
										>VARIATION 2</a>

										<ul class="submenu">
											<li
												v-for="item in mainMenu.shop.variation2"
												:key="item.id"
											>
												<nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
											</li>
										</ul>
									</div>

									<div class="col-lg-4 p-0">
										<div class="menu-banner">
											<figure>
												<img
													src="https://d-themes.com/vue/porto/demo-9/images/home/slider/slide-1.jpg"
													alt="Menu banner"
													width="300"
													height="300"
												/>
											</figure>
											<div class="banner-content">
												<h4>
													<span class>UP TO</span>
													<br />
													<b class>50%</b>
													<i>OFF</i>
												</h4>
												<nuxt-link
													to="/shop"
													class="btn btn-sm btn-dark"
												>SHOP NOW</nuxt-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>

						<li :class="{active: $route.path.indexOf('/product') > -1}">
							<nuxt-link
								to="/product/default/casual-note-bag"
								class="sf-with-ul"
								:class="{active: $route.path.indexOf('/product') > -1}"
							>
								<i class="sicon-present"></i>Products
							</nuxt-link>

							<div class="megamenu megamenu-fixed-width megamenu-3cols">
								<div class="row">
									<div class="col-lg-4">
										<a
											href="javascript:;"
											class="nolink"
										>PRODUCT PAGES</a>

										<ul class="submenu">
											<li
												v-for="item in mainMenu.product.pages"
												:key="item.title"
											>
												<nuxt-link :to="item.url">
													{{ item.title }}
													<span
														class="tip tip-hot"
														v-if="item.hot"
													>Hot</span>
												</nuxt-link>
											</li>
										</ul>
									</div>

									<div class="col-lg-4">
										<a
											href="javascript:;"
											class="nolink"
										>PRODUCT LAYOUTS</a>

										<ul class="submenu">
											<li
												v-for="item in mainMenu.product.layout"
												:key="item.title"
											>
												<nuxt-link :to="item.url">{{ item.title }}</nuxt-link>
											</li>

											<li>
												<a href="javascript:;">Build your own</a>
											</li>
										</ul>
									</div>

									<div class="col-lg-4 p-0">
										<div class="menu-banner menu-banner-2 position-relative">
											<figure>
												<img
													src="https://d-themes.com/vue/porto/demo-9/images/home/slider/slide-1.jpg"
													alt="Menu banner"
													class="product-promo"
													width="380"
													height="790"
												/>
											</figure>
											<i>OFF</i>
											<div class="banner-content">
												<h4>
													<span class>UP TO</span>
													<br />
													<b class>50%</b>
												</h4>
											</div>
											<nuxt-link
												to="/shop"
												class="btn btn-sm btn-dark"
											>SHOP NOW</nuxt-link>
										</div>
									</div>
								</div>
							</div>
						</li>

						<li :class="{active: isPageItemActived}">
							<a
								href="javascript:;"
								class="sf-with-ul"
								:class="{active: isPageItemActived}"
							>
								<i class="sicon-docs"></i>Pages
							</a>

							<ul>
								<li
									v-for="item in mainMenu.other"
									:key="item.title"
								>
									<nuxt-link
										:to="item.url"
										:class="{'sub-menu-link': item.subPages}"
									>{{ item.title }}</nuxt-link>

									<ul v-if="item.subPages">
										<li
											v-for="subItem in item.subPages"
											:key="subItem.title"
										>
											<nuxt-link :to="subItem.url">{{ subItem.title }}</nuxt-link>
										</li>
									</ul>
								</li>
							</ul>
						</li>

						<li :class="{active: $route.path.indexOf('/pages/blog') > -1}">
							<nuxt-link
								to="/pages/blog"
								:class="{active: $route.path.indexOf('/pages/blog') > -1}"
							>
								<i class="sicon-book-open"></i>Blog</nuxt-link>
						</li>
						<li>
							<nuxt-link to="/pages/about-us">
								<i class="sicon-users"></i>About Us
							</nuxt-link>
						</li>
						<li class="menu-item-sale">
							<a
								href="javascript:;"
								class="border-top-0"
							>HUGE SALE – 70% OFF</a>
						</li>
					</ul>
				</nav> -->
			</vue-slide-toggle>
		</div>
	</aside>
</template>

<script>

import { VueSlideToggle } from 'vue-slide-toggle';
import axios from 'axios'
export default {
	components: {
		VueSlideToggle, //PvMainMenu
	},
	data: function () {
		return {
			isHomeActive: false,
			sidebarOpened: false,
			mainMenu:[],
		};
	},
	watch: {
		$route: function () {
			this.isHomeActive = this.$route.path === '/' ? true : false;
		}
	},
	computed: {
		isPageItemActived: function () {
			let exItems = ['blog', 'about-us', 'contact-us'];
			if (
				this.$route.path.includes('/pages') &&
				exItems.findIndex(item => this.$route.path.includes(item)) ===
				-1
			) {
				return true;
			}

			return false;
		}
	},
	mounted: function () {

		this.getMainMenu();
		let items = document.querySelectorAll('.menu-vertical > li');
		items.forEach(item => {
			item.addEventListener('mouseenter', this.mouseOverHandler);
			item.addEventListener('mouseleave', this.mouseLeaveHandler);
		});
		this.isHomeActive = this.$route.path === '/' ? true : false;
	},
	destroyed: function () {
		window.removeEventListener('resize', this.resizeHandler);

		let items = document.querySelectorAll('.sf-with-ul');
		items.forEach(item => {
			item.removeEventListener('mouseover', this.mouseOverHandler);
			item.removeEventListener('mouseleave', this.mouseLeaveHandler);
		});
	},
	methods: {
		getMainMenu() {
                axios.get('https://api.cnphub.com/public/menu?id=1')
                .then(res => {
                    this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
                })
                .catch(res => {
                  console.log(res);
                });
            },
		mouseOverHandler: function (e) {
			let currentTarget = e.currentTarget;
			let item;

			currentTarget.classList.add('show');
			if (e.currentTarget.querySelector('.megamenu')) {
				item = e.currentTarget.querySelector('.megamenu');
			} else if (e.currentTarget.querySelector('ul')) {
				item = e.currentTarget.querySelector('ul');
			}
			if (item) {
				setTimeout(() => {
					if (currentTarget.classList.contains('show')) {
						item.setAttribute(
							'style',
							'left: 90%; display: block; opacity: 0;'
						);
					}
				}, 50);
				setTimeout(() => {
					if (currentTarget.classList.contains('show')) {
						item.setAttribute(
							'style',
							'left: 100%; display: block; opacity: 1;'
						);
					} else item.setAttribute('style', '');
				}, 200);
			}
		},
		mouseLeaveHandler: function (e) {
			e.currentTarget.classList.remove('show');
			if (e.currentTarget.querySelector('.megamenu'))
				e.currentTarget
					.querySelector('.megamenu')
					.setAttribute('style', '');
			if (e.currentTarget.querySelector('ul'))
				e.currentTarget.querySelector('ul').setAttribute('style', '');
		}
	}
};
</script>