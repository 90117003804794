<template>
  <div>
    <section
      class="innerbanner page-header page-header-lg custom-bg-color-light-1 border-0" :style="setBackgound"
    >
      <div class="container position-relative pt-5 pb-4 mt-5">
        <div
          class="custom-circle custom-circle-wrapper custom-circle-big custom-circle-pos-1 custom-circle-pos-1-1 appear-animation animated expandInWithBlur appear-animation-visible"
          data-appear-animation="expandInWithBlur"
          data-appear-animation-delay="900"
          data-appear-animation-duration="2s"
          style="animation-duration: 2s; animation-delay: 900ms"
        >
          <div
            class="bg-color-tertiary rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'bottom', 'speed': 0.5, 'transition': true, 'transitionDuration': 1000}"
            style="
              bottom: 0px;
              transition: transform 1000ms ease-out 0ms;
              transform: translate3d(0px, -62.1439%, 0px);
            "
          ></div>
        </div>
        <div
          class="custom-circle custom-circle-medium custom-circle-pos-2 custom-circle-pos-2-2 appear-animation animated expandInWithBlur appear-animation-visible"
          data-appear-animation="expandInWithBlur"
          data-appear-animation-delay="1450"
          data-appear-animation-duration="2s"
          style="animation-duration: 2s; animation-delay: 1450ms"
        >
          <div
            class="custom-bg-color-grey-2 rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'bottom', 'speed': 0.2, 'transition': true, 'transitionDuration': 2000}"
            style="
              bottom: 0px;
              transition: transform 2000ms ease-out 0ms;
              transform: translate3d(0px, -212.395%, 0px);
            "
          ></div>
        </div>
        <div
          class="custom-circle custom-circle-medium custom-circle-pos-3 custom-circle-pos-3-3 appear-animation animated expandIn appear-animation-visible"
          data-appear-animation="expandIn"
          data-appear-animation-delay="1300"
          style="animation-delay: 1300ms"
        >
          <div
            class="custom-bg-color-grey-2 rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'bottom', 'speed': 0.3, 'transition': true, 'transitionDuration': 1000}"
            style="
              bottom: 0px;
              transition: transform 1000ms ease-out 0ms;
              transform: translate3d(0px, -92.1495%, 0px);
            "
          ></div>
        </div>
        <div
          class="custom-circle custom-circle-small custom-circle-pos-4 custom-circle-pos-4-4 appear-animation animated expandIn appear-animation-visible"
          data-appear-animation="expandIn"
          data-appear-animation-delay="1600"
          style="animation-delay: 1600ms"
        >
          <div
            class="custom-bg-color-grey-2 rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'bottom', 'speed': 0.6, 'transition': true, 'transitionDuration': 500}"
            style="
              bottom: 0px;
              transition: transform 500ms ease-out 0ms;
              transform: translate3d(0px, -61.4176%, 0px);
            "
          ></div>
        </div>
        <div
          class="custom-circle custom-circle-medium custom-circle-pos-5 custom-circle-pos-5-5 appear-animation animated expandInWithBlur appear-animation-visible"
          data-appear-animation="expandInWithBlur"
          data-appear-animation-delay="1050"
          data-appear-animation-duration="2s"
          style="animation-duration: 2s; animation-delay: 1050ms"
        >
          <div
            class="bg-color-secondary rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 2000}"
            style="
              top: 0px;
              transition: transform 2000ms ease-out 0ms;
              transform: translate3d(0px, 130.763%, 0px);
            "
          ></div>
        </div>
        <div
          class="custom-circle custom-circle-medium custom-circle-pos-6 custom-circle-pos-6-6 appear-animation"
          data-appear-animation="expandInWithBlur"
          data-appear-animation-delay="1200"
          data-appear-animation-duration="2s"
        >
          <div
            class="custom-bg-color-grey-2 rounded-circle w-100 h-100"
            data-plugin-float-element=""
            data-plugin-options="{'startPos': 'bottom', 'speed': 0.8, 'transition': true, 'transitionDuration': 500}"
          ></div>
        </div>
        <div class="row py-5 mb-5 mt-2 p-relative z-index-1">
          <div class="col">
            <div class="overflow-hidden">
              <ul
                class="breadcrumb d-block text-center appear-animation animated maskUp appear-animation-visible"
                data-appear-animation="maskUp"
                data-appear-animation-delay="800"
                style="animation-delay: 800ms"
              >
                <!-- <li><a href="">Home</a></li> -->
              </ul>
            </div>
            <div class="overflow-hidden mb-4">
              <h1
                class="d-block text-color-quaternary font-weight-bold line-height-3 text-center mb-0 appear-animation animated maskUp appear-animation-visible"
                data-appear-animation="maskUp"
                data-appear-animation-delay="1000"
                style="animation-delay: 1000ms"
              >
                {{ post.name }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    };
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        "background-image":
          "url(" + this.settings.storageUrl + this.settings.banner_image + ")",
        "background-size": "cover",
        "background-position": "center",
      };
    },
  },
};
</script>

<style>
.page-header.page-header-lg {
  padding: 50px 0;
}

.custom-bg-color-light-1 {
  background-color: #eff1f3 !important;
}

.page-header {
  background-color: #212529;
  margin: 0 0 35px;
  padding: 30px 0;
  position: relative;
  text-align: left;
}

.m-0 {
  margin: 0 !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.position-relative {
  position: relative !important;
}

.custom-circle.custom-circle-pos-1.custom-circle-pos-1-1 {
  top: 45%;
}

.custom-circle.custom-circle-pos-1 {
  top: 25%;
  left: -13%;
}

.custom-circle.custom-circle-big {
  width: 52px;
  height: 52px;
}

.custom-circle {
  position: absolute;
  border-radius: 100%;
}

.expandInWithBlur {
  animation-name: expandInWithBlur;
}

.appear-animation-visible {
  opacity: 1;
}

.appear-animation {
  opacity: 0;
}

.custom-circle.custom-circle-pos-2.custom-circle-pos-2-2 {
  top: 77%;
  left: -1%;
}

.custom-circle.custom-circle-medium {
  width: 23px;
  height: 23px;
}

.custom-circle {
  position: absolute;
  border-radius: 100%;
}

.expandInWithBlur {
  animation-name: expandInWithBlur;
}

.custom-circle.custom-circle-pos-3.custom-circle-pos-3-3 {
  top: 35%;
}

.custom-circle.custom-circle-pos-3 {
  top: 9%;
  left: 17%;
}

.custom-circle.custom-circle-medium {
  width: 23px;
  height: 23px;
}

.custom-circle {
  position: absolute;
  border-radius: 100%;
}

.custom-circle.custom-circle-pos-4 {
  top: 53%;
  left: 20%;
}

.custom-circle.custom-circle-small {
  width: 15px;
  height: 15px;
}

.custom-circle {
  position: absolute;
  border-radius: 100%;
}

.expandIn {
  animation-name: expandIn;
}

.appear-animation-visible {
  opacity: 1;
}

.custom-circle.custom-circle-pos-5.custom-circle-pos-5-5 {
  top: 21%;
  left: 96%;
}

.custom-circle.custom-circle-pos-5 {
  top: 61%;
  left: 39%;
}

.custom-circle.custom-circle-medium {
  width: 23px;
  height: 23px;
}

.custom-circle.custom-circle-pos-6.custom-circle-pos-6-6 {
  top: 28%;
  left: 119%;
}

.custom-circle.custom-circle-medium {
  width: 23px;
  height: 23px;
}

.z-index-1 {
  z-index: 1 !important;
}

.p-relative {
  position: relative !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.custom-page-header-curved-top-1 {
    position: absolute;
    top: -575px;
    left: 0;
}

.innerbanner{
  margin-top: -2rem !important;
}

</style>