<template>
	<div class="container">
		<div class="home-slider-container">
			<pv-carousel
				class="home-slider swiper-carousel swiper-theme-light nav-outer show-nav-hover slide-animate"
				:options="IntroSlider"
				v-animate
			>
				<div class="home-slide swiper-slide">
					<img
						class="slide-bg"
						v-lazy="'@/assets/css/images/home/slider/slide-1.jpg'"
						alt="Home Banner"
						width="1180"
						height="569"
						image-ratio="48.22"
						style="background: #d4b394"
					/>
					<div class="home-slide-content sale-banner">
						<div
							class="row no-gutter bg-primary appear-animate"
							data-animation-name="fadeInLeftShorter"
						>
							<div class="col-auto col-md-6 d-flex flex-column justify-content-center col-1">
								<h4
									class="align-left text-uppercase mb-0 appear-animate"
									data-animation-name="slideInRight"
									data-animation-delay="400"
								>Furniture &amp; Garden</h4>
								<h3
									class="text-white mb-0 align-left text-uppercase appear-animate"
									data-animation-name="slideInRight"
									data-animation-delay="400"
								>Huge Sale
								</h3>
							</div>

							<div
								class="col-auto col-md-6 col-2 appear-animate"
								data-animation-name="slideInLeft"
								data-animation-delay="400"
							>
								<h2 class="text-white mb-0 position-relative align-left">
									50<small>%<ins>OFF</ins></small>
								</h2>
							</div>
						</div>
						<div class="mb-0 text-right">
							<router-link
								to="/shop"
								class="btn btn-lg btn-dark appear-animate"
								data-animation-name="fadeInUpShorter"
								data-animation-delay="600"
							>Shop Now!</router-link>
						</div>
					</div>
				</div>

				<div class="home-slide home-slide-left swiper-slide">
					<img
						class="slide-bg"
						v-lazy="'@/assets/css/images/home/slider/slide-2.jpg'"
						alt="Home Banner"
						width="1180"
						height="569"
						image-ratio="48.22"
						style="background: #d4b394"
					/>
					<div class="home-slide-content slide-content-dark sale-banner">
						<div
							class="row no-gutter bg-secondary appear-animate"
							data-animation-name="fadeInRightShorter"
						>
							<div class="col-auto col-md-6 d-flex flex-column justify-content-center col-1">
								<h4
									class="align-left text-uppercase mb-0 appear-animate"
									data-animation-name="slideInRight"
									data-animation-delay="300"
								>Home office sale</h4>
								<h3
									class="text-white mb-0 align-left text-uppercase appear-animate"
									data-animation-name="slideInRight"
									data-animation-delay="300"
								>mega off
								</h3>
							</div>

							<div
								class="col-auto col-md-6 col-2 appear-animate"
								data-animation-name="slideInLeft"
								data-animation-delay="300"
							>
								<h2 class="text-white mb-0 position-relative align-left">
									50<small>%<ins>OFF</ins></small>
								</h2>
							</div>
						</div>
						<div class="mb-0">
							<router-link
								to="/shop"
								class="btn btn-lg btn-primary appear-animate"
								data-animation-name="fadeInUpShorter"
								data-animation-delay="600"
							>Shop Now!</router-link>
						</div>
					</div>
				</div>
			</pv-carousel>
		</div>
	</div>
</template>

<script>
import PvCarousel from '../Utility/Common/PvCarousel8.vue';

export default {
	components: {
		PvCarousel
	},
	data: function() {
		return {
		};
	},
	mounted: function() {
		document
			.querySelector('.home-slider .swiper-prev i')
			.classList.add('icon-left-open-big');
		document
			.querySelector('.home-slider .swiper-next i')
			.classList.add('icon-right-open-big');
	}
};
</script>