<template>
	<nav class="main-nav w-100">
		<ul class="menu main-menu menu-arrow" v-if="position == 'left'">
			<li v-for="item in mainMenu.slice(0,5)" :key="item.id" >
				<router-link :to="item.url">{{ item.title }}</router-link>
				<div class="megamenu megamenu-fixed-width megamenu-3cols" v-if="item.has_child == 1">
					<div class="row">
						<div class="col-lg-4">
							<ul class="submenu">
								<li v-for="subitem in item.child" :key="subitem.id">
									<router-link :to="subitem.url">
										{{ subitem.title }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<ul class="menu main-menu menu-arrow" v-else>
			<li v-for="item in mainMenu.slice(5,11)" :key="item.id" >
				<router-link :to="item.url">{{ item.title }}</router-link>
				<div class="megamenu megamenu-fixed-width megamenu-3cols" v-if="item.has_child == 1">
					<div class="row">
						<div class="col-lg-4">
							<ul class="submenu">
								<li v-for="subitem in item.child" :key="subitem.id">
									<router-link :to="subitem.url">
										{{ subitem.title }}
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</nav>
</template>
<script>
import axios from 'axios'

export default {
	data: function () {
		return {
			mainMenu : [],
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	
	mounted() {
      this.getMainMenu();this.getWebsiteSettings();
    },
	props: {
		position:  {
			type: String,
			default: null, required: true
		},
	},
	methods: {
		getMainMenu() {
			axios.get('https://api.cnphub.com/public/menu')
			.then(res => {
				this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
			})
			.catch(res => {
				console.log(res);
			});
		},
		getWebsiteSettings() {
			this.settings = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.languages = JSON.parse(localStorage.getItem("languages"));

		},
	},
};
</script>