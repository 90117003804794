<template>
  <div class="top-news-posts">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">

        <div class="row">
          <div class="col-lg-12 col-sm-12 pb-5 pb-md-0">
            <h4 class="section-sub-title">{{ $t('Top News') }}</h4>
          </div>

          <!-- Post Wrapper Start -->
          <div class="col-md-6 col-12 col-xs-12 mb-20 main-articles">
            <div class="story-short article row" v-for="(post, index) in mainNews" :key="index">
              <div class=" col-md-5  col-xs-12">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              </div>
              <div class=" col-md-7 col-xs-12">
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>


          </div><!-- Post Wrapper End -->

          <!-- Small Post Wrapper Start -->
          <div class="col-md-6 col-12  mb-20 top-6-articles">
            <div class="article" v-for="(post, index) in newsList" :key="index">
              <h5><a :href="'/article/' + post.slug">{{ post.name }}</a></h5><span class="time">{{
                getPostDate(post.created_at)
              }}
                <span></span></span>
            </div>


          </div><!-- Small Post Wrapper End -->

        </div>

      </div>
    </div>
  </div>
</template>

<script>




export default {
  components: {},
  props: {
    posts: Array,
  },
  // props: {
  //   darkClass: {
  //     type: Object,
  //   },
  // },
  data: () => ({
    settings: null,
    mainNews: [],
    newsList: [

    ]
  }),
  mounted() {
    this.getTopNews();
    setInterval(this.updateTicker, 5000);
  },
  computed: {

  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    },
    getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));





      if (this.posts.length > 2) {
        this.mainNews.push(this.posts[0]);
        this.mainNews.push(this.posts[1]);
        this.posts.shift(); this.posts.shift();
      }



    },
  },
};
</script>

<style>
.top-6-articles {
  /* background-color: #f2f2f2; */
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.top-6-articles .article {
  background-color: #f2f2f2;
  margin-bottom: 5px;
  padding: 10px;
}

.top-6-articles .article h5 {
  margin-bottom: 5px;
}

.main-articles .article:first-child {
  border-bottom: 2px solid #f2f2f2;
}

.main-articles .article {

  padding-bottom: 20px;
  margin-bottom: 15px;
}

.top-6-articles .article a,
.main-articles .article a {
  color: #222529;
}

.top-6-articles .time,
.main-articles .time {
  color: #999;
  padding: 4px 0;
  font-size: 12px;
}
</style>