var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"partners-panel"},[_c('div',{staticClass:"container"},[_c('pv-carousel',{staticClass:"partners-carousel text-center",attrs:{"options":_vm.brandSlider}})],1)]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer-middle row"},[_c('div',{staticClass:"col-lg-5 col-xl-6"},[_c('div',{staticClass:"row mt-2",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"col-md-4 col-lg-12 col-xl-4"},[_c('router-link',{staticClass:"logo-footer",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/css/images/logo-footer.png"),"width":"112","height":"44","alt":"logo"}})])],1),_vm._m(0)])]),_c('div',{staticClass:"col-lg-7 col-xl-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("Account")]),_c('div',{staticClass:"widget-content"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/pages/account"}},[_vm._v("My Account")])],1),_vm._m(1),_vm._m(2),_vm._m(3)])])])]),_c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("About")]),_c('div',{staticClass:"widget-content"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/pages/about-us"}},[_vm._v("About Porto")])],1),_vm._m(4),_vm._m(5),_vm._m(6)])])])]),_vm._m(7)])])]),_c('div',{staticClass:"footer-bottom d-lg-flex align-items-center"},[_c('p',{staticClass:"footer-copyright font2 mb-0"},[_vm._v("© "+_vm._s(_vm.$t('copyright'))+" 2023. "+_vm._s(_vm.$t('All Rights Reserved.')))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-8 col-lg-12 col-xl-8"},[_c('div',{staticClass:"social-link"},[_c('h4',[_vm._v("Questions")]),_c('div',{staticClass:"links"},[_c('a',{staticClass:"phone_link",attrs:{"href":"javascript:;"}},[_vm._v("1-888-123-456")]),_c('hr',{staticClass:"vertical"}),_c('div',{staticClass:"social-icons"},[_c('a',{staticClass:"social-icon social-facebook",attrs:{"href":"javascript:;","title":"Facebook"}},[_c('i',{staticClass:"icon-facebook"})]),_c('a',{staticClass:"social-icon social-twitter",attrs:{"href":"javascript:;","title":"Twitter"}},[_c('i',{staticClass:"icon-twitter"})]),_c('a',{staticClass:"social-icon social-instagram",attrs:{"href":"javascript:;","title":"instagram"}},[_c('i',{staticClass:"icon-instagram"})]),_c('a',{staticClass:"social-icon social-linkedin",attrs:{"href":"javascript:;","title":"Linkedin"}},[_c('i',{staticClass:"fab fa-linkedin-in"})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Track Your Order")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Payment Methods")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Shipping Guide")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Our Guarantees")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Terms And Conditions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("Features")]),_c('div',{staticClass:"widget-content"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Powerful Admin Panel")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Mobile & Retina Optimized")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Super Fast Porto Theme")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("1st Fully working Ajax Theme")])])])])])])
}]

export { render, staticRenderFns }