<template>
  <section
    class="innerbanner page-header page-header-modern bg-color-light-scale-1 page-header-md" :style="setBackgound"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 v-if="post"
            class="text-dark font-weight-bold text-9 appear-animation animated fadeIn appear-animation-visible"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="100"
            style="animation-delay: 100ms"
          >
            {{ post.name }}
            {{ category.name }}
          </h1>
          <h1 v-if="category"
            class="text-dark font-weight-bold text-9 appear-animation animated fadeIn appear-animation-visible"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="100"
            style="animation-delay: 100ms"
          >
            {{ post.name }}
            {{ category.name }}
          </h1>
        </div>
        <div class="col-md-4 order-1 order-md-2 align-self-center">
          <ul
            class="breadcrumb d-block text-md-end appear-animation animated fadeIn appear-animation-visible"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="300"
            style="animation-delay: 300ms"
          >
            <!-- <li><a href="/">{{ $t("Home") }}</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    post: Object,
    category: Object,
  },
  data: function () {
    return {
      settings: [],
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        'background-image': 'url(' + this.settings.storageUrl + this.settings.banner_image + ')',
      }
    },
  },
};
</script>

<style>
.page-header {
    /* padding: 9rem 0; */
    /* padding: 10.5rem 0 !important; */
    margin: 0px !important;
}

.page-header .breadcrumb a {
    color: var(--secondary-color) !important;
}

.innerbanner{
  margin-top: -2rem !important;
}
</style>