<template>
  <section class="home-section mb-2">
    <div :class="attributes.theme+ ' container' ">
  
      <template >
        
          <component 
          :is="attributes.theme"  
          :Pv1Posts="Pv1Posts" 
          :cat1name="cat1name" 
          :Pv2Posts="Pv2Posts" 
          :cat2name="cat2name"  
          :Pv3Posts="Pv3Posts" 
          :cat3name="cat3name"  
          :Pv4Posts="Pv4Posts" 
          :cat4name="cat4name"  
          :key="attributes.theme"
          :topNews="topNews"></component>
      </template>
    </div>
  </section>
</template>
<script>
import axios from 'axios'

import NewsList4ColTheme1 from './NewsList4ColTheme1.vue'
import NewsList4ColTheme2 from './NewsList4ColTheme2.vue'
import NewsList4ColTheme3 from './NewsList4ColTheme3.vue'
import NewsList4ColTheme5 from './NewsList4ColTheme5.vue'
import NewsList4ColTheme10 from './NewsList4ColTheme10.vue'
import NewsList4ColTheme11 from './NewsList4ColTheme11.vue'
import NewsList4ColTheme12 from './NewsList4ColTheme12.vue'
export default {
  components: {
    NewsList4ColTheme1,
    NewsList4ColTheme2,
    NewsList4ColTheme3,
    NewsList4ColTheme5,
    NewsList4ColTheme10,
    NewsList4ColTheme11,
    NewsList4ColTheme12,
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },
  data: function () {
    return {
      Pv1Posts: [],
      Pv2Posts: [],
      Pv3Posts: [],
      Pv4Posts: [],
      cat1name: '',
      cat2name: '',
      cat3name: '',
      cat4name: '',
      topNews:[],
    };
  },
  created:async function () {
     await this.getPosts();
     this.getTopNews();
  },
   mounted: function () {
    
  },
  methods: {
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
     getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));
      if (!this.attributes.limit) this.attributes.limit = 5;
      axios.get(this.settings.BaseUrl + '/public/top-news?limit='+this.attributes.limit)
        .then(res => {

          this.topNews = res.data.data.posts ? res.data.data.posts : '';

          
         

        })
        .catch(res => {
          console.log(res);
        });
    },
    async getPosts() {
      this.isLoading = true;
      if (!this.attributes.limit) this.attributes.limit = 5;
      axios
        .get("https://api.cnphub.com/public/fourCategoryData" + '?images_query=1&limit=' +this.attributes.limit + 
          '&cat1=' + this.attributes.cat1 + '&' + 'cat2=' + this.attributes.cat2 +
          '&cat3=' + this.attributes.cat3 + '&' + 'cat4=' + this.attributes.cat4 
         , {
        })
        .then((result) => {
          this.Pv1Posts = result.data.data.cat1 ? result.data.data.cat1 : [];
          this.cat1name = result.data.data.cat1name ? result.data.data.cat1name : '';
          this.Pv2Posts = result.data.data.cat2 ? result.data.data.cat2 : [];
          this.cat2name = result.data.data.cat2name ? result.data.data.cat2name : '';
          this.Pv3Posts = result.data.data.cat3 ? result.data.data.cat3 : [];
          this.cat3name = result.data.data.cat3name ? result.data.data.cat3name : '';
          this.Pv4Posts = result.data.data.cat4 ? result.data.data.cat4 : [];
          this.cat4name = result.data.data.cat4name ? result.data.data.cat4name : '';
 
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

