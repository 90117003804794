<template>
	<article class="post">
		<figure
			class="post-media"
		>
			<router-link
				:to="'/article/'+post.slug+''"
			>
				<template>
					<img
						v-lazy="`${post.image}`"
						alt="blog"
					/>
				</template>
			</router-link>
		</figure>

		<div class="post-body">
			<h2 class="post-title">
				<router-link :to="'/article/'+post.slug+''">{{ post.title }}</router-link>
			</h2>

			<div
				class="post-content"
				v-if="isContent"
			>
				<p>{{ post.description }}
					<router-link
						:to="'/article/'+post.slug+''"
						class="read-more"
					>read more <i class="fas fa-angle-right"></i></router-link>
				</p>
			</div>
		</div>
	</article>
</template>
<script>
import { baseUrl } from '@/api';
import { baseSlider1 } from '@/utils/data/carousel';

export default {
	components: {
	},
	props: {
		post: Object,
		isContent: {
			type: Boolean,
			default: true
		},
		isAuthor: {
			type: Boolean,
			default: true
		},
		isMeta: {
			type: Boolean,
			default: true
		},
		postIndex: Number
	},
	data: function () {
		return {
			baseUrl: baseUrl,
			baseSlider1: baseSlider1
		};
	},
	computed: {
		date: function () {
			return new Date( this.post.date );
		}
	},
	methods: {
		
		openLightBox: function () {
			this.$parent.$parent.$parent.$refs.lightBox.showImage( this.postIndex );
		}
	}
};
</script>