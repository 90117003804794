<template>
  <div class="breaking-news-post">
    <div class="container">
      <div class="ticker box">
        <article class="media">
          <span class="breaking-news media-left" :style="'background:'+settings.primary_color">{{ $t('Breaking News') }}</span>
          <div class="media-content">
            <transition name="fade" tag="div" mode="out-in">
              <a v-for="(post,index) in news" class="news" :href="'/article/' + post.slug"  :key="index"> {{ post.name }}</a>
            </transition>
             <!-- {{new Intl.DateTimeFormat('en-US', { timeStyle: 'short'}).format(post.created_at) }} -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

import TrendingPosts from "../Data/TrendingPost";
export default {
  components: {},
  props: {
    attributes:  {
      type: Array,
      limit:Number,
    },
  },
  data: () => ({
    // posts: null,
    tickerLocation: 0,
    settings:null,
    news: [
      true,
      false,
      false,
    ]
  }),
  mounted() {
    this.getBreakingNews();
    setInterval(this.updateTicker, 5000);
  },
  computed: {
		breakingNewsCssVars() {
			return {
				'background': this.settings.secondary_color,
			}
		},
	},

  methods: {
    updateTicker: function () {
      var removed = this.news.pop();
      this.news.unshift(removed);
    },
    getBreakingNews() {
     this.settings = JSON.parse(localStorage.getItem("settings"));

      axios.get(this.settings.BaseUrl + '/public/breaking-news?limit='+this.attributes.limit)
        .then(res => {

          this.news = res.data.data.posts ? res.data.data.posts : '';


        })
        .catch(res => {
          console.log(res);
        });
    },
  },
};
</script>

<style>
.breaking-news-post{
  background-color: #f2f2f2;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.breaking-news {
  background-color: #33A3F1;
  color: #ffffff;
  border-radius: 10px 50px 50px 10px;
  padding: 5px;
 
}

.media-content {
  padding: 5px;
}

.time {
  color: #33A3F1;
  padding: 0 10px;
}

.news {
  color: #666666;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

@media only screen and (max-width: 768px) {
  .breaking-news{
  width: 120px!important;
}
.ticker.box .media {
  display: block;
  align-items: flex-start;
}
}
</style>