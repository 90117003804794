<template>
  <div>
    <div class="container pt-5">
      <div class="row py-4 mb-2">
        <div class="col-md-7 order-2">
          <div class="overflow-hidden">
            <h2
              class="text-color-dark font-weight-bold text-12 mb-2 pt-0 mt-0 appear-animation animated maskUp appear-animation-visible"
              data-appear-animation="maskUp"
              data-appear-animation-delay="300"
              style="animation-delay: 300ms"
            >
              {{ $t("About us") }}
            </h2>
          </div>
          <p
            class="lead appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="700"
            style="animation-delay: 700ms"
            v-html="settings.about_text"
          >
          </p>
          <hr
            class="solid my-4 appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="900"
            style="animation-delay: 900ms"
          />
          <div
            class="row align-items-center appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="1000"
            style="animation-delay: 1000ms"
          >
            <div class="col-lg-6">
              <a href="/contact" class="btn btn-modern btn-dark mt-3">{{ $t("Get In Touch") }}</a>
            </div>
            <div class="col-sm-6 text-lg-end my-4 my-lg-0">
              <strong class="text-uppercase text-1 me-3 text-dark"
                >{{ $t("follow us") }}</strong
              >
              <ul class="social-icons float-lg-end">
                <li class="social-icons-facebook">
                  <a
                    :href="settings.facebook"
                    target="_blank"
                    title="Facebook"
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                </li>
                <li class="social-icons-twitter">
                  <a
                    :href="settings.twitter"
                    target="_blank"
                    title="Twitter"
                    ><i class="fab fa-twitter"></i
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="col-md-5 order-md-2 mb-4 mb-lg-0 appear-animation animated fadeInRightShorter appear-animation-visible"
          data-appear-animation="fadeInRightShorter"
          style="animation-delay: 100ms"
        >
          <img :src="getImage(settings.about_image, 'default')" class="img-fluid mb-2" alt="About us" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>
</style>