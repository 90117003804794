<template>
  <div v-if="settings.status == 'pending' || settings.status == 'draft'">
      <soon :settings="settings">
      </soon>
  </div> 
    <main :class="'lang-' + getLang() +' id-'+ getWebsite() + ' main home'"  v-else>
      <div class="contact-page">
    <MainHeader :attributes="header.attributes"></MainHeader>

    <component
      :is="settings.inner_pages_banner"
      :key="settings.inner_pages_banner"
    ></component>

    <component
      :is="settings.contact_theme"
      :key="settings.contact_theme"
    ></component>

    <MainFooter :attributes="footer.attributes"></MainFooter>
    <pv-mobile-menu></pv-mobile-menu>
    </div>
  </main>
  
</template>
  
<script>
import axios from "axios";

import MainHeader from "../Utility/Header/MainHeader.vue";
import MainFooter from "../Utility/Footer/MainFooter.vue";
import PvMobileMenu from '../Utility/Common/PvMobileMenu.vue';
import Theme1 from "./Theme1.vue";
import Theme2 from "./Theme2.vue";
import Theme3 from "./Theme3.vue";
import Theme4 from "./Theme4.vue";
import Theme5 from "./Theme5.vue";
import Theme6 from "./Theme6.vue";
import Theme7 from "./Theme7.vue";
import Theme8 from "./Theme8.vue";

import Banner1 from "../Posts/partials/banner/banner1";
import Banner2 from "../Posts/partials/banner/banner2";
import Banner3 from "../Posts/partials/banner/banner3";
import Banner4 from "../Posts/partials/banner/banner4";
import Banner5 from "../Posts/partials/banner/banner5";
import Banner6 from "../Posts/partials/banner/banner6";
import Banner7 from "../Posts/partials/banner/banner7";
import Banner8 from "../Posts/partials/banner/banner8";

import { useFavicon } from "@vueuse/core";
import { computed, ref } from "vue";

import soon from "../soon.vue";

export default {
  components: {
    Theme1,
    Theme2,
    Theme3,
    Theme4,
    Theme5,
    Theme6,
    Theme7,
    Theme8,
    MainHeader,
    MainFooter,
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
    Banner8,
    PvMobileMenu,
    soon
  },
  data: function () {
    return {
      settings: [],
      header: "",
      footer: "",
    };
  },
  //insert the following code for vue-meta to work
  metaInfo() {
      const about = this.settings.about;
    if(about != ''){
      return {
        title:  'News',
        meta: [
          { name: "description", content: `${about}` },
          { property: "og:title", content: `${about}` },
          { property: "og:site_name", content: `${about}` },
          { property: "og:description", content: `${about}` },
          { property: "og:type", content: "page" },
          { property: "og:url", content: `${about}` },
          { property: "og:image", content: `${about}` },
        ],
      };
    }
    },
  created() {
    this.getWebsiteSettings();
    this.getLanguage();
    this.getMenu();
    this.favicon();
  },
  mounted() {
    this.getWebsiteSettings();
    this.favicon();
    this.header = JSON.parse(localStorage.getItem("header"));
    this.footer = JSON.parse(localStorage.getItem("footer"));
  },
  methods: {
    getLang: function(){
      return localStorage.getItem("locale");
    },
    getWebsite: function () {
      return this.settings.id;
    },
    favicon() {
      const type = ref("vueuse");
      const favicon = computed(
        () =>
          (type.value = this.$helpers.getImageFullPath(
            this.settings.favicon,
            "medium"
          ))
      );
      console.log(favicon);
      useFavicon(favicon, {
        baseUrl: "",
        rel: "icon",
      });
    },
    async getWebsiteSettings() {
      this.settings = [];
      axios.get('https://api.cnphub.com/public/settings')
        .then(res => {

          this.websiteSettings = res.data.data.settings ? res.data.data.settings : '';
          localStorage.setItem("settings", res.data.data.settings ? JSON.stringify(res.data.data.settings) : '');
          localStorage.setItem("submenu", res.data.data.submenu ? JSON.stringify(res.data.data.submenu) : '');
          localStorage.setItem("languages", res.data.data.languages ? JSON.stringify(res.data.data.languages) : '');
          this.settings = res.data.data.settings ? res.data.data.settings : [];
          localStorage.setItem("header", this.settings.header ? this.settings.header : 'Header1');
          localStorage.setItem("footer", this.settings.footer ? this.settings.footer : 'Footer1');
          localStorage.setItem("webiste_id", this.settings.id ? this.settings.id : '5');
          this.getWebsiteElements();
        })
        .catch(res => {
          console.log(res);
        });
    },
    getLanguage: function () {
      if (localStorage.getItem("locale")) {
        this.$i18n.locale = localStorage.getItem("locale");
      } else {
        localStorage.setItem("locale", this.$i18n.locale);
        localStorage.setItem("lang_code", 'en_US');
        localStorage.setItem("lang", 'en');
      }
    },
    getWebsiteElements() {
      this.body = [];
      this.isLoading = true;
      axios.get('https://api.cnphub.com/public/website')
        .then(res => {
          // this.body = res.data.data ? res.data.data : [];
          this.body = res.data.data.elements ? res.data.data.elements : [];
          localStorage.setItem("body", res.data.data.elements ? JSON.stringify(res.data.data.elements) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    getMenu() {
      axios.get('https://api.cnphub.com/public/menu')
        .then(res => {
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
          this.footerMenu = res.data.data.footerMenu ? res.data.data.footerMenu : [];
          localStorage.setItem("mainMenu", res.data.data.mainMenu ? JSON.stringify(res.data.data.mainMenu) : '');
          localStorage.setItem("footerMenu", res.data.data.footerMenu ? JSON.stringify(res.data.data.footerMenu) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
  },
};
</script>


<style>
</style>