<template>
	<footer class="footer">
		<div class="partners-panel">
			<div class="container">
				<pv-carousel
					class="partners-carousel text-center"
					:options="brandSlider"
				>
					<!-- <div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/1.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div>

					<div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/2.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div>

					<div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/3.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div>

					<div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/4.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div>

					<div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/5.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div>

					<div class="swiper-slide brand-slide">
						<img
							src="@/assets/css/images/home/logos/6.PNG"
							width="148"
							height="57"
							alt="logo"
						>
					</div> -->
				</pv-carousel>
			</div>
		</div>

		<div class="container">
			<div class="footer-middle row">
				<div class="col-lg-5 col-xl-6">
					<div
						class="row mt-2"
						style="display: flex; align-items: center;"
					>
						<div class="col-md-4 col-lg-12 col-xl-4">
							<router-link
								to="/"
								class="logo-footer"
							><img
									src="@/assets/css/images/logo-footer.png"
									width="112"
									height="44"
									alt="logo"
								/>
							</router-link>
						</div>
						<div class="col-md-8 col-lg-12 col-xl-8">
							<div class="social-link">
								<h4>Questions</h4>
								<div class="links">
									<a
										href="javascript:;"
										class="phone_link"
									>1-888-123-456</a>
									<hr class="vertical">
									<div class="social-icons">
										<a
											href="javascript:;"
											class="social-icon social-facebook"
											
											title="Facebook"
										>
											<i class="icon-facebook"></i>
										</a>
										<a
											href="javascript:;"
											class="social-icon social-twitter"
											
											title="Twitter"
										>
											<i class="icon-twitter"></i>
										</a>
										<a
											href="javascript:;"
											class="social-icon social-instagram"
											
											title="instagram"
										>
											<i class="icon-instagram"></i>
										</a>

										<a
											href="javascript:;"
											class="social-icon social-linkedin"
											
											title="Linkedin"
										>
											<i class="fab fa-linkedin-in"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-7 col-xl-6">
					<div class="row">
						<div class="col-md-4 col-lg-4">
							<div class="widget">
								<h3 class="widget-title">Account</h3>
								<div class="widget-content">
									<ul>
										<li>
											<router-link to="/pages/account">My Account</router-link>
										</li>
										<li><a href="javascript:;">Track Your Order</a></li>
										<li><a href="javascript:;">Payment Methods</a></li>
										<li><a href="javascript:;">Shipping Guide</a></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-4">
							<div class="widget">
								<h3 class="widget-title">About</h3>
								<div class="widget-content">
									<ul>
										<li>
											<router-link to="/pages/about-us">About Porto</router-link>
										</li>
										<li><a href="javascript:;">Our Guarantees</a></li>
										<li><a href="javascript:;">Terms And Conditions</a></li>
										<li><a href="javascript:;">Privacy Policy</a></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-lg-4">
							<div class="widget">
								<h3 class="widget-title">Features</h3>
								<div class="widget-content">
									<ul>
										<li><a href="javascript:;">Powerful Admin Panel</a></li>
										<li><a href="javascript:;">Mobile & Retina Optimized</a></li>
										<li><a href="javascript:;">Super Fast Porto Theme</a></li>
										<li><a href="javascript:;">1st Fully working Ajax Theme</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom d-lg-flex align-items-center">
				<p class="footer-copyright font2 mb-0">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</p>
				<!-- <img
					class="ml-lg-auto ml-0 mt-lg-0 mt-1"
					src="@/assets/css/images/home/payments_long.PNG"
					width="255"
					height="22"
					alt="payment"
				> -->
			</div>
		</div>
	</footer>
</template>

<script>
import PvCarousel from '../Common/PvCarousel.vue';
import { brandSlider } from '@/utils/data/carousel';

export default {
	components: {
		PvCarousel
	},
	data: function () {
		return {
			brandSlider: brandSlider
		}
	}
};
</script>