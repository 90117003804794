<template>
	<footer class="footer theme3 bg-dark position-relative">
		<!-- <div class="footer-middle">
			<div class="container position-static">
				<div class="row">
					<div class="col-lg-12 col-sm-12 pb-2 pb-sm-0">
						<div class="widget">
							<ul class="links">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>								
							</ul>
							
						</div>
					</div>

					
				</div>
			</div>
		</div> -->

		<div class="container">
			<div class="footer-top">
				<div class="container row  align-items-center">
					<div class=" col-md-3 pb-2 pb-sm-0">
						<div class="widget">
							<router-link to="/" class="logo">

								<img :src="settings.logo" width="150" height="80" alt="Logo" />
							</router-link>
							<div class="about-text">{{ settings.about }}</div>
							<a v-if="settings.email" :href="'mailto:'+settings.email" class="email social-envelop icon-envelop"
										title="Email"> {{ settings.email }}</a>
						</div>
					</div>
					<div class="col-md-9  footer-middle">
						<div class="container position-static">
							<div class="">
								<div class="col-lg-12 col-sm-12 pb-2 pb-sm-0">
									<div class="footer-menu">
										<ul class="links row">
											<li class="col-md-3" v-for="item in footerMenu" :key="item.id">
												<router-link :to="item.url">{{ item.title }}</router-link>
											</li>
										</ul>

									</div>
								</div>


							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="container container-footer">
			<div class="footer-bottom">
				<div class="container  align-items-center">

					<div class="d-sm-flex">
						<div class="footer-left">
							<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{
								$t('All Rights Reserved.')
							}}</span>
						</div>

						<div class="footer-right ml-auto mt-1 mt-sm-0">
							<div class="payment-icons">
								<div class="social-icons">
									<a v-if="settings.facebook" :href="settings.facebook" class="social-icon social-facebook icon-facebook"
										title="Facebook"></a>
									<a v-if="settings.twitter"  :href="settings.twitter" class="social-icon social-twitter icon-twitter"
										title="Twitter"></a>
									<a v-if="settings.instagram"  :href="settings.instagram" class="social-icon social-linkedin fab fa-linkedin-in"
										title="Linkedin"></a>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</footer>
</template>
<script>
import axios from 'axios'

export default {
	props: {
		attributes: {
			settings: Array,
			footerMenu: Array,
		},
	},
	data: function () {
		return {

		};
	},
	created() {
		this.getWebsiteSettings();
	},
	computed: {
	},
	mounted() {


	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},

	},
};
</script>

<style>
#app .footer.theme3 {
	background:var(--footer-color) !important;
}

#app .theme3 .footer-bottom {
	background: var(--color) !important;
	padding: 15px;
	color: #fff;
}
.email.icon-envelop{
	text-align: left;
    display: block;
    color: #fff;
    font-weight: 400;
}
#app .theme3 .footer-bottom p,
#app .theme3 .footer-bottom a,
#app .theme3 .footer-bottom .footer-copyright {
	color: #fff;
}

#app .footer-middle {
	padding-top: 1.7rem;
	padding-bottom: 0rem;
}

#app .theme3 .footer-menu ul.links li {
	margin-right: 0rem;
	text-align: left;
	padding-left: 15px;
}

#app .theme3 .footer-menu ul.links li a {
	font-size: 14px;
	text-align: left;
	color: #555;
}
#app .theme3 .footer-menu ul.links li  a:before {
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
    font-size: 16px;
    font-family: "Font Awesome 5 Pro";
}
#app .theme3  .about-text{
	color: #555;
}
#app .theme3   .logo{
	display: block;
}
</style>