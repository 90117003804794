<template>
  <header class="header theme19 mb-2">
    <!-- <div class="upper-header-top text-uppercase" :style="TopHeaderCssVars">
			<div class="container">
				<div class="header-left">
					<top-header-menu></top-header-menu>
				</div>

				<div class="header-right">
					
					<pv-header-search></pv-header-search>
				</div>
			</div>
		</div> -->
    <div class="header-middle sticky-header" :style="HeaderCssVars">
      <div class="container">
        <div class="header-center">
          <button
            class="mobile-menu-toggler mr-2"
            type="button"
            @click="showMobileMenu"
          >
            <i class="fas fa-bars"></i>
          </button>
          <router-link to="/" class="logo">
            <img
              :src="settings.logo"
              width="220"
              height="140"
              :alt="settings.webiste"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="header-top text-uppercase" :style="HeaderCssVars">
      <div class="container main-menu">
        <div class="header-left header-dropdowns ml-0">
          <div class="header-dropdown dropdown-expanded mr-3">
            <!-- <a href="javascript:;">Links</a> -->
            <div class="header-menu">
              <pv-main-menu></pv-main-menu>
            </div>
          </div>
        </div>
        <div class="header-right">
          <pv-header-search></pv-header-search>
          <div class="header-dropdown dropdown-language mr-auto">
            <div v-for="(language, index) in languages" :key="index">
              <a
                href="javascript:;"
                v-if="language.lang_locale == $i18n.locale"
              >
                <i :class="'flag-' + language.lang_flag + ' flag'"></i>
              </a>
            </div>
            <div class="header-menu">
              <ul>
                <li
                  v-for="(language, index) in languages"
                  :key="index"
                  @click="selectLang(language)"
                >
                  <a href="javascript:;">
                    <i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i
                    >{{ language.lang_name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <span class="separator d-none d-lg-inline-block"></span>
          <div class="social-icons">
            <a
              href="javascript:;"
              class="social-icon social-twitter icon-twitter"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import PvMainMenu from "./PvMainMenu";
// import TopHeaderMenu from './TopHeaderMenu';

import PvHeaderSearch from "./PvHeaderSearch";

document.querySelector("body").classList.add("loaded");

export default {
  components: {
    PvMainMenu,
    // TopHeaderMenu,
    PvHeaderSearch,
  },
  computed: {
    HeaderCssVars() {
      return {
        "background-color": this.settings.header_color,
      };
    },
    TopHeaderCssVars() {
      return {
        "background-color": this.settings.secondary_color,
      };
    },
  },
  data: function () {
    return {
      isHome: {
        type: Boolean,
        default: true,
      },
      settings: "",
      submenu: "",
      languages: "",
    };
  },
  mounted() {
    this.getWebsiteSettings();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      localStorage.setItem("locale", newVal);
    },
  },
  methods: {
    getWebsiteSettings() {
      this.settings = [];

      this.settings = JSON.parse(localStorage.getItem("settings"));

      this.languages = JSON.parse(localStorage.getItem("languages"));
    },
    showMobileMenu: function () {
      document.querySelector("body").classList.add("mmenu-active");
    },
    showMobileSearch: function (e) {
      let headerSearch = e.currentTarget.closest(".header-search");
      headerSearch.classList.add("show");
      headerSearch
        .querySelector(".header-search-wrapper")
        .classList.add("show");
    },
    selectLang: function (language) {
      this.$i18n.locale = language.lang_locale;
      localStorage.setItem("lang_code", language.lang_code);
      localStorage.setItem("langSwitch", true);
      window.location.reload();
    },
  },
};
</script>

<style>
#app .header.theme19 .logo {
  max-width: 240px;
  padding-right: 15px;
}
.header.theme19 .container.main-menu {
  border-top: 2px solid var(--color);
  padding: 0px;
}
#app .header.theme19 .main-nav .menu > li {
  margin-right: 1.2rem;
  letter-spacing: -0.01em;
  margin-left: 1.2rem;
}
.header.theme19 .header-search-inline .form-control {
  min-width: 15rem;
  padding: 0.5rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.theme19 .header-search-wrapper .btn {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.header.theme19 .header-search-wrapper {
  height: 32px;
}
.header.theme19 {
  background: #fff;
  padding: 0px;
  box-shadow: 0px 0px 5px 5px rgb(0 0 0 / 50%);
}
.theme19 .header-search-category .form-control {
  border-radius: 0rem 0 0 0rem;
}
.theme19 .header-center {
  margin: 0 auto;
}
.theme19 .header-middle {
  padding-top: 1.2rem;
  padding-bottom: 0.2rem;
}
.theme19 .header-search-category .btn {
  border-radius: 0 0rem 0rem 0;
}
.theme19 .header-top .main-nav .menu > li > a {
  font-size: 11px;
  font-weight: 500;
  padding: 2rem 0;
  color: #fff;
}

.main.home .header-middle .menu > li > a:hover {
  color: var(--color);
}

#app .theme19 .main-articles.top-news-sidebar {
  background: #fff;
  border: 1px solid #f2f2f2;
}

#app .theme19 .section-sub-title {
  color: #000;
  font-weight: 700;
  border: 0;
  font-size: 22px !important;
  border-radius: 0;
  padding: 5px;
  padding-left: 0px;
  border-bottom: 5px solid #000;
  background: #fff;
}

#app .theme-3 .logo {
  max-width: 220px;
  width: 240px;
  padding-right: 15px;
}
.theme19 .section-sub-title {
  color: #222529;
  font-weight: 700;
  border: 0px;
  font-size: 22px !important;
  border-radius: 0px;
  padding: 5px;
  border-bottom: 5px solid var(--color);
  background: #fff;
}
.theme19 .time,
.theme4 .main-articles .time {
  color: var(--secondary-color) !important;
  font-weight: 600;
}
.upper-header-top .main-nav .menu > li > a {
  color: #fff;
}
h4.footer-title {
  color: #222529;
  font-weight: 700;
  padding: 5px;
  border-bottom: 5px solid #555;
}

.theme19 .post-default .post-details {
  padding: 0;
}

.theme19 .post-widget .post-details {
  margin-bottom: 2px;
  max-width: calc(100% - 104px);
}

.theme19 .left-details .post-details {
  align-items: flex-start;
}

.theme19 .post-default .post-details {
  display: flex;
  display: -ms-flexbox;
  padding: 0 0.8rem;
  flex-direction: column;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-pack: center;
}
@media (max-width: 767px) {
  #app .header.theme19 .logo {
    max-width: 140px;
    padding-right: 15px;
  }
   #app .theme19  .swiper-dots.swiper-pagination-bullets {
    display: none;
  }
}
</style>
