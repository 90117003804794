<template>
  <div class="top-news-posts theme18">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">

        <div class="row">
          <div class="col-lg-12 col-sm-12 pb-5 pb-md-0">
            <h4 class="section-sub-title">{{ $t('Top Stories') }}</h4>
          </div>
          <div class="col-lg-6 col-sm-12 pb-5 pb-md-0">
            <div class="story-short main-article article row">
              <div class=" col-md-12  col-xs-12">
                <img v-if="mainNews.image" width="100%" :src="getImage(mainNews.image, 'medium')">
              </div>
              <div class=" col-md-12 col-xs-12">

                <h4><a :href="'/article/' + mainNews.slug">
                    {{ mainNews.name }}</a></h4>
                <div class="date">{{ getPostDate(mainNews.created_at) }} </div>
                <p style="padding-left:0;margin:5px;line-height:18px;"   v-html="getPostDescription(mainNews.content,300)"></p>
                <div class="slider-cats " v-if="mainNews.cats.length">
                  {{ $t('Categories') }} : <span v-for="(s_cat, s_cat_index) in mainNews.cats" class="slider-cat "
                    :key="s_cat_index">{{
                      s_cat.name
                    }}
                  </span>
                </div>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>
          </div>
          <!-- Post Wrapper Start -->
          <!-- <div class="col-md-4 col-12 col-xs-12 mb-20 main-articles" v-for="(post, index) in mainNews" :key="index">
            <div class="story-short article row">
              <div class=" col-md-12  col-xs-12">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              </div>
              <div class=" col-md-12 col-xs-12">
                <br>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                 <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a>
              </div>
            </div> 


          </div>--><!-- Post Wrapper End -->

          <!-- Small Post Wrapper Start -->
          <div class="row col-md-6 ">
            <div class="col-md-6    top-6-articles" v-for="(post, index) in newsList" :key="index">

              <div class="article" v-if="post.id != mainNews.id">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
                <h5><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 120) }}</a></h5><span
                  class="time">{{
                    getPostDate(post.created_at)
                  }}
                  <span></span></span>
              </div>


            </div><!-- Small Post Wrapper End -->

          </div>



        </div>

      </div>
    </div>
  </div>
</template>

<script>




export default {
  components: {},
  props: {
    posts: Array,
  },

  data: () => ({
    settings: null,
    mainNews: null,
    newsList: [

    ]
  }),
  created() {
    // setTimeout(this.getTopNews(), 5000);
    setTimeout(() => this.getTopNews(), 1000);
  },
  mounted() {


  },
  computed: {

  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 160) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));





      if (this.posts.length > 4) {

        for (let index = 0; index < this.posts.length; index++) {
          if (this.posts[index].image != null) {
            this.mainNews = (this.posts[index]);
            break;
          }


        }
        for (let index = 0; index < this.posts.length; index++) {
          if (this.posts[index].id != this.mainNews.id) {
            this.newsList.push(this.posts[index]);

          }
        }
        //this.mainNews.push(this.posts[0]);
        //this.mainNews.push(this.posts[1]);
        // this.posts.shift(); this.posts.shift();
      }



    },
  },
};
</script>

<style>
.top-news-posts.theme18 .section-sub-title {
  color: #222529;
  font-weight: 700;
  border: 0px;
  font-size: 22px !important;
  border-radius: 0px;
  padding: 10px;
  border-bottom: 2px solid var(--color);
  border-top: 2px solid var(--color);
  background: #fff;
  text-align: center;
}

.top-news-posts.theme18 .main-article.article {}

.top-news-posts.theme18 .main-article.article h4 {
  color: #222529;
  font-size: 2.4em;
  text-align: center;
}

.top-news-posts.theme18 .main-article.article .date {
  color: var(--color);
  text-align: center;
}

.top-news-posts.theme18 .main-article.article h4 a {
  color: #222529;
}

.top-news-posts.theme18 .main-article.article p {
  color: #222529;
  text-align: center;
}

.top-news-posts.theme18 .top-6-articles {
  background-color: #fff;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.top-news-posts.theme18 .top-6-articles .article {
  border: 4px solid var(--color);
  margin-bottom: 15px;
  padding: 10px;
  background-color: transparent;
}

.top-6-articles .article h5 {
  margin-bottom: 5px;
  min-height: 90px;
}

.top-news-posts.theme18 .main-articles .article:first-child {
  border-bottom: 0px solid #f2f2f2;
}
</style>