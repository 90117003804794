export default {
    getImage(url, size) {
        if(url){
            var filename = url.split('/').pop();
            var fileArray = filename.split('.');
            if (size == 'thumb') return url.replace(filename, fileArray[0] + '-150x150' + '.' + fileArray[1]);
            else if (size == 'featured') return url.replace(filename, fileArray[0] + '-565x375' + '.' + fileArray[1]);
            else if (size == 'medium') return url.replace(filename, fileArray[0] + '-540x360' + '.' + fileArray[1]);
            return url;
        }else{
            return ''
        }
        
    },
    getImageFullPath(url, size) {
         var settings = JSON.parse(localStorage.getItem("settings"));//process.env.URL_STORAGE
        return settings.storageUrl+this.getImage(url,size);
    }
}