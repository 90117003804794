<template>
	<header class="header theme-3 mb-2" :style="TopHewaderCssVars">
		
		<div class="header-top text-uppercase" :style="cssVars">
			<div class="container">
				<div class="header-left">
					

					<div class="header-dropdown  dropdown-language  mr-auto">
						<div v-for="(language, index) in languages" :key="index">
							<a href="javascript:;"  v-if="language.lang_locale==$i18n.locale">
							<i :class="'flag-' + language.lang_flag + ' flag'"></i> </a>
						</div>
						

						<div class="header-menu">
							<ul>
								<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">
									<a href="javascript:;">
										<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
	language.lang_name
										}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="header-right header-dropdowns ml-0 ml-sm-auto">
					<div class="header-dropdown dropdown-expanded mr-3">
						<!-- <a href="javascript:;">Links</a> -->
						<div class="header-menu">
							<top-header-menu></top-header-menu>
						</div>
					</div>

					<span class="separator d-none d-lg-inline-block"></span>

					<div class="social-icons">
						<a v-if="settings.facebook" :href="settings.facebook" class="social-icon social-facebook icon-facebook"
										title="Facebook"></a>
									<a v-if="settings.twitter"  :href="settings.twitter" class="social-icon social-twitter icon-twitter"
										title="Twitter"></a>
									<a v-if="settings.instagram"  :href="settings.instagram" class="social-icon social-linkedin fab fa-linkedin-in"
										title="Linkedin"></a>
					</div>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left">
					<button
						class="mobile-menu-toggler mr-2"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="220" height="140" :alt="settings.webiste" />
					</router-link>
					

					<pv-main-menu></pv-main-menu>

					
				</div>

				<div class="header-right">
					<div class="header-contact d-none d-lg-flex pl-4 pr-4">
						
					</div>

					<pv-header-search></pv-header-search>

					

					
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';
import TopHeaderMenu from './TopHeaderMenu';

import PvHeaderSearch from './PvHeaderSearch';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		TopHeaderMenu,
		PvHeaderSearch
	},
	computed: {
		cssVars() {
			return {
				'background-color': this.settings.primary_color,
			}
		},
		TopHewaderCssVars() {
			return {
				'background-color': this.settings.header_color,
			}
		},
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
		
			this.settings = JSON.parse(localStorage.getItem("settings"));
			
			this.languages = JSON.parse(localStorage.getItem("languages"));
			
		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			localStorage.setItem("langSwitch", true);
			window.location.reload();
		},

	}
};
</script>

<style>

.theme-3 .header-top  .main-nav .menu > li > a {
    font-size: 11px;
    font-weight: 500;
    padding: 2rem 0;
    color: #fff;
}
.main.home .header-middle  .menu > li > a:hover {
    color: var(--color);
}
#app .theme3 .main-articles.top-news-sidebar{
	background: #f2f2f2;
}
#app .theme3 .section-sub-title span{
	background-color: var(--color);
	padding: 10px 15px;
}
#app .theme3  h4.section-sub-title {
	font-size: 18px!important;
    color: #fff;
    border-radius: 0px;
    padding: 0px;
    border: 0px;
    background: #f2f2f2;
}
#app .theme-3 .logo {
    max-width: 220px;
    width: 240px;
    padding-right: 15px;
}
@media only screen and (max-width: 768px) {
#app .theme-3 .mobile-menu-toggler {
  
    color: #000 !important;
  }
  #app .theme-3 .logo {
  max-width: 120px!important;
  width: 140px!important;

  margin-left: 70px;
}
  .theme15 .c-hub-title__inner h2{
font-size: 2rem!important;  }
}
</style>