<template>
	<footer>
		<div class="footer font2">
			<div class="container">
				<div class="footer-middle">
					<div class="row">
						<div class="col-lg-2 d-flex align-items-center">
							<img
								:src="settings.logo"
								alt="Logo"
								class="logo mb-3"
							/>
						</div>
						<div class="col-lg-5 col-xl-6 d-flex align-items-center">
							<ul class="links mb-3">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>
							</ul>
						</div>
						<div class="col-lg-5 col-xl-4 d-flex justify-content-lg-end align-items-end">
							<div class="footer-question mb-3">
								<h6 class="text-white m-b-1">{{ $t('QUESTIONS?') }}</h6>
								<h3 class="text-white m-b-1">{{ settings.phone }}</h3>
							</div>

							<div class="social-icons mb-3">
								<a  v-if="settings.facebook"  
									:href="settings.facebook"
									class="social-icon social-facebook icon-facebook"
									
								></a>
								<a  v-if="settings.twitter"  
									:href="settings.twitter"
									class="social-icon social-twitter icon-twitter"
									
								></a>
								<a   v-if="settings.instagram"  
									:href="settings.instagram"
									class="social-icon social-instagram icon-instagram"
									
								></a>
							</div>
						</div>
					</div>
				</div>

				<div class="footer-bottom d-sm-flex align-items-center">
					<div class="footer-left">
						<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</span>
					</div>

					<div class="footer-right ml-auto mt-1 mt-sm-0">
						<div class="payment-icons mr-0">
							<span v-html="settings.about"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
    },
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
			console.log(this.settings);
		},
	},
};
</script>