<template>
  <section class="home-section mb-2 ">
    <div class="container theme15">
      <div class="c-hub-title no-description">
        <span class="c-hub-title__inner">

          <h2 class="">{{ $t("Top Stories") }}</h2>
        </span>
      </div>
      <div class="row col-md-12">
       
        <div class="col-md-12 col-xl-12 col-lg-12 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider  slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in posts" :key="index">
              <div class="row">
                <div class="col-md-5   mb-20 sub-list-col-1">
                  <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"><br>
                </div>
                <div class="col-md-7   mb-20 sub-list-col-1">
                  <h3 class=" appear-animate " data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                    v-animate>
                    <a class="slider-title" :href="'/article/' + post.slug">{{ getPostDescription(post.name,80) }}</a>
                  </h3>


                  <div v-if="post.subtitle" class="mb-20 appear-animate" v-html="post.subtitle"></div>
                  <p class="mb-20  appear-animate" v-else>{{ getPostDescription(post.content, 100) }}</p>

                </div>
              </div>


              <!-- <h6 v-if="post.created_at" class=" slider-date appear-animate" data-animation-name="fadeInDownShorter"
                data-animation-delay="100" v-animate>{{
                  getPostDate(post.created_at)
                }}
              </h6> -->
              <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->

            </div>


          </PvCarousel40>
        </div>
        <div class="col-md-12 row  mb-20 sub-list-col-1">
          <div class="article col-md-3" v-for="(post, index) in getShortList(1, 5)" :key="index">
            <div >
              <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              <!-- <div class="slider-date ">{{
                getPostDate(post.created_at)
              }}
              </div> -->
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
              <p v-html="getPostDescription(post.content, 150)"></p>
            </div>
           

          </div>


        </div>
        <div class="col-md-12 row  mb-20 sub-list-col-2">
          <div class="article col-md-3" v-for="(post, index) in getShortList(6, 10)" :key="index">
            
            <div  class="small-article">
              <div class="slider-date ">{{
                getPostDate(post.created_at)
              }}
              </div>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
            </div>

          </div>


        </div>




      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from '../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {

      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  created() {


  },
  mounted() {
    // this.getSliderNews();

  },
  methods: {
    getShortList(start, shortListSize) {
      if (this.posts)
        return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));




    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1); 
    //       }


    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;



    // },
  },
}
</script>

<style>
.theme15 .home-slider img {
  height: auto;

}

.theme15 .swiper-carousel .swiper-dots.disabled {
  display: block;
}

.text-center {
  text-align: center;
}

.theme15 .home-slider .home-slide1 h3 {
  margin-bottom: 0.8rem;
  font-size: 1.6em;
  padding-bottom: 20px;
  text-align: left;
}

.theme15 .home-slider .home-slide1 .slider-title {
  margin-bottom: 0.8rem;
    font-size: 1.6em;
    padding-bottom: 11px;
    text-align: left;

}

.theme15 .home-slider .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: #d3d3d3;
  opacity: 1;
}

.theme15 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
    right: 3.6rem;
    left: 50%;
    bottom: 1.1rem;
    text-align: center;
    transform: translateX(-50%);
    max-width: 180px;
}

.theme15 .swiper-theme .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
}

.theme15 .c-hub-title {
  overflow: hidden;
  position: relative;
  text-align: center;
  overflow: visible;
  margin-bottom: 30px;;
}

.theme15 .c-hub-title.no-description:before {
  /* border-left: 6px solid var(--color);
  border-right: 6px solid var(--color); */
  border-top: 6px solid var(--color);
  content: " ";
  height: 50px;
  left: -16px;
  position: absolute;
  right: -16px;
  top: 50%;
  pointer-events: none;
}

.theme15 .c-hub-title__inner {
  background-color: #000;
    color: #fff!important;
    color: currentColor;
    display: table-cell;
    padding: 10px 20px 8px!important;
    position: relative;
    padding: 16px;
    text-align: left!important;
    border: 6px solid var(--color);
    /* margin-bottom: 30px; */
    transform: skewY(-2deg);
    border-radius: 20px;
}
.theme15 .c-hub-title__inner h2{
  color: #fff!important;
    text-align: left!important;
    margin-bottom: 0.8rem;
    letter-spacing: 5px;
}
.theme15 .home-slider {
  /*   
  min-height: 50rem;
  max-height: 50rem; */
}

.theme15 .home-slider {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
}

.theme15 .home-slider .slider-title {
  color: #000;
}

.sub-list-col-1 h4 a,
.sub-list-col-2 h4 a {
  color: #000;
}

.theme15.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

.theme15 .small-article {
  padding-top: 15px;
    padding-bottom: 10px;
    border-top: 3px solid var(--color);
}


.theme15 .small-article h4 a {
  font-size: 0.75em;
  font-weight: bold;
  color: #333;


}

.theme15 .home-slider .slider-date,
.theme15 .sub-list-col-1 .slider-date,
.theme15 .sub-list-col-2 .slider-date {
  width: auto;
  padding: 10px;
  background: #fff;
  margin-top: -33px;
  display: block;
  position: absolute;
}

.theme2.top-6-articles .article {}

.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 2.4em;
}

@media only screen and (max-width: 768px) {
  
  .theme15 .home-slider .home-slide1 h3{
    padding-bottom: 10px;
  }
  .theme15 .home-slider .home-slide1 p{
    line-height: 16px;
    min-height: 99px;
  }
  .theme15 .home-slider .home-slide1 .slider-title {
  font-size: 1.2em;
  font-weight: 600;
}
}
</style>