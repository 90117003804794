<template>
  <aside class="sidebar blog-sidebar mobile-sidebar col-lg-3" sticky-container>
    <div class="sidebar-content" v-sticky="isSticky" sticky-offset="{top: 75}">
      <div class="sidebar-wrapper">
        <div class="sidebar-content">
          <div class="widget mb-5">
            <h4 class="widget-title section-sub-title">
              {{ $t("popularCategories") }}
            </h4>
            <div class="mb-3 pb-1">
              <a
                :href="`/category/${category.slug}`"
                v-for="(category, index) in popularCategories.slice(0, 5)"
                :key="index"
              >
                <span
                  class="badge badge-dark badge-sm text-uppercase pa-2 mr-b"
                  >{{ category.name }}</span
                >
              </a>
            </div>
          </div>

          <div class="widget mb-5" v-if="popularTags.length > 0">
            <h4 class="widget-title section-sub-title">
              {{ $t("PopularTags") }}
            </h4>
            <div class="mb-3 pb-1">
              <a
                :href="`/tag/${tag.slug}`"
                v-for="(tag, index) in popularTags"
                :key="index"
              >
                <span
                  class="badge badge-dark badge-sm text-uppercase pa-2 mr-b"
                  >{{ tag.name }}</span
                >
              </a>
            </div>
          </div>

          <div class="widget mb-5" v-if="popularTags.length > 0">
            <div v-html="settings.banner_300_250"></div>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import Sticky from "vue-sticky-directive";

export default {
  directives: {
    Sticky,
  },
  data: function () {
    return {
      isSticky: false,
      settings: [],
    };
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    popularCategories: {
      type: Array,
    },
    recentPosts: {
      type: Array,
    },
    popularTags: {
      type: Array,
    },
  },
  mounted: function () {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
    this.getWebsiteSettings();
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    resizeHandler: function () {
      this.isSticky = window.innerWidth > 991 ? true : false;
    },
	getWebsiteSettings() {
    this.settings = [];
    this.settings = JSON.parse(localStorage.getItem("settings"));
    console.log(this.settings);
  },
  },
  
};
</script>