var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"innerbanner page-header page-header-lg custom-bg-color-light-1 border-0",style:(_vm.setBackgound)},[_c('div',{staticClass:"container position-relative pt-5 pb-4 mt-5"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"row py-5 mb-5 mt-2 p-relative z-index-1"},[_c('div',{staticClass:"col"},[_vm._m(6),_c('div',{staticClass:"overflow-hidden mb-4"},[_c('h1',{staticClass:"d-block text-color-quaternary font-weight-bold line-height-3 text-center mb-0 appear-animation animated maskUp appear-animation-visible",staticStyle:{"animation-delay":"1000ms"},attrs:{"data-appear-animation":"maskUp","data-appear-animation-delay":"1000"}},[_vm._v(" "+_vm._s(_vm.post.name)+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-wrapper custom-circle-big custom-circle-pos-1 custom-circle-pos-1-1 appear-animation animated expandInWithBlur appear-animation-visible",staticStyle:{"animation-duration":"2s","animation-delay":"900ms"},attrs:{"data-appear-animation":"expandInWithBlur","data-appear-animation-delay":"900","data-appear-animation-duration":"2s"}},[_c('div',{staticClass:"bg-color-tertiary rounded-circle w-100 h-100",staticStyle:{"bottom":"0px","transition":"transform 1000ms ease-out 0ms","transform":"translate3d(0px, -62.1439%, 0px)"},attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'bottom', 'speed': 0.5, 'transition': true, 'transitionDuration': 1000}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-medium custom-circle-pos-2 custom-circle-pos-2-2 appear-animation animated expandInWithBlur appear-animation-visible",staticStyle:{"animation-duration":"2s","animation-delay":"1450ms"},attrs:{"data-appear-animation":"expandInWithBlur","data-appear-animation-delay":"1450","data-appear-animation-duration":"2s"}},[_c('div',{staticClass:"custom-bg-color-grey-2 rounded-circle w-100 h-100",staticStyle:{"bottom":"0px","transition":"transform 2000ms ease-out 0ms","transform":"translate3d(0px, -212.395%, 0px)"},attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'bottom', 'speed': 0.2, 'transition': true, 'transitionDuration': 2000}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-medium custom-circle-pos-3 custom-circle-pos-3-3 appear-animation animated expandIn appear-animation-visible",staticStyle:{"animation-delay":"1300ms"},attrs:{"data-appear-animation":"expandIn","data-appear-animation-delay":"1300"}},[_c('div',{staticClass:"custom-bg-color-grey-2 rounded-circle w-100 h-100",staticStyle:{"bottom":"0px","transition":"transform 1000ms ease-out 0ms","transform":"translate3d(0px, -92.1495%, 0px)"},attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'bottom', 'speed': 0.3, 'transition': true, 'transitionDuration': 1000}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-small custom-circle-pos-4 custom-circle-pos-4-4 appear-animation animated expandIn appear-animation-visible",staticStyle:{"animation-delay":"1600ms"},attrs:{"data-appear-animation":"expandIn","data-appear-animation-delay":"1600"}},[_c('div',{staticClass:"custom-bg-color-grey-2 rounded-circle w-100 h-100",staticStyle:{"bottom":"0px","transition":"transform 500ms ease-out 0ms","transform":"translate3d(0px, -61.4176%, 0px)"},attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'bottom', 'speed': 0.6, 'transition': true, 'transitionDuration': 500}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-medium custom-circle-pos-5 custom-circle-pos-5-5 appear-animation animated expandInWithBlur appear-animation-visible",staticStyle:{"animation-duration":"2s","animation-delay":"1050ms"},attrs:{"data-appear-animation":"expandInWithBlur","data-appear-animation-delay":"1050","data-appear-animation-duration":"2s"}},[_c('div',{staticClass:"bg-color-secondary rounded-circle w-100 h-100",staticStyle:{"top":"0px","transition":"transform 2000ms ease-out 0ms","transform":"translate3d(0px, 130.763%, 0px)"},attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 2000}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-circle custom-circle-medium custom-circle-pos-6 custom-circle-pos-6-6 appear-animation",attrs:{"data-appear-animation":"expandInWithBlur","data-appear-animation-delay":"1200","data-appear-animation-duration":"2s"}},[_c('div',{staticClass:"custom-bg-color-grey-2 rounded-circle w-100 h-100",attrs:{"data-plugin-float-element":"","data-plugin-options":"{'startPos': 'bottom', 'speed': 0.8, 'transition': true, 'transitionDuration': 500}"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overflow-hidden"},[_c('ul',{staticClass:"breadcrumb d-block text-center appear-animation animated maskUp appear-animation-visible",staticStyle:{"animation-delay":"800ms"},attrs:{"data-appear-animation":"maskUp","data-appear-animation-delay":"800"}})])
}]

export { render, staticRenderFns }