<template>
	<footer class="footer bg-dark position-relative">
		<div class="footer-middle">
			<div class="container position-static">
				
				<div class="row">
					<div class="col-lg-12 col-sm-12 pb-2 pb-sm-0">
						<div class="widget">
							<ul class="links">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>								
							</ul>
							
						</div>
					</div>

					
				</div>
			</div>
		</div>

		<div class="container">
			<div class="footer-bottom">
				<div class="container d-sm-flex align-items-center">
					<div class="footer-left">
						<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{ $t('All Rights Reserved.') }}</span>
					</div>

					<div class="footer-right ml-auto mt-1 mt-sm-0">
						<div class="payment-icons">
							<div class="social-icons">
								<a
									:href="settings.facebook"
									class="social-icon social-facebook icon-facebook"
									title="Facebook"
								></a>
								<a
									:href="settings.twitter"
									class="social-icon social-twitter icon-twitter"
									
									title="Twitter"
								></a>
								<a
									:href="settings.instagram"
									class="social-icon social-linkedin fab fa-linkedin-in"
									
									title="Linkedin"
								></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
			
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
     
    },
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
		
	},
};
</script>

<style>

#app .footer{
	background:var(--footer-color)!important;
}
#app .footer-middle {
    padding-top: 1.7rem;
    padding-bottom: 0rem;}
</style>