<template>
  <section
    class="innerbanner page-header page-header-modern bg-color-light-scale-1 page-header-md banner" :style="setBackgound">
    <div class="container">
      <div class="row">
        <div class="col-md-12 align-self-center order-1">
          <ul class="breadcrumb d-block text-center">
            <!-- <li>
              <a href="/">{{ $t("Home") }}</a>
            </li> -->
            <li class="active" v-if="post">{{ post.name }}</li>
            <li class="active" v-if="category">{{ category.name }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    props: {
        post: Object,
        category: Object,
  },
  mounted() {
  },
  data: function () {
    return {
      settings: [],
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {
    setBackgound() {
      return {
        'background-image': 'url(' + this.settings.storageUrl + this.settings.banner_image + ')',
      }
    },
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
}
</script>

<style>
.page-header {
    /* padding: 9rem 0; */
    /* padding: 10.5rem 0 !important; */
    margin: 0px !important;
}

.breadcrumb {
    background: 0 0;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.breadcrumb>li {
    display: inline-block;
    font-size: .8em;
    text-transform: uppercase;
    text-shadow: none;
}

.d-block {
    display: block!important;
}

.innerbanner{
  margin-top: -2rem !important;
}
</style>
