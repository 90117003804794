<template>
	<header class="header">
		<div class="header-top">
			<div class="container">
				<div class="header-left d-none d-sm-block">
					<div class="info-box info-box-icon-left text-primary justify-content-start p-0">
						<i class="icon-shipping"></i>

						<div class="info-box-content">
							<h4>FREE Express Shipping On Orders $99+</h4>
						</div>
					</div>
				</div>

				<div class="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100">
					<div class="header-dropdown ">
						<a href="javascript:;">USD</a>
						<div class="header-menu">
							<ul>
								<li><a href="javascript:;">EUR</a></li>
								<li><a href="javascript:;">USD</a></li>
							</ul>
						</div>
					</div>

					<div class="header-dropdown mr-auto mr-sm-3 mr-md-0 pl-2">
						<a href="javascript:;"><i class="flag-us flag"></i>Eng</a>
						<div class="header-menu">
							<ul>
								<li><a href="javascript:;"><i class="flag-us flag mr-2"></i>ENG</a>
								</li>
								<li><a href="javascript:;"><i class="flag-fr flag mr-2"></i>FRA</a></li>
							</ul>
						</div>
					</div>

					<div class="header-dropdown dropdown-expanded d-none d-lg-block pr-1">
						<a href="javascript:;">Links</a>
						<div class="header-menu">
							<ul>
								<li>
									<router-link to="/pages/contact-us">Contact Us</router-link>
								</li>
								<li>
									<router-link to="/pages/cart">Cart</router-link>
								</li>
								<li>
									<router-link
										to="/pages/login"
										class="login-link"
									>Log In</router-link>
								</li>
							</ul>
						</div>
					</div>

					<div class="social-icons">
						<a
							href="javascript:;"
							class="social-icon social-facebook icon-facebook"
						></a>
						<a
							href="javascript:;"
							class="social-icon social-twitter icon-twitter"
						></a>
						<a
							href="javascript:;"
							class="social-icon social-instagram icon-instagram"
						></a>
					</div>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left col-lg-2 w-auto pl-0">
					<button
						class="mobile-menu-toggler text-primary mr-2"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>
					<router-link
						to="/"
						class="logo"
					>
						<img
							src="@/assets/css/images/home/logo.png"
							width="111"
							height="44"
							class="w-100"
							alt="Porto Logo"
						/>
					</router-link>
				</div>

				<div class="header-right w-lg-max">
					<pv-header-search></pv-header-search>

					<div class="header-contact d-lg-flex pl-4 pr-4">
						
					</div>

					<router-link
						to="/pages/wishlist"
						class="header-icon position-relative"
						title="wishlist"
					>
						<i class="icon-wishlist-2"></i>
						<span class="wishlist-count badge-circle">{{ wishList.length }}</span>
					</router-link>

					<pv-cart-menu></pv-cart-menu>
				</div>
			</div>
		</div>

		<div class="header-bottom d-none d-lg-block">
			<div class="container">
				<nav class="thumbs-nav w-100">
					<ul class="thumbs d-flex align-items-center justify-content-center mb-2 row">
						<li
							class="thumb-item col-1"
							:class="{active: currentCat === 'all'}"
						>
							<router-link :to="{path: '/shop', query: {category: 'all'}}"><i class="fas fa-bars bg-primary"></i>Categories</router-link>
						</li>

						<li
							class="thumb-item col-1"
							v-for="(cat,index) in headerCategories"
							:key="'header-cat-' + index"
							:class="{active: cat.slug === currentCat}"
						>
							<router-link
								:to="{path: '/shop', query: {category: cat.slug}}"
								class="has-preview"
							>
								<span
									class="thumb-info-image fixed-image"
									v-lazy:background-image="cat.image"
								>
								</span>
								{{ cat.name }}
							</router-link>
						</li>

						<li class="thumb-item col-1">
							<router-link
								to="/shop"
								class="has-preview"
							>
								<span class="thumb-info thumb-info-preview">
									<span
										class="thumb-info-image fixed-image"
										v-lazy:background-image="'./images/home/categories/cat10.png'"
									>
									</span>
								</span>
								New Arrivals
							</router-link>
						</li>

						<li class="thumb-item col-1">
							<router-link
								:to="{path: '/shop', query: {}}"
								class="has-preview"
							>
								<span class="thumb-info thumb-info-preview">
									<span
										class="thumb-info-image fixed-image"
										v-lazy:background-image="'./images/home/categories/cat11.jpg'"
									>
									</span>
								</span>
								Sale
							</router-link>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>

<script>
import { mapGetters } from 'vuex';
// import PvMainMenu from './PvMainMenu';
import PvCartMenu from './PvCartMenu';
import PvHeaderSearch from './PvHeaderSearch';
import { headerCategories } from '@/utils/data/menu';

document.querySelector( 'body' ).classList.add( 'loaded' );

export default {
	components: {
		// PvMainMenu,
		PvCartMenu,
		PvHeaderSearch
	},
	data: function () {
		return {
			headerCategories: headerCategories,
			currentCat: ''
		}
	},
	computed: {
		...mapGetters( 'wishlist', [ 'wishList' ] )
	},
	watch: {
		$route: function () {
			this.currentCat = this.$route.query && this.$route.query.category ? this.$route.query.category : '';
		}
	},
	methods: {
		showMobileMenu: function () {
			document.querySelector( 'body' ).classList.add( 'mmenu-active' );
		},
		showMobileSearch: function ( e ) {
			let headerSearch = e.currentTarget.closest( '.header-search' );
			headerSearch.classList.add( 'show' );
			headerSearch
				.querySelector( '.header-search-wrapper' )
				.classList.add( 'show' );
		}
	}
};
</script>