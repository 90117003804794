import axios from 'axios';

export default function setup() {
    axios.interceptors.request.use(function (config) {
        // staging.algeriabusinessinfo.com done
        // staging.marocgazette.com done
        // staging.uaereview.com done
        // staging.algeriatimes.info done
        // staging.dohainsight.com not done
        // staging.beirutbizreport.com done
        // staging.bahrainbiznews.com
        // staging.saudidailyrecord.net done
        // staging.alshaminfo.com done
        // staging.tdailynews.net done
        // staging.q8dailynews.com done
        // staging.egyptcmnews.com done
        // staging.ammannewsdaily.com done
        // staging.iraqdailyjournal.com done
        // staging.tehrantelegram.com done
        // staging.yemennewstraffic.com done
        // staging.paljournal.net done
        // staging.libyabizinfo.com done
        // staging.omanbulletin.com done
        // staging.sdeconews.com

        config.params = { website: location.host , locale: localStorage.getItem("locale") ?? 'en' , lang_code: localStorage.getItem("lang_code") ??  'en_US',website_id: localStorage.getItem("webiste_id") ?? '5'  }

        // config.params = {
        //     website: 'alshaminfo.com',
        //     locale: localStorage.getItem("locale") ?? 'en',
        //     lang_code: localStorage.getItem("lang_code") ?? 'en_US',
        //     website_id: localStorage.getItem("webiste_id") ?? '5',
        //     langSwitch: localStorage.getItem("langSwitch") ?? 'false',
        // }
        
        return config;
    }, function (err) {
        return Promise.reject(err);
    });
}