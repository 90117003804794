<template>
  <!-- <style> -->

  <!-- </style>  -->
  <div @click="hideMobileSearch" >

    <div v-if="settings.status == 'pending' || settings.status == 'draft'">
      <soon :settings="settings">
      </soon>
  </div> 

    <!-- <div v-if="websiteSettings.custom_css" v-bind:style="[websiteSettings.custom_css]"></div> -->
    <div class="page-wrapper" v-else>

      <main :class="'lang-' + getLang() +' id-'+ getWebsite() + ' main home'">
        <!-- <div class="container mt-2"> -->
        <!-- <home-one /> -->
        <template v-for="block in body">
          <component :is="block.component" :block="block" :attributes="block.attributes" :key="block._uid"></component>
        </template>
        <!-- </div> -->
      </main>

    </div>

    <a id="scroll-top" href="javascript:;" title="Top" role="button" @click="scrollToTop">
      <i class="icon-angle-up"></i>
    </a>

    <pv-mobile-menu></pv-mobile-menu>

  </div>
</template>

<script>
import axios from 'axios'
import HeaderOne from "../components/Utility/Header/index";

// by abdulhamid
import HeaderBusinessReview from "../components/Utility/Headers/headerBusinessReview";
import FooterBusinessReview from "../components/Utility/Footer/FooterBusinessReview.vue";
import MainFooter from "../components/Utility/Footer/MainFooter.vue";
import MainHeader from "../components/Utility/Header/MainHeader.vue";
import BreakingNews from "../components/Home/BreakingNews";
import BusinessReviewTopNews from "../components/Home/BusinessReviewTopNews";
import TopNews from "../components/Home/topNews/TopNews.vue";
import NewsList1Col from "../components/Home/newsList/NewsList1Col.vue";
import NewsList2Col from "../components/Home/newsList/NewsList2Col.vue";
import NewsList4Col from "../components/Home/newsList/NewsList4Col.vue";
import BusinessReviewSlider from "../components/Home/sliders/BusinessReviewSlider.vue";
import MainSlider from "../components/Home/sliders/MainSlider.vue";
import PvCarousel40 from "../components/Home/sliders/PvCarousel40.vue";


import Header2 from "../components/Utility/Header2/index";
import Header3 from "../components/Utility/Header3/index";
import Header7 from "../components/Utility/Header7/index";
import Header8 from "../components/Utility/Header8/index";
import Header17 from "../components/Utility/Header17/index";
import Header20 from "../components/Utility/Header20/index";
import Header24 from "../components/Utility/Header24/index";
import Header29 from "../components/Utility/Header29/index";
import Header37 from "../components/Utility/Header37/index";

import FooterOne from "../components/Utility/Footer/FooterOne.vue";
import Footer1 from "../components/Utility/Footer/Footer1.vue";
import Footer2 from "../components/Utility/Footer/Footer2.vue";
import Footer3 from "../components/Utility/Footer/Footer3.vue";
import Footer7 from "../components/Utility/Footer/Footer7.vue";
import Footer8 from "../components/Utility/Footer/Footer8.vue";
import Footer17 from "../components/Utility/Footer/Footer17.vue";
import Footer20 from "../components/Utility/Footer/Footer20.vue";
import Footer24 from "../components/Utility/Footer/Footer24.vue";
import Footer29 from "../components/Utility/Footer/Footer29.vue";
import Footer31 from "../components/Utility/Footer/Footer31.vue";
import Footer28 from "../components/Utility/Footer/Footer28.vue";
import Footer37 from "../components/Utility/Footer/Footer37.vue";
import Footer38 from "../components/Utility/Footer/Footer38.vue";

// Home Page sections
import PvBannerSection2 from "../components/Home/PvBannerSection2.vue";
import PvBannerSection3 from "../components/Home/PvBannerSection3.vue";
import PvBestCollection24 from "../components/Home/PvBestCollection24.vue";

import PvIntroSection7 from '../components/Home/PvIntroSection7';
import PvIntroSection8 from '../components/Home/PvIntroSection8';
import PvIntroSection13 from '../components/Home/PvIntroSection13';
import PvIntroSection20 from "../components/Home/PvIntroSection20.vue";
import PvIntroSection29 from "../components/Home/PvIntroSection29.vue";

import PvFeaturedCollection2 from "../components/Home/PvFeaturedCollection2.vue";
import PvFeaturedCollection7 from "../components/Home/PvFeaturedCollection7.vue";
import PvFeaturedCollection24 from "../components/Home/PvFeaturedCollection24.vue";
import PvFeaturedCollection25 from '../components/Home/PvFeaturedCollection25.vue';

import PvCategorySection3 from "../components/Home/PvCategorySection3.vue";
import PvCategorySection7 from "../components/Home/PvCategorySection7.vue";
import PvCategorySection20 from "../components/Home/PvCategorySection20.vue";
import PvCategorySection34 from "../components/Home/PvCategorySection34.vue";

import PvServiceSection2 from '../components/Home/PvServiceSection2.vue';

import PvWidgetCollection7 from '../components/Home/PvWidgetCollection7';

import PvBlogSection26 from '../components/Home/PvBlogSection26';
import PvBlogSection4 from '../components/Home/PvBlogSection4';

import PvSmallCollection4 from '../components/Home/products/PvSmallCollection4.vue';

import PvCustomCollection from '../components/Home/products/PvCustomCollection.vue';
import PvCustomCollection2 from '../components/Home/products/PvCustomCollection2.vue';
import PvCustomCollection3 from '../components/Home/products/PvCustomCollection3.vue';
import PvCustomCollection4 from '../components/Home/products/PvCustomCollection4.vue';
import PvCustomCollection5 from '../components/Home/products/PvCustomCollection5.vue';


import PvBestCollection25 from '../components/Home/PvBestCollection25'


import dynamic2row from '../components/Home/dynamic2row'
import featuredCategory from '../components/Home/featuredCategory'
import featuredCategory1 from '../components/Home/featuredCategory1'
import featuredCategory2 from '../components/Home/featuredCategory2'
import FullWidth from '../components/Home/FullWidth'
import threeCategorywithSidebar from '../components/Home/threeCategorywithSidebar'



import PostPartHomeOne from "../components/Home/PostPartHomeOne.vue";
import PostGallery from "../components/Home/PostGallery.vue";
import Features from "../components/Home/Features.vue";
import TrendingNews from "../components/Home/TrendingNews.vue";
import SinglePlayPost from "../components/Home/SinglePlayPost.vue";
import VideoNews from "../components/Home/VideoNews.vue";
import EntertainmentNews from "../components/Home/EntertainmentNews.vue";
import SportsNews from "../components/Home/SportsNews.vue";
import BuisnessNews from "../components/Home/BuisnessNews.vue";
import SharePost from "../components/Home/SharePost.vue";
import VideoPopUp from "../components/Utility/VideoPopUp.vue";
import PvMobileMenu from '../components/Utility/Common/PvMobileMenu.vue';
import Vue from 'vue'
import VueGtag from "vue-gtag";
import router from '../router'

import { useFavicon } from '@vueuse/core'
import { computed, ref } from "vue";

import soon from "../components/soon.vue";

import {
  scrollTop,
  stickyHeaderHandler,
  resizeHandler,
  showScrollTopHandler,
  setCookie,
  getCookie
} from '@/utils';

export const adIntegration = () => {
  const adScript = document.createElement('script');

  adScript.type = 'text/javascript';
  adScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  document.head.appendChild(adScript);
};


export default {
  data() {
    return {
      websiteSettings: null,
      body: [],
      settings: [],
    };
  },
  metaInfo() {
    const settings = this.settings;
    if(settings != ''){
      return {
      title: `${(settings.seo_title !== 'undefined') ? settings.seo_title : 'News'}`,
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: `${settings.seo_title}`},
        {name: 'twitter:description', content: `${settings.seo_descripion}`},
        // image must be an absolute path
        {name: 'twitter:image', content: settings.logo},
          // Facebook OpenGraph
        { name: 'description', content: `${settings.seo_title}` },
        { property: 'og:title', content: `${settings.seo_descripion}` },
        { property: 'og:site_name', content: `${settings.seo_title}` },
        { property: 'og:description', content: `${settings.seo_descripion}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: `${settings.logo}` }
      ]
    };
    }
  },
  jsonld() {
    const settings = this.settings;
    if(settings != ''){
      return {
         "@context": "http://schema.org/",
         "@type": "Page",
         "name": settings.seo_title,
         "description": settings.seo_descripion,
         "discussionUrl": settings.name
      }
    }
  },
  async created() {
    await this.getWebsiteSettings();
     this.getLanguage();
     this.getMenu();
     this.favicon();
  },
  mounted() {
    adIntegration();
    setTimeout(() => this.mainPageAd(), 3000);
  },
  methods: {
    mainPageAd(){
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {

        var tags = JSON.parse(this.settings.tag_script);

        window.googletag.destroySlots();

        for(var i=0; i< tags.length; i++){
          window.googletag.defineSlot(''+ tags[i].id +'', [parseInt(tags[i].size1), parseInt(tags[i].size2)], ''+ tags[i].name +'').addService(window.googletag.pubads());
        }

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();

        for(var j=0; j< tags.length; j++){
            window.googletag.display('' +tags[j].name+ '');
        }

      });
    },
    favicon() {
      const type = ref('vueuse')
      const favicon = computed(() =>
        type.value = this.$helpers.getImageFullPath(this.settings.favicon, 'medium')
      )
      console.log(favicon);
      useFavicon(favicon, {
        baseUrl: '',
        rel: 'icon',
      })
    },
    getWebsiteElements() {
      this.body = [];
      this.isLoading = true;
      axios.get('https://api.cnphub.com/public/website')
        .then(res => {
          this.body = res.data.data.elements ? res.data.data.elements : [];
          localStorage.setItem("body", res.data.data.elements ? JSON.stringify(res.data.data.elements) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    hideMobileSearch: function () {
      if (document.querySelector('.header-search.header-search-inline')) {
        let headerSearch = document.querySelector(
          '.header-search.header-search-inline'
        );
        headerSearch.classList.remove('show');
        headerSearch
          .querySelector('.header-search-wrapper')
          .classList.remove('show');
      }
      if (document.querySelector('.search-suggests')) {
        document
          .querySelector('.search-suggests')
          .setAttribute('style', 'display: none');
      }
    },
    scrollToTop: function () {
      scrollTop(false);
    },
    getLanguage: function () {
      if (localStorage.getItem("locale")) {
        this.$i18n.locale = localStorage.getItem("locale");
      } else {
        localStorage.setItem("locale", this.$i18n.locale);
        localStorage.setItem("lang_code", 'en_US');
        localStorage.setItem("lang", 'en');
      }
    },
    getLang: function(){
      return localStorage.getItem("locale");
    },
    getWebsite: function () {
      return this.settings.id;
    },
    getMenu() {
      axios.get('https://api.cnphub.com/public/menu')
        .then(res => {
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
          this.footerMenu = res.data.data.footerMenu ? res.data.data.footerMenu : [];
          localStorage.setItem("mainMenu", res.data.data.mainMenu ? JSON.stringify(res.data.data.mainMenu) : '');
          localStorage.setItem("footerMenu", res.data.data.footerMenu ? JSON.stringify(res.data.data.footerMenu) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    async getWebsiteSettings() {
      this.settings = [];
      axios.get('https://api.cnphub.com/public/settings')
        .then(res => {

          this.websiteSettings = res.data.data.settings ? res.data.data.settings : '';
          localStorage.setItem("settings", res.data.data.settings ? JSON.stringify(res.data.data.settings) : '');
          localStorage.setItem("submenu", res.data.data.submenu ? JSON.stringify(res.data.data.submenu) : '');
          localStorage.setItem("languages", res.data.data.languages ? JSON.stringify(res.data.data.languages) : '');
          this.settings = res.data.data.settings ? res.data.data.settings : [];
          localStorage.setItem("header", this.settings.header ? this.settings.header : 'Header1');
          localStorage.setItem("footer", this.settings.footer ? this.settings.footer : 'Footer1');
          localStorage.setItem("webiste_id", this.settings.id ? this.settings.id : '5');
          this.getWebsiteElements();
          this.addAnalytics();
        })
        .catch(res => {
          console.log(res);
        });


    },

    addAnalytics(){
      Vue.use(VueGtag, {
        config: { id: this.settings.google_analytics }
      }, router);
    }
  },

  components: {
    HeaderOne,
    PostPartHomeOne,
    PostGallery,
    Features,
    TrendingNews,
    SinglePlayPost,
    VideoNews,
    EntertainmentNews,
    SportsNews,
    BuisnessNews,
    SharePost,
    FooterOne,
    VideoPopUp,
    PvMobileMenu,

    HeaderBusinessReview,//by Abdulhamid
    Header2,
    Header3,
    Header7,
    Header8,
    Header17,
    Header20,
    Header24,
    Header29,
    Header37,

    Footer1,
    Footer2,
    Footer3,
    Footer7,
    Footer8,
    Footer17,
    Footer20,
    Footer24,
    Footer28,
    Footer29,
    Footer31,
    Footer37,
    Footer38,

    PvBannerSection2,
    PvBannerSection3,

    PvIntroSection7,
    PvIntroSection8,
    PvIntroSection13,
    PvIntroSection20,
    PvIntroSection29,

    PvFeaturedCollection2,
    PvFeaturedCollection7,
    PvFeaturedCollection24,
    PvFeaturedCollection25,

    PvCategorySection3,
    PvCategorySection7,
    PvCategorySection20,
    PvCategorySection34,

    PvWidgetCollection7,

    PvSmallCollection4,

    PvCustomCollection,
    PvCustomCollection2,
    PvCustomCollection3,
    PvCustomCollection4,
    PvCustomCollection5,

    PvBestCollection24,
    PvBestCollection25,

    PvBlogSection26,
    PvBlogSection4,

    dynamic2row,
    featuredCategory,
    featuredCategory1,
    featuredCategory2,
    FullWidth,
    threeCategorywithSidebar,

    PvServiceSection2,
    BreakingNews,//by Abdulhamid
    BusinessReviewSlider,//by Abdulhamid
    MainSlider,//by Abdulhamid
    BusinessReviewTopNews,//by Abdulhamid
    PvCarousel40,//by Abdulhamid
    NewsList4Col,//by Abdulhamid
    FooterBusinessReview,//by Abdulhamid
    TopNews,//by Abdulhamid
    NewsList2Col,//by Abdulhamid
    NewsList1Col,//by Abdulhamid
    MainFooter,//by Abdulhamid
    MainHeader,//by Abdulhamid

    soon,
  },
};
</script>

<style scoped ></style>