var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer font2"},[_c('div',{staticClass:"footer-middle"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-lg-3"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("CUSTOMER SERVICE")]),_c('div',{staticClass:"widget-content"},[_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('router-link',{attrs:{"to":"/pages/login"}},[_vm._v("Login")])],1)])])])]),_c('div',{staticClass:"col-sm-6 col-lg-3"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v(_vm._s(_vm.$t("About Us")))]),_c('div',{staticClass:"widget-content"},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/pages/about-us"}},[_vm._v(_vm._s(_vm.$t("About Us")))])],1),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])]),_vm._m(9),_vm._m(10)])])]),_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container d-sm-flex align-items-center"},[_c('div',{staticClass:"footer-left"},[_c('span',{staticClass:"footer-copyright"},[_vm._v("© "+_vm._s(_vm.$t('copyright'))+" 2023. "+_vm._s(_vm.$t('All Rights Reserved.')))])]),_c('div',{staticClass:"footer-right ml-auto mt-1 mt-sm-0"},[_c('div',{staticClass:"payment-icons"},[_c('span',{staticClass:"payment-icon visa",style:({'background-image': 'url(./images/payments/payment-visa.svg)'})}),_c('span',{staticClass:"payment-icon paypal",style:({'background-image': 'url(./images/payments/payment-paypal.svg)'})}),_c('span',{staticClass:"payment-icon stripe",style:({'background-image': 'url(./images/payments/payment-stripe.png)'})}),_c('span',{staticClass:"payment-icon verisign",style:({'background-image':  'url(./images/payments/payment-verisign.svg)'})})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Help & FAQs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Order Tracking")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Shipping & Delivery")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Orders History")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Advanced Search")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Careers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Our Stores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Corporate Sales")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Careers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-3"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("More Information")]),_c('div',{staticClass:"widget-content"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Affiliates")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Refer a Friend")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Student Beans Offers")])]),_c('li',[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("Gift Vouchers")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6 col-lg-3"},[_c('div',{staticClass:"widget"},[_c('h3',{staticClass:"widget-title"},[_vm._v("Social Media")]),_c('div',{staticClass:"widget-content"},[_c('div',{staticClass:"social-icons"},[_c('a',{staticClass:"social-icon",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"fab fa-facebook-f"})]),_c('a',{staticClass:"social-icon",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"fab fa-twitter"})]),_c('a',{staticClass:"social-icon",attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"icon-instagram"})])])])])])
}]

export { render, staticRenderFns }