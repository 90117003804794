<template>
	<div
		class="home-top-container appear-animate"
		data-animation-name="fadeIn"
		data-animation-delay="100"
		v-animate
	>
		<div class="container">
			<div class="row">
				<div class="col-lg-6 mb-2" v-if="posts.length > 0">
					<div class="home-banner banner banner1 banner-md-vw banner-sm-vw d-flex align-items-center">
						<figure class="w-100">
							<img
								:src="getImage(posts[0].image, 'medium')"
								style="background: #3f4c5c"
								alt="banner"
								width="565"
								height="500"
								image-ratio="88.49"
							/>
						</figure>

						<div class="banner-layer">
							<h2 class=" text-white mb-0">{{ posts[0].name }}</h2>
							<h5 class="text-white text-uppercase ls-n-20 align-text-bottom">
								<b class="coupon-sale-text bg-secondary text-white d-inline-block">
									<a
										:href="'/article/' + posts[0].slug"
										class="btn btn-dark btn-black ls-10"
									>{{ $t("Read More") }}</a>
								</b></h5>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-sm-6 mb-2" v-if="posts.length > 1">
					<div class="row h-100">
						<div class="col-lg-12 mb-2">
							<div class="banner banner2 banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										:src="getImage(posts[1].image, 'medium')"
										alt="banner"
										width="273"
										height="240"
										style="background: #f4f4f4"
										image-ratio="100"
									/>
								</figure>
								<div class="banner-layer banner-layer-middle text-center">
									<h3 class="m-b-1 ls-10">{{ posts[1].name }}</h3>
									<a
										:href="'/article/' + posts[1].slug"
										class="btn btn-dark btn-black ls-10"
									>{{ $t("Read More") }}</a>
								</div>
							</div>
						</div>

						<div class="col-lg-12" v-if="posts.length > 2">
							<div class="banner banner3 text-uppercase banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										:src="getImage(posts[2].image, 'medium')"
										alt="banner"
										width="273"
										height="240"
										style="background: #fff"
										image-ratio="100"
									/>
								</figure>
								<div class="banner-layer banner-layer-middle text-center">
									<h3 class="m-b-1 ls-10">{{ posts[2].name }}</h3>
									<a
										:href="'/article/' + posts[2].slug"
										class="btn btn-dark btn-black ls-10"
									>{{ $t("Read More") }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-sm-6 mb-2" v-if="posts.length > 3">
					<div class="row h-100">
						<div class="col-lg-12 mb-2">
							<div class="banner banner2 banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										:src="getImage(posts[3].image, 'medium')"
										alt="banner"
										width="273"
										height="240"
										style="background: #f4f4f4"
										image-ratio="100"
									/>
								</figure>
								<div class="banner-layer banner-layer-middle text-center">
									<h3 class="m-b-1 ls-10">{{ posts[3].name }}</h3>
									<a
										:href="'/article/' + posts[3].slug"
										class="btn btn-dark btn-black ls-10"
									>{{ $t("Read More") }}</a>
								</div>
							</div>
						</div>

						<div class="col-lg-12" v-if="posts.length > 4">
							<div class="banner banner3 text-uppercase banner-hover-shadow d-flex align-items-center">
								<figure class="w-100">
									<img
										:src="getImage(posts[4].image, 'medium')"
										alt="banner"
										width="273"
										height="240"
										style="background: #fff"
										image-ratio="100"
									/>
								</figure>
								<div class="banner-layer banner-layer-middle text-center">
									<h3 class="m-b-1 ls-10">{{ posts[4].name }}</h3>
									<a
										:href="'/article/' + posts[4].slug"
										class="btn btn-dark btn-black ls-10"
									>{{ $t("Read More") }}</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data: function() {
	},
	props: {
		posts: Array,
	},
	mounted() {
    },
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
	},
};
</script>

<style>
	.id-25 .banner-layer h3{
		background: rgba(234, 99, 0, .7);
		padding: 10px 10px;
	}
</style>