<template>
  <section class="home-section mb-2 ">

    <div class="container theme11">

      <div class="row ">

        <div class="col-md-3   mb-20 sub-list-col-2">
          <div class="article" v-for="(post, index) in getShortList(3, 7)" :key="index">
            <div v-if="index == 0">

              <span class="slider-cat ">{{
                post.cats[0].name
              }}
              </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
              <p v-html="getPostDescription(post.content, 150)"></p>
            </div>
            <div v-if="index > 0" class="small-article">
              <span v-if="post.cats.length > 0" class="slider-cat ">{{
                post.cats[0].name
              }}
              </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
            </div>

          </div>


        </div>
        <div class="col-md-6 h-100 col-xl-6 col-lg-6 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider  slide-animate show-nav-hover dot-inside nav-big  text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in posts" :key="index">

              <div class="row">
                <div class="col-md-12">
                  <h3 class=" appear-animate " data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                    v-animate>
                    <a class="slider-title" :href="'/article/' + post.slug">{{ post.name }}</a>
                  </h3>
                  <!-- <div v-if="post.subtitle" class=" appear-animate" v-html="getPostDescription(post.subtitle, 90)"></div> -->
                </div>
                <div class="col-md-12">
                  <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
                </div>
                <div class="col-md-12">
                  


                 
                  <!-- <p class="  appear-animate" v-else>{{ getPostDescription(post.content, 90) }}</p> -->

                </div>
              </div>

              <!-- <h6 v-if="post.created_at" class=" slider-date appear-animate" data-animation-name="fadeInDownShorter"
                data-animation-delay="100" v-animate>{{
                  getPostDate(post.created_at)
                }}
              </h6> -->
              <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->

            </div>


          </PvCarousel40>


        </div>

        <div class="col-md-3   mb-20 sub-list-col-2">
          <div class="article" v-for="(post, index) in getShortList(7, 11)" :key="index">
            <div v-if="index == 0">

              <span class="slider-cat ">{{
                post.cats[0].name
              }}
              </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
              <p v-html="getPostDescription(post.content, 150)"></p>
            </div>
            <div v-if="index > 0" class="small-article">
              <span v-if="post.cats.length > 0" class="slider-cat ">{{
                post.cats[0].name
              }}
              </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
            </div>

          </div>


        </div>
        <div class="row">
          <div class="col-md-3   mb-20 sub-list-col-1" v-for="(post, index) in getShortList(1, 5)" :key="index">
            <div class="article">
              <div>
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
                <span class="slider-cat ">{{
                  post.cats[0].name
                }}
                </span>
                <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
                <!-- <p>{{ getPostDescription(post.content, 150) }}</p> -->
              </div>

            </div>


          </div>
        </div>



      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from '../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {

      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  created() {
    console.log('this.posts', this.posts);


  },
  mounted() {
    // this.getSliderNews();

  },
  methods: {
    getPosts() {
      return this.posts;
    },
    getShortList(start, shortListSize) {
      if (this.posts)
        return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));




    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1); 
    //       }


    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;



    // },
  },
}
</script>

<style>
.theme11 .home-slider img {
  height: auto;

}

.container.theme11 {
  margin-top: 20px;
}

.theme11 .slider-cat {
  background: var(--color);
  color: #fff;
  padding: 5px;
  display: block;
  margin-bottom: 5px;;
}

.theme11 .sub-list-col-2 {
  background: transparent;
  border: 1px solid #f2f2f2;
}

.theme11 .swiper-carousel .swiper-dots.disabled {
  display: block;
}

.text-center {
  text-align: center;
}

.theme11 .home-slider .home-slide1 h3 {
    margin-bottom: 0.8rem;
    font-size: 1.8em;
    padding: 5px;
    padding-top: 0px;
}

.theme11 .home-slider .home-slide1 .slider-title {
  color: #333;

  text-align: center;

}

.theme11 .home-slider .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: #d3d3d3;
  opacity: 1;
}

.theme11 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 50%;
  bottom: 0.1rem;
  text-align: center;
  transform: translateX(-50%);
  max-width: 180px;
}

.theme11 .swiper-theme .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
}

.theme11 .c-hub-title {
  overflow: hidden;
  position: relative;
  text-align: center;
  overflow: visible;
  margin-bottom: 10px;
}

.theme11 .c-hub-title.no-description:before {

  border-top: 6px solid var(--color);
  content: " ";
  height: 50px;
  left: -16px;
  position: absolute;
  right: -16px;
  top: 50%;
  pointer-events: none;
}

.theme11 .c-hub-title__inner {
  background-color: var(--color);
  color: #fff;

  display: inline-block;
  padding: 32px 26px 16px;
  position: relative;
  padding: 16px;
  font-weight: 700;
}

.theme11 .home-slider {}

.theme11 .home-slider {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
}

.theme11 .home-slider .slider-title {
  color: #000;
}

.sub-list-col-1 h4 a,
.sub-list-col-2 h4 a {
  color: #000;
}

.theme11.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

.theme11 .sub-list-col-1 .slider-cat {
  margin-top: 0px;
  display: inline-block;
  position: absolute;
  top: 0;

}

.theme11 .sub-list-col-2 .small-article {
  border-top: 2px solid var(--color);
}

.theme11 .sub-list-col-1 .small-article {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #f2f2f2;

}



.theme11 .small-article h4 {
  line-height: 1em;
}

.theme11 .small-article h4 a {
  font-size: 0.75em;
  font-weight: bold;
  color: #333;


}

.theme11 .home-slider .slider-date,
.theme11 .sub-list-col-1 .slider-date,
.theme11 .sub-list-col-2 .slider-date {
  width: 160px;
  padding: 10px;
  background: #fff;
  margin-top: -33px;
  display: block;
  position: absolute;
}

.theme11 .swiper-wrapper {
  margin-bottom: 30px;

}

.theme2.top-6-articles .article {}

.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 2.4em;
}
</style>