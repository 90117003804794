<template>
  <div class="container">    
    <div class="row">
      <div class="col-lg-6">
        <div class="row mt-4">
        <div class="col-lg-12 text-center appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="400"
          style="animation-delay: 400ms">
          <h2 class="font-weight-normal text-6 mt-3 mb-5"><strong class="font-weight-extra-bold">{{ cat1name }}</strong>
          </h2>
        </div></div>
        <div class="blog-posts">
          <article
            class="post post-medium"
            v-for="(post, index) in cat1Posts"
            :key="index"
          >
            <div class="row mb-3">
              <div class="col-lg-5">
                <div class="post-image">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'default')"
                      class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="post-content">
                  <h2
                    class="font-weight-semibold pt-4 pt-lg-0 text-5 line-height-4 mb-2"
                  >
                    <a :href="'/article/' + post.slug">{{
                      getPostDescription(post.name, 80)
                    }}</a>
                  </h2>
                  <p class="mb-0" v-html="getPostDescription(post.content, 400) ">
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="post-meta">
                  <span
                    ><i class="far fa-calendar-alt"></i>
                    {{ getPostDate(post.created_at) }}
                  </span>
                  <span
                    ><i class="far fa-folder"></i>{{ cat1name }},
                  </span>
                  <span
                    class="d-block d-sm-inline-block float-sm-end mt-3 mt-sm-0"
                    ><a
                      :href="'/article/' + post.slug"
                      class="btn btn-xs btn-light text-1 text-uppercase"
                      >{{ $t("Read More") }}</a
                    ></span
                  >
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="row mt-4">
        <div class="col-lg-12 text-center appear-animation animated fadeInUpShorter appear-animation-visible"
          data-appear-animation="fadeInUpShorter"
          data-appear-animation-delay="400"
          style="animation-delay: 400ms">
          <h2 class="font-weight-normal text-6 mt-3 mb-5"><strong class="font-weight-extra-bold">{{ cat2name }}</strong>
          </h2>
        </div></div>
        <div class="blog-posts">
          <article
            class="post post-medium"
            v-for="(post, index) in cat2Posts"
            :key="index"
          >
            <div class="row mb-3">
              <div class="col-lg-5">
                <div class="post-image">
                  <a :href="'/article/' + post.slug">
                    <img v-if="post.image"
                      :src="getImage(post.image, 'medium')"
                      class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                      :alt="post.name"
                    />
                    <img v-else
                      :src="getImage('banners/image.jpg', 'default')"
                      class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                      :alt="post.name"
                    />
                  </a>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="post-content">
                  <h2
                    class="font-weight-semibold pt-4 pt-lg-0 text-5 line-height-4 mb-2"
                  >
                    <a :href="'/article/' + post.slug">{{
                      getPostDescription(post.name, 80)
                    }}</a>
                  </h2>
                  <p class="mb-0" v-html="getPostDescription(post.content, 500) ">
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="post-meta">
                  <span
                    ><i class="far fa-calendar-alt"></i>
                    {{ getPostDate(post.created_at) }}
                  </span>
                  <span
                    ><i class="far fa-folder"></i>{{ cat2name }},
                  </span>
                  <span
                    class="d-block d-sm-inline-block float-sm-end mt-3 mt-sm-0"
                    ><a
                      :href="'/article/' + post.slug"
                      class="btn btn-xs btn-light text-1 text-uppercase"
                      >{{ $t("Read More") }}</a
                    ></span
                  >
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>
	
	
	<script>
import axios from "axios";

export default {
  components: {},
  data: function () {
    return {
      cat1name: '',
      cat2name: '',
      cat3name: '',
      cat1Posts: [],cat2Posts: [],
    };
  },
  props: {
    attributes: {
      type: Array,
      limit: Number,
    },
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text , length) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    getPosts() {
      axios
        .get(
          "https://api.cnphub.com/public/twoCategoryData" +
            "?limit=" +
            this.attributes.limit +
            "&cat1=" +
            this.attributes.cat1 +
            "&cat2=" +
            this.attributes.cat2,
          {}
        )
        .then((res) => {
          this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
          this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : "";

          this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
          this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : "";
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>
	
	
<style>
.post-content h2 {
  font-size: 16px;
}

h2 a {
  color: var(--color);
}

.post-meta span i {
  color: var(--color);
}

.post a:hover,
.post a:focus {
  color: var(--color);
}

.post-meta span a:hover,
.post-meta span a:focus {
  /* background-color: var(--color); */
  color: #fff;
}

h4.post-title {
    text-align: left;
}

</style>