<template>
  <section class="home-section mb-2">
    <div class="container ">
      <div class="row">
        <div class="col-md-12 col-xl-8 col-lg-12 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in mainSliderArticle"
              :key="index">
              <img  class="slide-bg" :src="getImage(post.image, 'featured')" alt="slider image"
                style="background: #c0e1f2">
              <div class="container d-flex align-items-center bg-black-trans">
                <div class="banner-layer d-flex flex-column">
                  <h6 v-if="post.created_at" class="text-white text-transform-none appear-animate" data-animation-name="fadeInDownShorter"
                    data-animation-delay="100" v-animate>{{ getPostDate(post.created_at) }} 
                  </h6>
                  <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->
                  <a :href="'/article/' + post.slug"><h3 class=" appear-animate text-white" data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                    v-animate>{{ post.name }}</h3></a>
                  <!-- <span class="custom-font text-transform-none appear-animate" data-animation-name="fadeInRightShorter"
                    data-animation-delay="1100" v-animate><span>Extra!</span></span> -->
                  <h5 class=" appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="1400"
                    v-animate >
                    </h5>
                    <div class="text-white appear-animate" v-html="post.subtitle"></div>
                  <!-- <h4 class="d-inline-block appear-animate" data-animation-name="fadeInRightShorter"
                    data-animation-delay="1800" v-animate>
                    <sup>UP TO</sup>
                    <b class="coupon-sale-text ls-10 text-white align-middle">50%</b>
                  </h4> -->
                </div>
              </div>
            </div>


          </PvCarousel40>
        </div>

        <div class="col-md-12 col-xl-4 col-lg-12 d-sm-flex d-xl-block">
          <div class="banner banner2 mb-0 pr-sm-3 pr-0 pr-xl-0">
            <img class="slide-bg" :src="getImage(article1.image, 'medium')" alt="slider image"
              style="background: #d9e2e1">
            <div class="container d-flex align-items-center bg-black-trans">
              <div class="banner-layer d-flex flex-column pt-0">
                <h6 class="text-transform-none mb-1 appear-animate text-white" data-animation-name="fadeInDownShorter"
                  data-animation-delay="100" v-animate>{{ getPostDate(article1.created_at) }}
                </h6>
                <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                  data-animation-delay="600" v-animate>Organic Coffee</h2> -->
                <h3 class=" appear-animate text-white" data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                  v-animate>{{ article1.name }}</h3>
                <!-- <span class="custom-font text-transform-none appear-animate" data-animation-name="fadeInRightShorter"
                  data-animation-delay="1100" v-animate><span>Fresh!</span></span> -->
              </div>
            </div>
          </div>

          <div class="banner banner2 pl-sm-3 pl-0 pl-xl-0">
            <img class="banner2slide-bg" :src="getImage(article2.image, 'medium')" alt="slider image"
              style="background: #f4d1be">
            <div class="container d-flex align-items-center bg-black-trans">
              <div class="banner-layer d-flex flex-column">
                <h6 class="text-transform-none mb-1 appear-animate text-white" data-animation-name="fadeInDownShorter"
                  data-animation-delay="100" v-animate>{{ getPostDate(article2.created_at) }}
                </h6>
                <!-- <h2 class="text-transform-none text-white ml-0 appear-animate" data-animation-name="fadeInUpShorter"
                  data-animation-delay="400" v-animate>Low Carb</h2> -->
                <h3 class=" text-white appear-animate" data-animation-name="fadeInUpShorter" data-animation-delay="800"
                  v-animate>{{ article2.name }}</h3>
                <!-- <span class="custom-font text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                  data-animation-delay="1200" v-animate><span
                    class="text-white text-transform-none">Sugar-Free</span></span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from './../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {
    attributes:  {
      hide_images: Boolean,
      hide_news_sidebar: Boolean,
    },
  },
  data: function () {
    return {
      posts: null,
      mainSliderArticle: [],
      article1: null,
      article2: null,
      
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  mounted() {
    this.getSliderNews();

  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getSliderNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));

      axios.get(this.settings.BaseUrl + '/public/slider-news')
        .then(res => {

          this.posts = res.data.data.posts ? res.data.data.posts : '';
          console.log('posts', this.posts);
          if (this.posts.length > 2) {
            this.article1 = this.posts[0];
            this.article2 = this.posts[1];
            this.posts.shift(1);  this.posts.shift(1); 
          }
       
          
          if (this.posts.length > 0)
            this.mainSliderArticle = this.posts;
          

        })
        .catch(res => {
          console.log(res);
        });
    },
  },
}
</script>

<style>
.home-section .bg-black-trans{
  background: rgba(0,0,0,0.3);
}
.home-section .banner2 .slide-bg{
  max-height: 23vh;
}
.home-section .banner2 .banner-layer {
    top: auto !important;
    right: 9.5%;
    margin-top: 0px;
    bottom: 10px!important;
}
.home-section .banner2 h3{
  font-size: 1.2em;
}
.home-section .home-slide1 h3 {
    margin-bottom: 0.8rem;
    font-size: 2.4em;
}
</style>