<template>
	<div>
		<div class="container banner banner2">
			<div class="row no-gutters m-0">

				<div v-for="(post, index) in cat1Posts" :key="index"
					:class="'col-lg-'+row1class+' parallax-bg'"
					data-parallax="{'speed': 3, 'enableOnMobile': true}"
					:src="getImage(post.image, 'medium')"
					v-parallax
					style="background-color: #f4f4f2"
				>
					<div class="dot-image">
						<img
							:src="getImage(post.image, 'medium')"
							alt="dots"
							width="123"
							height="126"
						>
					</div>
				</div>
				
			</div>
		</div>

		<div class="container banner banner3">
			<div class="row no-gutters m-0">

				<div  v-for="(post, index) in cat2Posts" :key="index"
					:class="'col-lg-'+row2class+' parallax-bg order-lg-last'"
					data-parallax="{'speed': 3, 'enableOnMobile': true}"
					:src="getImage(post.image, 'medium')"
					v-parallax
					style="background-color: #f5dee4"
				>
					<div class="dot-image">
						<img
							:src="getImage(post.image, 'medium')"
							alt="dots"
							width="123"
							height="126"
						>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>


<script>
import axios from 'axios'

export default {
	components: {
	},
	data: function() {
		return {
			row1count : '',
			row1class : '',
			row2count : '',
			row2class : '',
			cat1Posts : [],
			cat2Posts : []
		};
	},
	props: {
		attributes:  {
		type: Array,
		limit:Number,
		},
	},
	mounted() {
		var row1 = this.attributes.row1.split(" ");
		var row2 = this.attributes.row2.split(" ");
		this.row1class = row1[0];
		this.row1count = row1[1];
		this.row2class = row2[0];
		this.row2count = row2[1];
		this.getPosts();
    },
	methods: {
		getImage(url, size) {
			return 'https://cnphub.menaws.com/storage/press-release/Post-102/from-l-r-ishwar-sridharan-co-founder-and-chief-operating-officer-coo-exotel-shivakumar-ganesan-co-founder-and-chief-executive-officer-ceo-exotel-and-sachin-bhatia-co-founder-and-chief-growth-officer-cgo-540x360.jpg'
		},
		getPosts() {
			axios
				.get("https://api.cnphub.com/public/twoCategoryData" + '?limit=' + this.row1count + '&cat1=' + this.attributes.cat1 + '&cat2=' + this.attributes.cat2, {
				})
				.then((res) => {
					this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
					this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : '';
				})
				.catch((res) => {
				console.log(res);
				});

			axios
				.get("https://api.cnphub.com/public/twoCategoryData" + '?limit=' + this.row2count + '&cat1=' + this.attributes.cat1 + '&cat2=' + this.attributes.cat2, {
				})
				.then((res) => {
					this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
					this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : '';
				})
				.catch((res) => {
					console.log(res);
				});
		},
	},
};
</script>


<style>

.no-gutters .col-lg-6 , .no-gutters .col-lg-4 , .no-gutters .col-lg-3 , .no-gutters .col-lg-2{
	padding: 20px !important;
}

</style>