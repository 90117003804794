<template>
	<header class="header theme2 header-2 mb-2" :style="cssVars">
		
		<div class="container">
			<div class="header-top d-flex align-items-center w-100">
				<div class="header-left">
					<p class="top-message ls-0 text-uppercase text-white d-none d-sm-block">{{ settings.about }}</p>
				</div>

				<div class="header-right header-dropdowns w-sm-100">
					<div class="header-dropdown dropdown-expanded  ">
						<a href="javascript:;">{{ $t('Links') }}</a>
						<div class="header-menu">
							<ul>
								<li>
									<router-link to="/contact">{{ $t('Contact Us') }}</router-link>
								</li>
								<li>
									<router-link to="/about">{{ $t('About Us') }}</router-link>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator"></span>
					<div class="header-dropdown   dropdown-language mr-auto mr-sm-3 mr-md-0">
						<div v-for="(language, index) in languages" :key="index">
							<a href="javascript:;"  v-if="language.lang_locale==$i18n.locale">
							<i :class="'flag-' + language.lang_flag + ' flag'"></i>{{ language.lang_name }} </a>
						</div>
						

						<div class="header-menu">
							<ul>
								<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">
									<a href="javascript:;">
										<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
	language.lang_name
										}}</a>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator"></span>

					<div class="social-icons">
						<a :href="settings.facebook" class="social-icon social-facebook icon-facebook"></a>
						<a :href="settings.twitter" class="social-icon social-twitter icon-twitter"></a>
						<a :href="settings.instagram" class="social-icon social-instagram icon-instagram"></a>
					</div>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header mobile-sticky">
			<div class="container">
				<div class="header-left col-lg-3 w-auto pl-0">
					<button class="mobile-menu-toggler mr-2" type="button" @click="showMobileMenu">
						<i class="fas fa-bars"></i>
					</button>
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="250" height="40" :alt="settings.webiste" />
					</router-link>
				</div>

				<div class="header-right w-lg-max ml-0">
					<pv-header-search class="pl-3 pr-1"></pv-header-search>

					<div class="header-contact d-none d-lg-flex pl-4 ml-3 mr-xl-5">
						<img alt="phone" src="@/assets/css/images/phone-white.png" width="30" height="30"
							class="pb-1" />
						<h6>{{ $t('Call us now') }}<a href="tel:#" class="font1">{{ settings.phone }}</a>
						</h6>
					</div>


				</div>
			</div>
		</div>

		<div class="header-bottom sticky-header desktop-sticky d-none d-lg-block">
			<div class="container">
				<div class="header-left">
					<router-link to="/" class="logo">
						<img :src="settings.logo" width="101" height="40" />
					</router-link>
				</div>
				<div class="header-center">
					<pv-main-menu></pv-main-menu>
				</div>
				<div class="header-right">
					<pv-header-search></pv-header-search>

					<a href="javascript:;" class="header-icon header-icon-user" title="login">
						<i class="icon-user-2"></i>
					</a>

					<router-link to="/pages/wishlist" class="header-icon" title="wishlist">
						<i class="icon-wishlist-2"></i>
					</router-link>

				</div>
			</div>
		</div>
	</header>
</template>

<script>

import PvMainMenu from "./PvMainMenu.vue";
import PvHeaderSearch from "./PvHeaderSearch.vue";
document.querySelector('body').classList.add('loaded');


// import TopHeaderMenu from './TopHeaderMenu.vue';



document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		// TopHeaderMenu,
		PvHeaderSearch
	},
	computed: {
		cssVars() {
			return {
				'background-color': this.settings.primary_color,
			}
		},
		TopHewaderCssVars() {
			return {
				'background-color': this.settings.header_color,
			}
		},
	},
	data: function () {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			settings: '',
			submenu: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	watch: {
		'$i18n.locale': function (newVal, oldVal) {
			localStorage.setItem('locale', newVal)
		}
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
		
			this.settings = JSON.parse(localStorage.getItem("settings"));
			
			this.languages = JSON.parse(localStorage.getItem("languages"));
			
		},
		showMobileMenu: function () {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function (e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("langSwitch", true);
			localStorage.setItem("lang_code", language.lang_code);
			window.location.reload();
		},

	}
};
</script>

<style>
.top-news-posts.theme2 .section-sub-title,.news-List-2-col.theme2 .section-sub-title {
    color: #222529;
    font-weight: 700;
    border: 0px;
    font-size: 22px!important;
    border-radius: 0px;
    padding: 5px;
    border-bottom: 5px solid var(--color);
    background: #fff;
}

#app .header.header-2 .logo {
	max-width: 250px;
	padding-right: 15px;
}

#app .header.header-2 .header-top {
	padding-top: 7px;
	padding-bottom: 7px;
	border-bottom: 1px solid #ffff;
}

#app .header.header-2 .header-search-category .form-control {
	border-radius: 0rem 0 0 0rem;
}

#app .header.header-2 .main-nav .menu>li>a:hover {
	color: var(--color);
}
#app .header.header-2 .header-contact h6 {
    margin-top: 2px;
    color: #fff;
}
#app .header.header-2  .separator {
    height: 20px;
    border-left: 1px solid #fff;
    margin: 0 15px;
}
  @media (max-width: 767px){

#app .theme2 .mobile-menu-toggler {

  color: #fff !important;

}
#app .theme2  .header-dropdown.dropdown-expanded  li + li {
  margin-left: 0.4rem;
  letter-spacing: 0.025em;
}


}
</style>