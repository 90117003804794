<template>
  <section class="home-section mb-2">
    <div class="slider theme4">
      <div class="row">
        <div class="col-md-3 col-12   top-6-articles " v-for="(post, index) in posts" :key="index">

          <div class="banner banner2  pr-sm-3 pr-0 pr-xl-0">
            
            <img class="slide-bg" :src="getImage(post.image, 'medium')" alt="slider image" style="background: #d9e2e1">
            <div class="container d-flex align-items-center bg-black-trans">
              <div class="banner-layer d-flex flex-column pt-0">


                <h3 class=" appear-animate text-white" data-animation-name="fadeInRightShorter"
                  data-animation-delay="1100" v-animate><a class="text-white" :href="'/article/' + post.slug">{{
                    post.name
                  }}</a></h3>
                

              </div>
              <h6 class="text-transform-none mb-1 appear-animate text-grey floating-date" data-animation-name="fadeInDownShorter"
                  data-animation-delay="100" v-animate>{{ getPostDate(post.created_at) }}
                </h6>
            </div>
           
          </div>


        </div><!-- Small Post Wrapper End -->





      </div>
    </div>
  </section>
</template>

<script>
// import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from '../../../helpers/image-helper'

export default {
  components: {
    // PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {

      mainSliderArticle: [],
      articlesList: null,


      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  mounted() {
    // this.getSliderNews();

  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    }
  }

}
</script>

<style>
.home-slider .theme4 {
  border: 4px solid var(--color);
  min-height: 50rem;
  max-height: 50rem;
}

.home-slider .theme4 {
  margin-top: 30px;
  margin-bottom: 0px;
  padding: 3px;
}

.theme4 .top-6-articles {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 2;
  padding-left: 2px;
  padding-right: 2px
}

.home-slider.theme4 .slide-bg {
  min-height: 50vh;
}

.slider .theme4 h3 a {
  margin-top: 10px;
  background: #fff;
  color: var(--color);
  width: 110px;
  text-align: center;
  padding: 5px 12px;
  font-size: 13px;
}
.slider.theme4 .floating-date {
    position: absolute;
    top: 4px;
    left: 5px;
    padding: 4px 8px;
    background: var(--secondary-color);
}
.theme2.top-6-articles .article {}

.home-slider.theme4 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-section .theme4 .banner2 .slide-bg {
  max-height: 33vh;
}

.home-slider.theme4 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme4 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .theme4 .banner2 h3 {
  font-size: 1.0em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 0.8rem;
  /* font-size: 2.4em; */
}
</style>