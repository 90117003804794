<template>
  <header class="header theme18 mb-2">
    <div class="header-top">
      <div class="container">
        <div class="header-left"></div>

        <div class="header-right">
          <top-header-menu></top-header-menu>

          <span class="separator d-none d-lg-block"></span>

          <div class="social-icons d-flex align-items-center">
            <a
              v-if="settings.facebook"
              :href="settings.facebook"
              class="social-icon social-facebook icon-facebook"
              rel="nofollow"
              title="Facebook"
            ></a>
            <a
              v-if="settings.twitter"
              :href="settings.twitter"
              class="social-icon social-twitter icon-twitter"
              rel="nofollow"
              title="Twitter"
            ></a>
            <a
              v-if="settings.instagram"
              :href="settings.instagram"
              class="social-icon social-instagram icon-instagram"
              rel="nofollow"
              title="Instagram"
            ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="header-middle sticky-header d-flex w-100">
        <div class="container">
          <div class="header-left">
            <button
              class="mobile-menu-toggler text-white mr-2"
              type="button"
              @click="showMobileMenu"
            >
              <i class="fas fa-bars"></i>
            </button>
            <a href="/" class="logo">
              <img :src="settings.logo" width="200" height="40" alt="" />
            </a>
          </div>

          <div
            class="header-center flex-1 ml-lg-0 justify-content-end justify-content-lg-start w-lg-max"
          >
            <img
              class="banner_image"
              :src="getImage(settings.banner_image)"
              width="60%"
              height="auto"
              alt=""
            />
          </div>

          <div class="header-right ml-0 ml-lg-auto">
            <div class="header-dropdown  dropdown-language  mr-auto">
              <div v-for="(language, index) in languages" :key="index">
                <a
                  class="lang"
                  href="javascript:;"
                  v-if="language.lang_locale == $i18n.locale"
                >
                  <i :class="'flag-' + language.lang_flag + ' flag'"></i>
                  {{ language.lang_name }}</a
                >
              </div>
              <div class="header-menu">
                <ul>
                  <li
                    v-for="(language, index) in languages"
                    :key="index"
                    @click="selectLang(language)"
                  >
                    <a href="javascript:;">
                      <i
                        :class="'flag-' + language.lang_flag + ' flag mr-2'"
                      ></i
                      >{{ language.lang_name }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container menu-container d-none d-lg-block"
      :style="HeaderCssVars"
    >
      <div class="row">
        <div class="col-lg-9 menu-custom-block">
          <pv-main-menu></pv-main-menu>
        </div>
        <!-- <div class="col-lg-9">
				<div class="menu-custom-block  text-right">
					<a href="javascript:;">{{ $t("About Us") }}</a>
					<a href="javascript:;">Contact US</a>

				</div>
			</div> -->
        <div class="col-lg-3">
          <pv-header-search></pv-header-search>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import PvMainMenu from "./PvMainMenu";
import TopHeaderMenu from "./TopHeaderMenu";
// import PvHeaderSidebar from './Headertheme18/PvHeaderSidebar.vue';
import PvHeaderSearch from "./PvHeaderSearch";

document.querySelector("body").classList.add("loaded");

export default {
  components: {
    PvMainMenu,
    TopHeaderMenu,
    PvHeaderSearch,
    // PvHeaderSidebar
  },
  computed: {
    HeaderCssVars() {
      return {
        "background-color": this.settings.header_color,
      };
    },
    TopHeaderCssVars() {
      return {
        "background-color": this.settings.secondary_color,
      };
    },
  },
  data: function () {
    return {
      isHome: {
        type: Boolean,
        default: true,
      },
      settings: "",
      submenu: "",
      languages: "",
    };
  },
  mounted() {
    this.getWebsiteSettings();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      localStorage.setItem("locale", newVal);
    },
  },
  methods: {
    getWebsiteSettings() {
      this.settings = [];

      this.settings = JSON.parse(localStorage.getItem("settings"));

      this.languages = JSON.parse(localStorage.getItem("languages"));
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    showMobileMenu: function () {
      document.querySelector("body").classList.add("mmenu-active");
    },
    showMobileSearch: function (e) {
      let headerSearch = e.currentTarget.closest(".header-search");
      headerSearch.classList.add("show");
      headerSearch
        .querySelector(".header-search-wrapper")
        .classList.add("show");
    },
    selectLang: function (language) {
      this.$i18n.locale = language.lang_locale;
      localStorage.setItem("lang_code", language.lang_code);
      localStorage.setItem("langSwitch", true);
      window.location.reload();
    },
  },
};
</script>

<style>
.header.theme18 {
  background-color: #fff;
}

.header.theme18 .menu-container {
  border-radius: 30px;
  padding: 2px 20px;
}
.header.theme18 .lang {
  background: var(--color);
  padding: 8px 15px;
  border-radius: 5px;
}
.header.theme18 .main-nav .menu > li > a {
  font-size: 12px;
  font-weight: 700;
  padding: 0.1rem 0;
}
.header.theme18 .banner_image {
  margin: 0 auto;
}

.header.theme18 .header-search-wrapper {
  height: 36px;
  margin-top: 8px !important;
}

#app .header.theme18 .header-middle {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  color: #fff;
}

#app .header.theme18 .logo {
  max-width: 220px;
  padding-right: 15px;
}

#app .header.theme18 .menu.menu-vertical > li:hover,
#app .header.theme18 .menu.menu-vertical > li.show,
#app .header.theme18 .menu.menu-vertical > li.active {
  background: var(--color);
}

.side-menu-title.cursor-pointer a {
  color: #050606;
  background-color: transparent;
}

#app .header.theme18 .header-top {
  background: var(--color);
  border-bottom: 1px solid var(--secondary-color);
}

#app .header.theme18 .header-top ul li a {
  color: #ffffff;
}

#app .header.theme18 .menu-custom-block a {
  color: #ffffff;
}

#app .header.theme18 .menu.menu-vertical {
  border: 1px solid #f2f2f2;
}

#app .header.theme18 .menu.menu-vertical > li > a {
  padding: 1.1rem 1rem 1.1rem 0.5rem;
}

#app .header.theme18 .sub-list-col-1 .slider-date {
  padding: 6px;
}
@media only screen and (max-width: 768px) {
  #app .header.theme18 .header-center img {
    display: none;
  }
  #app .header.theme18 {
    background: #f2f2f2;
  }
  #app .header.theme18 .lang {
    display: inline-block;
  }
  #app .theme18 .slider-col-img,
  .theme18 .slider-col-content {
    min-height: auto !important;
  }
 
  #app .header.theme18 .mobile-menu-toggler {
  
    color: #000 !important;
  }
}
</style>
