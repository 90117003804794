<template>
	<footer class="footer">
		<footer class="footer">
			<div class="footer-top">
				<div class="container">
					<div class="footer-left nav-links">
						<nuxt-link v-for="item in footerMenu" :key="item.id" :to="item.url">{{ item.title }}</nuxt-link>
					</div>
				</div>
			</div>
			<div class="footer-middle">
				<div class="container">
					<div class="footer-left">
						<nuxt-link to="/">
							<img
								:src="'https://cnphub.menaws.com/storage/'+settings.logo"
								class="logo-footer"
								alt="logo image"
								width="110"
								height="46"
							>
						</nuxt-link>
						<div class="social-icons">
							<a
								:href="settings.facebook"
								class="social-icon social-facebook icon-facebook"
								
								title="Facebook"
							></a>
							<a
								:href="settings.twitter"
								class="social-icon social-twitter icon-twitter"
								
								title="Twitter"
							></a>
							<a
								:href="settings.instagram"
								class="social-icon social-instagram icon-instagram"
								title="Instagram"
							></a>
							<a
								:href="settings.linkedin"
								class="social-icon social-linkedin fab fa-linkedin-in"
								
								title="linkedin"
							></a>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="container">
					<p>© copyright 2021. All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	</footer>
</template>

<script>
import axios from 'axios'

export default {
	data: function() {
		return {
			footerMenu : [],
			settings : [],
		};
	},
	computed: {
	},
	mounted() {
      this.getWebsiteSettings();
    },
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
	},
};
</script>