<template>
    <main :class="'lang-' + getLang() +' id-'+ getWebsite() + ' main home'">

    <MainHeader :attributes="header.attributes"></MainHeader>

    <component :is="settings.inner_pages_banner" :key="settings.inner_pages_banner"></component>

    <div class="container py-4">
      <div class="row">
        <pv-blog-sidebar
          v-if="settings.inner_page_style == 'LeftSideBar' && posts.length > 0"
          :popularCategories="popularCategories"
          :popularTags="popularTags"
          :recentPosts="recentPosts"
        ></pv-blog-sidebar>

        <div :class="contentShow()" v-if="posts.length > 0">
          <div class="blog-posts">
            <section class="timeline">
              <div class="timeline-body" v-for="(post, index) in posts" :key="index" :date="post.created_at">
                                  
                  <!-- <div class="timeline-date" v-if="post.created_at == dateValue">
                    <h3 class="text-primary font-weight-bold">{{ getPostDate(post.created_at) }}</h3>
                  </div> -->

                  <article :class="getPosition(index)+' timeline-box post post-medium'">
                    <div class="timeline-box-arrow"></div>
                    <div class="p-2">
                      <div class="row mb-2">
                        <div class="col">
                          <div class="post-image">
                            <a :href="'/article/' + post.slug">
                              <img v-if="post.image"
                                :src="getImage(post.image , 'medium')"
                                class="img-fluid img-thumbnail img-thumbnail-no-borders rounded-0"
                                :alt="post.name"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="post-content">
                            <h2
                              class="font-weight-semibold text-5 line-height-4 mt-2 mb-2"
                            >
                              <a :href="'/article/' + post.slug">{{
                                post.name
                              }}</a>
                            </h2>
                            <p v-html="getPostDescription(post.content, 500)"></p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="post-meta">
                            <span
                              ><i class="far fa-calendar-alt"></i>
                              {{ getPostDate(post.created_at) }}</span
                            ><br />
                          </div>
                        </div>
                      </div>
                      <!-- <div class="row">
                        <div class="col">
                          <div class="post-meta">
                            <span
                              ><i class="far fa-folder"></i>
                              <a href="#">Lifestyle</a>, <a href="#">Design</a>
                            </span>
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col">
                          <a
                            :href="'/article/' + post.slug"
                            class="btn btn-xs btn-light text-1 text-uppercase"
                            >{{ $t("Read More") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </article>
              </div>
            </section>
            <div style="text-align:center" v-if="posts.length > 0">
                  <button class="button-arounder" v-bind:class="[isFinished ? 'finish' : 'load-more']"  @click='loadMore()' v-cloak>{{ buttonText }}</button>
              </div>
          </div>
         
        </div>
        <div v-else>
          <p class="blogs-info" v-if="posts.length === 0">{{ $t('No posts were found matching your selection.') }}</p>
        </div>

        <pv-blog-sidebar 
          v-if="settings.inner_page_style == 'RightSideBar' && posts.length > 0"
          :popularCategories="popularCategories"
          :popularTags="popularTags"
          :recentPosts="recentPosts"
        ></pv-blog-sidebar>
      </div>
    </div>

    <MainFooter :attributes="footer.attributes"></MainFooter>
  </main>
</template>
<script>
import axios from "axios";
import PvBlogSidebar from "../Posts/partials/PvBlogSidebar";
import MainHeader from "../Utility/Header/MainHeader.vue";
import MainFooter from "../Utility/Footer/MainFooter.vue";

import Banner1 from "../Posts/partials/banner/banner1";
import Banner2 from "../Posts/partials/banner/banner2";
import Banner3 from "../Posts/partials/banner/banner3";
import Banner4 from "../Posts/partials/banner/banner4";
import Banner5 from "../Posts/partials/banner/banner5";
import Banner6 from "../Posts/partials/banner/banner6";
import Banner7 from "../Posts/partials/banner/banner7";
import Banner8 from "../Posts/partials/banner/banner8";

export default {
  components: {
    PvBlogSidebar,
    MainHeader, //by Abdulhamid
    MainFooter, //by Abdulhamid
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
    Banner8,
  },
  props: {},
  data: function () {
    return {
      posts: [],
      popularCategories: [],
      recentPosts: [],
      relatedPosts: [],
      popularTags: [],
      settings:[],
      header: "",
      footer: "",
      dateValue: "",
      category: "",
      tag: "",
      search_tearm: "",
      isFinished: false,
      page: 2,  // Record selection position
      rowperpage: 12,  // Number of records fetch at a time
      buttonText: this.$t('Load More')
    };
  },
  //insert the following code for vue-meta to work
  metaInfo() {
    const category = this.category;
    const tag = this.tag;
    const search_tearm = this.search_tearm;
    if(category != ''){
      return {
        title: `${category.name}`,
        meta: [
          { name: "description", content: `${category.description}` },
          { property: "og:title", content: `${category.name}` },
          { property: "og:site_name", content: `${category.name}` },
          { property: "og:description", content: `${category.description}` },
          { property: "og:type", content: "category" },
          { property: "og:url", content: `${category.slug}` },
          { property: "og:image", content: `${category.image}` },
        ],
      };
    }else if(tag != ''){
      return {
        title: `${tag.name}`,
        meta: [
          { name: "description", content: `${tag.description}` },
          { property: "og:title", content: `${tag.name}` },
          { property: "og:site_name", content: `${tag.name}` },
          { property: "og:description", content: `${tag.description}` },
          { property: "og:type", content: "tag" },
          { property: "og:url", content: `${tag.slug}` },
          { property: "og:image", content: `${tag.image}` },
        ],
      };
    }else{
      return {
        title: `${search_tearm}`,
        meta: [
          { name: "description", content: `${search_tearm}` },
          { property: "og:title", content: `${search_tearm}` },
          { property: "og:site_name", content: `${search_tearm}` },
          { property: "og:description", content: `${search_tearm}` },
          { property: "og:type", content: "tag" },
          { property: "og:url", content: `${search_tearm}` },
          { property: "og:image", content: `${search_tearm}` },
        ],
      };
    }
    
  },
  mounted() {
    this.getListDate();
    this.getPopularCategories();
    this.getRecentPosts();
    this.getPopularTags();
    this.header = JSON.parse(localStorage.getItem("header"));
    this.footer = JSON.parse(localStorage.getItem("footer"));
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  watch: {
    $route: {
      handler: "asyncData",
      immediate: true,
    },
  },
  computed: {},
  methods: {
    getLang: function(){
      return localStorage.getItem("locale");
    },
    getWebsite: function () {
      return this.settings.id;
    },
    getPosition(index){
        if(index % 2 == 0){
            return 'left'
        }else{
            return 'right'
        }
    },
    getImage(url, size) {
		return this.$helpers.getImageFullPath(url, size);
	},
	getPostDate(date) {
	return this.$postHelper.getPostDate(date);
	},
	getPostMonth(date) {
	return this.$postHelper.getPostDay(date);
	},
	getPostDay(date) {
	return this.$postHelper.getPostMonth(date);
	},
	getPostDescription(text) {
	return this.$postHelper.getPostSmallDescription(text, 160);
	},
    asyncData() {
      this.getListDate();
      document.querySelector("body").classList.remove("mmenu-active");
    },
    getListDate() {
      var slug = window.location.pathname.split("/").pop();
      var type = window.location.pathname.split("/");

      type = type[1];
      var callFunc = '';
      if (type == 'category') {
        callFunc = "https://api.cnphub.com/public/Category_by_slug?slug=" + slug + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      } else if (type == 'tag'){
        callFunc = "https://api.cnphub.com/public/Tag_by_slug?slug=" + slug + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      } else if(type == 'search'){
        var search_term = location.search.split('search_term=')[1];
        callFunc = "https://api.cnphub.com/public/Search?search_term=" + search_term + "&page=" + 1 + "&rowperpage=" + this.rowperpage
      }

      axios
        .get(
          callFunc
        )
        .then(res => {
          this.posts = res.data.data.posts.data ? res.data.data.posts.data : [];
          this.category = res.data.data.category ? res.data.data.category : '';
          this.tag = res.data.data.tag ? res.data.data.tag : '';
          this.search_tearm = res.data.data.search_tearm ? res.data.data.search_tearm : '';
        })
    },
    loadMore() {
      var slug = window.location.pathname.split("/").pop();
      var type = window.location.pathname.split("/");

      type = type[1];
      var callFunc = '';
      if (type == 'category') {
        callFunc = "https://api.cnphub.com/public/Category_by_slug?slug=" + slug + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      } else if (type == 'tag'){
        callFunc = "https://api.cnphub.com/public/Tag_by_slug?slug=" + slug + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      } else if(type == 'search'){
        var search_term = location.search.split('search_term=')[1];
        callFunc = "https://api.cnphub.com/public/Search?search_term=" + search_term + "&page=" + this.page + "&rowperpage=" + this.rowperpage
      }
      axios
        .get(
          callFunc
        )
        .then((res) => {
          if (res.data.data.posts.data.length !== 0) {
            this.page++;
            var len = res.data.data.posts.data.length;
            if (len > 0) {
              this.buttonText = this.$t("Loading ...");
              setTimeout(() => this.appendData(res.data.data.posts.data), 500);
            } else {
              this.posts = res.data.data.posts ? res.data.data.posts : [];
            }
          } else {
            this.buttonText = this.$t('No more records avaiable.');
            this.isFinished = true;
          }
        });
    },
    getPopularCategories() {
      axios
        .get("https://api.cnphub.com/public/popularCategories")
        .then((res) => {
          this.popularCategories = res.data.data.popularCategories
            ? res.data.data.popularCategories
            : [];
        });
    },
    appendData(data){
      this.buttonText = this.$t('Load More');
      for (let i = 0; i < data.length; i++){
          this.posts.push(data[i]);
        }
    },
    getRecentPosts() {
      axios
        .get("https://api.cnphub.com/public/recentPosts")
        .then((res) => {
          this.recentPosts = res.data.data.recentPosts
            ? res.data.data.recentPosts
            : [];
        });
    },
    getPopularTags() {
      axios
        .get("https://api.cnphub.com/public/popularTags")
        .then((res) => {
          this.popularTags = res.data.data.popularTags
            ? res.data.data.popularTags
            : [];
        });
    },
    contentShow() {
      if (this.settings.inner_page_style == "Full") {
        return "col-md-12";
      } else {
        return "col-md-9";
      }
    },
  },
};
</script>

<style>
section.timeline .timeline-date h3 {
    display: block;
    text-align: center;
    color: #757575;
    font-size: .9em;
    line-height: 32px;
}
section.timeline {
    width: 100%;
    margin: 50px 0 0;
    position: relative;
    float: left;
    padding-bottom: 120px;
}
section.timeline .timeline-body {
    position: relative;
    z-index: 1!important;
}
section.timeline .timeline-date {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 4%);
    position: relative;
    display: block;
    clear: both;
    width: 200px;
    height: 45px;
    padding: 5px;
    border: 1px solid var(--color);
    margin: 0 auto 10px;
    text-shadow: 0 1px 1px #fff;
    border-radius: 4px;
    background: #fff;
    z-index: 1;
}
section.timeline .timeline-box.left {
    clear: both;
}
.blog-posts:not(.blog-posts-no-margins) article {
    border-bottom: 1px solid rgba(0,0,0,.06);
    margin-bottom: 50px;
    padding-bottom: 20px;
}
section.timeline .timeline-box {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 4%);
    border: 1px solid var(--color);
    border-radius: 4px;
    margin: 20px 10px 10px;
    padding: 10px;
    width: 45.7%;
    float: left;
    z-index: 1;
    margin-bottom: 30px;
    background: #fff;
}
section.timeline .timeline-box.left .timeline-box-arrow {
    transform: rotate(45deg);
    content: '';
    display: block;
    background: #fff;
    border-right: 1px solid var(--color);
    border-top: 1px solid var(--color);
    float: right;
    height: 14px;
    margin-top: 17px;
    margin-right: -18px;
    width: 14px;
    z-index: 0;
}
.img-thumbnail.img-thumbnail-no-borders {
    border: none;
    padding: 0;
}
.img-thumbnail {
    border-radius: 4px;
    position: relative;
}
section.timeline .timeline-box.right {
    clear: right;
    float: right;
    right: -1px;
    margin-top: 40px;
}
.p-2 {
    padding: 0.5rem!important;
}
.img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
}
section.timeline:before {
    background: var(--color);
    background: var(--color);
    content: "";
    display: block;
    height: 100%;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: -60px;
    width: 3px;
    z-index: 0;
    filter: alpha(opacity=35);
    opacity: .35;
}
section.timeline .timeline-box.left:before, section.timeline .timeline-box.right:before {
    background: var(--color);
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px var(--color);
}
section.timeline .timeline-box.right:before {
    background: none repeat scroll 0 0 var(--color);
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px var(--color);
    content: "";
    display: block;
    height: 7px;
    position: absolute;
    left: 50%;
    margin-top: 32px;
    width: 8px;
    transform: translate3d(-50%,0,0);
    z-index: 1;
}
.timeline-date .text-primary{
    color: var(--color) !important;
}
section.timeline .timeline-box.right .timeline-box-arrow {
    transform: rotate(45deg);
    content: '';
    display: block;
    background: #fff;
    border-left: 1px solid var(--color);
    border-bottom: 1px solid var(--color);
    float: left;
    height: 14px;
    margin-top: 28px;
    margin-left: -18px;
    width: 14px;
    z-index: 0;
}
section.timeline .timeline-box.left:before {
    background: none repeat scroll 0 0 var(--color);
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff, 0 0 0 6px var(--color);
    content: "";
    display: block;
    height: 7px;
    margin-right: -5px;
    position: absolute;
    left: 50%;
    margin-top: 22px;
    width: 8px;
    transform: translate3d(-50%,0,0);
    z-index: 1;
}
.more-parent{
  position: relative;
}



button {
  background: white;
  border: solid 2px black;
  padding: .375em 1.125em;
  font-size: 1rem;
}

.button-arounder {
  font-size: 2rem;
  background: var(--color);
  color: hsl(190deg, 10%, 95%);
  
  box-shadow: 0 0px 0px hsla(190deg, 15%, 5%, .2);
  transfrom: translateY(0);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  
  --dur: .15s;
  --delay: .15s;
  --radius: 16px;
  
  transition:
    border-top-left-radius var(--dur) var(--delay) ease-out,
    border-top-right-radius var(--dur) calc(var(--delay) * 2) ease-out,
    border-bottom-right-radius var(--dur) calc(var(--delay) * 3) ease-out,
    border-bottom-left-radius var(--dur) calc(var(--delay) * 4) ease-out,
    box-shadow calc(var(--dur) * 4) ease-out,
    transform calc(var(--dur) * 4) ease-out,
    background calc(var(--dur) * 4) steps(4, jump-end);
}

.button-arounder:hover,
.button-arounder:focus {
  box-shadow: 0 4px 8px hsla(190deg, 15%, 5%, .2);
  transform: translateY(-4px);
  background: var(--bs-border-color);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  color: var(--color);
}
@media (max-width: 767px) {
  section.timeline .timeline-box {
        width: 100%!important;
    }
    section.timeline .timeline-box.right
    ,section.timeline .timeline-box.left {
  clear: right;
  float: left!important;}
  section.timeline .timeline-box.right::before
  ,section.timeline .timeline-box.left::before{
    opacity: 0;
  }
}
</style>