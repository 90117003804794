<template>
	<section
		class="best-sellers appear-animate"
		data-animation-name="fadeIn"
		data-animation-delay="300"
		v-animate
	>
		<div class="container">
			<div class="heading pb-3 mb-2 d-flex align-items-center justify-content-between">
				<h2 class="section-title border-0">Best Sellers Books</h2>
				<router-link
					to="/shop"
					class="btn btn-link"
				>View all products<i class="fas fa-long-arrow-alt-right"></i></router-link>
			</div>

			<div class="row no-gutters m-0">
				<div class="col-md-4">
					<div class="banner banner1 bg-white">
						<div class="banner-layer d-flex flex-column align-items-center justify-content-center h-100">
							<h5 class="font-weight-normal text-uppercase m-b-1"><i>Book Club</i></h5>
							<h2 class="text-center">A selection with only the best books</h2>
							<figure>
								<img
									v-lazy="'./images/home/banners/banner-1.png'"
									alt="banner"
									width="343"
									height="283"
								>
							</figure>
							<router-link
								to="/shop"
								class="btn btn-primary"
							>Explore Books <i class="fas fa-long-arrow-alt-right"></i></router-link>
						</div>
					</div>
				</div>

				<div class="col-md-8">
					<div
						class="row custom-products no-gutters"
						v-if="products.length === 0"
					>
						<div
							class="col-6 col-md-4 col-xl-3"
							v-for="index in [1,2,3,4,5,6,7,8]"
							:key="'best-skel-' + index"
						>
							<div class="product-loading-overlay skel-white"></div>
						</div>
					</div>

					<div
						class="row custom-products no-gutters"
						v-if="products.length > 0"
					>
						<div
							class="col-6 col-md-4 col-xl-3"
							v-for="(product,index) in products.slice(0,8)"
							:key="'best-' + index"
						>
							<pv-product-one :product="product">
							</pv-product-one>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import PvProductOne from './products/PvProductOne25.vue'
import axios from 'axios'

export default {
	components: {
		PvProductOne
	},
	data: function () {
		return {
			products: [],
		};
	},
	mounted: function () {
		this.getPosts();
	},
	methods: {
		getPosts() {
			this.slides = [];
			this.isLoading = true;
			axios.get('https://api.cnphub.com/public/posts')
			.then(res => {
				this.products = res.data.data ? res.data.data : [];
			})
			.catch(res => {
				console.log(res);
			});
		},

	},
}
</script>