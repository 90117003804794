<template>
  <div class="container">
    <div class="product-widgets-container row pb-2 theme1">

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" >
        <h4 class="section-sub-title"><span>{{ cat1name }}</span></h4>

        <pv-small-post :post="post" v-for="(post, index) in Pv1Posts" :key="'small-post-' + index"></pv-small-post>

      </div>

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" >
        <h4 class="section-sub-title"><span>{{ cat2name }}</span></h4>

        <pv-small-post :post="post" v-for="(post, index) in Pv2Posts" :key="'small-post-' + index"></pv-small-post>

      </div>

      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" >
        <h4 class="section-sub-title"><span>{{ cat3name }}</span></h4>

        <pv-small-post :post="post" v-for="(post, index) in Pv3Posts" :key="'small-post-' + index"></pv-small-post>

      </div>
      <div class="col-lg-3 col-sm-6 pb-5 pb-md-0" >
        <h4 class="section-sub-title"><span>{{ cat4name }}</span></h4>

        <pv-small-post :post="post" v-for="(post, index) in Pv4Posts" :key="'small-post-' + index"></pv-small-post>

      </div>
    </div>
  </div>
</template>

<script>
import PvSmallPost from "./PvSmallPost.vue";
import axios from "axios";

export default {
  components: {
    PvSmallPost,
  },
  props: {

    Pv1Posts: {
      type: Array,
      required: true,
      default: () => []
    },
    Pv3Posts: {
      type: Array,
      required: true,
      default: () => []
    },
    Pv2Posts: {
      type: Array,
      required: true,
      default: () => []
    },
    Pv4Posts: {
      type: Array,
      required: true,
      default: () => []
    },

    cat1name: String,
    cat2name: String,
    cat3name: String,
    cat4name: String,
  },

  data: function () {
    return {

    };
  },

  mounted: function () {

  },
  watch: {

  },
  computed: {
    col1Visible: {
      // getter
      get() {
        return (this.Pv1Posts.length > 0) ?  true:false;
      },
    },
    col2Visible: {
      // getter
      get() {
        return (this.Pv2Posts.length > 0) ?  true:false;
      },
    },
    col3Visible: {
      // getter
      get() {
        return (this.Pv3Posts.length > 0) ?  true:false;
      },
    },
    col4Visible: {
      // getter
      get() {
        return (this.Pv4Posts.length > 0) ?  true:false;
      },
    },
  },
  methods: {
    IsNotEmpty(posts) {
      
      if (Array.isArray(posts)) {
        if (posts.length > 0) return true;
        if (posts.length == null) {
          return this.IsNotEmpty(posts);
        }
        console.log('posts.length' + posts.length)
      }

      return false;
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },

  },
};
</script>

<style>
.post-details .time {
  color: #999;
  padding: 4px 0;
  font-size: 12px
}

.NewsList4ColTheme1 .product-default a {
  color: inherit;
  white-space: break-spaces;
}
</style>