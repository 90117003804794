<template>
	<div>
		<nav class="main-nav w-100">
			<ul class="menu main-menu sf-arrows">
				<li v-for="item in mainMenu" :key="item.id">
					<router-link :to="item.url">{{ item.title }}</router-link>
					<div class="megamenu megamenu-fixed-width megamenu-3cols" v-if="item.has_child == 1">
						<div class="row">
							<div class="col-lg-4">
								<ul class="submenu">
									<li v-for="subitem in item.child" :key="subitem.id">
										<router-link :to="subitem.url">
											{{ subitem.title }}
										</router-link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</nav>
		
	</div>
</template>
<script>
import axios from 'axios'

export default {
	data: function () {
		return {
			mainMenu: [],
			languages: [],
		};
	},
	computed: {
	},
	mounted() {
		this.getMainMenu();
	},
	methods: {
		getMainMenu() {
			
			axios.get('https://api.cnphub.com/public/menu?id=1')
				.then(res => {
					this.mainMenu = res.data.data.top_header_menu ? res.data.data.top_header_menu : [];
				})
				.catch(res => {
					console.log(res);
				});
				
		},
	},
};
</script>