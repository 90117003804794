<template>
	<header class="header">
		<div class="header-top text-uppercase">
			<div class="container">
				<div class="header-left">
					<div class="header-dropdown mr-3 pr-1">
						<a
							href="javascript:;"
							class="pl-0"
						>USD</a>
						<div class="header-menu">
							<ul>
								<li><a href="javascript:;">EUR</a></li>
								<li><a href="javascript:;">USD</a></li>
							</ul>
						</div>
					</div>

					<div class="header-dropdown mr-auto">
						<a
							href="javascript:;"
							class="pl-0"
						><i class="flag-us flag"></i>ENG</a>
						<div class="header-menu">
							<ul>
								<li><a href="javascript:;"><i class="flag-us flag mr-2"></i>ENG</a>
								</li>
								<li><a href="javascript:;"><i class="flag-fr flag mr-2"></i>FRA</a></li>
							</ul>
						</div>
					</div>
				</div>

				<div class="header-right header-dropdowns ml-0 ml-sm-auto">
					<div class="header-dropdown dropdown-expanded mr-3">
						<!-- <a href="javascript:;">Links</a> -->
						<div class="header-menu">
							<ul>
								<li>
									<router-link to="/pages/account">My Account</router-link>
								</li>
								<li>
									<router-link to="/pages/about-us">{{ $t("About Us") }}</router-link>
								</li>
								<li>
									<router-link to="/pages/blog">Blog</router-link>
								</li>
								<li>
									<router-link to="/pages/wishlist">My Wishlist</router-link>
								</li>
								<li>
									<router-link to="/pages/cart">Cart</router-link>
								</li>
								<li>
									<router-link
										to="/pages/login"
										class="login-link"
									>Log In</router-link>
								</li>
							</ul>
						</div>
					</div>

					<span class="separator d-none d-lg-inline-block"></span>

					<div class="social-icons">
						<a
							href="javascript:;"
							class="social-icon social-facebook icon-facebook"
							
						></a>
						<a
							href="javascript:;"
							class="social-icon social-twitter icon-twitter"
							
						></a>
						<a
							href="javascript:;"
							class="social-icon social-instagram icon-instagram mr-1"
							
						></a>
					</div>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left">
					<button
						class="mobile-menu-toggler mr-2"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>
					<router-link
						to="/"
						class="logo w-100"
					>
						<img
							src="@/assets/css/images/logo-black.png"
							width="101"
							height="40"
							alt="Porto Logo"
						/>
					</router-link>

					<pv-main-menu></pv-main-menu>

					<pv-header-search></pv-header-search>
				</div>

				<div class="header-right">
					<div class="header-contact d-none d-lg-flex pl-4 pr-4">
						<img
							alt="phone"
							:src="'./images/phone.png'"
							width="30"
							height="30"
							class="pb-1"
						/>
						<h6>
							<span>{{ $t('Call us now') }}</span>
							<a
								href="tel:#"
								class="text-dark font1"
							>+123 5678 890</a>
						</h6>
					</div>

					<router-link
						to="/pages/wishlist"
						class="header-icon d-lg-none d-block"
						title="wishlist"
					>
						<i class="icon-wishlist-2"></i>
					</router-link>

					<span class="separator d-lg-inline-block d-none"></span>

					<pv-cart-menu></pv-cart-menu>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';
import PvCartMenu from './PvCartMenu';
import PvHeaderSearch from './PvHeaderSearch';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		PvCartMenu,
		PvHeaderSearch
	},
	data: function() {
		return {
			isHome: {
				type: Boolean,
				default: true
			}
		};
	},
	methods: {
		showMobileMenu: function() {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function(e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		}
	}
};
</script>