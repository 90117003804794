<template>
	<div class="container">

	<div
		class="product-widgets row pb-2 appear-animate skeleton-body"
		data-animation-name="fadeIn"
		data-animation-delay="100"
		v-animate
	>
		<div class="col-md-4 col-sm-6 pb-5">
			<h4 class="section-title border-0 text-left text-uppercase">Best Selling Products</h4>

			<div v-if="bestProducts.length === 0">
				<div
					class="skel-product-col skel-pro mb-2"
					v-for="(item,index) in [0,1,2]"
					:key="'best' + index"
				></div>
			</div>

			<pv-small-product
				:product="product"
				v-for="(product,index) in bestProducts"
				:is-lazy="false"
				:key="'best-product' + index"
				:is-cat="true"
			></pv-small-product>
		</div>

		<div class="col-md-4 col-sm-6 pb-5">
			<h4 class="section-title border-0 text-left text-uppercase">Top Rated Products</h4>

			<div v-if="topRatedProducts.length === 0">
				<div
					class="skel-product-col skel-pro mb-2"
					v-for="(item,index) in [0,1,2]"
					:key="'best' + index"
				></div>
			</div>

			<pv-small-product
				:product="product"
				v-for="(product,index) in topRatedProducts"
				:is-lazy="false"
				:key="'top-rated-product' + index"
				:is-cat="true"
			></pv-small-product>
		</div>

		<div class="col-md-4 col-sm-6 pb-5">
			<h4 class="section-title border-0 text-left text-uppercase">Featured Products</h4>

			<div v-if="featuredProducts.length === 0">
				<div
					class="skel-product-col skel-pro mb-2"
					v-for="(item,index) in [0,1,2]"
					:key="'best' + index"
				></div>
			</div>

			<pv-small-product
				:product="product"
				v-for="(product,index) in featuredProducts"
				:is-lazy="false"
				:key="'featured-product' + index"
				:is-cat="true"
			></pv-small-product>
		</div>
	</div>

	<hr class="m-0" />

	</div>
</template>

<script>
import PvSmallProduct from './products/PvSmallProduct.vue';
import Api, { baseUrl } from '@/api';
import {
	getProductsByAttri,
	getTopSellingProducts,
	getTopRatedProducts
} from '@/utils/service';


export default {
	components: {
		PvSmallProduct
	},
	data: function() {
		return {
			featuredProducts: [],
			bestProducts: [],
			topRatedProducts: []
		};
	},
	mounted: function() {
		Api.get(`${baseUrl}/demo7`)
			.then(response => {
				this.products = response.data.products;
				this.posts = response.data.posts;
				this.featuredProducts = getProductsByAttri(
					response.data.products
				);
				this.newProducts = getProductsByAttri(
					response.data.products,
					'is_new'
				);
				this.bestProducts = getTopSellingProducts(
					response.data.products
				);
				this.topRatedProducts = getTopRatedProducts(
					response.data.products
				);
			})
			.catch(error => ({ error: JSON.stringify(error) }));

	},
};
</script>