<template>
  <section class="home-section mb-2 ">
    <div class=" theme14">
      <div class="c-hub-title no-description" :style="setBackgound">
        <span class="c-hub-title__inner">

          <h2 class="top-title">{{ $t("Top Stories") }}</h2>
        </span>
      </div>
    </div>

    <div class="container main-section-slider theme14">

      <div class="row col-md-12">
        <div class="col-md-3   mb-20 sub-list-col-1">
          <div class="article" v-for="(post, index) in getShortList(1, 4)" :key="index">
            <div v-if="index == 0 || index == 1">
              <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              <!-- <div class="slider-date ">{{
                getPostDate(post.created_at)
              }}
              </div> -->
              <h4 class="content-title">
                <span v-if="post.cats.length" class="slider-cat ">{{
                  post.cats[0].name
                }}
                </span><br>
                <a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 120) }}</a>
              </h4>
              <!-- <p>{{ getPostDescription(post.content, 150) }}</p> -->
            </div>
            <!-- <div v-if="index > 0" class="small-article">
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
            </div> -->

          </div>


        </div>
        <div class="col-md-6 col-xl-6 col-lg-6 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider  slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in posts" :key="index">


              <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              <!-- <h6 v-if="post.created_at" class=" slider-date appear-animate" data-animation-name="fadeInDownShorter"
                data-animation-delay="100" v-animate>{{
                  getPostDate(post.created_at)
                }}
              </h6> -->
              <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->
              <div class="content-title">
                <div v-if="post.cats.length" >
                  <span v-for="(cat, cat_index) in post.cats" class="slider-cat " :key="cat_index">{{
                    cat.name
                  }}
                  </span>
                </div>

                <h3 class=" appear-animate " data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                  v-animate>
                  <a class="slider-title" :href="'/article/' + post.slug">{{ post.name }}</a>
                </h3>


                <div v-if="post.subtitle" class="text-center appear-animate" v-html="post.subtitle"></div>
                <p class=" text-center appear-animate" v-else v-html="getPostDescription(post.content, 150)"></p>

              </div>

            </div>


          </PvCarousel40>
        </div>

        <div class="col-md-3   mb-20 sub-list-col-2">
          <div class="article" v-for="(post, index) in getShortList(4, 7)" :key="index">
            <div v-if="index == 0 || index == 1">
              <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              <!-- <div class="slider-date ">{{
                getPostDate(post.created_at)
              }}
              </div> -->
              <h4 class="content-title"><span v-if="post.cats.length" class="slider-cat ">{{
                post.cats[0].name
              }}
                </span><br><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 120) }}</a>
              </h4>
              <!-- <p>{{ getPostDescription(post.content, 150) }}</p> -->
            </div>
            <!-- <div v-if="index > 0" class="small-article">
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 90) }}</a></h4>
            </div> -->

          </div>


        </div>




      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from '../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {
      settings: [],
      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {

    setBackgound() {
      return {
        'background-image': 'url(' + this.settings.storageUrl + this.settings.banner_image + ')',
      }
    },
  },
  mounted() {
    // this.getSliderNews();setBackgound

  },
  methods: {
    getShortList(start, shortListSize) {
      if (this.posts)
        return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));




    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1); 
    //       }


    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;



    // },
  },
}
</script>

<style>
.theme14 .home-slider img {
  height: auto;

}

.theme14 .swiper-carousel .swiper-dots.disabled {
  display: block;
}

.theme14 span.slider-cat {
  background: #bfc5c7;
  color: #fff;
  font-size: 12px;
  padding: 4px 6px;
  margin-left: 2px;
}

.theme14 h4.content-title,
.theme14 div.content-title {
  padding: 10px;
  background: #fff;
  margin-top: -20px;
  z-index: 99999;
  /* display: block; */
  position: relative;
  width: 90%;
  margin-left: 5%;
  min-height: 140px;
}

.text-center {
  text-align: center;
}

.theme14 .home-slider .home-slide1 h3 {
  margin-bottom: 0.8rem;
  font-size: 1.6em;
  padding: 20px;
  text-align: center;
}

.theme14 .home-slider .home-slide1 .slider-title {
  color: #333;

  text-align: center;

}

.theme14 .home-slider .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: #d3d3d3;
  opacity: 1;
}

.theme14 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 50%;
  bottom: 0.1rem;
  text-align: center;
  transform: translateX(-50%);
  max-width: 160px;
}

.theme14 .swiper-theme .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
}

.theme14 .top-title {
  font-size: 3.6em;
  color: #000;
}

.theme14 .c-hub-title {
  overflow: hidden;
  position: relative;
  text-align: center;
  overflow: visible;
  padding-top: 90px;
  padding-bottom: 140px;
  margin-top: -2rem;
}

.main-section-slider.theme14 {
  margin-top: -5rem;
}

.theme14 .c-hub-title.no-description:before {
  /* border-left: 6px solid var(--color);
  border-right: 6px solid var(--color);
  border-top: 6px solid var(--color);
  content: " ";
  height: 50px;
  left: -16px;
  position: absolute;
  right: -16px;
  top: 50%;
  pointer-events: none; */
}

.theme14 .c-hub-title__inner {
  /* background-color: #fff; */
  color: currentColor;
  display: inline-block;
  padding: 32px 26px 16px;
  position: relative;
  padding: 16px;
}

.theme14 .home-slider {
  /*   
  min-height: 50rem;
  max-height: 50rem; */
}

.theme14 .home-slider {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
}

.theme14 .home-slider .slider-title {
  color: #000;
}

.sub-list-col-1 h4 a,
.sub-list-col-2 h4 a {
  color: #000;
}

.theme14.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

.theme14 .small-article {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #f2f2f2;
}


.theme14 .small-article h4 a {
  font-size: 0.75em;
  font-weight: bold;
  color: #333;


}

.theme14 .home-slider .slider-date,
.theme14 .sub-list-col-1 .slider-date,
.theme14 .sub-list-col-2 .slider-date {
  width: 160px;
  padding: 10px;
  background: #fff;
  margin-top: -33px;
  display: block;
  position: absolute;
}

.theme2.top-6-articles .article {}

.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 2.4em;
}</style>