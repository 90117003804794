<template>
  <section class="home-section mb-2">
    <div class="container top-news">
      <template >
        
          <component :is="attributes.theme" :posts="posts"  :key="attributes.theme"></component>
      </template>
    </div>
  </section>
</template>

<script>

import axios from 'axios'

import TopNewsTheme1 from './TopNewsTheme1.vue'
import TopNewsTheme2 from './TopNewsTheme2.vue'
import TopNewsTheme17 from './TopNewsTheme17.vue'

import TopNewsTheme18 from './TopNewsTheme18.vue'
export default {
  components: {TopNewsTheme1,TopNewsTheme2,TopNewsTheme17,TopNewsTheme18},
  props: {
    attributes:  {
      type: Array,
      limit:Number,
      images_query:null,
    },
  },

  data: () => ({
    posts: null,
    
    settings: null,
   
  }),
  mounted() {
    this.getTopNews();
    
  },
  computed: {
    
  },

  methods: {
    sortItems() {
      if (this.sortatoz === "alphabetically") {
        return this.posts.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        return this.posts.sort(function () { return 0.5 - Math.random() });
      }
    },
    getTopNews() {
      this.settings = JSON.parse(localStorage.getItem("settings"));

      axios.get(this.settings.BaseUrl + '/public/top-news?limit='+this.attributes.limit+'&images_query='+this.attributes.images_query)
        .then(res => {

          this.posts = res.data.data.posts ? res.data.data.posts : '';

          
          this.posts =this.sortItems();

        })
        .catch(res => {
          console.log(res);
        });
    },
  },
};
</script>