<template>
  <div>
    <div class="container my-5 pt-4">
      <div class="row align-items-center justify-content-center mb-5">
        <div class="col-lg-6 pb-sm-4 pb-lg-0 mb-5 mb-lg-0">
          <div class="overflow-hidden">
            <h2
              class="font-weight-bold text-color-dark line-height-1 mb-0 appear-animation animated maskUp appear-animation-visible"
              data-appear-animation="maskUp"
              data-appear-animation-delay="300"
              style="animation-delay: 300ms"
            >
            {{ $t("About us") }}
            </h2>
          </div>
          <div
            class="custom-divider divider divider-primary divider-small my-3"
          >
            <hr
              class="my-0 appear-animation animated customLineProgressAnim appear-animation-visible"
              data-appear-animation="customLineProgressAnim"
              data-appear-animation-delay="700"
              style="animation-delay: 700ms"
            />
          </div>
          <p
            class="font-weight-light text-3-5 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="450"
            style="animation-delay: 450ms"
            v-html="settings.about_text"
          >
          </p>
          
        </div>
        <div
          class="col-10 col-md-9 col-lg-6 ps-lg-5 pe-5 appear-animation animated fadeInRightShorterPlus appear-animation-visible"
          data-appear-animation="fadeInRightShorterPlus"
          data-appear-animation-delay="1450"
          data-plugin-options="{'accY': -200}"
          style="animation-delay: 1450ms"
        >
          <div class="position-relative">
            <div
              data-plugin-float-element=""
              data-plugin-options="{'startPos': 'top', 'speed': 0.2, 'transition': true, 'transitionDuration': 1000, 'isInsideSVG': true}"
            >
              <img
                :src="getImage(settings.about_image ,'default')"
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>
</style>