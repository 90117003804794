<template>
  <div>
    <div class="container-fluid mt-2 mb-2">
      <div class="history-section row align-items-center">
        <div class="col-xl-5 col-lg-6">
          <figure>
            <img
              width="717"
              height="342"
              alt="about us"
              :src="getImage(settings.about_image, 'default')"
              class="fade-in"
            />
          </figure>
        </div>
        <div class="col-xl-7 col-lg-6">
          <div class="row">
            <h3 class="col-12 about-title">{{ $t("About Us") }}</h3>
            <div class="col-xl-12">
              <p v-html="settings.about_text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings:[]
    };
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  }
};
</script>

<style>
</style>