<template>
  <div class="top-news-posts theme4">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">

        <div class="row">


          <!-- Post Wrapper Start -->
          <div :class="colClassSection + 'col-12 col-xs-12 mb-20 main-articles'">

            <div class="col-lg-12 ">
              <h4 class="section-sub-title">{{ cat1name }}</h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat1Posts" :key="index">
              <div class=" col-md-4  col-xs-12">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              </div>
              <div class=" col-md-8 col-xs-12">
                <br>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p>
              </div>
            </div>


          </div><!-- Post Wrapper End -->
          <div v-if="topNewsSection" :class="'col-4 col-xs-12 mb-20 main-articles'">

            <div class="col-lg-12 ">
              <h4 class="section-sub-title">{{ $t('Top News') }}</h4>
            </div>
            <div class="col-lg-12 ">
              <div class="story-short article row" v-for="(post, index) in topNews" :key="index">
                <div class=" col-md-12  col-xs-12">
                  <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
                </div>
                <div class=" col-md-12 col-xs-12">
                  <br>
                  <h4><a :href="'/article/' + post.slug">
                      {{ post.name }}</a></h4>
                  <div class="time">{{ getPostDate(post.created_at) }} </div>
                  <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;">{{
                    getPostDescription(post.content)
                  }}<br></p>
                  <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
  </div>
</template>

<script>




export default {
  components: {},
  props: {
    cat1Posts: Array,
    topNews: Array,

    cat1name: String,

  },
  // props: {
  //   darkClass: {
  //     type: Object,
  //   },
  // },
  data: () => ({
    settings: null,
    // mainNews: [],
    // newsList: [

    // ]
  }),
  mounted() {


  },
  computed: {
    colClassSection: {
      // getter
      get() {
        return this.topNews.length > 0 ? 'col-md-8  col-xs-12' : 'col-md-12  col-xs-12'
      },
    },
    topNewsSection: {
      // getter
      get() {
        return this.topNews.length > 0 ? 1 : 0
      },
    }
  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    },

  },
};
</script>

<style>
.top-6-articles {
  /* background-color: #f2f2f2; */
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.top-6-articles .article {
  background-color: #f2f2f2;
  margin-bottom: 5px;
  padding: 10px;
}

.top-6-articles .article h5 {
  margin-bottom: 5px;
}

.main-articles .article:first-child {
  border-bottom: 2px solid #f2f2f2;
}

.main-articles .article {

  padding-bottom: 20px;
  margin-bottom: 15px;
}

.top-6-articles .article a,
.main-articles .article a {
  color: #222529;
}

.top-6-articles .time,
.main-articles .time {
  color: #999;
  padding: 4px 0;
  font-size: 12px;
}
</style>