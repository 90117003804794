<template>
  <section>
    <div class="container">
      <div class="  row pb-1">
      <div class="col-lg-6 mb-4 pb-1">
        <article
          class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-2 mb-2"
          v-for="(post, index) in posts.slice(0, 1)"
          :key="index"
        >
          <div class="row">
            <div class="col">
              <a :href="'/article/' + post.slug">
                <img
                  :src="getImage(post.image, 'medium')"
                  class="img-fluid border-radius-0"
                  :alt="post.name"
                />
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="thumb-info-caption-text">
                <div class="d-inline-block text-default text-1 mt-2 float-none">
                  <a
                    :href="'/article/' + post.slug"
                    class="text-decoration-none text-color-default"
                    >{{ getPostDate(post.created_at) }}</a
                  >
                </div>
                <h4
                  class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                >
                  <a
                    :href="'/article/' + post.slug"
                    class="text-decoration-none text-color-dark text-color-hover-primary"
                    >{{ getPostDescription(post.name, 200) }}</a
                  >
                </h4>
                <h5
                  class="d-block line-height-2 text-4 text-dark font-weight-bold mb-0"
                  v-html="getPostDescription(post.content, 500)"
                >
                </h5>
              </div>
            </div>
          </div>
        </article>
      </div>

      <div class="col-lg-6">
        <article
          class="thumb-info thumb-info-no-zoom bg-transparent border-radius-0 pb-4 mb-2"
          v-for="(post, index) in posts.slice(1, 5)"
          :key="index"
        >
          <div class="row align-items-center pb-1">
            <div class="col-sm-4">
              <a :href="'/article/' + post.slug">
                <img
                  :src="getImage(post.image, 'medium')"
                  class="img-fluid border-radius-0"
                  :alt="post.name"
                />
              </a>
            </div>
            <div class="col-sm-8 ps-sm-0">
              <div class="thumb-info-caption-text">
                <div class="d-inline-block text-default text-1 float-none">
                  <a
                    :href="'/article/' + post.slug"
                    class="text-decoration-none text-color-default"
                    >{{ getPostDate(post.created_at) }}</a
                  >
                </div>
                <h4
                  class="d-block pb-2 line-height-2 text-3 text-dark font-weight-bold mb-0"
                >
                  <a
                    :href="'/article/' + post.slug"
                    class="text-decoration-none text-color-dark text-color-hover-primary"
                    >{{ getPostDescription(post.name, 200) }}</a
                  >
                </h4>
                <p v-html="getPostDescription(post.content, 200)">
                </p>  
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>

    <div class="heading heading-border heading-middle-border">
      <h3 class="text-4">
        <strong
          class="font-weight-bold text-1 px-3 text-light py-2 bg-secondary"
          ></strong
        >
      </h3>
    </div>
    </div>
   
  </section>
</template>

<script>

export default {
  components: {
  },
  props: {
    posts: Array,
  },
  data: function () {
    return {
      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: ".swiper-dots",
          clickable: true,
        },
      },
    };
  },
  created() {
    console.log("this.posts", this.posts);
  },
  mounted() {
  },
  methods: {
    getPosts() {
      return this.posts;
    },
    getShortList(start, shortListSize) {
      if (this.posts) return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
  },
};
</script>

<style>
</style>