<template>
  <div class="main">
    <div class="container container-xl-custom p-relative z-index-2 py-5">
      <div class="row justify-content-center">
        <div class="col-lg-9 text-center">
          <h2 class="text-color-dark font-weight-bold text-10 ls-0 pb-2 mb-3">
            
            {{ $t("Get Touch with us") }}
          </h2>

          <p class="font-weight-medium text-4-5 line-height-5" v-html="settings.contact_text"></p>
          
        </div>
      </div>

      <div class="row p-relative mt-5 py-3">
        <div class="col-lg-6 py-5 py-lg-0 align-self-center">
          <h2 class="text-color-dark font-weight-bold text-6 ls-0 pb-2 mb-3">
            {{ $t("Contact Us") }}
          </h2>

          <ul class="list list-icons list-icons-style-2 list-icons-lg mb-5">
            <li class="text-3 font-weight-medium mb-1 line-height-5">
              <i class="icon-envelope icons border-width-2 text-3"></i
              ><a :href="'mailto:'+settings.email" class="p-relative top-5"
                >{{ settings.email }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="col-lg-6 text-center text-lg-start py-5 py-lg-0 align-self-center"
        >
          <div class="card border-0 bg-color-light">
            <div class="card-body px-lg-5 py-5 box-shadow-6 border-radius-2">
              <h2
                class="text-color-dark font-weight-bold text-6 ls-0 pb-2 mb-3"
              >
                {{ $t("Send a Message") }}
              </h2>

              <form
                class="contact-form form-style-3"
                ref="form"
                novalidate="novalidate"
              >
                <div
                  class="contact-form-success alert alert-success mt-4" v-if="success"
                >
                  <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}

                </div>

                <div class="contact-form-error alert alert-danger mt-4" v-if="error">
                  <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
                  <span class="mail-error-message text-1 d-block"></span>
                </div>

                <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
                    <strong>{{ $t("Error!") }}</strong> 
                    <ul>
                      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>

                <div class="row row-gutter-sm">
                  <div class="form-group col-lg-6 mb-4">
                    <input
                      type="text"
                      value=""
                      data-msg-required="Please enter your name."
                      maxlength="100"
                      class="form-control"
                      name="name"
                      id="name"
                      v-model="formData.name"
                      required
                      :placeholder="$t('Your Name')"
                    />
                  </div>
                  <div class="form-group col-lg-6 mb-4">
                    <input
                      type="text"
                      value=""
                      data-msg-required="Please enter your phone number."
                      maxlength="100"
                      class="form-control"
                      name="phone"
                      v-model="formData.phone"
                      id="phone"
                      required
                      :placeholder="$t('Phone Number')"
                    />
                  </div>
                </div>
                <div class="row row-gutter-sm">
                  <div class="form-group col-lg-6 mb-4">
                    <input
                      type="email"
                      :placeholder="$t('E-mail Address')"
                      value=""
                      data-msg-required="Please enter your email address."
                      data-msg-email="Please enter a valid email address."
                      maxlength="100"
                      class="form-control"
                      name="email"
                      v-model="formData.email"
                      id="email"
                      required
                    />
                  </div>
                  <div class="form-group col-lg-6 mb-4">
                    <input
                      type="text"
                      value=""
                      v-model="formData.subject"
                      data-msg-required="Please enter the subject."
                      maxlength="100"
                      class="form-control"
                      name="subject"
                      id="subject"
                      required
                      :placeholder="$t('Subject')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col mb-4">
                    <textarea
                      maxlength="5000"
                      v-model="formData.message"
                      data-msg-required="Please enter your message."
                      rows="10"
                      class="form-control"
                      name="message"
                      id="message"
                      required
                      :placeholder="$t('Message')"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col mb-0">
                    <button
                      v-on:click="checkForm"
                      class="btn btn-modern btn-primary border-0 font-weight-semi-bold positive-ls-1 text-uppercase text-2-5 px-5 py-3"
                      data-loading-text="Loading..."
                    >
                    {{ $t("Send a Message") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    checkForm: function (e) {
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      this.loading = true,
      axios
        .post(this.settings.BaseUrl + '/public/contact', this.formData)
        .then((res) => {
          this.loading = false;
          this.success = true;
          // this.response = res.data.data ? res.data.data : [];
            })
        .catch((res) => {
          this.error = true;
        }).finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
        });
    }
  },
};
</script>

<style>
.justify-content-center {
    justify-content: center!important;
}

.p-relative {
    position: relative!important;
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}

.mt-5 {
    margin-top: 3rem!important;
}

html .text-color-dark, html .text-dark {
    color: #212529!important;
}

.list.list-icons {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
}

.list.list-icons.list-icons-lg.list-icons-style-2 li {
    padding-top: 8px;
    padding-left: 42px;
}

.list.list-icons li {
    position: relative;
    padding-left: 25px;
}

html .bg-color-light, html .bg-light {
    background-color: #fff!important;
}

.box-shadow-6:not(.box-shadow-hover) {
    box-shadow: 0 12px 45px rgba(0,0,0,.08);
}

.border-radius-2 {
    border-radius: 8px!important;
}

.card-body {
    padding: 32px;
    padding: 2rem;
    position: relative;
    z-index: 1;
}

html .text-color-dark, html .text-dark {
    color: #212529!important;
}

.font-weight-bold {
    font-weight: 700!important;
}

.text-6 {
    font-size: 1.8em!important;
}

.row-gutter-sm {
    margin-right: -10px;
    margin-left: -10px;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
    font-size: 13.6px;
    font-size: 1.2rem;
    line-height: 1.85;
}

.form-style-3 .form-control {
    padding: 11.2px 16px;
    padding: 0.7rem 1rem;
    background-color: #f4f4f4;
    border: none;
}

input[type=text], input[type=password], input[type=datetime], input[type=datetime-local], input[type=date], input[type=month], input[type=time], input[type=week], input[type=number], input[type=email], input[type=url], input[type=search], input[type=tel], input[type=color], textarea {
    -webkit-appearance: none;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btn-primary {
    border-color: var(--color) !important;
    background-color: var(--color) !important;
    color: #fff;
    box-shadow: none;
}
.loader{  /* Loader Div Class */
    position: absolute;
    top:0px;
    right:0px;
    width:100%;
    height:100%;
    background-color:#b04040;
    /* background-image: url('../themes/shopwise/images/ajax-loader.gif'); */
    background-size: 50px;
    background-repeat:no-repeat;
    background-position:center;
    z-index:10000000;
    opacity: 0.2;
    filter: alpha(opacity=40);
}

.alert-success{
  text-align: center;
    font-weight: bold;
    color: green;
}

.alert-danger{
    text-align: center;
    font-weight: bold;
    color: red;
}

.btn-primary{
  color: var(--color);
  cursor:pointer;
}

</style>