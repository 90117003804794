import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/ssr'
import VueLazyLoad from 'vue-lazyload';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from "vue-gtag";

import interceptorsSetup from './helpers/interceptors';
import ImageHelpers from './helpers/image-helper';
import PostHelper from './helpers/post-helper';

//then put this into your main.js file anywhere AFTER loading Vue
import VueMeta from 'vue-meta'
import Embed from 'v-video-embed'
import VueJsonLD from 'vue-jsonld'
 
// schema google reach result
Vue.use(VueJsonLD)

// and running it somewhere here
interceptorsSetup()

Vue.use(VueAxios, axios);

Vue.use( VueAwesomeSwiper );

// global register
Vue.use(Embed);

Vue.use( VueLazyLoad, {
  preLoad: 0,
  throttleWait: 0,
  attempt: 1,
  scale: 0,
  observer: true,

  // optional
  observerOptions: {
      rootMargin: '0px',
      threshold: 0.1
  },
  adapter: {
      loaded( { bindType, el } ) {
          if ( el.nodeName === 'IMG' ) {
              handler( el );
          }
      },
      loading( listener, Init ) {
          if ( !listener.el.style.paddingTop ) {
              var padding = 150;
              var ratio = listener.el.getAttribute( 'image-ratio' );

              if ( ratio ) {
                  padding = ratio;
              } else if ( listener.el.getAttribute( 'width' ) && listener.el.getAttribute( 'height' ) ) {
                  padding = listener.el.getAttribute( 'height' ) / listener.el.getAttribute( 'width' ) * 100;
              }
              if ( listener.el.nodeName === 'IMG' ) {
                  listener.el.style.paddingTop = padding + '%';
                  listener.el.style.height = 0;
              }
          }

          if ( !listener.el.style.backgroundColor && !listener.el.nodeName === 'IMG' ) {
              listener.el.style.backgroundColor = '#f4f4f4';
          }
      },
  }
} );


function handler( el ) {
    let num = 0;
    let interval = window.setInterval( () => {
        if ( el.currentSrc || el.backgroundImage || num === 100 ) {
            el.removeAttribute( 'style' );
            el.classList.add( 'fade-in', 'fadeIn' );
            if ( !el.classList.contains( 'last-image' ) && !el.classList.contains( 'vue-lb-modal-image' ) ) {
                el.setAttribute( 'style', 'animation-duration: .3s; animation-delay: 0s' );
            }
            window.clearInterval( interval );
        }
        num++;
    }, 300 );
}

import 'swiper/dist/css/swiper.css'
import '../src/assets/css/css/animate.min.css'
import '../src/assets/css/css/ar.css'
import '../src/assets/css/css/global.css'
import '../src/assets/css/css/bootstrap.min.css'
import '../src/assets/css/css/porto-icons.min.css'
import '../src/assets/css/vendor/simple-line-icons/css/simple-line-icons.min.css'
import '../src/assets/css/vendor/fontawesome-free/css/all.min.css'
import '../src/assets/css/sass/style.scss'

import '../src/assets/plugins/localstorage.js'
import '../src/assets/plugins/filters.js'
import '../src/assets/plugins/directives/animate.js'
import '../src/assets/plugins/directives/parallax.js'
import '../src/assets/plugins/directives/sticky.js'



import {store} from './store/index'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.config.baseURLStorage = 'https://cnphub.com';

Vue.use(VueMeta)

const plugins = {
    install() {
        Vue.ImageHelpers = ImageHelpers;
        Vue.prototype.$helpers = ImageHelpers;
        Vue.PostHelper = PostHelper;
        Vue.prototype.$postHelper = PostHelper;
    }
  }
  Vue.use(plugins);
  
new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
