<template>
  <div class="post-page">
    <h4 class="widget-title section-sub-title">{{ $t("Recent Posts") }}</h4>
    <div class="row appear-animation animated fadeInUpShorter appear-animation-visible"
      data-appear-animation="fadeInUpShorter" data-appear-animation-delay="350" style="animation-delay: 350ms">
      <div class="col-lg-4" v-for="(post, index) in recentPosts" :key="index">
        <div class="custom-card-style-1 card rounded-0 hover-effect-1">
          <div class="custom-card-img-top-wrapper">
            <div class="post-date">
              <span class="day text-color-light font-weight-extra-bold bg-primary">{{ getPostDay(post.created_at)
              }}</span>
              <span class="month text-color-dark bg-light">{{
                getPostMonth(post.created_at)
              }}</span>
            </div>
            <a v-if="post.image" :href="'/article/' + post.slug"><img class="card-img-top" :src="getImage(post.image, 'medium')"
                :alt="post.name" /></a>
            <a v-else :href="'/article/' + post.slug"><img class="card-img-top" :src="getImage('banners/image.jpg' , 'default')"
                :alt="post.name" /></a>
          </div>
          <div class="card-body p-5">
            <h4 class="card-title font-weight-bold text-5 mb-3">
              <a :href="'/article/' + post.slug" class="text-decoration-none text-color-light">{{ post.name }}</a>
            </h4>
            <p class="card-text">
              {{ post.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="widget mb-5">
            <h4 class="widget-title section-sub-title">{{ $t("popularCategories") }}</h4>
            <div class="mb-3 pb-1">
              <a :href="`/category/${category.slug}`" v-for="(category, index) in popularCategories.slice(0, 9)"
                :key="index">
                <span class="badge badge-dark badge-sm text-uppercase pa-2 mr-b">{{ category.name }}</span>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-4" v-if="settings.popularTags > 0">
          <div class="widget mb-5">
            <h4 class="widget-title section-sub-title">{{ $t("PopularTags") }}</h4>
            <div class="mb-3 pb-1">
              <a :href="`/tag/${tag.slug}`" v-for="(tag, index) in popularTags" :key="index">
                <span class="badge badge-dark badge-sm text-uppercase pa-2 mr-b">{{ tag.name }}</span>
              </a>
            </div>
          </div>
        </div>

        <div v-if="settings.about" class="col-4">
          <div class="widget widget-categories mb-5">
            <h4 class="widget-title section-sub-title">{{ $t("About Us") }}</h4>
            <p>{{ settings.about }}</p>
          </div>
        </div>

      </div>

      <div class="row">

        <div class="col-4" v-if="hasPhotosFun()==1">
          <h4 class="widget-title section-sub-title" v-if="count(photos) > 0">{{ $t("Images") }}</h4>
          <div v-for="(item, index) in photos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <a href="#" target="_blank" class="my-4 pt-3 d-block" v-if="logo.key !== 'caption'">
                <img :alt="logo.caption" class="img-fluid" :src="getImage(logo.value)" />
              </a>
            </div>
          </div>
        </div>

        <div v-if="hasLogosFun()==1" class="col-4">
          <h4 class="widget-title section-sub-title" v-if="count(logos) > 0">{{ $t("Logos") }}</h4>
          <div v-for="(item, index) in logos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <a href="#" target="_blank" class="my-4 pt-3 d-block" v-if="logo.key !== 'logo_url'">
                <img :alt="logo.caption" class="img-fluid" :src="getImage(logo.value)" />
              </a>
            </div>
          </div>
        </div>
 
        <div v-if="hasVidFun()==1" class="col-4">
          <h4 class="widget-title section-sub-title" v-if="count(logos) > 0">{{ $t("Videos") }}</h4>
          <div v-for="(item, index) in videos" :key="index" class="mb-5">
            <div v-for="(logo, index1) in item" :key="index1">
              <video-embed :params="{ autoplay: 1 }" :src="logo.value"></video-embed>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>



<script>
import Sticky from "vue-sticky-directive";

export default {
  directives: {
    Sticky,
  },
  data: function () {
    return {
      isSticky: false,

    };
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
    popularCategories: {
      type: Array,
    },
    recentPosts: {
      type: Array,
    },
    popularTags: {
      type: Array,
    },
    logos: {
      type: String,
    },
    videos: {
      type: String,
    },
    photos: {
      type: String,
    },
  },
  mounted: function () {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });

  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostMonth(date) {
      return this.$postHelper.getPostDay(date);
    },
    getPostDay(date) {
      return this.$postHelper.getPostMonth(date);
    },
    getPostDescription(text) {
      return this.$postHelper.getPostSmallDescription(text, 160);
    },
    resizeHandler: function () {
      this.isSticky = window.innerWidth > 991 ? true : false;
    },
    
    hasVidFun() {
    
       return  this.$postHelper.hasVidFun(this.videos);
    },
    hasLogosFun() {
      
      return  this.$postHelper.hasLogosFun(this.logos);
    },
    hasPhotosFun() {
      
      return  this.$postHelper.hasPhotosFun(this.photos);
    },
    count(element) {
      if (element !== undefined) {
        return element.length;
      } else {
        return 0;
      }
    },
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
};
</script>
  
<style>
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.btn-dark {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
  color: #fff;
  box-shadow: none;
}

.badge-dark {
  color: #fff;
  background-color: var(--color) !important;
}

.custom-card-style-1 .custom-card-img-top-wrapper .post-date {
  position: absolute;
  top: 35px;
  left: 35px;
  text-align: center;
}

.custom-card-style-1 .custom-card-img-top-wrapper .post-date .day {
  background: var(--color) !important;
  border-radius: 2px 2px 0 0;
  color: #ccc;
  display: block;
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
  font-family: "Poppins", Arial, sans-serif;
}

.custom-card-style-1 .custom-card-img-top-wrapper .post-date .month {
  display: block;
  background: #ccc;
  border-radius: 0 0 2px 2px;
  color: var(--color) !important;
  font-size: 0.8em;
  line-height: 1.8;
  padding: 1px 10px;
  text-transform: uppercase;
  font-family: "Poppins", Arial, sans-serif;
}

.post-page h4 a {
  color: var(--color) !important;
}
</style>