<template>
	<section class="blog-section post-date-in-media media-with-zoom bg-gray">
		<div class="container">
			<pv-carousel
				class="appear-animate"
				data-animation-name="fadeIn"
				:options="blogSlider"
				v-animate
			>
				<pv-post-four
					class="swiper-slide"
					v-for="(post,index) in posts"
					:key="'post' + index"
					:post="post"
					:post-index="index"
				></pv-post-four>
			</pv-carousel>
		</div>
	</section>
</template>

<script>
import PvCarousel from '../Utility/Common/PvCarousel.vue';
import PvPostFour from './posts/PvPostFour';
import { blogSlider } from '@/utils/data/carousel';
import axios from 'axios'

export default {
	components: {
		PvCarousel,
		PvPostFour,
	},
	data: function () {
		return {
			blogSlider: blogSlider,
			posts: [],
		}
	},
	mounted: function () {
		this.getPosts();
	},
	methods: {
		getPosts() {
			this.slides = [];
			this.isLoading = true;
			axios.get('https://api.cnphub.com/public/posts')
			.then(res => {
				this.posts = res.data.data ? res.data.data : [];
			})
			.catch(res => {
				console.log(res);
			});
		},

	},
}
</script>