<template>
  <section class="error-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7">
          <!-- <div class="error-thumb text-center">
            <img src="@/assets/images/404.png" alt="" />
          </div> -->
        </div>
        <div class="col-lg-6">
          <div class="error-content text-center">
            <h3 class="title">410 Page Deleted permanently </h3>
            <ul>
              <li>
                <a class="main-btn" href @click.prevent="$router.go(-1)"
                  >GO Back</a
                >
              </li>
              <li>
                <router-link class="main-btn btn-2" to="/contact"
                  >CONTACT US</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  //insert the following code for vue-meta to work
  metaInfo() {
    return {
      meta: [
        { name: "robots", content: 'noindex,nofollow' },
      ],
    };
  },
};
</script>

<style></style>
