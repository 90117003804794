<template>
	<header class="header">
		<div class="header-top">
			<div class="container">
				<div class="header-col w-100 text-center">
					<span class="text-primary text-uppercase">Use <strong>PORTO24</strong> and get 20% off in all
						products</span>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left">
					<button
						class="mobile-menu-toggler"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>

					<router-link
						to="/"
						class="logo"
					>
						<img
							src="@/assets/css/images/home/logo.png"
							width="97"
							height="51"
							alt="Porto Logo"
						/>
					</router-link>
				</div>

				<div class="header-center d-none d-lg-block">
					<pv-main-menu></pv-main-menu>
				</div>

				<div class="header-right">
					<router-link
						to="/pages/login"
						class="header-icon"
					>
						<i class="icon-user-2"></i>
					</router-link>

					<pv-header-search></pv-header-search>

					<router-link
						to="/pages/wishlist"
						class="header-icon"
					>
						<i class="icon-wishlist-2"></i>
					</router-link>

					<pv-cart-menu></pv-cart-menu>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';
import PvCartMenu from './PvCartMenu';
import PvHeaderSearch from './PvHeaderSearch';

document.querySelector( 'body' ).classList.add( 'loaded' );

export default {
	components: {
		PvMainMenu,
		PvCartMenu,
		PvHeaderSearch
	},
	methods: {
		showMobileMenu: function () {
			document.querySelector( 'body' ).classList.add( 'mmenu-active' );
		},
		showMobileSearch: function ( e ) {
			let headerSearch = e.currentTarget.closest( '.header-search' );
			headerSearch.classList.add( 'show' );
			headerSearch
				.querySelector( '.header-search-wrapper' )
				.classList.add( 'show' );
		}
	}
};
</script>