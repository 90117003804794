<template>
	<div class="container">
		<section
			v-animate
			data-animation-delay="100"
			data-animation-duration="300"
		>
			<isotope
				class="row grid"
				:options="isotopeOptions"
				:list="[]"
			>
				<div
					class="grid-item col-lg-5 height-x1"
					key="intro-1"
				>
					<div class="home-banner">
						<figure class="figure29" style="background-color: #f1f4f9">
							<img
								:src="slider[0].image"
								width="674"
								height="316"
								alt="banner"
							/>
						</figure>
					</div>
				</div>
				<div
					class="grid-item col-lg-7 height-x2"
					key="intro-2"
				>
					<div class="home-banner">
						<figure class="figure29" style="background-color: #efedee">
							<img
								:src="slider[1].image"
								width="951"
								height="651"
								alt="banner"
							/>
						</figure>
					</div>
				</div>
				<div
					class="grid-item col-6 col-lg-2 height-x1"
					key="intro-3"
				>
					<div class="home-banner">
						<figure class="figure29" style="background-color: #24252a">
							<img
								:src="slider[2].image"
								width="300"
								height="315"
								alt="banner"
							/>
						</figure>
					</div>
				</div>
				<div class="grid-item col-6 col-lg-3 height-x1" key="intro-4">
					<div class="home-banner">
						<figure class="figure29" style="background-color: #616da9">
							<img
								:src="slider[3].image"
								width="300"
								height="315"
								alt="banner"
							/>
						</figure>
					</div>
				</div>
				<div
					class="col-1 pr-0 pl-0 grid-col-sizer"
					key="intro-sizer"
				></div>
			</isotope>
		</section>
	</div>
</template>

<script>
import isotope from 'vueisotope';
import axios from 'axios'

export default {
	components: {
		isotope
	},
	data: function () {
		return {
			isotopeOptions: {
				itemSelector: 'grid-item',
				layoutMode: 'masonry',
				percentPosition: false,
				masonry: {
					columnWidth: '.grid-col-sizer'
				}
			},
			slider: []
		}
	},
	mounted() {
      this.getWebsiteElements();
    },
	methods: {
      getWebsiteElements() {
                this.slides = [];
                this.isLoading = true;
                axios.get('https://api.cnphub.com/public/posts')
                .then(res => {
                    this.slider = res.data.data ? res.data.data : [];
					console.log(this.slider[0].image);
					console.log('Slider');
                })
                .catch(res => {
                  console.log(res);
                });
            },
        },
}
</script>