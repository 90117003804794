<template>
  <section class="home-section mb-2 ">
    <div class="container theme18">
      <div class="row">
        <div class="col-md-8 col-xl-8 col-lg-8 mb-xl-0 mb-2">

          <PvCarousel40 class="home-slider  slide-animate show-nav-hover dot-inside nav-big h-100 text-uppercase"
            :options="introSlider" v-animate>
            <div class="home-slide home-slide1 banner swiper-slide" v-for="(post, index) in posts" :key="index">
              <div class="row">
                <div class="col-md-5 slider-col-img">
                  <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
                </div>
                <div class="col-md-7 slider-col-content">
                  <h3 class=" appear-animate " data-animation-name="fadeInRightShorter" data-animation-delay="1100"
                    v-animate>
                    <a class="slider-title" :href="'/article/' + post.slug">{{ getPostDescription(post.name, 99) }}</a>
                  </h3>


                  <!-- <div class=" appear-animate" v-if="post.subtitle">{{ getPostDescription(post.subtitle, 120) }}</div>
                  <div class="slider-cats " v-if="post.cats.length">
                    <span v-for="(s_cat, s_cat_index) in post.cats" class="slider-cat " :key="s_cat_index">{{
                      s_cat.name
                    }}
                    </span>
                  </div> -->
                </div>
                <div class="col-md-4   mb-20 sub-list-col-2" v-for="(post, index) in getShortList(4, 7)"
                  :key="index">
                  <div class="article m-h-100">
                    <div>

                      <div class="date ">{{
                        getPostDate(post.created_at)
                      }}
                      </div>

                      <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 120) }}</a></h4>
                      <div class="slider-cats " v-if="post.cats.length">
                        <span v-for="(cat, cat_index) in post.cats.slice(0, 2)" class="slider-cat " :key="cat_index">{{
                          cat.name
                        }}
                        </span>
                      </div>
                    </div>
                    <!-- <div v-if="index > 0" class="small-article">
              <span v-for="(c1_cat, c1_cat_index) in post.cats" class="slider-cat " :key="c1_cat_index">{{
                  c1_cat.name
                }}
                </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name) }}</a></h4>
            </div> -->

                  </div>


                </div>

              </div>


              <!-- <h6 v-if="post.created_at" class=" slider-date appear-animate" data-animation-name="fadeInDownShorter"
                data-animation-delay="100" v-animate>{{
                  getPostDate(post.created_at)
                }}
              </h6> -->
              <!-- <h2 class="text-transform-none appear-animate" data-animation-name="fadeInUpShorter"
                    data-animation-delay="600" v-animate>Condensed Milk</h2> -->

            </div>

          </PvCarousel40>
        </div>
        <div class="col-md-4 b-left-right   mb-20 sub-list-col-1">
          <div class="article" v-for="(post, index) in getShortList(1, 4)" :key="index">
            <div class="row">
              <div class="col-md-4 small-post-image">
                <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')">
              </div>
              
              <div class="col-md-8">

                <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name, 120) }}</a></h4>
                <div class="date ">{{
                  getPostDate(post.created_at)
                }}
                </div>
              </div>
              <!-- <div class="slider-cats " v-if="post.cats.length">
                <span v-for="(c1_cat, c1_cat_index) in post.cats" class="slider-cat " :key="c1_cat_index">{{
                  c1_cat.name
                }}
                </span>
              </div> -->

            </div>
            <!-- <div v-if="index > 0" class="small-article">
              <span v-for="(c1_cat, c1_cat_index) in post.cats" class="slider-cat " :key="c1_cat_index">{{
                  c1_cat.name
                }}
                </span>
              <h4><a :href="'/article/' + post.slug">{{ getPostDescription(post.name) }}</a></h4>
            </div> -->

          </div>

        </div>




      </div>
    </div>
  </section>
</template>

<script>
import PvCarousel40 from './PvCarousel40.vue';
import axios from 'axios'
import imageHelper from '../../../helpers/image-helper'

export default {
  components: {
    PvCarousel40

  },
  props: {

    posts: Array,


  },
  data: function () {
    return {

      mainSliderArticle: [],
      articlesList: null,
      subListCol1: [],
      subListCol2: [],
      introSlider: {
        slidesPerView: 1,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-dots',
          clickable: true
        }
      }
    }
  },
  created() {


  },
  mounted() {
    // this.getSliderNews();

  },
  methods: {
    getShortList(start, shortListSize) {
      if (this.posts)
        return this.posts.slice(start, shortListSize);
    },
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text, length = 180) {
      return this.$postHelper.getPostSmallDescription(text, length);
    },
    // getSliderNews() {
    //   this.settings = JSON.parse(localStorage.getItem("settings"));




    //       if (this.attributes.posts.length > 2) {
    //         this.article1 = this.attributes.posts[0];
    //         this.article2 = this.attributes.posts[1];
    //         this.attributes.posts.shift(1);  this.attributes.posts.shift(1); 
    //       }


    //       if (this.attributes.posts.length > 0)
    //         this.mainSliderArticle = this.attributes.posts;



    // },
  },
}
</script>

<style>
.theme18 .home-slider img {
  height: auto;

}
.theme18 .date{
  font-size: 13px;
    color: var(--color);
}
.theme18 .article.m-h-100 h4 {
  min-height: 100px;
  text-transform: capitalize;
}

.theme18 .slider-col-img,
.theme18 .slider-col-content {
  border-bottom: 2px solid var(--color);
  padding-bottom: 20px;
  margin-bottom: 15px;
  min-height: 300px;
}

.theme18 .b-left-right {
  border-left: 2px solid var(--color);
  border-right: 2px solid var(--color);
}

.theme18 .home-slider {
  /*   
  min-height: 50rem;
  max-height: 50rem; */
}

.theme18 .home-slider {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
}

.theme18 .slider-cats {
  display: block;
  margin-top: 10px;
  margin-bottom: 8px;
}

.theme18 .slider-cat {
  background-color: var(--color);
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.theme18 .home-slider .slider-title {
  color: #000;
}

.sub-list-col-1 h4,
.sub-list-col-2 h4 {
  font-weight: 400;
  font-size: 18px;
  border-bottom: 3px solid var(--color);
  padding: 10px 2px;
}

.sub-list-col-1 h4 a,
.sub-list-col-2 h4 a {
  color: #000;
}

.theme2.top-6-articles {
  margin-top: 30px;
  margin-bottom: 20px;
}

.theme18 .small-article {
  padding-top: 15px;
  padding-bottom: 10px;
  border-top: 1px solid #f2f2f2;
}

.theme18 .small-article h4 a {
  font-size: 0.8em;
  font-weight: bold;

}

.theme18 .home-slider .slider-date,
.theme18 .sub-list-col-1 .slider-date,
.theme18 .sub-list-col-2 .slider-date {
  width: 160px;
  padding: 10px;
  background: #fff;
  margin-top: -33px;
  display: block;
  position: absolute;
}

.theme2.top-6-articles .article {}

.theme18 .sub-list-col-1 .article{
  font-weight: 400;
  font-size: 18px;
  border-bottom: 3px solid var(--color);
  padding: 10px 2px;
}
.theme18 .sub-list-col-1  .small-post-image{
width: 90px;
}
.home-slider.theme2 .readmore {
  padding: 10px;
  background-color: var(--color);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  font-size: 14px;
  display: table-row;
  width: 200px;
  text-align: center;
  margin-top: 15px;
}

.home-slider.theme2 .swiper-pagination-bullets .swiper-pagination-bullet {

  border-color: var(--color);

}

.home-slider.theme2 .bg-black-trans {
  background: rgba(255, 255, 255, 0.7);
}

.home-section .banner2 .slide-bg {
  max-height: 23vh;
}

.home-slider.theme2 .swiper-carousel.dot-inside .swiper-pagination-bullets {
  position: absolute;
  right: 3.6rem;
  left: 6.4rem;
  bottom: 3.1rem;
  text-align: center;
}

.home-section .banner2 .banner-layer {
  top: auto !important;
  right: 9.5%;
  margin-top: 0px;
  bottom: 10px !important;
}

.home-section .banner2 h3 {
  font-size: 1.2em;
}

.home-section .home-slide1 h3 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 2.4em;
}
</style>