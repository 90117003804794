<template>

	<footer class="footer bg-dark position-relative theme7">

		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="footer-logo">
						<router-link to="/" class="logo">

							<img :src="settings.logo" width="150" height="80" alt="Logo" />
						</router-link>
						<div class="text-about">
							{{ settings.about }}
						</div>
					</div>
				</div>
				
				<div class="col-md-4">
					<div class="widget">
						<h4 class="footer-title">
							<span class="word-1">{{ $t('Latest') }}</span><span class="word-2">{{ $t('News') }}</span>
						</h4>
						<ul class="latest-news">
							<div class="post-default left-details product-widget " v-for="post in topNews" :key="post.id">
								<figure>
									<router-link :to="'/article/' + post.slug + ''">
										
											<img v-if="post.image" :src="`${getImage(post.image, 'thumb')}`"
												:width="60" :height="60"
												:alt="post.name" />
										
									</router-link>
								</figure>

								<div class="post-details">

									<h2 class="post-title">
										<router-link :to="'/article/' + post.slug + ''">{{ post.name }}</router-link>
									</h2>

								</div>
							</div>
							
						</ul>

					</div>
				</div>
				<div class="col-md-4">
					<div class="widget">
						<h4 class="footer-title">
							<span class="word-1">{{ $t('Quick') }}</span><span class="word-2">{{ $t('Links') }}</span>
						</h4>
						<ul class="footer-links">
							<li v-for="item in footerMenu" :key="item.id">
								<router-link :to="item.url">{{ item.title }}</router-link>
							</li>
						</ul>

					</div>
				</div>
			</div>

		</div>
		<div class="footer-middle">
			<div class="container position-static">
				<!-- <div class="row">
					<div class="col-lg-12 col-sm-12 pb-2 pb-sm-0">
						<div class="widget">
							<ul class="links">
								<li v-for="item in footerMenu" :key="item.id">
									<router-link :to="item.url">{{ item.title }}</router-link>
								</li>
							</ul>

						</div>
					</div>


				</div> -->
			</div>
		</div>

		<div class="footer-bottom-section">
			<div class="footer-bottom container">
				<div class="container  align-items-center">
					<div class="pb-2 pb-sm-0">




					</div>
					<div class="d-sm-flex">

						<div class="footer-left">
							<span class="footer-copyright">© {{ $t('copyright') }} 2023. {{
								$t('All Rights Reserved.')
							}}</span>
						</div>

						<div class="footer-center">
							<!-- <router-link to="/" class="logo">

						<img :src="settings.logo" width="150" height="80" alt="Logo" />
					</router-link> -->
						</div>

						<div class="footer-right">
							<div class="payment-icons">
								<div class="social-icons">
									<a :href="settings.facebook" class="social-icon social-facebook icon-facebook"
										title="Facebook"></a>
									<a :href="settings.twitter" class="social-icon social-twitter icon-twitter"
										title="Twitter"></a>

									<!-- <a :href="settings.instagram" class="social-icon social-linkedin fab fa-linkedin-in"
								title="Linkedin"></a> -->
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</footer>
</template>
<script>
import axios from 'axios'

export default {
	props: {
		attributes: {
			settings: Array,
			footerMenu: Array,
		},
	},
	data: function () {
		return {
			topNews: Array,
		};
	},
	created() {
		this.getWebsiteSettings();
		this.getTopNews();
	},
	computed: {
	},
	mounted() {


	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.footerMenu = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.footerMenu = JSON.parse(localStorage.getItem("footerMenu"));
		},
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getTopNews() {
			this.settings = JSON.parse(localStorage.getItem("settings"));

			axios.get(this.settings.BaseUrl + '/public/top-news?limit=3')
				.then(res => {

					this.topNews = res.data.data.posts ? res.data.data.posts : '';

				})
				.catch(res => {
					console.log(res);
				});
		},

	},
};
</script>

<style>
#app .footer.theme7  a,#app .footer.theme7  p{
    font-size: 14.6px;
}
#app .footer.theme7  .text-about {
    text-align: left;
	font-size: 14.6px;
}
#app .footer.theme7 .footer-logo {
	widows: 300px;
	display: inline-block;
	max-width: 300px;
}
#app .footer.theme7 h4.footer-title{
	padding: 0;
	border-bottom: 5px solid var(--secondary-color);
}
.footer-title span.word-1,
.footer-title span.word-2{
	color:#fff;
	padding: 5px 10px;
	font-weight: 500;
}
.footer-title span.word-1{
background-color: var(--secondary-color);
}
.footer-title span.word-2{
	background-color: var(--color);
}
#app .footer.theme7 .footer-bottom-section {
	background: var(--footer-color) !important;
}

#app .footer.theme7 .footer-links a:hover {
	color: var(--color) !important;
}

#app .footer.theme7 {
	padding-top: 20px;
	background: #f2f2f2 !important;
}

.footer.theme7 .footer-center {
	margin: 0 auto;
}

#app .footer.theme7 .footer-middle {
	padding-top: 1.7rem;
	padding-bottom: 0rem;
}

.footer.theme7 .footer-copyright {
	color: #fff;
}

.footer.theme7 .footer-links li {
	width: 50%;
	float: left;
}
.footer.theme7  .post-title a:hover{
	color: var(--color) !important;
}
</style>