<template>
	<nav class="main-nav w-100 theme7">
		<ul class="menu main-menu ">


			<li v-for="item in mainMenu" :key="item.id">
				<a :href="item.url" :class="getClassMenu(item.css_class)">{{ item.title }}</a>

				<div v-if="item.css_class == 'mega'" class="megamenu megamenu-fixed-width megamenu-3cols">


					<div v-for="(catItem, index) in categories" :key="catItem.category.name">
						<!-- {{ index }},,{{ item.category }} -->

						<div class="col-lg-12" v-if="compareString(index, item.url) == true">
							<h4 class="section-sub-title"><span class="word-1">{{
								catItem.category.name
							}}</span><span class="word-2">{{ $t('News') }}</span></h4>
							<!-- <h3 class="cat-title"><a :href="item.url" class="nolink"></a></h3> -->
							<div class="row">
								<div class="post-block col-lg-6" v-for="post in catItem.posts" :key="post.id">
									<img v-if="post.image" :src="`${getImage(post.image, 'medium')}`"
										:v-lazy="`${getImage(post.image, 'medium')}`" :width="80" :height="80"
										:alt="post.name" />
									<br><a class="post-title" :href="post.slug">
										{{ post.name }}
									</a>
								</div>
							</div>

						</div>


					</div>
				</div>
			</li>




		</ul>
	</nav>
</template>
<script>
// import { mainMenu } from '~/utils/data/menu';
import axios from 'axios'
export default {
	data: function () {
		return {
			mainMenu: [],
			categories: [],
		};
	},
	created() {
		this.getMainMenu();
		this.getPosts();
	},
	computed: {
		isPageItemActived: function () {
			let exItems = ['blog', 'about-us', 'contact-us'];

			if (
				this.$route.path.includes('/pages') &&
				exItems.findIndex(item => this.$route.path.includes(item)) ===
				-1
			) {
				return true;
			}

			return false;
		}
	},
	methods: {

		getClassMenu(css_class) {
			return css_class == 'mega' ? 'menu-link sf-with-ul' : '';
		},
		compareString(str1, str2) {
			var lastSegment = '';
			var parts = str2.split('/');
			if (parts){
				lastSegment = parts.pop() || parts.pop(); 
			}
			console.log('lastSegment=' + lastSegment );
			console.log( '-' + str1);
			if (str1== lastSegment) return true;
			return false;
		},
		getMainMenu() {
			this.mainMenu = JSON.parse(localStorage.getItem("mainMenu"));

		},
		getPostsByCatSlug(slug) {

		},
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getPosts() {
			this.isLoading = true;

			axios
				.get("https://api.cnphub.com/public/get-categories-with-data" + '?limit=2', {
				})
				.then((res) => {
					this.categories = res.data.data.categories ? res.data.data.categories : [];



				})
				.catch((res) => {
					console.log(res);
				});
		},
	},
};
</script>
<style>
.main-nav.theme7 .menu-vertical>li:not(:first-child)>a {
	margin-top: -1px;
	border-top: 1px solid #e7e7e7;
}
.main-nav.theme7 a.post-title,.main-nav.theme7  h3.cat-title a {
	color: #000!important;
}

.main-nav.theme7 .menu.main-menu>li .menu-link {
	font-size: 12px;
	padding: 1.4rem 0 1.4rem 0.5rem;
	letter-spacing: -.025em;
}

.main-nav.theme7 .menu>li {
	margin-right: 1.2rem;
	letter-spacing: -0.01em;
	width: 100%;
	padding: 1.2rem 0 1.1rem 0.5rem;
}

.main-nav.theme7 .menu.main-menu>li a {
	color: #fff;
	padding: 0 !important;
}

.main-nav.theme7 .post-block img {
	width: 100%;
	height: auto !important;
}

.main-nav.theme7 .post-block a {
	color: #000 !important;
	padding: 0 !important;
	font-size: 16px;
}

h3.cat-title {
	color: #000 !important;
	padding: 0 !important;
	font-size: 16px;
}

.main-nav.theme7 .menu-vertical>li .menu-link {
	display: block;
	padding: 1.2rem 1rem 1.4rem 0.5rem;
	font-size: 1.4rem;
	font-weight: 600;
	text-transform: capitalize;
	transition: none;
	color: #fff;
}
</style>