<template>
	<div class="container feature-cat">
		<div class="row mt-4">
			<div class="col-lg-12 text-center appear-animation animated fadeInUpShorter appear-animation-visible"
				data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" style="animation-delay: 400ms">
				<h2 class="cat-title font-weight-normal text-6 mt-3 mb-5"><strong class="font-weight-extra-bold">{{ cat1name
				}}</strong>
				</h2>
			</div>
		</div>
		<div class="row recent-posts pb-4 mb-5 appear-animation animated fadeInRightShorter appear-animation-visible"
			data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200" style="animation-delay: 200ms">
			<div :class="getClass+' col-sm-12 mb-4 mb-lg-0'" v-for="(post, index) in cat1Posts" :key="index">
				<article class="cat-post">
					<div class="row">
						<div class="col-auto pe-0">
							<div class="date">
								<span class="day text-color-dark font-weight-extra-bold">{{ getPostDay(post.created_at)
								}}</span>
								<span class="month bg-color-primary font-weight-semibold text-color-light text-1">{{
									getPostMonth(post.created_at) }}</span>
							</div>
						</div>
						<div class="col ps-1">
							<h4 class="post-title line-height-3 text-4">
								<a :href="'/article/' + post.slug" class="text-dark">{{ getPostDescription(post.name, 99)
								}}</a>
							</h4>
							<p class="line-height-5 pe-3 mb-1" v-html="getPostDescription(post.content, 200)"></p>
							<a class="btn btn-light text-uppercase text-primary text-1 py-2 px-3 mb-1 mt-2"
								:href="'/article/' + post.slug"><strong>{{ $t("Read More") }}</strong><i
									class="fas fa-chevron-right text-2 ps-2"></i></a>
						</div>
					</div>
				</article>
			</div>
		</div>
	</div>
</template>


<script>
import axios from "axios";

export default {
	components: {},
	data: function () {
		return {
			cat1Posts: [],
			cat2Posts: [],
			cat1name: '',
			cat2name: '',
			cat3name: ''
		};
	},
	props: {
		attributes: {
			type: Array,
			limit: Number,
		},
	},
	computed: {

		getClass() {
			if (this.cat1Posts.length == 1)
				return 'col-md-12';
			else if (this.cat1Posts.length == 2)
				return 'col-md-6';
			else if (this.cat1Posts.length == 3)
				return 'col-md-4';
			else return 'col-md-3';

		},
	},
	mounted() {
		this.getPosts();
	},
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
		getPostDate(date) {
			return this.$postHelper.getPostDate(date);
		},
		getPostMonth(date) {
			return this.$postHelper.getPostDay(date);
		},
		getPostDay(date) {
			return this.$postHelper.getPostMonth(date);
		},
		getPostDescription(text ,lenght=120) {
			return this.$postHelper.getPostSmallDescription(text, lenght);
		},
		getPosts() {
			axios
				.get(
					"https://api.cnphub.com/public/twoCategoryData" +
					"?limit=" +
					this.attributes.limit +
					"&cat1=" +
					this.attributes.cat1 +
					"&cat2=" +
					this.attributes.cat2,
					{}
				)
				.then((res) => {
					this.cat1Posts = res.data.data.cat1 ? res.data.data.cat1 : [];
					this.cat1name = res.data.data.cat1name ? res.data.data.cat1name : "";
				})
				.catch((res) => {
					console.log(res);
				});

			axios
				.get(
					"https://api.cnphub.com/public/twoCategoryData" +
					"?limit=" +
					this.row2count +
					"&cat1=" +
					this.attributes.cat1 +
					"&cat2=" +
					this.attributes.cat2,
					{}
				)
				.then((res) => {
					this.cat2Posts = res.data.data.cat2 ? res.data.data.cat2 : [];
					this.cat2name = res.data.data.cat2name ? res.data.data.cat2name : "";
				})
				.catch((res) => {
					console.log(res);
				});
		},
	},
};
</script>


<style>
.recent-posts .date {
	margin-right: 10px;
	text-align: center;
}

.recent-posts .date .day {
	background: #f7f7f7;
	color: #ccc;
	display: block;
	font-size: 18px;
	font-weight: 500;
	font-weight: 700;
	padding: 8px;
}

.recent-posts .date .month {
	background: var(--color) !important;
	color: #fff;
	font-size: .9em;
	padding: 3px 10px;
	position: relative;
	top: -2px;
}

a strong {
	color: var(--color);
}

a i {
	color: var(--color);
}

h2 strong {
	color: var(--color);
}

html body .btn-light,
html body .btn-outline.btn-light {
	border-color: #d9d9d9 #d9d9d9 #bfbfbf;
	color: #777;
}

.line-height-3 a:hover {
	color: var(--color);
}

.recent-posts .date .day {
	color: var(--color) !important;
}
</style>