<template>
	<div class="container">
		<section
			class="featured-products-section appear-animate skeleton-body"
			data-animation-name="fadeInUpShorter"
			data-animation-delay="100"
			v-animate
		>
			<h2 class="section-title text-center d-flex align-items-center">Featured Products</h2>

			<pv-carousel
				class="products-slider swiper-carousel dots-top dots-small"
				:options="baseSlider3"
				v-if="products.length === 0"
			>
				<div
					v-for="item in [1,2,3,4,5]"
					:key="'featured' + item"
					class="swiper-slide product-loading-overlay"
				></div>
			</pv-carousel>

			<pv-carousel
				class="products-slider swiper-carousel dots-top dots-small"
				:options="baseSlider3"
			>
				<pv-product-three
					v-for="(product,index) in products"
					:key="'featured' + index"
					:product="product"
					class="swiper-slide appear-animate"
					data-animation-name="fadeIn"
					data-animation-delay="100"
					data-animation-duration="800"
					v-animate
				></pv-product-three>
			</pv-carousel>
		</section>
	</div>
</template>

<script>
import PvCarousel from '../Utility/Common/PvCarousel7.vue';
import PvProductThree from './products/PvProductThree.vue';
import { baseSlider3 } from '@/utils/data/carousel';

export default {
	components: {
		PvCarousel,
		PvProductThree
	},
	props: {
		products: Array
	},
	data: function() {
		return {
			baseSlider3: baseSlider3
		};
	}
};
</script>