<template>
  <div v-if="settings.status == 'pending' || settings.status == 'draft'">
      <soon :settings="settings">
      </soon>
  </div> 
  <div v-else>
<component :is="settings.listing_style" :key="settings.listing_style">
  </component>
  <pv-mobile-menu></pv-mobile-menu>
  </div>
  
  
</template>

<script>
import axios from 'axios'

import TimeLine from "./TimeLine.vue";
import LargeImage from "./LargeImage.vue";
import MediumImage from "./MediumImage.vue";
import Grid from "./Grid.vue";
import { useFavicon } from "@vueuse/core";
import { computed, ref } from "vue";
import PvMobileMenu from "../Utility/Common/PvMobileMenu.vue";
import soon from "../soon.vue";
import Vue from 'vue'
import VueGtag from "vue-gtag";
import router from '../../router'

export const adIntegration = () => {
  const adScript = document.createElement('script');

  adScript.type = 'text/javascript';
  adScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  document.head.appendChild(adScript);
};


export default {
  components: {
    Grid,
    PvMobileMenu,
    TimeLine,
    LargeImage,
    MediumImage,
    soon
  },
  data: function () {
    return {
      settings: [],
    };
  },
  created() {
    this.getWebsiteSettings();
     this.getLanguage();
     this.getMenu();
     this.favicon();
  },
  mounted() {
    this.getWebsiteSettings();
    this.favicon();
    adIntegration();
    setTimeout(() => this.mainPageAd(), 3000);
  },
  methods: {
    favicon() {
      const type = ref("vueuse");
      const favicon = computed(
        () =>
          (type.value = this.$helpers.getImageFullPath(
            this.settings.favicon,
            "medium"
          ))
      );
      console.log(favicon);
      useFavicon(favicon, {
        baseUrl: "",
        rel: "icon",
      });
    },
    mainPageAd(){

        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(() => {

        window.googletag.destroySlots();

        var tags = JSON.parse(this.settings.tag_script);

        for(var i=0; i< tags.length; i++){
          window.googletag.defineSlot(''+ tags[i].id +'', [parseInt(tags[i].size1), parseInt(tags[i].size2)], ''+ tags[i].name +'').addService(window.googletag.pubads());
        }

        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();

        for(var j=0; j< tags.length; j++){
            window.googletag.display('' +tags[j].name+ '');
        }

      });
    },
    addAnalytics(){
      Vue.use(VueGtag, {
        config: { id: this.settings.google_analytics }
      }, router);
    },
    async getWebsiteSettings() {
      this.settings = [];
      axios.get('https://api.cnphub.com/public/settings')
        .then(res => {

          this.websiteSettings = res.data.data.settings ? res.data.data.settings : '';
          localStorage.setItem("settings", res.data.data.settings ? JSON.stringify(res.data.data.settings) : '');
          localStorage.setItem("submenu", res.data.data.submenu ? JSON.stringify(res.data.data.submenu) : '');
          localStorage.setItem("languages", res.data.data.languages ? JSON.stringify(res.data.data.languages) : '');
          this.settings = res.data.data.settings ? res.data.data.settings : [];
          localStorage.setItem("header", this.settings.header ? this.settings.header : 'Header1');
          localStorage.setItem("footer", this.settings.footer ? this.settings.footer : 'Footer1');
          localStorage.setItem("webiste_id", this.settings.id ? this.settings.id : '5');
          this.getWebsiteElements();
          this.addAnalytics();
        })
        .catch(res => {
          console.log(res);
        });
    },
    getLanguage: function () {
      if (localStorage.getItem("locale")) {
        this.$i18n.locale = localStorage.getItem("locale");
      } else {
        localStorage.setItem("locale", this.$i18n.locale);
        localStorage.setItem("lang_code", 'en_US');
        localStorage.setItem("lang", 'en');
      }
    },
    getWebsiteElements() {
      this.body = [];
      this.isLoading = true;
      axios.get('https://api.cnphub.com/public/website')
        .then(res => {
          // this.body = res.data.data ? res.data.data : [];
          this.body = res.data.data.elements ? res.data.data.elements : [];
          localStorage.setItem("body", res.data.data.elements ? JSON.stringify(res.data.data.elements) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
    getLang: function(){
      return localStorage.getItem("locale");
    },
    getMenu() {
      axios.get('https://api.cnphub.com/public/menu')
        .then(res => {
          this.mainMenu = res.data.data.mainMenu ? res.data.data.mainMenu : [];
          this.footerMenu = res.data.data.footerMenu ? res.data.data.footerMenu : [];
          localStorage.setItem("mainMenu", res.data.data.mainMenu ? JSON.stringify(res.data.data.mainMenu) : '');
          localStorage.setItem("footerMenu", res.data.data.footerMenu ? JSON.stringify(res.data.data.footerMenu) : '');
        })
        .catch(res => {
          console.log(res);
        });
    },
  },
};
</script>

<style></style>
