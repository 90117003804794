<template>
  <div class="news-List-2-col  theme7">
    <div class="container">
      <div class="tab-pane fade show active" id="feature-cat-1">
       
        <div class="row">

          <div v-if="topNewsSection" :class=" ' col-md-2 mb-20 main-articles top-news-sidebar'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span>{{ $t('Top News') }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in topNews" :key="index">
              <div class=" col-md-12  col-xs-12">
                <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-12 col-xs-12">
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <h4><a :href="'/article/' + post.slug">
                    {{ post.name }}</a></h4>
                
                
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>
          </div>
          <!-- Post Wrapper Start -->
          <div :class="colClassSection + 'mb-20 main-articles'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span class="word-1">{{ cat1name }}</span><span class="word-2">{{ $t('News') }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat1Posts" :key="index">
              <div class=" col-md-4  ">
                <img alt="blog" :width="200" :height="200" v-lazy="`${getImage(post.image,'medium')}`"
						:src="getImage(post.image)" lazy="loaded" class="fade-in fadeIn"/>
                <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-8 col-xs-12">
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <h4><a :href="'/article/' + post.slug">
                    {{ getPostDescription(post.name,99) }}</a></h4>
                
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>


          </div><!-- Post Wrapper End -->

          <div :class="colClassSection + 'mb-20 main-articles'">
            <div class="pb-5 pb-md-0">
              <h4 class="section-sub-title"><span class="word-1">{{ cat2name }}</span><span class="word-2">{{ $t('News') }}</span></h4>
            </div>
            <div class="story-short article row" v-for="(post, index) in cat2Posts" :key="index">
              <div class=" col-md-4  ">
                <img alt="blog"  v-lazy="`${getImage(post.image,'medium')}`"
						:src="getImage(post.image)" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" />
            <!-- <img v-if="post.image" width="100%" :src="getImage(post.image, 'medium')">
                <img v-else width="100%" :src="getImage('banners/image.jpg', 'medium')"> -->
              </div>
              <div class=" col-md-8 col-xs-12">
                <div class="time">{{ getPostDate(post.created_at) }} </div>
                <h4><a :href="'/article/' + post.slug">
                    {{ getPostDescription(post.name,99) }}</a></h4>
                
                <p style="padding-left:0;margin:5px;;line-height:18px;text-align:left;" v-html="getPostDescription(post.content)"></p><br>
                <!-- <a class="reding-btn" href="story-z18192681">{{ $t("Read More") }}</a> -->
              </div>
            </div>


          </div><!-- Post Wrapper End -->
          

       
        </div>

      </div>
    </div>
  </div>
</template>

<script>




export default {
  components: {},
  props: {

    cat1Posts: Array,
    cat2Posts: Array,
    topNews: Array,
    cat1name: String,
    cat2name: String,

  },

  data: () => ({
    settings: null,
    colClass: 'col-md-6  col-xs-12',

  }),


  mounted() {


  },
  computed: {
    colClassSection: {
      // getter
      get() {
        return this.topNews.length > 0 ?'col-md-5  col-xs-12':'col-md-6  col-xs-12'
      },
    },
    topNewsSection: {
      // getter
      get() {
        return this.topNews.length > 0 ? 1 : 0
      },
    }
  },
  created() {

   
    // if (this.topNews.length > 0) {
    //   setTimeout(() => {
    //     this.colClass = 'col-md-4  col-xs-12';
    //   }, 1500);
      
    // }
    // console.log(this.topNews);
    // console.log(this.topNews.length);
  },

  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
    getPostDate(date) {
      return this.$postHelper.getPostDate(date);
    },
    getPostDescription(text,length=150) {
      return this.$postHelper.getPostSmallDescription(text,length);
    },

  },
};
</script>

<style>
.top-news-sidebar {

}
#app .theme7 .section-sub-title span {
    background-color: transparent;
    padding: 10px 15px;
    color: #000;
}

#app .theme7  .top-news-sidebar h4 a{
  font-size: 1.62rem;
    line-height: 1.35;
}
.news-List-2-col.theme7 .story-short h4 {
  min-height: 50px;
}
#app .theme7   .top-news-sidebar .article {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #f2f2f2;
}
#app .theme7  h4.section-sub-title {
    font-size: 22px!important;
    color: #555;
    
    padding: 0px;
    border-left: 0px solid var(--color);
    background: #fff;
}


#app .theme7  .section-sub-title span.word-1 {
    background-color: var(--secondary-color);
}
#app .theme7  .section-sub-title span.word-2 {
    background-color: var(--color);
    padding: 5px 10px;
    color:#fff;
}
#app .theme7  .section-sub-title span.word-1,  .section-sub-title span.word-2 {
    color: #fff;
    padding: 5px 10px;
    font-weight: 500;
}
.news-List-2-col.theme7 .main-articles{
border-left:2px solid #f2f2f2;;
}
</style>