<template>
	<div class="product-default left-details product-widget" :class="mb - 2">
		<figure>
			<router-link :to="'/article/' + product.slug + ''">
				<template v-if="isLazy">
					<!-- <img alt="blog" :width="450" :height="230" v-lazy="`${getImage(cat4[0].image)}`"
						:src="getImage(cat4[0].image)" lazy="loaded" class="fade-in fadeIn"
						style="animation-duration: 0.3s; animation-delay: 0s" /> -->
					<img :v-lazy="`${baseUrl}${item.url}`" v-for="(item, index) in product.small_pictures.slice(0, 2)"
						:key="'small-product' + index" :width="item.width" :height="item.height"
						alt="small-product-image" />
				</template>

				<template v-else>
					<img :src="`${baseUrl}${item.url}`" v-for="(item, index) in product.small_pictures.slice(0, 2)"
						:key="'small-product' + index" :width="item.width" :height="item.height"
						alt="small-product-image" />
				</template>
			</router-link>
		</figure>

		<div class="product-details">
			<div class="category-list" v-if="isCat">
				<span v-for="(cat, index) in product.product_categories" :key="`product-category-${index}`">
					<router-link :to="{ path: '/shop', query: { category: cat.slug } }">{{ cat.name
					}}</router-link><template v-if="index < product.product_categories.length - 1">, </template>
				</span>
			</div>

			<h2 class="product-title">
				<router-link :to="'/article/' + product.slug + ''">{{ product.name }}</router-link>
			</h2>

		</div>
	</div>
</template>

<script>
import { baseUrl } from '@/api/index';

export default {
	props: {
		product: Object,
		isLazy: {
			type: Boolean,
			default: true
		},
		isCat: {
			type: Boolean,
			default: false
		},
	},
	data: function () {
		return {
			baseUrl: baseUrl,
			minPrice: 0,
			maxPrice: 0
		};
	},
	mounted: function () {
	},
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
	}
};
</script>