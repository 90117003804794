<template>
	<header class="header" :style="HeaderCssVars">
		
		<div class="header-middle sticky-header">
			<div class="container">
				<div class="header-left pl-0">
					<pv-main-menu position="left"></pv-main-menu>
				</div>

				<div class="header-center ml-lg-auto ml-0">
					<button
						class="mobile-menu-toggler mr-2"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>

					<router-link
						to="/"
						class="logo"
					>
						<img
							:src="settings.logo"
							width="101"
							height="40"
							alt="News Logo"
						/>
					</router-link>
					<div class="header-dropdown  dropdown-language  mr-auto mr-sm-3 mr-md-0">
							<div v-for="(language, index) in languages" :key="index">
								<a href="javascript:;" v-if="language.lang_locale == $i18n.locale">
									<!-- <i class="flag-us flag"></i>{{$i18n.locale}} </a> -->
									<i :class="'flag-' + language.lang_flag + ' flag'"></i>{{ language.lang_name }} </a>
							</div>
							<div class="header-menu">
								<ul>
									<li v-for="(language, index) in languages" :key="index" @click="selectLang(language)">

										<a href="javascript:;">
											<i :class="'flag-' + language.lang_flag + ' flag mr-2'"></i>{{
												language.lang_name
											}}</a>
									</li>
								</ul>
							</div>
						</div>
				</div>

				<div class="header-right">
					<pv-main-menu position="right"></pv-main-menu>
				</div>
			</div>
		</div>

		<!-- <div class="container">
			<div class="header-bottom w-100">
				<h4 class="mb-0 text-center pr-3 pl-3">Get 10% OFF at the Porto Kitchen Selection - <router-link to="/shop">Shop
						Now!</router-link>
				</h4>
			</div>
		</div> -->
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';

document.querySelector( 'body' ).classList.add( 'loaded' );

export default {
	components: {
		PvMainMenu,
	},
	computed: {
		HeaderCssVars() {
			return {
				'background-color': this.settings.header_color,
			}
		},
		TopHeaderCssVars() {
			return {
				'background-color': this.settings.secondary_color,
			}
		},
	},
	data: function () {
		return {
			settings: '',
			languages: '',
		};
	},
	mounted() {
		this.getWebsiteSettings();
	},
	methods: {
		getWebsiteSettings() {
			this.settings = [];
			this.settings = JSON.parse(localStorage.getItem("settings"));
			this.languages = JSON.parse(localStorage.getItem("languages"));
		},
		showMobileMenu: function () {
			document.querySelector( 'body' ).classList.add( 'mmenu-active' );
		},
		showMobileSearch: function ( e ) {
			let headerSearch = e.currentTarget.closest( '.header-search' );
			headerSearch.classList.add( 'show' );
			headerSearch
				.querySelector( '.header-search-wrapper' )
				.classList.add( 'show' );
		},
		selectLang: function (language) {
			this.$i18n.locale = language.lang_locale
			localStorage.setItem("lang_code", language.lang_code);
			window.location.reload();
		},
	}
};
</script>

<style>
.header-middle .header-right {
    padding-right: 0rem !important;
}

</style>