<template>
	<header class="header">
		<div class="header-top">
			<div class="header-row container">
				<div class="header-left">
					<div class="header-dropdown">
						<a
							href="javascript:;"
							class="pl-0"
						>USD</a>
						<div class="header-menu">
							<ul>
								<li>
									<a href="javascript:;">EUR</a>
								</li>
								<li>
									<a href="javascript:;">USD</a>
								</li>
							</ul>
						</div>
					</div>

					<div class="header-dropdown mr-auto mr-sm-3 mr-md-0">
						<a href="javascript:;">
							<i class="flag-us flag"></i>ENG</a>

						<div class="header-menu">
							<ul>
								<li>
									<a href="javascript:;">
										<i class="flag-us flag mr-2"></i>ENG</a>
								</li>
								<li>
									<a href="javascript:;">
										<i class="flag-fr flag mr-2"></i>FRA</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div class="header-right">
					<div class="wel-msg text-uppercase d-none d-lg-block">FREE Returns. Standard Shipping Orders $99+</div>

					<span class="separator d-none d-xl-block"></span>

					<ul class="top-links mega-menu show-arrow d-none d-sm-inline-block">
						<li class="menu-item narrow">
							<router-link to="/pages/account">My Account</router-link>
						</li>
						<li class="menu-item narrow">
							<router-link to="/pages/about-us">{{ $t("About Us") }}</router-link>
						</li>
						<li class="menu-item narrow">
							<router-link to="/pages/blog">Blog</router-link>
						</li>
						<li class="menu-item narrow">
							<router-link to="/pages/cart">Cart</router-link>
						</li>
						<li class="menu-item">
							<router-link
								to="/pages/login"
								class="login-link"
							>Log In</router-link>
						</li>
					</ul>

					<span class="separator d-none d-xl-block"></span>

					<div class="share-links d-none d-xl-block">
						<a
							href="javascript:;"
							class="social-icon social-facebook icon-facebook"
							
							rel="nofollow"
						></a>
						<a
							href="javascript:;"
							class="social-icon social-twitter icon-twitter"
							
							rel="nofollow"
						></a>
						<a
							href="javascript:;"
							class="social-icon social-instagram icon-instagram"
							
							rel="nofollow"
						></a>
					</div>
				</div>
			</div>
		</div>

		<div class="header-middle sticky-header mobile-sticky">
			<div class="container">
				<div class="header-left d-lg-block d-none">
					<div class="header-contact d-none d-lg-flex align-items-center pl-1 mr-lg-5 pr-xl-2">
						<i class="icon-phone-2"></i>
						<h6>{{ $t('Call us now') }}<a
								href="tel:#"
								class="text-dark font1"
							>+123 5678 890</a>
						</h6>
					</div>
				</div>

				<div class="header-center">
					<button
						class="mobile-menu-toggler"
						type="button"
						@click="showMobileMenu"
					>
						<i class="fas fa-bars"></i>
					</button>

					<router-link
						to="/"
						class="logo"
					>
						<img
							src="@/assets/css/images/home/logo_red.png"
							width="104"
							height="41"
							alt="Porto Logo"
						/>
					</router-link>
				</div>

				<div class="header-right">

					<router-link
						to="/pages/wishlist"
						class="header-icon"
						title="wishlist"
					>
						<i class="icon-wishlist-2"></i>
					</router-link>

					<pv-header-search></pv-header-search>

					<pv-cart-menu></pv-cart-menu>
				</div>
			</div>
		</div>

		<div class="header-bottom sticky-header desktop-sticky d-none d-lg-flex">
			<div class="container">
				<pv-main-menu></pv-main-menu>
			</div>
		</div>
	</header>
</template>

<script>
import PvMainMenu from './PvMainMenu';
import PvCartMenu from './PvCartMenu';
import PvHeaderSearch from './PvHeaderSearch';

document.querySelector('body').classList.add('loaded');

export default {
	components: {
		PvMainMenu,
		PvCartMenu,
		PvHeaderSearch
	},
	data: function() {
		return {
			isHome: {
				type: Boolean,
				default: true
			},
			isFullwidth: {
				type: Boolean,
				default: false
			}
		};
	},
	watch: {
		$route: function() {
			if (this.$route.path.includes('fullwidth')) {
				this.isFullwidth = true;
			} else this.isFullwidth = false;
		}
	},
	mounted: function() {
		if (this.$route.path.includes('fullwidth')) {
			this.isFullwidth = true;
		} else this.isFullwidth = false;
	},
	methods: {
		showMobileMenu: function() {
			document.querySelector('body').classList.add('mmenu-active');
		},
		showMobileSearch: function(e) {
			let headerSearch = e.currentTarget.closest('.header-search');
			headerSearch.classList.add('show');
			headerSearch
				.querySelector('.header-search-wrapper')
				.classList.add('show');
		}
	}
};
</script>