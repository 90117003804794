<template>
  <div>
    <section
      class="section section-secondary border-0 py-0 m-0 appear-animation animated fadeIn appear-animation-visible pt-2 pb-2 mt-2 mb-2"
      data-appear-animation="fadeIn"
      style="animation-delay: 100ms"
    >
      <div class="container black-background">
        <div
          class="row align-items-center justify-content-center justify-content-lg-between pb-5 pb-lg-0"
        >
          <div
            class="col-lg-5 order-2 order-lg-1 pt-4 pt-lg-0 pb-5 pb-lg-0 mt-5 mt-lg-0 appear-animation animated fadeInRightShorter appear-animation-visible"
            data-appear-animation="fadeInRightShorter"
            data-appear-animation-delay="200"
            style="animation-delay: 200ms"
          >
            <h2 class="font-weight-bold text-color-light text-7 mb-2">              
              {{ $t("Who We Are") }}
            </h2>
            <p class="lead font-weight-light text-color-light text-4 " v-html="settings.about_text">
            </p>
            <a href="/contact" class="btn btn-dark-scale-2 btn-px-5 btn-py-2 text-2"
              >
              {{ $t("Contact Us") }}
              </a
            >
          </div>
          <div class="col-9 offset-lg-1 col-lg-5 order-1 order-lg-2 scale-2">
            <img
              class="img-fluid box-shadow-3 my-2 border-radius"
              :src="getImage(settings.about_image ,'default')"
              alt="About us"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data: function () {
    return {
      settings: [],
    };
  },

  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    getImage(url, size) {
      return this.$helpers.getImageFullPath(url, size);
    },
  },
};
</script>

<style>
html .btn-dark-scale-2 {
    background-color: #0a0c0d;
    border-color: #0a0c0d #0a0c0d #000;
    color: #fff;
}

html section.section-secondary {
    background-color: #212529!important;
    border-color: #16181b!important;
}

</style>