<template>
  <div class="main">
    <div class="container py-5 my-3">
      <div class="row">
        <div class="col-lg-9">
          <div class="card custom-card-info bg-color-quaternary border-0 mb-4">
            <div class="card-body bg-transparent p-relative p-4 m-2 z-index-1">
              <h3
                class="text-color-dark font-weight-semibold text-5 d-block mt-1 mb-2"
              >
              {{ $t("Contact Us") }}
              </h3>

              <p v-html="settings.contact_text"></p>
              <form
                class="contact-form form-style-3"
                novalidate="novalidate"
              >
                <div
                  class="contact-form-success alert alert-success mt-4"
                  v-if="success"
                >
                  <strong>{{ $t("Success!") }}</strong> {{ $t("Your message has been sent to us.") }}

                </div>

                <div
                  class="contact-form-error alert alert-danger d-none mt-4"
                  v-if="error"
                >
                <strong>{{ $t("Error!") }}</strong> {{ $t("There was an error sending your message.") }}
                  <span class="mail-error-message text-1 d-block"></span>
                </div>

                <div class="contact-form-error alert alert-danger mt-4" v-if="errors.length">
                    <strong>{{ $t("Error!") }}</strong> 
                    <ul>
                      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                    </ul>
                    <span class="mail-error-message text-1 d-block"></span>
                  </div>
                  
                <div class="row">
                  <div class="form-group mb-2">
                    <input
                      type="text"
                      value=""
                      data-msg-required="Please enter your name."
                      maxlength="100"
                      v-model="formData.name"
                      class="form-control bg-color-light box-shadow-none border-0"
                      name="name"
                      id="name"
                      required=""
                      :placeholder="$t('Your Name')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-2">
                    <input
                      type="email"
                      value=""
                      v-model="formData.email"
                      data-msg-required="Please enter your email address."
                      data-msg-email="Please enter a valid email address."
                      maxlength="100"
                      class="form-control bg-color-light box-shadow-none border-0"
                      name="email"
                      id="email"
                      required=""
                      :placeholder="$t('E-mail Address')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-2">
                    <input
                      type="text"
                      value=""
                      v-model="formData.phone"
                      data-msg-required="Please enter your phone number."
                      maxlength="100"
                      class="form-control bg-color-light box-shadow-none border-0"
                      name="phone"
                      id="phone"
                      required=""
                      :placeholder="$t('Phone Number')"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-2">
                    <textarea
                      maxlength="5000"
                      v-model="formData.message"
                      data-msg-required="Please enter your message."
                      rows="13"
                      class="form-control bg-color-light box-shadow-none border-0"
                      name="message"
                      id="message"
                      required=""
                      :placeholder="$t('Message')"
                    ></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group mb-0">
                    <div class="d-grid gap-2">
                      <button
                        v-on:click="checkForm"
                        class="btn btn-secondary font-weight-semibold border-0 p-relative text-2 text-uppercase mt-1 btn-px-4 btn-py-2 mb-2"
                      >
                      {{ $t("Send a Message") }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <hr class="solid my-5" />
        </div>
        <div class="col-lg-3">
          <div class="row">
            <div class="col-12">
              <h3 class="mt-2 font-weight-semibold text-5">{{ $t("Email") }}</h3>
              <p class="color">
                <a :href="'mailto:' + settings.email">{{ settings.email }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import axios from "axios";

export default {
  components: {},
  props: {},

  data: function () {
    return {
      settings: [],
      errors: [],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      },
      loading: false,
      success: false,
      error: false,
    };
  },
  mounted() {
    this.settings = JSON.parse(localStorage.getItem("settings"));
  },
  computed: {},
  methods: {
    checkForm: function (e) {
      this.errors = [];

      if (!this.formData.name) {
        this.errors.push(this.$t('Name required.'));
      }
      if (!this.formData.email) {
        this.errors.push(this.$t('Email required.'));
      } else if (!this.validEmail(this.formData.email)) {
        this.errors.push(this.$t('Valid email required.'));
      }

      if (!this.errors.length) {
        // return true;
        this.submit();
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    submit() {
      (this.loading = true),
        axios
          .post(this.settings.BaseUrl + "/public/contact", this.formData)
          .then((res) => {
            this.loading = false;
            this.success = true;
            // this.response = res.data.data ? res.data.data : [];
          })
          .catch((res) => {
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
            // this.success = false;
            // this.error = false;
          });
    },
  },
};
</script>
  
<style>
.color a {
  color: var(--color) !important;
}

html .bg-color-quaternary,
html .bg-quaternary {
  background-color: #f4f4f4 !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.form-control.bg-light,
.form-control.bg-color-light {
  color: #777;
  border-color: #fff;
}

.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 13.6px;
  font-size: 1.2rem;
  line-height: 1.85;
}

.form-style-3 .form-control {
  padding: 11.2px 16px;
  padding: 0.7rem 1rem;
  background-color: #f4f4f4;
  border: none;
}

.form-group {
  width: 100%;
}

.btn-secondary {
  border-color: var(--color) !important;
  background-color: var(--color) !important;
  color: #fff;
  box-shadow: none;
}
</style>