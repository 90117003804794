<template>
	<article class="post">
		<figure
			class="post-media">
			<router-link
				:to="`/article/${post.slug}`" >
				<img
					v-if="post.image"
					:src="getImage(post.image, 'medium')"
					:alt="post.name"
					:width="200"
					:height="200"
				/>
				<img v-else
					:src="getImage('banners/image.jpg' , 'default')"
					:alt="post.name"/>
			</router-link>

			<div class="post-date">
				<span class="day">{{ this.$postHelper.getPostDay(post.created_at) }}</span>
				<span class="month">{{ this.$postHelper.getPostMonth(post.created_at) }}</span>
			</div>
		</figure>

		<div class="post-body">
			<h2 class="post-title">
				<router-link :to="'/article/' + post.slug">{{ this.$postHelper.getPostSmallDescription(post.name, 80) }}</router-link>
			</h2>

			<div class="post-content">
				<p v-html="this.$postHelper.getPostSmallDescription(post.content, 250)"></p>
			</div>

		</div>
	</article>
</template>
<script>

export default {
	components: {
	},
	props: {
		post: Object,
	},
	data: function() {
		return {
			settings:[]
		};
	},
	computed: {
		date: function() {
			return new Date(this.post.created_at);
		}
	},
	mounted(){
		this.settings = JSON.parse(localStorage.getItem("settings"));
	},
	methods: {
		getImage(url, size) {
			return this.$helpers.getImageFullPath(url, size);
		},
	}
};
</script>